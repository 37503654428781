/* eslint-disable no-shadow */
import React, {
// useState
} from 'react'
import { Trans } from '@lingui/react'

// import { useStateValue } from '../../../stores/contextStore/stateStore'
import {
// FilterOrderByCode,
// FilterOrderByCustomer,
// FilterOrderByLogistic,
// FilterOrderByProvider,
// FilterOrderByStatus,
// FilterOrderByDate,
} from '../../_Hub/OrdersSectionPage/components'
import {
// SliderArrowIcon,
// FilterIcon
} from '../../../icons'

import './TableHubOrderHeader.css'

const translations = {
	code: <Trans id='TableHubOrderHeader.code' />,
	totalPrice: <Trans id='TableHubOrderHeader.totalPrice' />,
	customer: <Trans id='TableHubOrderHeader.customer' />,
	status: <Trans id='TableHubOrderHeader.status' />,
	logistic: <Trans id='TableHubOrderHeader.logistic' />,
	address: <Trans id='TableHubOrderHeader.Address' />,
	date: <Trans id='TableHubOrderHeader.date' />,
	driver: <Trans id='TableHubOrderHeader.driver' />
}

const TableHubOrderHeader = React.memo( () =>
// const [ { displayCodeFilter }, openCodeFilter ] = useStateValue()
// const [ { displayProviderFilter }, openProviderFilter ] = useStateValue()
/* const [ { displayCustomerFilter }, openCustomerFilter ] = useStateValue()
	const [ { displayStatusFilter }, openStatusFilter ] = useStateValue()
	const [ { displayLogisticFilter }, openLogisticFilter ] = useStateValue()
	const [ { displayDateFilter }, openDateFilter ] = useStateValue()
	const [ code, sortByCode ] = useState( false )
	const [ totalPrice, sortByTotalPrice ] = useState( false )
	const [ customer, sortByCustomer ] = useState( false )
	const [ status, sortByStatus ] = useState( false )
	const [ logistic, sortByLogistic ] = useState( false )
	const [ date, sortByDate ] = useState( false ) */

/* function filterCodeFunct( event ) {
		// based on https://codepen.io/StrengthandFreedom/pen/LeyjXG/

		document.addEventListener( 'click', ( event ) => {
			if ( event.target.closest( '.filter-icon' ) ) return
			openCodeFilter( { type: 'displayCodeFilter', displayCodeFilter: false } )
		} )

		if ( event.target.id === 'codeFilter' && displayCodeFilter ) {
			openCodeFilter( { type: 'displayCodeFilter', displayCodeFilter: false } )
		}

		if ( event.target.id === 'codeFilter' && !displayCodeFilter ) {
			openCodeFilter( { type: 'displayCodeFilter', displayCodeFilter: true } )
		}
	}

	/* function filterProviderFunct( event ) {
		document.addEventListener( 'click', ( event ) => {
			if ( event.target.closest( '.filter-icon' ) ) return
			openProviderFilter( { type: 'displayProviderFilter', displayProviderFilter: false } )
		} )

		if ( event.target.id === 'providerFilter' && displayProviderFilter ) {
			openProviderFilter( { type: 'displayProviderFilter', displayProviderFilter: false } )
		}
		if ( event.target.id === 'providerFilter' && !displayProviderFilter ) {
			openProviderFilter( { type: 'displayProviderFilter', displayProviderFilter: true } )
		}
	} */

/* function filterCustomerFunct( event ) {
		document.addEventListener( 'click', ( event ) => {
			if ( event.target.closest( '.filter-icon' ) ) return
			openCustomerFilter( { type: 'displayCustomerFilter', displayCustomerFilter: false } )
		} )

		if ( event.target.id === 'customerFilter' && displayCustomerFilter ) {
			openCustomerFilter( { type: 'displayCustomerFilter', displayCustomerFilter: false } )
		}
		if ( event.target.id === 'customerFilter' && !displayCustomerFilter ) {
			openCustomerFilter( { type: 'displayCustomerFilter', displayCustomerFilter: true } )
		}
	}

	function filterStatusFunct( event ) {
		document.addEventListener( 'click', ( event ) => {
			if ( event.target.closest( '.filter-icon' ) ) return
			openStatusFilter( { type: 'displayStatusFilter', displayStatusFilter: false } )
		} )

		if ( event.target.id === 'statusFilter' && displayStatusFilter ) {
			openStatusFilter( { type: 'displayStatusFilter', displayStatusFilter: false } )
		}
		if ( event.target.id === 'statusFilter' && !displayStatusFilter ) {
			openStatusFilter( { type: 'displayStatusFilter', displayStatusFilter: true } )
		}
	}

	function filterLogisticFunct( event ) {
		document.addEventListener( 'click', ( event ) => {
			if ( event.target.closest( '.filter-icon' ) ) return
			openLogisticFilter( { type: 'displayLogisticFilter', displayLogisticFilter: false } )
		} )

		if ( event.target.id === 'logisticFilter' && displayLogisticFilter ) {
			openLogisticFilter( { type: 'displayLogisticFilter', displayLogisticFilter: false } )
		}
		if ( event.target.id === 'logisticFilter' && !displayLogisticFilter ) {
			openLogisticFilter( { type: 'displayLogisticFilter', displayLogisticFilter: true } )
		}
	}

	function filterDateFunct( event ) {
		document.addEventListener( 'click', ( event ) => {
			if ( event.target.closest( '.filter-icon' ) ) return
			openDateFilter( { type: 'displayDateFilter', displayDateFilter: false } )
		} )

		if ( event.target.id === 'dateFilter' && displayDateFilter ) {
			openDateFilter( { type: 'displayDateFilter', displayDateFilter: false } )
		}
		if ( event.target.id === 'dateFilter' && !displayDateFilter ) {
			openDateFilter( { type: 'displayDateFilter', displayDateFilter: true } )
		}
	}

	function codeFunct() {
		if ( code ) {
			sortByCode( false )
		} else {
			sortByCode( true )
		}
		sortByTotalPrice( false )
		sortByCustomer( false )
		sortByStatus( false )
		sortByLogistic( false )
		sortByDate( false )
	}

	function totalPriceFunct() {
		if ( totalPrice ) {
			sortByTotalPrice( false )
		} else {
			sortByTotalPrice( true )
		}
		sortByCode( false )
		sortByCustomer( false )
		sortByStatus( false )
		sortByLogistic( false )
		sortByDate( false )
	}

	function customerFunct() {
		if ( customer ) {
			sortByCustomer( false )
		} else {
			sortByCustomer( true )
		}
		sortByCode( false )
		sortByTotalPrice( false )
		sortByStatus( false )
		sortByLogistic( false )
		sortByDate( false )
	}

	function statusFunct() {
		if ( status ) {
			sortByStatus( false )
		} else {
			sortByStatus( true )
		}
		sortByCode( false )
		sortByTotalPrice( false )
		sortByCustomer( false )
		sortByLogistic( false )
		sortByDate( false )
	}

	function logisticFunct() {
		if ( logistic ) {
			sortByLogistic( false )
		} else {
			sortByLogistic( true )
		}
		sortByCode( false )
		sortByTotalPrice( false )
		sortByCustomer( false )
		sortByStatus( false )
		sortByDate( false )
	}

	function dateFunct() {
		if ( date ) {
			sortByDate( false )
		} else {
			sortByDate( true )
		}
		sortByCode( false )
		sortByTotalPrice( false )
		sortByCustomer( false )
		sortByStatus( false )
		sortByLogistic( false )
	} */

	// eslint-disable-next-line implicit-arrow-linebreak
	(
		<div className='TableHubOrderHeader-container'>
			<div
				className='code'
			>
				<span
					className='code-text'
					role='button'
					tabIndex={ -1 }
					// onClick={ () => codeFunct() }
					// onKeyPress={ () => codeFunct() }
				>
					{ translations.code }
				</span>
				{/* <span
					className={ code ? 'sort-arrow-up' : 'sort-arrow-down' }
					role='button'
					tabIndex={ -1 }
					onClick={ () => codeFunct() }
					onKeyPress={ () => codeFunct() }
				>
					<SliderArrowIcon fontSize={ 12 } />
				</span>
				<span
					className='filter-icon'
				>
					<FilterIcon
						id='codeFilter'
						fontSize={ 32 }
						color={ displayCodeFilter ? '#f6ab29' : 'grey' }
						onClick={ ( e ) => filterCodeFunct( e ) }
						onKeyPress={ ( e ) => filterCodeFunct( e ) }
					/>
					<FilterOrderByCode visible={ displayCodeFilter } />
				</span> */}
			</div>
			<div
				className='provider'
			>
				<span
					className='totalPrice-text'
					role='button'
					tabIndex={ -1 }
					// onClick={ () => totalPriceFunct() }
					// onKeyPress={ () => totalPriceFunct() }
				>
					{ translations.totalPrice }
				</span>
				{/* <span
					className={ totalPrice ? 'sort-arrow-up' : 'sort-arrow-down' }
					role='button'
					tabIndex={ -1 }
					onClick={ () => totalPriceFunct() }
					onKeyPress={ () => totalPriceFunct() }
				>
					<SliderArrowIcon fontSize={ 12 } />
				</span>
				<span
					className='filter-icon'
				>
					<FilterIcon
						id='providerFilter'
						fontSize={ 32 }
						color={ displayProviderFilter ? '#f6ab29' : 'grey' }
						onClick={ ( e ) => filterProviderFunct( e ) }
						onKeyPress={ ( e ) => filterProviderFunct( e ) }
					/>
					<FilterOrderByProvider visible={ displayProviderFilter } />
				</span> */}
			</div>
			<div
				className='customer'
			>
				<span
					className='customer-text'
					role='button'
					tabIndex={ -1 }
					// onClick={ () => customerFunct() }
					// onKeyPress={ () => customerFunct() }
				>
					{ translations.customer }
				</span>
				{/* <span
					className={ customer ? 'sort-arrow-up' : 'sort-arrow-down' }
					role='button'
					tabIndex={ -1 }
					onClick={ () => customerFunct() }
					onKeyPress={ () => customerFunct() }
				>
					<SliderArrowIcon fontSize={ 12 } />
				</span>
				<span
					className='filter-icon'
				>
					<FilterIcon
						id='customerFilter'
						fontSize={ 32 }
						color={ displayCustomerFilter ? '#f6ab29' : 'grey' }
						onClick={ ( e ) => filterCustomerFunct( e ) }
						onKeyPress={ ( e ) => filterCustomerFunct( e ) }
					/>
					<FilterOrderByCustomer visible={ displayCustomerFilter } />
				</span> */}
			</div>
			<div
				className='status'
			>
				<span
					className='status-text'
					role='button'
					tabIndex={ -1 }
					// onClick={ () => statusFunct() }
					// onKeyPress={ () => statusFunct() }
				>
					{ translations.status }
				</span>
				{/* <span
					className={ status ? 'sort-arrow-up' : 'sort-arrow-down' }
					role='button'
					tabIndex={ -1 }
					onClick={ () => statusFunct() }
					onKeyPress={ () => statusFunct() }
				>
					<SliderArrowIcon fontSize={ 12 } />
				</span>
				<span
					className='filter-icon'
				>
					<FilterIcon
						id='statusFilter'
						fontSize={ 32 }
						color={ displayStatusFilter ? '#f6ab29' : 'grey' }
						onClick={ ( e ) => filterStatusFunct( e ) }
						onKeyPress={ ( e ) => filterStatusFunct( e ) }
					/>
					<FilterOrderByStatus visible={ displayStatusFilter } />
				</span> */}
			</div>
			<div
				className='logistic'
			>
				<span
					className='logistic-text'
					role='button'
					tabIndex={ -1 }
					// onClick={ () => logisticFunct() }
					// onKeyPress={ () => logisticFunct() }
				>
					{ translations.logistic }
				</span>
				{/* <span
					className={ logistic ? 'sort-arrow-up' : 'sort-arrow-down' }
					role='button'
					tabIndex={ -1 }
					onClick={ () => logisticFunct() }
					onKeyPress={ () => logisticFunct() }
				>
					<SliderArrowIcon fontSize={ 12 } />
				</span>
				<span
					className='filter-icon'
				>
					<FilterIcon
						id='logisticFilter'
						fontSize={ 32 }
						color={ displayLogisticFilter ? '#f6ab29' : 'grey' }
						onClick={ ( e ) => filterLogisticFunct( e ) }
						onKeyPress={ ( e ) => filterLogisticFunct( e ) }
					/>
					<FilterOrderByLogistic visible={ displayLogisticFilter } />
				</span> */}
			</div>
			<div
				className='orderAddress'
			>
				<span
					className='orderAddress-text'
					role='button'
					tabIndex={ -1 }
				>
					{ translations.address }
				</span>
			</div>
			<div
				className='date'
			>
				<span
					className='date-text'
					role='button'
					tabIndex={ -1 }
					// onClick={ () => dateFunct() }
					// onKeyPress={ () => dateFunct() }
				>
					{ translations.date }
				</span>
				{/* <span
					className={ date ? 'sort-arrow-up' : 'sort-arrow-down' }
					role='button'
					tabIndex={ -1 }
					onClick={ () => dateFunct() }
					onKeyPress={ () => dateFunct() }
				>
					<SliderArrowIcon fontSize={ 12 } />
				</span>
				<span
					className='filter-icon'
				>
					<FilterIcon
						id='dateFilter'
						fontSize={ 32 }
						color={ displayDateFilter ? '#f6ab29' : 'grey' }
						onClick={ ( e ) => filterDateFunct( e ) }
						onKeyPress={ ( e ) => filterDateFunct( e ) }
					/>
					<FilterOrderByDate visible={ displayDateFilter } />
				</span> */}
			</div>
			<div
				className='driver'
			>
				<span
					className='driver-text'
					role='button'
					tabIndex={ -1 }
				>
					{ translations.driver }
				</span>
			</div>
		</div>

	) )

export default TableHubOrderHeader
