import React from 'react'
import propTypes from 'prop-types'

const MarketIcon = ( { color, fontSize } ) => (
	<span className='icon-market-icon-15' style={ { fontSize: `${ fontSize }px`, color } }>
		<span className='path1' />
		<span className='path2' />
		<span className='path3' />
		<span className='path4' />
		<span className='path5' />
		<span className='path6' />
		<span className='path7' />
		<span className='path8' />
		<span className='path9' />
		<span className='path10' />
		<span className='path11' />
		<span className='path12' />
		<span className='path13' />
		<span className='path14' />
		<span className='path15' />
		<span className='path16' />
		<span className='path17' />
		<span className='path18' />
		<span className='path19' />
		<span className='path20' />
		<span className='path21' />
		<span className='path22' />
		<span className='path23' />
		<span className='path24' />
		<span className='path25' />
		<span className='path26' />
		<span className='path27' />
		<span className='path28' />
		<span className='path29' />
		<span className='path30' />
		<span className='path31' />
		<span className='path32' />
		<span className='path33' />
		<span className='path34' />
		<span className='path35' />
		<span className='path36' />
		<span className='path37' />
		<span className='path38' />
		<span className='path39' />
		<span className='path40' />
		<span className='path41' />
		<span className='path42' />
		<span className='path43' />
		<span className='path44' />
		<span className='path45' />
		<span className='path46' />
		<span className='path47' />
		<span className='path48' />
		<span className='path49' />
		<span className='path50' />
		<span className='path51' />
		<span className='path52' />
		<span className='path53' />
		<span className='path54' />
		<span className='path55' />
	</span>
)

MarketIcon.defaultProps = {
	color: 'black',
	fontSize: 18
}
MarketIcon.propTypes = {
	color: propTypes.string,
	fontSize: propTypes.number
}

export default MarketIcon
