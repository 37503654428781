import React from 'react'
import propTypes from 'prop-types'

import './NumberOfElements.css'

const NumberOfElements = React.memo( ( { numberOfNotifications } ) => {
	function setFontSize() {
		if ( numberOfNotifications < 10 ) {
			return 14
		} if ( numberOfNotifications < 100 ) {
			return 13
		}
		return 10
	}
	return (
		<div className='red-circle'>
			<span style={ { fontSize: `${ setFontSize() }px` } }>{ numberOfNotifications }</span>
		</div>
	)
} )

NumberOfElements.propTypes = {
	numberOfNotifications: propTypes.number.isRequired
}


export default NumberOfElements
