import React from 'react'
import propTypes from 'prop-types'

const ProfileIcon = ( { color, fontSize } ) => (
	<span className='icon-profile-account-01' style={ { fontSize: `${ fontSize }px`, color } } />
)

ProfileIcon.defaultProps = {
	color: 'black',
	fontSize: 18
}
ProfileIcon.propTypes = {
	color: propTypes.string,
	fontSize: propTypes.number
}

export default ProfileIcon
