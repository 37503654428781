import React, { useEffect } from 'react'
import gql from 'graphql-tag'
import propTypes from 'prop-types'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import uuid from 'uuid/v1'

import './MainPage.css'
import './defaultStyles.css' // import Variables
import Header from './components/Header'
import ProfileMenu from './components/ProfileMenu'
import CategoriesMenu from './components/CategoriesMenu'
import PrintOverlay from './components/_Globals/PrintOverlay'
import PublicRoutes from './routes/PublicRoutes'
import reducer from './stores/contextStore/storeReducer'
import { StateProvider } from './stores/contextStore/stateStore'


import {
	GET_ORDERS,
	UPDATE_ORDER_WITH_CUSTOMER_ID,
	UPDATE_CART_ITEM_WITH_ORDER_ID,
} from './MainPageGQL'


import GET_DELIVERY_ADDRESS from './components/ShoppingCart/AddressPage/addressPageGQL'

/* global sessionStorage localStorage */

const MainPage = ( { isAuthenticated } ) => {
	const initState = {
		isAuth: isAuthenticated,			//	Keep props of isAuthenticated. Default is false
		hubMenu: false,						//	Toggles	hub menu. Default is opened
		profileMenu: false,				 	//	Toggles right-side menu ( profile menu ). Default is closed
		isProvider: false,					//	Toggles between provider / product. Default is products
		notificationBoxMenu: false,		 	//	Toggles notification box. Default is closed
		shoppingListVisibility: false,		// 	Taggles the shopping list visibility
		selectedHub: null,					//	Hold value of selected HUB for shopping.
		selectedHubProducts: null,			//	Hold value of products of selected HUB for shopping.
		getProviderInfo: null, 				//	Holds detail information about provider.
		isProductSelected: null,			// Holds detail information about product,
		selectedStand: null,				// Hold data of selected stand
		displayCodeFilter: false,			// Hold value if filter is displayed
		displayCustomerFilter: false,		// Hold value if filter is displayed
		displayStatusFilter: false,			// Hold value if filter is displayed
		displayLogisticFilter: false,		// Hold value if filter is displayed
		displayDateFilter: false,			// Hold value if filter is displayed
		deafultQuantities: [],				// Hold default quantities for new product
		buyerType: false,					// Hold default value if buyer registration page is displayed
		providerType: false,				// Hold default value if provider registration page is displayed
		dataForMutationProduct: null,		// Hold deafult falue for updating data of a product
		fatalError: null,					// Holds a default value if there is no fatal error in app
		printOverlayMenu: false,				//  Toggles printOverlay component
		myOfferListVisibility: false,		// Toggles MyOffers filter visibility
	}

	const tenantId = sessionStorage.getItem( 'uhub_tenantId' )
	const tempUUID = localStorage.getItem( 'temp-cart-id' )
	let uuidValue = sessionStorage.getItem( 'uuid' )

	if ( tempUUID && tempUUID !== uuidValue ) {
		uuidValue = tempUUID
		sessionStorage.setItem( 'uuid', uuidValue )
		localStorage.removeItem( 'temp-cart-id' )
	}

	if ( !uuidValue ) {
		uuidValue = uuid()
		sessionStorage.setItem( 'uuid', uuidValue )
	}

	const [ updateCartItem ] = useMutation( UPDATE_CART_ITEM_WITH_ORDER_ID )
	const [ updateOrderWithCustomerId ] = useMutation( UPDATE_ORDER_WITH_CUSTOMER_ID, {
		refetchQueries: () => [ {
			query: gql`${ GET_DELIVERY_ADDRESS }`,
			variables: {
				customer_id: tenantId
			}
		} ]
	} )

	const [ getOrders ] = useLazyQuery( GET_ORDERS, {
		variables: {
			cart_uuid: uuidValue
		},
		onCompleted: ( data ) => {
			if ( data.cart_orders && data.cart_orders.length > 0 ) {
				data.cart_orders.forEach( ( order ) => {
					updateOrderWithCustomerId( {
						variables: {
							id: order.id,
							customer_id: tenantId
						}
					} )
					order.items.forEach( () => {
						updateCartItem( {
							variables: {
								order_id: order.id,
								customer_id: tenantId
							}
						} )
					} )
				} )
			}
		}
	} )

	// when user logged, update items in cart with user 'customer_id'
	useEffect( () => {
		if ( tenantId ) {
			getOrders()
		}
	}, [ getOrders, tenantId ] )

	return (
		<div className='main-container'>
			<StateProvider initState={ initState } reducer={ reducer }>
				<Header isAuthenticated={ isAuthenticated } />
				<CategoriesMenu isAuthenticated={ isAuthenticated } />
				<PrintOverlay />
				<div id='content' className='content'>
					<ProfileMenu />
					<PublicRoutes
						isAuthenticated={ isAuthenticated }
					/>
				</div>
			</StateProvider>
		</div>
	)
}

MainPage.propTypes = {
	isAuthenticated: propTypes.bool.isRequired
}

export default MainPage
