import React from 'react'
import propTypes from 'prop-types'

import './notifier.css'

const Notifier = ( { id, icon, message } ) => (
	<div id={ `Notifier_${ id }` } className='message-handler' style={ { bottom: `calc( 55px * ${ id })` } }>
		<div className='icon-item'>{ icon }</div>
		<div>{ message }</div>
	</div>
)

Notifier.defaultProps = {
	id: 0,
	icon: {},
	message: 'UNHANDLED_CODE'
}
Notifier.propTypes = {
	id: propTypes.number,
	icon: propTypes.object,
	message: propTypes.any
}

export default Notifier
