import React from 'react'
import propTypes from 'prop-types'

const DeleteIcon = ( { color, fontSize } ) => (
	<span className='icon-delete-icon' style={ { fontSize: `${ fontSize }px`, color } } />
)

DeleteIcon.defaultProps = {
	color: 'black',
	fontSize: 18
}
DeleteIcon.propTypes = {
	color: propTypes.string,
	fontSize: propTypes.number
}

export default DeleteIcon
