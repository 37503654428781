import React from 'react'
import propTypes from 'prop-types'

const StarIconBlue = ( { color, fontSize } ) => (
	<span className='icon-star-icon-blue-07' style={ { fontSize: `${ fontSize }px`, color } } />
)

StarIconBlue.defaultProps = {
	color: 'black',
	fontSize: 18
}
StarIconBlue.propTypes = {
	color: propTypes.string,
	fontSize: propTypes.number
}

export default StarIconBlue
