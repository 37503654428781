import gql from 'graphql-tag'

const CART_ORDERS_BASKET = gql`
subscription getCartOrders(
	$cart_uuid: uuid!
	$customer_id: String
){
	cart_items(
		where: {
			cart_uuid: { _eq: $cart_uuid },
			customer_id: { _eq: $customer_id },
			status_id: { _eq: 1 },
			order: {status_id: {_neq: 4 } } 
		},
		order_by: { id: asc } ) 
	{
    	id
    	hub_id
		bruto_price
		neto_price
		order {
			commission_fee
			id
		}
    	product {
      		id
			gs1_brick {
				id
				translation_sl
				unit {
					id
					unit
				}
				files {
					id
					file_id
					tenant_id
					public
				}
			}
			galleries {
				id
				file {
					id
					file_id
					tenant_id
				}
			}
			quantity
			created_at
			variety {
				id
				name
			}
		}
    	market {
			id
      		name
    	}
    	quantity
    	qr_code_id
    	order_id
		cart_uuid
  	}
}`

const DELETE_ITEM_FROM_CART = gql`
mutation deleteItemFromCart( 
	$id: bigint!
	$hub_id: bigint!
	$cart_uuid: uuid!
	$customer_id: String
) {
	delete_cart_items(
		where: {
			id: { _eq: $id },
			hub_id: { _eq: $hub_id },
			cart_uuid: { _eq: $cart_uuid },
			customer_id: { _eq: $customer_id },
	} ) {
    	affected_rows
  	}	
}`


// DO NOT REMOVE THIS MUTATION ON ITEMS!!!!!!
// IN THIS CASE FOR CONTROLLING IF DELETE BUTTON IS LOCKED
const SUBSCRIPTION_CART_ITEMS = gql`
subscription subscriptionQuantity(
	$cart_uuid: uuid!
	$customer_id: String
){
	cart_items(
		where: {
			cart_uuid: { _eq: $cart_uuid },
			customer_id: { _eq: $customer_id },
			status_id: {_eq: 1} 
		},
		order_by: { hub_id: asc } ) 
	{
    	id
    	hub_id
    	product {
      		id
			gs1_brick {
				id
				translation_sl
				unit {
					id
					unit
				}
				files {
					id
					file_id
					tenant_id
					public
				}
			}
			galleries {
				id
				file {
					id
					file_id
					tenant_id
				}
			}
			quantity
			bruto_price
			created_at
		}
		market {
			id
			name
		}
		quantity
		qr_code_id
		order_id
		cart_uuid
  	}
}`

const GET_SUM_TOTAL_BRUTO_PRICES = gql`
subscription getSumTotalBrutoPrices(
	$cart_uuid: uuid!
	$customer_id: String
) {
	cart_orders_aggregate( where: { cart_uuid: { _eq: $cart_uuid }, customer_id: { _eq: $customer_id }, status_id: { _in: [1, 3] } } ) {
		aggregate {
      		sum {
        		total_bruto_price
      		}
    	}
  	}
}`

const GET_SUM_TOTAL_NETO_PRICES = gql`
subscription getSumTotalNetoPrices(
	$cart_uuid: uuid!
	$customer_id: String
) {
	cart_orders_aggregate(where: { cart_uuid: { _eq: $cart_uuid}, customer_id: { _eq: $customer_id }, status_id: { _in: [1, 3] } } ) {
		aggregate {
      		sum {
        		total_neto_price
      		}
    	}
  	}
}`

export {
	CART_ORDERS_BASKET,
	DELETE_ITEM_FROM_CART,
	SUBSCRIPTION_CART_ITEMS,
	GET_SUM_TOTAL_BRUTO_PRICES,
	GET_SUM_TOTAL_NETO_PRICES
}
