
import React from 'react'
import propTypes from 'prop-types'

import './PdfRender.css'

const PdfCanvas = React.memo( ( { index } ) => (
	<div>
		<canvas id={ `pdf_page_${ ( index - 1 ) }` } />
	</div>
) )
const PdfRender = React.memo( ( { pdf } ) => {
	function renderPDF() {
		const pagePromises = []
		const canvasComponents = []
		for ( let i = 1; i <= pdf.numPages; i += 1 ) {
			pagePromises.push( pdf.getPage( i ) )
			canvasComponents.push(
				<PdfCanvas
					key={ `pdf_page_${ i - 1 }` }
					index={ i }
				/>
			)
		}

		Promise.all( pagePromises ).then( ( resolved ) => {
			resolved.forEach( ( page, index ) => {
				const viewport = page.getViewport( 1 )
				const canvas = document.getElementById(
					`pdf_page_${ index }`
				)
				if ( canvas ) {
					const context = canvas.getContext( '2d' )
					canvas.height = viewport.height
					canvas.width = viewport.width
					const renderContext = {
						canvasContext: context,
						viewport
					}
					page.render( renderContext )
				}
			} )
		} )

		return canvasComponents
	}
	return (
		<div id='pdf-render-container'>
			{ renderPDF() }
		</div>
	)
} )

PdfCanvas.propTypes = {
	index: propTypes.number.isRequired
}
PdfRender.propTypes = {
	pdf: propTypes.any.isRequired
}
export default PdfRender
