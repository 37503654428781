import gql from 'graphql-tag'

const GET_PRODUCTS = gql`
query getProducts(
	$marketFilters: [ bigint! ],
	$gs1Filters: [ Int! ],
	$limit: Int!,
	$offset: Int,
) {
	provider_market_has_product(
		limit: $limit,
		offset: $offset,
		order_by: { product: { created_at: desc } },
		where: {
			product: { disabled: { _eq: false },
			gs1_brick: { id: { _in:  $gs1Filters } }, 
			quantity: { _neq: 0 } },
			market_id: {_in: $marketFilters },
			market: { active: { _eq: true }, confirmed: { _eq: true } }
		} ) {
			id
			market_id
			product {
				id
				gs1_brick {
					id
					translation_sl
					files {
						id
						public
						file_id
						tenant_id
					}
					unit {
      					id
      					unit
    				}
				}
				description
				bruto_price
				neto_price
				quality
				quantity
				variety {
					id
					name
				}
				galleries (where: { default: { _eq: true } } ){
					id
					file {
						id
						file_id
						tenant_id
					}
				}
				tenant_id
			}
		market {
			id
			name
			hub_id
			description
			hub {
				id
				description
				tenant_id
			}
			provider {
				id
				average_mark
			}
		}
  	}
}`

const GET_PRODUCTS_SUBSCRIPTION = gql`
subscription getProductsSubscription(
	$marketFilters: [ bigint! ],
	$gs1Filters: [ Int! ],
	$limit: Int!,
) {
	provider_market_has_product(
		limit: $limit,
		order_by: { product: { created_at: desc } },
		where: {
			product: { disabled: { _eq: false },
			gs1_brick: { id: { _in:  $gs1Filters } }, 
			quantity: { _neq: 0 } },
			market_id: {_in: $marketFilters }
		} ) {
			id
			market_id
			product {
				id
				gs1_brick {
					id
					translation_sl
					files {
						id
						public
						file_id
						tenant_id
					}
					unit {
      					id
      					unit
    				}
				}
				description
				bruto_price
				neto_price
				quality
				quantity
				variety {
					id
					name
				}
				galleries (where: { default: { _eq: true } } ){
					id
					file {
						id
						file_id
						tenant_id
					}
				}
				tenant_id
			}
		market {
			id
			name
			hub_id
			description
			hub {
				id
				description
				tenant_id
			}
			provider {
				id
				average_mark
			}
		}
  	}
}`

const GET_ALL_DEFAULT_QUANTITIES = gql`
query {
  code_register_units_default_quantities {
	id
    unit_id
    quantity
  }
}`

export {
	GET_PRODUCTS,
	GET_PRODUCTS_SUBSCRIPTION,
	GET_ALL_DEFAULT_QUANTITIES
}
