import React from 'react'
import propTypes from 'prop-types'

const ConfirmIcon = ( { color, fontSize } ) => (
	<span className='icon-confirm-icon' style={ { fontSize: `${ fontSize }px`, color } } />
)

ConfirmIcon.defaultProps = {
	color: 'black',
	fontSize: 18
}
ConfirmIcon.propTypes = {
	color: propTypes.string,
	fontSize: propTypes.number
}

export default ConfirmIcon
