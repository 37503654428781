import React from 'react'
import propTypes from 'prop-types'
import './ToggleHubButton.css'

const ToggleHubButton = React.memo( ( { isHubMenuClosed, handleButtonClick } ) => (
	<div
		className='btn-hub'
		role='button'
		tabIndex={ 0 }
		onKeyPress={ () => handleButtonClick() }
		onClick={ () => handleButtonClick() }
	>
		<div id='white-arrow' className={ isHubMenuClosed ? 'white-arrow-right' : 'white-arrow-left' } />
	</div>

) )

ToggleHubButton.propTypes = {
	isHubMenuClosed: propTypes.bool.isRequired,
	handleButtonClick: propTypes.func.isRequired
}

export default ToggleHubButton
