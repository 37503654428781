import React, { useState, Suspense, lazy } from 'react'
import propTypes from 'prop-types'
import { useMutation, useSubscription } from '@apollo/react-hooks'
import { Trans } from '@lingui/macro'
import getPriceFormatter from '../../../utils/PriceFormatter'

import './BasketItem.css'
import { DeleteIcon } from '../../../icons'
import Button from '../../_Globals/Button/Button'
import Loader from '../../Loader'
import mainStore from '../../../stores/mainStore'

import {
	CART_ORDERS_BASKET,
	DELETE_ITEM_FROM_CART,
	GET_SUM_TOTAL_BRUTO_PRICES,
	GET_SUM_TOTAL_NETO_PRICES
} from './BasketItemGQL'

/* global sessionStorage */

const Image = lazy( () => import( './components/image' ) )

const BasketItem = ( { clickNext, customerId } ) => {
	// eslint-disable-next-line no-unused-vars
	const uuidValue = sessionStorage.getItem( 'uuid' )
	const [ lockCartButton, setLockCartButton ] = useState( false )
	const { language } = mainStore

	const {
		data: totalBrutoData,
		loading: totalBrutoLoading,
		error: totalBrutoError,
	} = useSubscription( GET_SUM_TOTAL_BRUTO_PRICES, {
		variables: {
			cart_uuid: uuidValue,
			customer_id: customerId
		}
	} )

	const {
		data: totalNetoData,
		loading: totalNetoLoading,
		error: totalNetoError,
	} = useSubscription( GET_SUM_TOTAL_NETO_PRICES, {
		variables: {
			cart_uuid: uuidValue,
			customer_id: customerId
		}
	} )

	const [ deleteItemFromCart ] = useMutation( DELETE_ITEM_FROM_CART )

	// DO NOT REMOVE THIS MUTATION ON ITEMS!!!!!!
	// WE NEED THIS, TO UPDATE QUANTITY FIELD OF A PRODUCT AND FOR SHOWING ITEMS IN CART!!!!!!
	const {
		data: cartOrdersData,
		loading: cartOrdersLoading,
		error: cartOrdersError,
	} = useSubscription( CART_ORDERS_BASKET, {
		variables: {
			cart_uuid: uuidValue,
			customer_id: customerId
		},
		onSubscriptionData: () => {
			setLockCartButton( false )
		},
	} )

	const totalBruto = totalBrutoData
		&& totalBrutoData.cart_orders_aggregate
		&& totalBrutoData.cart_orders_aggregate.aggregate
		&& totalBrutoData.cart_orders_aggregate.aggregate.sum.total_bruto_price
		? totalBrutoData.cart_orders_aggregate.aggregate.sum.total_bruto_price : null

	const totalNeto = totalNetoData
		&& totalNetoData.cart_orders_aggregate
		&& totalNetoData.cart_orders_aggregate.aggregate
		&& totalNetoData.cart_orders_aggregate.aggregate.sum.total_neto_price
		? totalNetoData.cart_orders_aggregate.aggregate.sum.total_neto_price : null

	const productsInCart = cartOrdersData
		&& cartOrdersData.cart_items.length > 0
		? cartOrdersData.cart_items : []

	// eslint-disable-next-line camelcase
	const commissionFeeOrder = cartOrdersData?.cart_items[ 0 ]?.order?.commission_fee

	function deleteProductToCart( item ) {
		if ( !lockCartButton ) {
			deleteItemFromCart( {
				variables: {
					id: item.id,
					hub_id: item.hub_id,
					cart_uuid: item.cart_uuid,
					customer_id: customerId
				}
			} )
		}
	}

	function displayProductNameTitle( item ) {
		if ( item.product.variety ) {
			return `${ item.product.gs1_brick.translation_sl.charAt( 0 ).toUpperCase() + item.product.gs1_brick.translation_sl.slice( 1 ) } - ${ item.product.variety.name }`
		}
		return `${ item.product.gs1_brick.translation_sl.charAt( 0 ).toUpperCase() + item.product.gs1_brick.translation_sl.slice( 1 ) }`
	}

	function renderItems() {
		return productsInCart.map(
			( item ) => (
				<div className='basket-item-row' key={ item.qr_code_id }>
					<Suspense fallback={ <div style={ { width: 145, height: 145, backgroundColor: '#ececec' } } /> }>
						{ item.product.gs1_brick.files ? (
							<Image
								itemData={ item }
								publicImage={ item.product.gs1_brick.files.public }
								galleries={ item.product.galleries }
							/>
						) : null }
						{ !item.product.gs1_brick.files ? (
							<img style={ { width: 145, height: 145 } } color='#ececec' src='/img/no_default_picture.png' alt='' />
						) : null }
					</Suspense>
					<div className='product-supplier'>
						<div className='supplier-name'>{ item.market.name }</div>
						<div className='product-name'>{ displayProductNameTitle( item ) }</div>
					</div>
					<div className='quantity-selector'>
						<div className='quantity-selector-text'><Trans id='ShoppingCart.quantity' /></div>
						<div className='dropdown'>{ ` ${ item.quantity } ${ item.product.gs1_brick.unit.unit }` }</div>
					</div>
					<div className='price'>
						{` ${ getPriceFormatter( language ).format( item.bruto_price ) }` }
					</div>
					<div className='withTax'>
						<Trans id='ItemComponent.withTax' />
					</div>
					<div className='basket-buttons-container'>
						{ /* <div className='heart-icon'><HeartIcon fontSize={ 15 } /></div> */ }
						<div
							className={ !lockCartButton ? 'delete-icon' : 'delete-icon-disabled' }
							role='button'
							tabIndex={ -1 }
							disabled={ lockCartButton }
							onKeyPress={ () => {
								setLockCartButton( true )
								deleteProductToCart( item )
							} }
							onClick={ () => {
								setLockCartButton( true )
								deleteProductToCart( item )
							} }
						>
							<DeleteIcon fontSize={ 15 } />
						</div>
					</div>
				</div>
			)
		)
	}

	return (
		<div className='basket-main-container'>
			{ cartOrdersLoading ? <Loader /> : null }
			{ cartOrdersError ? <div>{JSON.stringify( cartOrdersError ) }</div> : null }
			<div>
				{ productsInCart.length > 0 ? renderItems() : null }
			</div>
			{ productsInCart.length > 0 ? (
				<div className='account-total-container'>
					<div className='commission-fee-basket-text'><Trans id='CommissionFeeMyOrders' /></div>
					{ commissionFeeOrder
						? (
							<div className='commission-fee-basket'>
								{ getPriceFormatter( language ).format( commissionFeeOrder ) }
							</div>
						) : null }
					<div className='sum-total'><Trans id='ShoppingCart.totalNoTax' /></div>
					{ totalNetoError ? <div>{ JSON.stringify( totalNetoError ) }</div> : null }
					{ totalNetoLoading ? <Loader /> : null }
					{ totalNeto ? (
						<div className='sum-total-neto'>
							{ getPriceFormatter( language ).format( totalNeto ) }
						</div>
					) : null }
					{ /* <div className='tax'><Trans id='ShoppingCart.tax' /></div>
					<div className='tax-value'>&#8364; 9,90</div>
					<div className='tax-percentage'>
						<Trans id='ShoppingCart.tax' />
						%
					</div>
					<div className='tax-value-percentage'>&#8364; 9,90</div>
					*/ }
					<div className='total'><Trans id='ShoppingCart.totalWithTax' /></div>
					{ totalBrutoError ? <div>{ JSON.stringify( totalBrutoError ) }</div> : null }
					{ totalBrutoLoading ? <Loader /> : null }
					{ totalBruto ? (
						<div className='total-bruto'>
							{ getPriceFormatter( language ).format( totalBruto ) }
						</div>
					) : null }
				</div>
			) : null }
			{ productsInCart.length === 0 ? <Trans id='ShoppingCart.youHaveNothingToOrder' /> : null }
			<div className='button-next-step'>
				<Button
					text={ <Trans id='ShoppingCart.continueToTheNextStep' /> }
					disabled={ productsInCart.length > 0 }
					onClick={ () => clickNext() }
				/>
			</div>
		</div>
	)
}

BasketItem.defaultProps = {
	customerId: null
}

BasketItem.propTypes = {
	clickNext: propTypes.func.isRequired,
	customerId: propTypes.string
}

export default BasketItem
