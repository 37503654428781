/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import propTypes from 'prop-types'

import './ToggleButton.css'

const ToggleButton = ( {
	activate, rowId, onClick, textActive, textDeactive, styleInput, styleLabel, styleDiv
} ) => (
	<div className={ styleDiv }>
		<span className={ styleLabel }>
			{ activate ? textActive : textDeactive }
		</span>
		<input
			type='checkbox'
			id={ rowId }
			className={ `checkbox ${ styleInput }` }
			checked={ !activate }
			readOnly
			onClick={ onClick }
		/>
		<label htmlFor={ rowId } className='switch' />
	</div>
)

ToggleButton.defaultProps = {
	activate: true,
	styleInput: null,
	styleLabel: null,
	styleDiv: null,
	textActive: null,
	textDeactive: null,
}

ToggleButton.propTypes = {
	activate: propTypes.bool,
	rowId: propTypes.number.isRequired,
	onClick: propTypes.func.isRequired,
	textActive: propTypes.object,
	textDeactive: propTypes.object,
	styleInput: propTypes.string,
	styleLabel: propTypes.string,
	styleDiv: propTypes.string
}

export default ToggleButton
