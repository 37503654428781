import React from 'react'
import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'
import { withRouter } from 'react-router-dom'

import { ReturnSendIcon } from '../../../icons'

import './MyPurchasesHeader.css'

const MyPurchasesHeader = React.memo( ( { history } ) => (
	<div id='my-purchases-hader'>
		<span id='my-purchases-text'><Trans id='MyPurchasesPage.myPurchases' /></span>
		<div id='my-purchases-back-to-store'>
			<div
				id='my-purchases-click-back-to-store'
				role='button'
				onClick={ () => history.push( '/' ) }
				onKeyPress={ () => history.push( '/' ) }
				tabIndex={ 0 }
			>
				<span><ReturnSendIcon fontSize={ 14 } /></span>
				<Trans id='MyPurchasesPage.backToStore' />
			</div>
		</div>
	</div>
) )

MyPurchasesHeader.propTypes = {
	history: propTypes.object.isRequired
}

export default withRouter( MyPurchasesHeader )
