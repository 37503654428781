import gql from 'graphql-tag'

const GET_PRODUCTS = gql`
query getProductsByTenantId(
	$tenant_id: String!,
	$limit: Int!,
	$offset: Int,
) {
	provider_product (
		limit: $limit,
		offset: $offset,
		where: { tenant_id: { _eq: $tenant_id } }, 
		order_by: { created_at: asc }
	){
		id
		brick_code
		description
		disabled
		gs1_brick {
			id
			translation_sl
			files {
				id
				public
				file_id
				tenant_id
			}
			unit {
				id
				unit
			}
		}
		bruto_price
		neto_price
		ddv_enum
		ddv_value
		quality
		quantity
		variety {
			id
			name
		}
		galleries (where: { default: { _eq: true } } ){
			id
			file {
				id
				file_id
				tenant_id
			}
		}
	}		
}`

const HASURA_UPDATE_PRODUCT_DATA = gql`
	mutation update_provider_product(
		$id: bigint!,
		$changes: provider_product_set_input
	) { 
		update_provider_product( where: { id: { _eq: $id } }, _set: $changes ) {
			returning {
				id
				disabled
				description
				quality
				bruto_price
				neto_price
				ddv_enum
			}
		} 
	}
`
export { GET_PRODUCTS, HASURA_UPDATE_PRODUCT_DATA }
