import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

import { Trans } from '@lingui/macro'
import EditArea from '../../../../components/_Globals/EditArea'
import { addNotification } from '../../../../utils/messageHandler/notifierStack'


import './UserProfileSettings.css'

const UPDATE_PROVIDER_PROFILE = gql`
mutation Tenant_update( $input: UpdateTenantInput ) {
	Tenant_update(input: $input) {
	  id
	}
  }
`

/* global sessionStorage */

const ProviderProfile = ( { providerData, passText } ) => {
	const [ isEditable, toggleIsEditable ] = useState( false )
	const [ editedText, setEditedText ] = useState( {} )

	const tenantId = sessionStorage.getItem( 'uhub_tenantId' )

	const regexBankName = new RegExp( /^[A-Za-zČŠčšŽž0-9.-\s]+$/, 'i' )
	const regexPhoneNum = new RegExp( /^[+]*[0-9\-\s]+$/, 'i' )
	const regexBic = new RegExp( /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/, 'i' )
	// eslint-disable-next-line no-useless-escape
	const regexIban = new RegExp( /^[A-Z]{2}\d{2} ?\d{4} ?\d{4} ?\d{4} ?\ ?[\d]{0,3}$/, 'i' )

	const [ updateProviderProfile ] = useMutation( UPDATE_PROVIDER_PROFILE )

	useEffect( () => {
		if ( Object.keys( editedText ).length > 0 ) {
			passText( editedText )
			setEditedText( {} )
		}
	}, [ editedText, passText ] )

	function getText( id, text ) {
		const objectCopy = editedText

		objectCopy[ id ] = text
		setEditedText( objectCopy )
	}

	function checkNew( ) {
		toggleIsEditable( !isEditable )
		let keys = null
		if ( Object.keys( editedText ).length > 0 ) {
			keys = Object.entries( editedText )
			let allValuesValid = true
			// eslint-disable-next-line no-restricted-syntax
			for ( const [ key, value ] of keys ) {
				if ( ( key === 'bankName' && !regexBankName.test( value ) )
						|| ( key === 'BIC' && !regexBic.test( value ) )
						|| ( key === 'phone' && !regexPhoneNum.test( value ) )
						|| ( key === 'IBAN' && !regexIban.test( value ) ) ) {
					allValuesValid = false
					break
				}
			}

			if ( allValuesValid ) {
				updateProviderProfile( {
					variables: {
						input: {
							id: tenantId,
							mobile: editedText.mobile,
							phone: editedText.phone,
							bankAccount: {
								BIC: editedText.BIC,
								IBAN: editedText.IBAN,
								name: editedText.bankName
							}
						}

					}
				} )
				addNotification( 'OK', 'USER_PROFILE_SETTINGS_UPDATED' )
			} else {
				addNotification( 'ERROR', 'INVALID_INPUTS_USER_PROFILE_SETTINGS' )
			}
		} else {
			addNotification( 'OK', 'USER_PROFILE_SETTINGS_UPDATED' )
		}
	}

	return (
		<div className='user-profile-container'>
			<div className='name-settings'>
				<Trans id='RegistrationInput.companyName' />
			</div>
			<EditArea
				id='companyName'
				isInput
				myStyle='hub-view-hub-title-editable edit-area-settings'
				text={ providerData.tenant.identifiers.companyName }
			/>
			<div className='name-settings'>
				<Trans id='RegistrationInput.companyRegistrationNumber' />
			</div>
			<EditArea
				id='companyRegistrationNumber'
				isInput
				myStyle='hub-view-hub-title-editable edit-area-settings'
				getText={ getText }
				text={ providerData.tenant.identifiers.companyRegistrationNumber }
			/>
			<div className='name-settings'>
				<Trans id='RegistrationInput.taxId' />
			</div>
			<EditArea
				id='taxId'
				isInput
				myStyle='hub-view-hub-title-editable edit-area-settings'
				getText={ getText }
				text={ providerData.tenant.identifiers.taxId }
			/>
			<div className='name-settings'>
				<Trans id='RegistrationInput.mobileNum' />
			</div>
			<div>
				<EditArea
					id='mobile'
					isInput
					myStyle='hub-view-hub-title-editable edit-area-settings'
					getText={ getText }
					text={ providerData.tenant.applications[ 0 ].data.mobile }
					editable={ isEditable }
					regex={ regexPhoneNum }
				/>
			</div>
			<div className='name-settings'>
				<Trans id='RegistrationInput.phoneNum' />
			</div>
			<div>
				<EditArea
					id='phone'
					isInput
					myStyle='hub-view-hub-title-editable edit-area-settings'
					getText={ getText }
					text={ providerData.tenant.applications[ 0 ].data.phone }
					editable={ isEditable }
					regex={ regexPhoneNum }
				/>
			</div>
			<div className='name-settings'>
				<Trans id='RegistrationInput.Bic' />
			</div>
			<div>
				<EditArea
					id='BIC'
					isInput
					myStyle='hub-view-hub-title-editable edit-area-settings'
					getText={ getText }
					text={ providerData.tenant.applications[ 0 ].data.bankAccount.BIC }
					editable={ isEditable }
					regex={ regexBic }
				/>
			</div>
			<div className='name-settings'>
				<Trans id='RegistrationInput.Iban' />
			</div>
			<div>
				<EditArea
					id='IBAN'
					isInput
					myStyle='hub-view-hub-title-editable edit-area-settings'
					getText={ getText }
					text={ providerData.tenant.applications[ 0 ].data.bankAccount.IBAN }
					editable={ isEditable }
					regex={ regexIban }
				/>
			</div>
			<div className='name-settings'>
				<Trans id='RegistrationInput.bankName' />
			</div>
			<div>
				<EditArea
					id='bankName'
					isInput
					myStyle='hub-view-hub-title-editable edit-area-settings'
					getText={ getText }
					text={ providerData.tenant.applications[ 0 ].data.bankAccount.name }
					editable={ isEditable }
					regex={ regexBankName }
				/>
			</div>
			<div className='my-profile-button-container'>
				<button
					className='settings-button'
					type='button'
					onClick={ isEditable ? () => checkNew()
						: () => toggleIsEditable( !isEditable ) }
				>
					{isEditable ? <p><Trans id='UserProfileSettings.SaveButton' /></p> : <p><Trans id='UserProfileSettings.EditButton' /></p>}
				</button>
			</div>
		</div>
	)
}

ProviderProfile.defaultProps = {
	passText: () => {},
}

ProviderProfile.propTypes = {
	providerData: propTypes.object.isRequired,
	passText: propTypes.func,
}

export default ProviderProfile
