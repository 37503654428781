import React from 'react'
import propTypes from 'prop-types'

import './Step.css'

const StepProgress = ( {
	stepStatuses, currentStep, translations, totalSteps
} ) => {
	function renderStepBrick( step, index ) { // Rendering step bar
		// if condition met, render brick blue with an arrow
		if ( step === index ) {
			if ( step === totalSteps ) {
				return 'step-brick-blue-last'
			}
			return 'step-brick-blue'
		}

		// if condition met, render brick blue without an arrow
		if ( ( step >= index && step < totalSteps ) ) {
			return 'step-brick-blue-noarrow'
		}

		// otherwise render brick default
		return 'step-brick'
	}

	return (
		<div className='progress-bar'>
			{ stepStatuses.map( ( step, index ) => (
				<div
					className={renderStepBrick( currentStep, index ) }
					key={ stepStatuses.indexOf( step ) }
				>
					<div className={ currentStep === index && currentStep <= totalSteps ? 'text text-white' : 'text' }>
						{ translations[ step ] }
					</div>
				</div>
			) ) }
		</div>
	)
}

StepProgress.propTypes = {
	stepStatuses: propTypes.array.isRequired,
	currentStep: propTypes.number.isRequired,
	translations: propTypes.object.isRequired,
	totalSteps: propTypes.number.isRequired,
}

export default StepProgress
