import React from 'react'
import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

import './HubDetails.css'
// import HubCycle from '../../../../components/_Globals/HubCycle'
// import { HeartIcon, LocationIcon } from '../../../../icons'
// import DistanceFromHub from '../../../../components/_Globals/DistanceFromHub'
// import { useStateValue } from '../../../../stores/contextStore/stateStore'
import Loader from '../../../../components/Loader'

const GET_HUB_BY_ID = gql`
query GetHubById( $hubId: bigint! ){
    hub_hub( where: { id: { _eq: $hubId } } ) {
        id
        name
        description
    }
}`

const HubDetails = React.memo( ( { hubId } ) => {
	// const [ { isAuth } ] = useStateValue()
	const { data, error, loading } = useQuery( GET_HUB_BY_ID, {
		variables: {
			hubId
		}
	} )

	if ( loading ) {
		return <Loader />
	}

	if ( error ) {
		return <div>{ JSON.stringify( error ) }</div>
	}

	const hubData = data && data.hub_hub ? data.hub_hub[ 0 ] : {}

	return (
		<div className='hubDetails-main-container'>

			<div className='HubDetails-container'>
				<div className='HubDetails-name'>
					<span>
						<Trans id='HubInfo.hub' />
					</span>
					<span><b>{ hubData.name }</b></span>
				</div>
				<div className='HubDetails-description'>
					{ hubData.description }
				</div>
				{/* <div className='HubDetails-cylce'>
					<HubCycle data={ hub } />
				</div> */}
				{/* <div className='HubDetails-distance-from-hub'>
					<DistanceFromHub data={ hub } isAuth={ isAuth } />
				</div> */}
				{/* <div className='HubDetails-icons'>
					<HeartIcon fontSize={ 25 } />
					<LocationIcon fontSize={ 25 } />
				</div> */}
			</div>
		</div>
	)
} )

HubDetails.propTypes = {
	hubId: propTypes.number.isRequired,
}

export default HubDetails
