import ReactDOM from 'react-dom'
import { I18nProvider } from '@lingui/react'
import React from 'react'

import {
	onLogoutClearStore,
	refreshToken,
	parseUserRoles
} from './utils/keycloak'
import mainStore from './stores/mainStore'

//	Older browser support
import 'core-js'

import RenderLandingPage from './LandingPage'
import TenantPickerPopup from './components/TenantPickerPopup'
import catalogs from './utils/languageSettings'
import registerServiceWorker from './utils/registerServiceWorker'

/*	global sessionStorage */

/**
 * Render main / Landing page
 * null = keycloak is not running / error with keycloak
 * true = keycloak is authenticated
 * false = keycloak is not authenticated, but running
 */
RenderLandingPage( null )

// Used to refresh session token
let refreshTokenIntervalHandler = null

mainStore.keycloak
	.init( { onLoad: 'check-sso' } )
	.success( ( success ) => {
		if ( success ) {
			const INTERVAL_TIME = process.env.REACT_APP_KEYCLOAK_INTERVAL_TIME
			const refreshCallback = () => { }

			refreshTokenIntervalHandler = setInterval(
				refreshToken.bind( null, mainStore.keycloak, refreshCallback ),
				INTERVAL_TIME
			)

			mainStore.keycloak.loadUserInfo().success( ( userData ) => {
				// TODO - Move to Apollo Store later
				sessionStorage.setItem(
					'uhub_idToken',
					mainStore.keycloak.idToken
				)

				mainStore.user = userData
				mainStore.keycloak.loadUserProfile().success( ( userProfile ) => {
					const roles = parseUserRoles( userProfile.attributes )
					if ( userProfile.attributes.language ) {
						const [ language ] = userProfile.attributes.language
						mainStore.language = language
					}

					// TODO handle on backend!!!!!!
					if ( !roles[ process.env.REACT_APP_KEYCLOAK_CLIENT_ID ] ) {
						mainStore.userRoles = {
							[ process.env.REACT_APP_KEYCLOAK_CLIENT_ID ]: {
								[ sessionStorage.getItem( 'uhub_tenantId' ) ]: []
							}
						}
					} else {
						mainStore.userRoles = roles[
							process.env.REACT_APP_KEYCLOAK_CLIENT_ID
						][ sessionStorage.getItem( 'uhub_tenantId' ) ]
					}
					RenderLandingPage( true )
					const validRoles = Object.keys( userProfile.attributes ).filter( ( key ) => key.match( /\w_roles_\w/ ) )
					if ( validRoles.length > 1 ) {
						ReactDOM.render(
							<I18nProvider language={ mainStore.language } catalogs={ catalogs }>
								<TenantPickerPopup roles={ validRoles } />
							</I18nProvider>,
							document.getElementById( 'root' )
						)
						registerServiceWorker()
					}
				} )
			} )
		} else {
			RenderLandingPage( false )
		}
	} )
	.error( ( error ) => {
		console.error( 'Keycloak error ( please check index.js ) ', error )
		RenderLandingPage( null )
	} )


mainStore.keycloak.onAuthRefreshError = () => {
	sessionStorage.clear()
	window.location.href = mainStore.keycloak.createLogoutUrl()
	onLogoutClearStore()
	clearInterval( refreshTokenIntervalHandler )
	// window.location = '/'
	// window.location.reload( true )
}

mainStore.keycloak.onTokenExpired = () => {
	sessionStorage.clear()
	window.location.href = mainStore.keycloak.createLogoutUrl()
	onLogoutClearStore()
	clearInterval( refreshTokenIntervalHandler )
	// window.location = '/'
	// window.location.reload( true )
}
