import React from 'react'
// import FooterTop from './components/FooterTop'
// import FooterMiddle from './components/FooterMiddle'
import FooterBottom from './components/FooterBottom'

import './Footer.css'
// import '../_Hub/GS1/GS1.css'
// import '../_Hub/GS1/components/TableGS1Header.css'
// import '../_Hub/GS1/components/TableRowGS1.css'

const Footer = () => (
	<div className='footer-container'>
		{/* <div id='gs1-container'>awdawd</div> */}
		{ /* <FooterTop /> */ }
		{ /* <FooterMiddle /> */ }
		<FooterBottom />
	</div>
)

export default Footer
