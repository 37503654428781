import React from 'react'
import propTypes from 'prop-types'

const UhubIcon = ( { color, fontSize } ) => (
	<span className='icon-UHUB_logo' style={ { fontSize: `${ fontSize }px`, color } }>
		<span className='path1' />
		<span className='path2' />
	</span>
)

UhubIcon.defaultProps = {
	color: 'black',
	fontSize: 18
}
UhubIcon.propTypes = {
	color: propTypes.string,
	fontSize: propTypes.number
}

export default UhubIcon
