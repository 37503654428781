import gql from 'graphql-tag'

const GET_ORDER_STATUSES = gql`
query getOrderStatuses( $order_id: bigint! ){
	cart_order_status_history(	where: { order_id: { _eq: $order_id } } ) {
		id
    	new_status_id
    	timestamp
		action_author_to_tenant {
			owner {
				firstName
				lastName
			}
		}
		reference
    	meta
    	imprint
  	}
}`

export default GET_ORDER_STATUSES
