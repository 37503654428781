/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import propTypes from 'prop-types'
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Trans } from '@lingui/macro'

import Button from '../../../_Globals/Button/Button'
import DropDownSelect from './DropDownSelect'
import Loader from '../../../Loader'
import { addNotification } from '../../../../utils/messageHandler/notifierStack'

import './PopupAddProduct.css'

/* global sessionStorage */

const GET_PRODUCTS = gql`
query getProductsByTenantId(
	$tenant_id: String!,
) {
	provider_product ( where: { tenant_id: { _eq: $tenant_id } }, order_by: { created_at: asc } ){
		id
		brick_code
		description
		disabled
		gs1_brick {
			id
			translation_sl
			files {
				id
				public
				file_id
				tenant_id
			}
			unit {
				id
				unit
			}
		}
		bruto_price
		neto_price
		quality
		quantity
		variety {
			id
			name
		}
		galleries (where: { default: { _eq: true } } ){
			id
			file {
				id
				file_id
				tenant_id
			}
		}
	}
}`

const GET_ALL_CLASS_PRODUCTS = gql`{
    code_register_gs1_brick {
		id
		translation_sl
  	}
}`

const GET_ALL_VARIETIES_PRODUCTS = gql`
	query code_register_variety( $brick_code: Int! ) {
    	code_register_variety( where: { brick_code: { _eq: $brick_code } } ) {
			id
    		name
  		}
	}
`

const INSERT_HASURA_PRODUCT = gql`
	mutation insert_provider_product(
		$brick_code: Int!,
		$variety_id: Int,
		$tenant_id: String!,
		
	) {
		insert_provider_product( objects: { 
			brick_code: $brick_code,
			variety_id: $variety_id,
			tenant_id: $tenant_id,
			neto_price: 0,
			bruto_price: 0,
			ddv_enum: 0,
		} ) {
			returning {
				id
				gs1_brick {
					id
					translation_sl
					files {
						id
						file_id
						tenant_id
					}
					unit {
						id
						unit
					}
				}
				variety {
					id
					name
				}
			}
		}
	}
`

const PopupAddProduct = React.memo( ( {
	closePopUp, handleAddProduct, handleResponse, toggleEditable, isNewProduct
} ) => {
	// eslint-disable-next-line no-unused-vars
	const tenantId = sessionStorage.getItem( 'uhub_tenantId' )
	const [ product, selectProduct ] = useState( null )
	const [ productVariety, selectVariety ] = useState( null )

	const {
		data: dataProducts,
		loading: loadingProducts,
		error: errorProducts
	} = useQuery( GET_ALL_CLASS_PRODUCTS )

	const [ getAllVarietiesProducts, {
		data: dataVarieties,
		loading: loadingVarieties,
		error: errorVarieties,
	} ] = useLazyQuery( GET_ALL_VARIETIES_PRODUCTS )

	const [
		insertNewProduct,
		{
			data: dataNewProduct,
			loading: loadingNewProduct,
			error: errorNewProduct,
		}
	] = useMutation( INSERT_HASURA_PRODUCT, {
		onCompleted: ( data ) => {
			handleResponse( {
				id: data.insert_provider_product.returning[ 0 ].id,
				gs1_brick: data.insert_provider_product.returning[ 0 ].gs1_brick,
				variety: data.insert_provider_product.returning[ 0 ].variety,
			} )
			toggleEditable( true )
			isNewProduct( true )
		},
		refetchQueries: () => [ {
			query: gql`${ GET_PRODUCTS }`,
			variables: { tenant_id: tenantId }
		} ]
	} )


	const products = dataProducts
		&& dataProducts.code_register_gs1_brick
		? dataProducts.code_register_gs1_brick : []

	const productsVarieties = dataVarieties
		&& dataVarieties.code_register_variety
		? dataVarieties.code_register_variety : []

	function selectProductClass( prod ) {
		selectProduct( prod.id )

		getAllVarietiesProducts( {
			variables: {
				brick_code: prod.id,
			}
		} )
	}

	function selectProductVariety( variety ) {
		selectVariety( variety.id )
	}

	function addingProduct() {
		insertNewProduct( {
			variables: {
				brick_code: product,
				variety_id: productVariety,
				tenant_id: tenantId,
			}
		} )
		handleAddProduct( true )
	}

	return (
		<div className='PopupAddProduct-popup-main-container'>
			<div className='popup-window'>
				<div className='popup-instructions'>
					<Trans id='PopupChooseHub.choosingTheProductType' />
				</div>
				<div className='popup-dropDown'>
					{ errorProducts ? <div>{ JSON.stringify( errorProducts ) }</div> : null }
					{ loadingProducts
						? <Loader />
						: (
							<DropDownSelect
								items={ products }
								select={ ( prod ) => selectProductClass( prod ) }
								type='product'
							/>
						) }
				</div>
				<div className='popup-instructions'>
					<Trans id='PopupChooseHub.choosingTheProductVariacijo' />
				</div>
				<div className='popup-dropDown'>
					{ errorVarieties ? <div>{ JSON.stringify( errorVarieties ) }</div> : null }
					{ loadingVarieties
						? <Loader />
						: (
							<DropDownSelect
								items={ productsVarieties }
								select={ ( variety ) => selectProductVariety( variety ) }
								type='variety'
							/>
						) }
				</div>
				<div className='popup-buttons'>
					<Button
						text={ <Trans id='PopupAddProduct.addProduct' /> }
						disabled={ !!product }
						onClick={ () => {
							addingProduct()
							closePopUp( false )
						} }
					/>
					<Button
						text={ <Trans id='PopupAddProduct.closePopup' /> }
						disabled
						onClick={ () => {
							closePopUp( false )
						} }
					/>
				</div>
			</div>
		</div>
	)
} )


PopupAddProduct.defaultProps = {
	handleResponse: () => {},
	isNewProduct: () => {},
}

PopupAddProduct.propTypes = {
	handleAddProduct: propTypes.func.isRequired,
	handleResponse: propTypes.func,
	isNewProduct: propTypes.func,
	closePopUp: propTypes.func.isRequired,
	toggleEditable: propTypes.func.isRequired
}


export default PopupAddProduct
