import React from 'react'
import { Trans } from '@lingui/macro'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import './ProfileFooter.css'

const ProfileFooter = React.memo( ( { history } ) => (
	<div className='side-menu-footer'>
		{/* <div>Jezik : Slo - TODO -</div> */}
		<button type='button' onClick={ () => history.push( '/settings' ) }>
			<Trans id='ProfileMenu.settings' />
		</button>
		{/* <div>Pomoč - TODO -</div> */}
	</div>
) )

ProfileFooter.propTypes = {
	history: propTypes.object.isRequired
}

export default withRouter( ProfileFooter )
