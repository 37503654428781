/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import gql from 'graphql-tag'
import {
	useQuery, useSubscription, useMutation, useLazyQuery
} from '@apollo/react-hooks'
import propTypes from 'prop-types'
import { PayPalButton } from 'react-paypal-button-v2'
import { Trans } from '@lingui/macro'
import axios from 'axios'
import Button from '../../_Globals/Button/Button'
import Loader from '../../Loader'
import mainStore from '../../../stores/mainStore'
import getPriceFormatter from '../../../utils/PriceFormatter'
import './OrderSummary.css'

/* global sessionStorage */

import {
	GET_SUM_TOTAL_BRUTO_PRICES,
	GET_SUM_TOTAL_NETO_PRICES
} from '../BasketItem/BasketItemGQL'

import GET_DELIVERY_ADDRESS from '../AddressPage/addressPageGQL'

const CART_ORDERS = gql`
query getCartOrders(
	$cart_uuid: uuid!
	$customer_id: String
	$status_id: [Int!]
){
	cart_orders( where: { 
			cart_uuid: { _eq: $cart_uuid },
			customer_id: { _eq: $customer_id },
			status_id: {_in: $status_id }
		}, order_by: { hub_id: asc } ) {
		id
		commission_fee
		hub {
			id
			cycles {
				id
                finished
				closed_time
			}
			name
		}
		customer_pickup
		delivery_price_with_VAT
        items {
			id
            product {
				id
                gs1_brick {
					id
                    translation_sl
					unit {
                        id
                        unit
                    }
                }
				quantity
				variety {
					id
					name
				}
                neto_price
				bruto_price
            }
            market {
				id
                name
            }
			quantity
			bruto_price
        }
		total_bruto_price
		qr_code {
			id
			base64_data
		}
    }
}`

const GET_HUB_CYCLE = gql`{
	hub_hub {
		id
		cycles {
			id
			closed_time
		}
		name
	}
}`

const SEND_MAIL = gql`
	query sendMail( 
		$input: MailInput
	) {
		sendMail( 
			input: $input
		)
	}
`

const UPDATE_STATUS_ORDER_TO_PAYED = gql`
	mutation updateOrderToPayed( 
		$id: bigint!
	) {
		update_cart_orders( where: { id: { _eq: $id } }, _set: { status_id: 4 } ) {
			affected_rows
		}
	}
`

const UPDATE_ORDERS_STATUS_TO_IN_PAYMENT_PROCESSING = gql`
	mutation updateOrderToInPaymentProcessing( 
		$ids: [bigint!]
	) {
		update_cart_orders( where: { id: { _in: $ids } }, _set: { status_id: 3 } ) {
			affected_rows
		}
	}
`

const OrderSummary = ( {
	clickBack, clickNext, clickNextPaymentFailed
} ) => {
	const customerId = sessionStorage.getItem( 'uhub_tenantId' )
	const uuidValue = sessionStorage.getItem( 'uuid' )
	const { language } = mainStore
	const [ greyOutScreen, setGreyOutScreen ] = useState( false )
	const { email } = mainStore.user
	const { REACT_APP_PAYPAL_CLIENT_ID } = process.env

	const buildEnv = process.env.REACT_APP_BUILD_ENV

	const {
		data: addressData,
		loading: addressLoading,
		error: addressError,
	} = useQuery( GET_DELIVERY_ADDRESS, {
		variables: {
			customer_id: customerId

		},
		fetchPolicy: 'no-cache'
	} )

	const {
		data: cartOrdersData,
		loading: cartOrdersLoading,
		error: cartOrdersError,
	} = useQuery( CART_ORDERS, {
		variables: {
			cart_uuid: uuidValue,
			customer_id: customerId,
			status_id: [ 1, 3 ]
		},
		fetchPolicy: 'no-cache'
	} )

	const {
		data: hubCycleData,
		loading: hubCycleLoading,
		error: hubCycleError,
	} = useQuery( GET_HUB_CYCLE )

	const {
		data: totalBrutoData,
		loading: totalBrutoLoading,
		error: totalBrutoError,
	} = useSubscription( GET_SUM_TOTAL_BRUTO_PRICES, {
		variables: {
			cart_uuid: uuidValue,
		}
	} )

	const {
		data: totalNetoData,
		loading: totalNetoLoading,
		error: totalNetoError,
	} = useSubscription( GET_SUM_TOTAL_NETO_PRICES, {
		variables: {
			cart_uuid: uuidValue,
		}
	} )

	const hubsCycles = hubCycleData && hubCycleData.hub_hub ? hubCycleData.hub_hub : null

	const deliveryAddress = addressData
		&& addressData.cart_orders.length > 0
		&& addressData.cart_orders[ 0 ].customer
		? addressData.cart_orders[ 0 ].customer : null

	const totalBruto = totalBrutoData
		&& totalBrutoData.cart_orders_aggregate
		&& totalBrutoData.cart_orders_aggregate.aggregate
		&& totalBrutoData.cart_orders_aggregate.aggregate.sum.total_bruto_price
		? totalBrutoData.cart_orders_aggregate.aggregate.sum.total_bruto_price : null

	const totalNeto = totalNetoData
		&& totalNetoData.cart_orders_aggregate
		&& totalNetoData.cart_orders_aggregate.aggregate
		&& totalNetoData.cart_orders_aggregate.aggregate.sum.total_neto_price
		? totalNetoData.cart_orders_aggregate.aggregate.sum.total_neto_price : null

	const productsInCart = cartOrdersData
		&& cartOrdersData.cart_orders
		? cartOrdersData.cart_orders.map( ( order ) => order.items ).flat() : null

	const productsInCartByHub = cartOrdersData
		&& cartOrdersData.cart_orders
		? cartOrdersData.cart_orders.map(
			( order ) => ( { hub: order.hub, items: order.items, orderId: order.id } )
		).flat() : null

	const [ updateOrderToPayed ] = useMutation(
		UPDATE_STATUS_ORDER_TO_PAYED
	)

	const [ updateOrdersToInPaymentProcessing ] = useMutation(
		UPDATE_ORDERS_STATUS_TO_IN_PAYMENT_PROCESSING
	)

	const input = []
	if ( productsInCartByHub ) {
		for ( let i = 0; i < productsInCartByHub.length; i += 1 ) {
			const hubId = productsInCartByHub[ i ].hub.id

			const products = productsInCartByHub[ i ].items.map( ( product ) => {
				const productName = product.product.variety ? `${ product.product.gs1_brick.translation_sl } - ${ product.product.variety.name }` : `${ product.product.gs1_brick.translation_sl }`
				const marketName = product.market.name
				const quantity = `${ product.quantity } ${ product.product.gs1_brick.unit.unit }`
				const price = `€  ${ product.bruto_price }`

				return `${ productName } - ${ marketName } - ${ quantity } - ${ price }`
			} )

			const { orderId } = productsInCartByHub[ i ]

			let itemsString = ''
			for ( let j = 0; j < products.length; j += 1 ) {
				itemsString += `'${ products[ j ] }',`
			}
			itemsString = itemsString.substring( 0, itemsString.length - 1 )

			const qrCode = cartOrdersData.cart_orders.filter(
				( order ) => order.id === orderId
			)[ 0 ].qr_code.base64_data

			input.push( {
				recipient: email,
				template: 'orderPaid',
				locals: `{ 'orderId': ${ orderId }, 'items': [ ${ itemsString } ], 'qr_code': '${ qrCode }' }`
			} )
		}
	}

	const [ getSendMail ] = useLazyQuery( SEND_MAIL )

	function sendManyMails() {
		for ( let i = 0; i < input.length; i += 1 ) {
			getSendMail( {
				variables: {
					input: input[ i ]
				}
			} )
		}
	}

	function displayProductNameTitle( product ) {
		if ( product.product.variety ) {
			return `${ product.product.gs1_brick.translation_sl.charAt( 0 ).toUpperCase() + product.product.gs1_brick.translation_sl.slice( 1 ) } - ${ product.product.variety.name }`
		}
		return `${ product.product.gs1_brick.translation_sl.charAt( 0 ).toUpperCase() + product.product.gs1_brick.translation_sl.slice( 1 ) }`
	}

	function orderedProducts() {
		return productsInCart.map( ( product, index ) => (
			// eslint-disable-next-line react/no-array-index-key
			<div className='choosen-product-container' key={ index }>
				<div className='quantity'> 1x </div>
				<div className='choosen-product-name'>
					{ displayProductNameTitle( product ) }
				</div>
				<div className='provider-name'>
					{ product.market.name }
				</div>
				<div className='product-quantity-weight'>{ ` ${ product.quantity } ${ product.product.gs1_brick.unit.unit }` }</div>
				<div className='product-price' style={ { textAlign: 'right' } }>
					{ `${ getPriceFormatter( language ).format( product.bruto_price ) }` }
				</div>
			</div>
		) )
	}

	function parsingDate( dateString ) {
		let miliseconds = Date.parse( dateString )
		miliseconds += 259200000
		const newDate = new Date( miliseconds )
		const formattedNewDate = ` ${ newDate.getDate() }.${ newDate.getMonth() + 1 }.${ newDate.getFullYear() }`
		return formattedNewDate
	}

	function renderDeliveryDate() {
		return cartOrdersData.cart_orders.map( ( hubDelivery ) => (
			<div key={ hubDelivery.hub.id }>
				<span>{ `${ hubDelivery.hub.name } ` }</span>
				<span><Trans id='ShoppingCart.estimatedDeliveryDate' /></span>
				<span>
					{
						hubDelivery?.hub?.cycles?.length > 0
							? parsingDate(
								hubDelivery.hub.cycles[ hubDelivery.hub.cycles.length - 1 ].closed_time
							)
							: null
					}

				</span>
			</div>
		) )
	}

	function renderDeliveryHubCost() {
		return cartOrdersData.cart_orders.map( ( hubDelivery ) => (
			<div className='delivery-cost' key={ hubDelivery.hub.id }>
				{ !hubDelivery.customer_pickup ? (
					<div className='delivery-hub' key={ hubDelivery.hub.id }>
						<div className='delivery-type-hub'>
							<strong>
								{ `${ hubDelivery.hub.name } `}
							</strong>
						</div>
						<div className='delivery-price'>
							{
								` ${ getPriceFormatter( language ).format( hubDelivery.delivery_price_with_VAT ) } `

							}
							<Trans id='OrderSummary.withTax' />
						</div>
					</div>
				) : (
					<div className='delivery-hub' key='customerPickup'>
						<div className='delivery-type-hub'>
							<strong>
								<Trans id='OrderSummary.CustomerPickup' />
								{' '}
								{ `${ hubDelivery.hub.name } `}
							</strong>
						</div>
						<div className='delivery-price'>
							0 €
						</div>
					</div>
				) }
			</div>
		) )
	}

	function renderHubCommissionFeeCost() {
		return cartOrdersData.cart_orders.map( ( hubFee ) => (
			<div className='delivery-cost' key={ hubFee.hub.id }>
				{ hubFee?.commission_fee && hubFee?.commission_fee !== 0 ? (
					<div className='delivery-hub' key={ hubFee?.hub.id }>
						<div className='delivery-type-hub'>
							<strong>
								{ `${ hubFee?.hub.name } `}
							</strong>
						</div>
						<div className='delivery-price'>
							{
								` ${ getPriceFormatter( language ).format( hubFee?.commission_fee ) } `

							}
						</div>
					</div>
				) : (
					<div className='delivery-hub' key={ hubFee?.hub.id }>
						<div className='delivery-type-hub'>
							<strong>
								{ `${ hubFee?.hub.name } `}
							</strong>
						</div>
						<div className='delivery-price'>
							0 €
						</div>
					</div>
				) }
			</div>
		) )
	}

	function payedOrder() {
		for ( let i = 0; i < cartOrdersData.cart_orders.length; i += 1 ) {
			updateOrderToPayed( {
				variables: {
					id: cartOrdersData.cart_orders[ i ].id
				}
			} )
		}
	}

	async function payOrderWithPaypal() {
		const { REACT_APP_UHUB_CART_PAY } = process.env
		const orderIds = cartOrdersData.cart_orders.map( ( order ) => order.id )

		if ( orderIds && orderIds.length !== 0 ) {
			updateOrdersToInPaymentProcessing( {
				variables: {
					ids: orderIds
				}
			} )

			const orderIdsPayParameter = orderIds.join( '_' )
			console.log( 'orderIdsPayParameter', orderIdsPayParameter )
			try {
				console.log( `URL -------> ${ REACT_APP_UHUB_CART_PAY }/${ orderIdsPayParameter }` )
				const answer = await axios.get( `${ REACT_APP_UHUB_CART_PAY }/${ orderIdsPayParameter }` )
				console.log( 'answer ---> ', answer )
				window.location.replace( answer.data.authorizationRedirectUrl )

				sessionStorage.setItem( 'orderIdsPayParameter', orderIdsPayParameter )
				sessionStorage.setItem( 'transactionId', answer.data.id )
			} catch ( e ) {
				console.log( 'Cannot pay with Sunesis - paypal, error: ', e )
			}
		} else {
			// eslint-disable-next-line no-console
			console.error( 'Cannot pay with Sunesis - paypal as orderId is not provided!' )
		}
	}

	function parseDateToStreetAndNumber( address ) {
		const lastSpaceIndex = address.lastIndexOf( ' ' )

		return {
			streetName: address.substring( 0, lastSpaceIndex ),
			streetNumber: address.substring( lastSpaceIndex + 1, address.length ),
		}
	}

	async function payOrderWithBankart() {
		const { REACT_APP_UHUB_CART_PAY_BANKART } = process.env
		const orderIds = cartOrdersData.cart_orders.map( ( order ) => order.id )

		if ( orderIds && orderIds.length !== 0 ) {
			updateOrdersToInPaymentProcessing( {
				variables: {
					ids: orderIds
				}
			} )

			const orderIdsPayParameter = orderIds.join( '_' )

			const streetAndNumber = parseDateToStreetAndNumber(
				deliveryAddress.applications[ 0 ].data.address[ 0 ].address
			)

			const data = {
				orderIds: orderIdsPayParameter,
				streetName: streetAndNumber.streetName,
				streetNumber: streetAndNumber.streetNumber,
				postalCode: deliveryAddress.applications[ 0 ].data.address[ 0 ].zip,
				city: deliveryAddress.applications[ 0 ].data.address[ 0 ].city,
				countryAlpha2: 'SI',
				firstName: deliveryAddress?.owner?.firstName,
				lastName: deliveryAddress?.owner?.lastName,
				email
			}

			try {
				console.log( `URL -------> ${ REACT_APP_UHUB_CART_PAY_BANKART }, data: ${ data }` )
				const answer = await axios.post( `${ REACT_APP_UHUB_CART_PAY_BANKART }`, data )
				console.log( 'answer ---> ', answer )
				window.location.replace( answer.data.authorizationRedirectUrl )

				sessionStorage.setItem( 'orderIdsPayParameter', orderIdsPayParameter )
				sessionStorage.setItem( 'transactionId', answer.data.id )
			} catch ( e ) {
				console.log( 'Cannot pay with Sunesis - bankart, error: ', e )
			}
		} else {
			// eslint-disable-next-line no-console
			console.error( 'Cannot pay with Sunesis - bankart as orderId is not provided!' )
		}
	}

	return (
		<>
			{!greyOutScreen
				? (
					<div className='shopping-cart-summary-main-container'>
						<div className='delivery-summary-title'><Trans id='ShoppingCart.summaryOfYourPayment' /></div>
						{ cartOrdersLoading ? <Loader /> : null}
						{ cartOrdersError ? <div>{JSON.stringify( cartOrdersError )}</div> : null }
						{ productsInCartByHub ? (
							<div className='delivery-summary-form'>
								<div className='product-list'>
									{orderedProducts()}
								</div>
								<div className='delivery-date'>
									{hubCycleLoading ? <Loader /> : null}
									{hubCycleError ? <div>{JSON.stringify( hubCycleError )}</div> : null}
									{hubsCycles ? renderDeliveryDate() : null}
								</div>
								<div className='fee-cost-container'>
									<div className='delivery-text'>
										<Trans id='CommissionFeeMyOrders' />
									</div>
									{renderHubCommissionFeeCost()}
								</div>
								<div className='delivery-cost-container'>
									<div className='delivery-text'>
										<Trans id='ShoppingCart.delivery' />
									</div>
									{renderDeliveryHubCost()}
								</div>

								<div className='delivery-total-container'>
									<div className='delivery-type-text'><Trans id='ShoppingCart.selectedPaymentMethod' /></div>
									<div className='delivery-type'><Trans id='ShoppingCart.onlinePaymentWithACard' /></div>
									<div className='delivery-no-tax-text'><Trans id='ShoppingCart.forPaymentWithoutTax' /></div>
									{totalNetoError ? <div>{JSON.stringify( totalNetoError )}</div> : null}
									{totalNetoLoading ? <Loader /> : null}
									{totalNeto ? (
										<div className='delivery-no-tax-value'>
											{getPriceFormatter( language ).format( totalNeto )}
										</div>
									) : null}
									<div className='delivery-total'><Trans id='ShoppingCart.totalForPaymentWithTax' /></div>
									{totalBrutoError ? <div>{JSON.stringify( totalBrutoError )}</div> : null}
									{totalBrutoLoading ? <Loader /> : null}
									{totalBruto ? (
										<div className='delivery-total-value'>
											{getPriceFormatter( language ).format( totalBruto )}
										</div>
									) : null}
								</div>
								<div className='separator' />
								{addressLoading ? <Loader /> : null}
								{addressError ? <div>{JSON.stringify( addressError )}</div> : null}
								{addressData
									? (
										<div className='delivery-data'>
											<div className='address-to-deliver-text'><Trans id='ShoppingCart.shippingAddress' /></div>
											<div className='address-to-deliver-container'>
												<p className='name'>
													{`${ deliveryAddress?.owner?.firstName } ${ deliveryAddress?.owner?.lastName }`}
												</p>
												<p className='address'>
													{`${ deliveryAddress.applications[ 0 ].data.address[ 0 ].address }`}
												</p>
												<p className='zip'>
													{`${ deliveryAddress.applications[ 0 ].data.address[ 0 ].zip } ${ deliveryAddress.applications[ 0 ].data.address[ 0 ].city }`}
												</p>
												<p className='telephone'>
													{`${ deliveryAddress.applications[ 0 ].data.mobile }`}
												</p>
											</div>
											<div className='account-data-text'><Trans id='ShoppingCart.accountInformation' /></div>
											<div className='account-data-container'>
												<p className='name'>
													{`${ deliveryAddress?.owner?.firstName } ${ deliveryAddress?.owner?.lastName }`}
												</p>
												<p className='address'>
													{`${ deliveryAddress.applications[ 0 ].data.address[ 0 ].address }`}
												</p>
												<p className='zip'>
													{`${ deliveryAddress.applications[ 0 ].data.address[ 0 ].zip } ${ deliveryAddress.applications[ 0 ].data.address[ 0 ].city }`}
												</p>
												<p className='telephone'>
													{`${ deliveryAddress.applications[ 0 ].data.mobile }`}
												</p>
											</div>
										</div>
									) : null}
							</div>
						) : null}
						<div className='button-next-step'>
							<Button
								text={ <Trans id='Button.back' /> }
								disabled={ !false }
								onClick={ () => clickBack() }
							/>
							{productsInCartByHub && buildEnv !== 'development'
								? (
									<div>
										<div>
											<Button
												text={ <Trans id='PAY_WITH_PAYPAL' /> }
												disabled={ !false }
												onClick={ () => {
													setGreyOutScreen( true )
													payOrderWithPaypal()
												} }
											/>
										</div>
										<div>
											<Button
												text={ <Trans id='PAY_WITH_BANKART' /> }
												disabled={ !false }
												onClick={ () => {
													setGreyOutScreen( true )
													payOrderWithBankart()
												} }
											/>
										</div>
									</div>
								) : (
									<PayPalButton
										options={
											{
												clientId: REACT_APP_PAYPAL_CLIENT_ID,
												currency: 'EUR'
											}
										}
										amount={ totalBruto }
										// shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
										onSuccess={ ( details, data ) => {
											payedOrder()
											sendManyMails()
											clickNext()
										} }
										onError={ ( err ) => {
											// eslint-disable-next-line no-console
											console.error( `Transaction encountered errors ${ err }` )
										} }
									/>
								) }
						</div>

						{ /* <div className='payment-guarantee-title'>
						<Trans id='ShoppingCart.weGuarantee' /></div>
			<div className='payment-guarantee' /> */ }
					</div>
				) : <Loader />}
		</>
	)
}

OrderSummary.propTypes = {
	clickNext: propTypes.func.isRequired,
	clickNextPaymentFailed: propTypes.func.isRequired,
	clickBack: propTypes.func.isRequired,
}

export default OrderSummary
