import gql from 'graphql-tag'

const GET_PROVIDER_RATING = gql`
query getProviderRatingProviderMainInfoDetail( $id: bigint! ){
	provider_provider( where: { id: { _eq: $id } } ) {
		id
    	average_mark
  	}
}`

export default GET_PROVIDER_RATING
