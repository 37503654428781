import React from 'react'
import { Trans } from '@lingui/react'

import './TableHubCurrentCycleHeader.css'

const translations = {
	numCycle: <Trans id='TableHubCycleHeader.numCycle' />,
	startDate: <Trans id='TableHubCycleHeader.startDate' />,
	duration: <Trans id='TableHubCycleHeader.duration' />,
	endDate: <Trans id='TableHubCycleHeader.endDate' />,
	numOffers: <Trans id='TableHubCycleHeader.numOffers' />,
	numOrders: <Trans id='TableHubCycleHeader.numOrders' />
}

const TableHubCurrentCycleHeader = React.memo( () => (
	<div className='TableHubCurrentCycleHeader-container'>
		<div
			className='numCycle'
		>
			<span>{ translations.numCycle }</span>
		</div>
		<div
			className='startDate'
		>
			<span>{ translations.startDate }</span>
		</div>
		<div
			className='duration'
		>
			<span>{ translations.duration }</span>
		</div>
		<div
			className='endDate'
		>
			<span>{ translations.endDate }</span>
		</div>
		{/* <div
			className='numOffers'
		>
			<span>{ translations.numOffers }</span>
		</div> */}
		<div
			className='numOrders'
		>
			<span>{ translations.numOrders }</span>
		</div>
	</div>

) )

export default TableHubCurrentCycleHeader
