import React from 'react'
import { Trans } from '@lingui/macro'
import propTypes from 'prop-types'
import { useHistory } from 'react-router-dom'


import './HubRow.css'
import { LogoSmallIcon } from '../../../../icons'

const HubRow = React.memo( ( {
	hub, setHub, showSLA, pending, slaExist, marketExist
} ) => {
	const history = useHistory()

	function showSLAButton( input ) {
		setHub( input )
		if ( slaExist ) {
			showSLA( true )
		} else {
			showSLA( false )
		}
	}

	function showUploadSLAButton( input ) {
		setHub( input )
		showSLA( false )
	}

	return (
		<div id='hub-row-line'>
			<LogoSmallIcon fontSize={ 180 } />
			<div className='hub-row-line-info'>
				<span className='hub-row-text'>hub</span>
				<span className='hub-row-text-name'>{ hub.name }</span>
				<div className='hub-row-description'>{ hub.description }</div>
			</div>
			<div className='hub-row-line-cycle'>
				{/* <span>TODO Cikel,dostava </span> */}
			</div>
			<div id='my-hub-buttons-container'>
				{slaExist ? (
					<div
						className='hub-row-line-show-sla'
						role='button'
						tabIndex={ 0 }
						onKeyPress={ () => showSLAButton( hub ) }
						onClick={ () => showSLAButton( hub ) }
					>
						<Trans id='HubRow.showsla' />
						<div id='black-arrow' className='hub-row-line-arrow-right' />
					</div>
				) : null }
				{ pending ? null
					: (
						<>
							{ marketExist === false
								? (
									<button
										id='noMarketWarning'
										className='hub-row-line-show-hub'
										type='button'
										onClick={ () => history.push( '/my-offers/my-stands' ) }
									>
										<Trans id='HubRow.PleaseCreateMarket' />
									</button>
								)
								: (
									<div
										className='hub-row-line-show-hub'
										role='button'
										tabIndex={ 0 }
										onKeyPress={ () => showUploadSLAButton( hub ) }
										onClick={ () => showUploadSLAButton( hub ) }
									>
										<Trans id='HubRow.uploadSLA' />
										<div className='hub-row-line-arrow-right' />
									</div>
								)							}
						</>
					) }
			</div>
		</div>
	)
} )

HubRow.propTypes = {
	hub: propTypes.object.isRequired,
	setHub: propTypes.func.isRequired,
	showSLA: propTypes.func.isRequired,
	pending: propTypes.bool.isRequired,
	slaExist: propTypes.bool.isRequired,
	marketExist: propTypes.bool.isRequired,
}


export default HubRow
