/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, * as react from 'react'
import propTypes from 'prop-types'
import { useQuery, useMutation, useSubscription } from '@apollo/react-hooks'
import { Trans } from '@lingui/macro'
import { multiply, round } from 'mathjs'
import { composeTransforms } from 'graphql-tools/dist/transforms/transforms'
import roleHandler from '../../../utils/ContentRoleHandler'
import { useStateValue } from '../../../stores/contextStore/stateStore'
import mainStore from '../../../stores/mainStore'

import CheckBox from '../../_Globals/CheckBox'
import ImageSlider from '../../ImageSlider'
import EditArea from '../../_Globals/EditArea'
import Dropdown from '../../_Globals/Dropdown'
import DropdownMyOrders from '../../_Globals/DropdownMyOrders'
import ConditionalWrapper from '../../_Globals/ConditionalWrapper'
import Button from '../../_Globals/Button/Button'
import ImageGallery from '../../_Globals/ImageGallery'
import Loader from '../../Loader'
import CartBlueIcon from '../../../icons/CartBlueIcon'
import { addNotification, addNotificationAddProductToCart } from '../../../utils/messageHandler/notifierStack'
import getPriceFormatter from '../../../utils/PriceFormatter'
import getNumberFormatter from '../../../utils/NumberFormater'
import regex from './ProductViewRegex'

import {
	GET_PRODUCT_BY_ID,
	SUBSCRIPTION_BRUTO_PRICE,
	GET_MARKETS,
	GET_PRODUCT_MARKET,
	HASURA_ADD_PRODUCT_TO_MARKET,
	HASURA_REMOVE_PRODUCT_FROM_MARKET,
	GET_UNITS_QUANTITIES,
	INSERT_ITEM_TO_CART,
	SUBSCRIPTION_QUANTITY,
	GET_TAX_RATES,

} from './ProductViewGQL'

import './ProductView.css'

/* global sessionStorage */

// For displaying price without rounding  https://helloacm.com/javascripts-tofixed-implementation-without-rounding/
// eslint-disable-next-line no-extend-native
Number.prototype.toFixedNoRounding = function ( n ) {
	const reg = new RegExp( `^-?\\d+(?:\\.\\d{0,${ n }})?`, 'g' )
	const a = this.toString().match( reg )[ 0 ]
	const dot = a.indexOf( '.' )
	if ( dot === -1 ) { // integer, insert decimal dot and pad up zeros
		return `${ a }.${ '0'.repeat( n ) }`
	}
	const b = n - ( a.length - dot ) + 1
	return b > 0 ? ( a + '0'.repeat( b ) ) : a
}

const ProductView = React.memo( ( {
	product,
	editableFields,
	passText,
	inputData,
	itemsRefetch,
	getTaxValue,
	getProductId,
	isSuccessfull,
	newProduct,
	back
} ) => {
	const [ { isAuth } ] = useStateValue()
	const { language } = mainStore
	const tenantID = sessionStorage.getItem( 'uhub_tenantId' )
	const uuidValue = sessionStorage.getItem( 'uuid' )

	const [ lockCartButton, setLockCartButton ] = react.useState( false )
	const [ editable, toggleEditable ] = react.useState( false )
	const [ { galleryOpened }, openGallery ] = useStateValue()
	const [ editedText, setEditedText ] = react.useState( {} )
	const [ selectedQuantity, getQuantity ] = react.useState( null )
	const [ selectedTaxValue, selectTaxValue ] = react.useState( product.product.ddv_enum )

	const [ productQuantity, getNewProductQuantity ] = react.useState( null )

	const {
		data: productViewData,
		error: productViewError,
		loading: productViewLoading,
		refetch: productViewRefetch,
	} = useQuery( GET_PRODUCT_BY_ID,
		{
			variables: {
				id: product.product.id
			},
			onCompleted: ( data ) => {
				if ( data.provider_product.length > 0 ) {
					getNewProductQuantity( data.provider_product[ 0 ].quantity )
				}
			},
		} )

	const {
		data: dataMarkets,
		loading: loadingMarkets,
		error: errorMarkets,
	} = useQuery( GET_MARKETS, {
		variables: {
			tenant_id: tenantID || product.product.tenant_id
		}
	} )

	const {
		data: dataProductMarket,
		error: errorProductMarket,
		refetch: refetchProductMarkets
	} = useQuery( GET_PRODUCT_MARKET, {
		variables: {
			product_id: product.product.id,
		},
	} )

	// Query for getting dropdown values for specific unit
	const {
		data: quantitiesData,
		error: quantitiesError,
	} = useQuery( GET_UNITS_QUANTITIES, {
		variables: {
			unit_id: product.product.gs1_brick.unit.id,
		}
	} )

	const {
		data: taxRateData,
		error: taxRateError,
		loading: taxRateLoading,
	} = useQuery( GET_TAX_RATES )

	// Query for inserting item/product into cart list
	const [ insertItemToCart ] = useMutation( INSERT_ITEM_TO_CART, {
		variables: {
			hub_id: product.market ? product.market.hub_id : null,
			market_id: product.market_id ? product.market_id : null,
			product_id: product.product.id,
			quantity: selectedQuantity,
			unit_id: product.product.gs1_brick.unit.id,
			status_id: 1,
			cart_uuid: uuidValue,
			customer_id: tenantID
		},
		onCompleted: ( data ) => {
			if ( productQuantity < selectedQuantity
				&& data.insert_cart_items
				&& data.insert_cart_items.returning[ 0 ].id
			) {
				addNotificationAddProductToCart( 'ERROR', 'NOT_ENOUGH_OF_PRODUCT_ON_STOCK' )
			}

			if ( !( productQuantity < selectedQuantity )
					&& data.insert_cart_items
					&& data.insert_cart_items.returning[ 0 ].id
			) {
				addNotificationAddProductToCart( 'OK', 'PRODUCT_ADDED_TO_CART' )
				setLockCartButton( true )
			}
			if ( data.insert_cart_items.returning[ 0 ].id === undefined
					|| data.insert_cart_items.returning[ 0 ].id === null
			) {
				addNotificationAddProductToCart( 'ERROR', 'CANNOT_ADD_PRODUCT_TO_CART' )
			}
		},
		onError: ( error ) => {
			if ( error ) {
				addNotificationAddProductToCart( 'ERROR', 'CANNOT_ADD_PRODUCT_TO_CART' )
			}
		},
	} )

	const {
		data: subscribeQuantity,
		error: subscribeQuantityError
	} = useSubscription( SUBSCRIPTION_QUANTITY, {
		variables: {
			productId: product.product.id
		},
		fetchPolicy: 'no-cache',
		onSubscriptionData: ( data ) => {
			if ( data ) {
				setLockCartButton( false )
			}
			if ( data?.subscriptionData?.data?.provider_product[ 0 ]?.quantity ) {
				let smallestQuantity = 100000000

				const quantitiesLength = quantitiesData?.code_register_units_default_quantities?.length ?? 0

				// eslint-disable-next-line max-len
				for ( let i = 0; i < quantitiesLength; i += 1 ) {
					// eslint-disable-next-line max-len
					if ( quantitiesData?.code_register_units_default_quantities[ i ]?.quantity < smallestQuantity ) {
						smallestQuantity = quantitiesData?.code_register_units_default_quantities[ i ]?.quantity
					}
				}

				if ( data?.subscriptionData?.data?.provider_product[ 0 ]?.quantity < smallestQuantity ) {
					if ( quantitiesLength !== 0 ) {
						setLockCartButton( true )
						addNotificationAddProductToCart( 'ERROR', 'PRODUCT_IS_OUT_OF_STOCK' )
					}
				}
			}
		}
	} )

	const {
		data: subscribeBrutoPrice,
	} = useSubscription( SUBSCRIPTION_BRUTO_PRICE, {
		variables: {
			id: product.product.id
		},
		fetchPolicy: 'no-cache',
		onSubscriptionData: () => {
		}
	} )

	const brutoPrice = subscribeBrutoPrice
		&& subscribeBrutoPrice.provider_product.length > 0
		? subscribeBrutoPrice.provider_product[ 0 ].bruto_price : false

	const quantityValue = subscribeQuantity
		&& subscribeQuantity.provider_product.length > 0
		? subscribeQuantity.provider_product[ 0 ].quantity : false


	const qualityValue = subscribeQuantity
	&& subscribeQuantity.provider_product.length > 0
		? subscribeQuantity.provider_product[ 0 ].quality : false

	const taxRates = taxRateData
		&& taxRateData.provider_ddv_enum.length > 0
		? taxRateData.provider_ddv_enum : []

	const [ addProductOnMarket ] = useMutation( HASURA_ADD_PRODUCT_TO_MARKET )

	const [ removeProductFromMarket ] = useMutation( HASURA_REMOVE_PRODUCT_FROM_MARKET )

	react.useEffect( () => {
		if ( roleHandler( isAuth, [ 'provider' ] ) ) {
			toggleEditable( editableFields )
		}
	}, [ editableFields, isAuth ] )

	react.useEffect( () => {
		if ( Object.keys( editedText ).length > 0 ) {
			passText( editedText )
			setEditedText( {} )
		}
	}, [ editedText, passText ] )

	getTaxValue( selectedTaxValue )
	getProductId( product.product.id )
	back( false )

	// removes quantities that are more than in stock from the dropdown
	function validQuantities() {
		const validQuantitiesArray = []
		let quantitiesInDropdown = []
		let sortedQuantities = []
		if ( quantitiesData && quantitiesData.code_register_units_default_quantities
			&& quantitiesData.code_register_units_default_quantities.length > 0
			&& subscribeQuantity
			&& subscribeQuantity.provider_product.length > 0 ) {
			for ( let i = 0; i < quantitiesData.code_register_units_default_quantities.length; i += 1 ) {
				if ( quantitiesData.code_register_units_default_quantities[ i ].quantity
					<= subscribeQuantity.provider_product[ 0 ].quantity ) {
					validQuantitiesArray[ i ] = {
						quantity: quantitiesData.code_register_units_default_quantities[ i ].quantity
					}
				}
			}
			if ( validQuantitiesArray.length === 0 ) {
				validQuantitiesArray[ 0 ] = {
					quantity: 0
				}
				if ( !lockCartButton ) {
					setLockCartButton( true )
					addNotificationAddProductToCart( 'ERROR', 'PRODUCT_IS_OUT_OF_STOCK' )
				}
			}

			quantitiesInDropdown = validQuantitiesArray.map( ( obj ) => obj.quantity )
			sortedQuantities = quantitiesInDropdown.sort( ( a, b ) => a - b )
			const biggestValidQuantity = sortedQuantities[ sortedQuantities.length - 1 ]
			if ( selectedQuantity > biggestValidQuantity ) {
				getQuantity( biggestValidQuantity )
			}

			return validQuantitiesArray
		}
		if ( validQuantitiesArray.length === 0 ) {
			validQuantitiesArray[ 0 ] = {
				quantity: 0
			}
		}

		return validQuantitiesArray
	}

	function checkInput( input ) {
		let changedInput = input.replace( /[^0-9.,]/g, '' ) // replace all NON digits, dots and commas
		changedInput = changedInput.replace( /,/g, '.' )
		const firstOccuranceIndex = changedInput.search( /\./ ) + 1 // Index of first occurance of (.)

		changedInput = changedInput.substr( 0, firstOccuranceIndex )
								+ changedInput.slice( firstOccuranceIndex ).replace( /\./g, '' )
		return changedInput
	}

	function getText( id, text ) {
		const objectCopy = editedText
		if ( !objectCopy.id ) {
			objectCopy.id = product.product.id || false
		}

		objectCopy[ id ] = text


		if ( objectCopy.neto_price !== undefined && objectCopy.neto_price !== 0 && objectCopy.neto_price !== '' ) {
			objectCopy.neto_price = checkInput( objectCopy.neto_price )
		}

		if ( objectCopy.neto_price === '' || objectCopy.neto_price === ',' || objectCopy.neto_price === '.' ) {
			objectCopy.neto_price = '0'
		}

		if ( objectCopy.quantity === '' || objectCopy.quantity === ',' || objectCopy.quantity === '.' ) {
			objectCopy.quantity = '0'
		}

		if ( objectCopy.quantity !== undefined && objectCopy.quantity.length > 0 && ( objectCopy?.quantity?.indexOf( ',' ) >= 0 || objectCopy?.quantity?.indexOf( '.' ) >= 0 ) ) {
			objectCopy.quantity = checkInput( objectCopy.quantity )
		}

		if ( objectCopy.ddv_enum ) {
			getTaxValue( selectedTaxValue )
		}

		setEditedText( objectCopy )
	}
	const markets = dataMarkets && dataMarkets.provider_market ? dataMarkets.provider_market : []

	if ( quantitiesError ) {
		return (
			<div>
				{ JSON.stringify( quantitiesError ) }
			</div>
		)
	}

	const productMarkets = dataProductMarket
		&& dataProductMarket.provider_market_has_product.length > 0
		? dataProductMarket.provider_market_has_product : null

	const viewData = productViewData
		&& productViewData.provider_product
		? productViewData.provider_product[ 0 ] : false

	if ( subscribeQuantityError ) {
		return (
			<div>
				{ JSON.stringify( subscribeQuantityError ) }
			</div>
		)
	}

	function productAlreadyOnMarket( marketId, productId ) {
		refetchProductMarkets()
		if ( productMarkets !== null ) {
			const arr = productMarkets.map(
				( market ) => market.market_id === marketId && market.product_id === productId
			)
			return arr.indexOf( true ) > -1
		}
		return false
	}

	function displayProductNameTitle() {
		if ( viewData.variety ) {
			return `${ viewData.gs1_brick.translation_sl.charAt( 0 ).toUpperCase() + viewData.gs1_brick.translation_sl.slice( 1 ) } - ${ viewData.variety.name }`
		}
		return `${ viewData.gs1_brick.translation_sl.charAt( 0 ).toUpperCase() + viewData.gs1_brick.translation_sl.slice( 1 ) }`
	}

	function addProductOnMarketFunct( marketId, prodId ) {
		addProductOnMarket( {
			variables: {
				product_id: prodId,
				market_id: marketId,
			}
		} ).then(
			( res ) => {
				if ( res
					&& res.data
					&& res.data.insert_provider_market_has_product
					&& res.data.insert_provider_market_has_product.affected_rows > 0 ) {
					addNotification( 'OK', 'PRODUCT_ADDED_TO_MARKET' )
				}
			}
		).catch(
			( err ) => {
				if ( err ) { addNotification( 'ERROR', 'CANNOT_ADD_PRODUCT_ON_MARKET' ) }
			}
		)
	}

	function removeProductFromMarketFunct( marketId, prodId ) {
		removeProductFromMarket( {
			variables: {
				product_id: prodId,
				market_id: marketId,
			}
		} ).then(
			( res ) => {
				if ( res && res.data
					&& res.data.delete_provider_market_has_product
					&& res.data.delete_provider_market_has_product.affected_rows > 0 ) {
					addNotification( 'OK', 'PRODUCT_REMOVED_FROM_MARKET' )
				}
			}
		).catch(
			( err ) => {
				if ( err ) { addNotification( 'ERROR', 'CANNOT_REMOVE_PRODUCT_ON_MARKET' ) }
			}
		)
	}

	function addProductToCart() {
		if ( !lockCartButton && !( productQuantity < selectedQuantity ) ) { insertItemToCart() }
		if ( !lockCartButton && productQuantity < selectedQuantity ) {
			addNotificationAddProductToCart( 'ERROR', 'NOT_ENOUGH_OF_PRODUCT_ON_STOCK' )
		}
	}

	function displayQuality() {
		if ( qualityValue || inputData.quality ) {
			return (
				<p>
					<Trans id='ItemComponent.Quality' />
					{ `: ${ qualityValue || inputData.quality }. ` }
					<Trans id='ItemComponent.Class' />
				</p>
			)
		}
		return (
			<p>
				<Trans id='ItemComponent.Quality' />
				:
				<Trans id='ItemComponent.noClass' />
			</p>
		)
	}

	function displayPriceValue() {
		if ( inputData.neto_price ) {
			return getNumberFormatter( language ).format( inputData.neto_price )
		}
		if ( !inputData.neto_price ) {
			if ( viewData.neto_price ) {
				return getNumberFormatter( language ).format( viewData.neto_price )
			}
			return 0
		}
		return 0
	}

	function onTaxChangeUpdatePrice( val ) {
		selectTaxValue( val )
		passText( editedText )
		setEditedText( {} )

		getProductId( product.product.id )
	}

	return (
		<div id='product-view-container'>
			<div id='product-view-picture-slider' style={ { backgroundColor: '#fffffff' } }>
				{ productViewLoading ? <Loader /> : null }
				{ productViewError ? <Trans id='ProductView.cannotDisplayProductImages' /> : null }
				{ viewData && viewData.galleries
					? (
						<ImageSlider
							galleryItems={ viewData.galleries.length > 0 ? viewData.galleries : [] }
							galleryType='product'
							product={ viewData }
						/>
					)
					: null }
				{ editable ? (
					<div className='gallery-button'>
						<Button
							text={ <Trans id='UploadPic.button' /> }
							disabled
							onClick={ () => openGallery( { type: 'galleryOpened', galleryOpened: true } ) }
						/>
					</div>
				) : null}
			</div>
			{galleryOpened
				? (
					<ImageGallery
						gallery='ProductView'
						title={ <Trans id='ImageGallery.titleMyProducts' /> }
						itemsRefetch={ () => itemsRefetch() }
						galleryRefetch={ () => productViewRefetch() }
						productId={ product.product.id }
						marketId={ null }
						hubId={ null }

					/>
				) : null}
			{ productViewError ? <Trans id='ProductView.cannotDisplayProductInfo' /> : null }
			{ subscribeQuantityError ? <div>{ JSON.stringify( subscribeQuantityError )}</div> : null }
			{ productViewLoading ? <Loader /> : null }
			{ productViewData
				? (
					<div id='product-view-info'>
						<div id='product-view-first-row'>
							<div className='product-view-first-row-name'>
								<span id='title' />
								<EditArea
									id='productViewTitle'
									// getText={ getText }
									myStyle='product-view-title-editable'
									text={ displayProductNameTitle() }
									editable={ false }
									placeholder={ language === 'sl' ? 'Izdelek...' : 'Product...' }
								/>
							</div>
							<div className='title-actions' />
						</div>
						<div className='product-description'>
							<EditArea
								id='description'
								getText={ getText }
								text={ viewData.description }
								editable={ editable }
								placeholder={ language === 'sl' ? 'Opis...' : 'Description...' }
							/>
						</div>
						<ConditionalWrapper
							condition={ editableFields }
							falseConditionComponent={ (
								<div className='product-view-quality'>
									<div className='product-view-item-data'>
										{ displayQuality() }
										<p className='product-view-one-line'>
											<Trans id='ItemComponent.PricePerKilo' />
											{ ` ${ viewData.gs1_brick.unit.unit }` }
											{`: ${ getNumberFormatter( language ).format( brutoPrice ) } EUR `}
											<Trans id='ItemComponent.withTax' />
										</p>
										<p className='product-view-one-line'>
											<Trans id='ItemComponent.Avalible' />
											{`: ${ getNumberFormatter( language ).format( round( quantityValue || inputData.quantity || 0, 2 ) ) } ${ viewData.gs1_brick.unit.unit }`}
										</p>
									</div>
								</div>
							) }
						>
							<div className='product-view-quality'>
								<div className='product-view-item-data'>
									<p className='product-view-one-line'>
										<Trans id='ItemComponent.Quality' />
										<EditArea
											id='quality'
											getText={ getText }
											text={ inputData.quality || viewData.quality }
											editable={ editable }
											regex={ regex.quality }
											isInput
										/>
										<Trans id='ItemComponent.Class' />
									</p>
									<p className='product-view-one-line'>
										<Trans id='ItemComponent.PricePerKilo' />
										{ ` ${ viewData.gs1_brick.unit.unit }` }
										<EditArea
											id='neto_price'
											getText={ getText }
											text={
												displayPriceValue()
											}
											editable={ editable }
											regex={ regex.price }
											isInput
										/>
										<span>EUR</span>
									</p>
									<p className='product-view-one-line'>
										<Trans id='ItemComponent.Avalible' />
										<EditArea
											id='quantity'
											getText={ getText }
											// eslint-disable-next-line max-len
											text={ round( ( inputData.quantity || quantityValue || 0 ), 2 ) }
											editable={ editable }
											regex={ regex.quantity }
											isInput
										/>
										<span>{`${ viewData.gs1_brick.unit.unit }` }</span>
									</p>
									{ taxRateError ? <Trans id='ProductView.cannotDisplayProductInfo' /> : null }
									{ taxRateLoading ? <Loader /> : null }
									{ taxRateData
										? (
											<div className='product-view-info-dropdown'>
												<div><Trans id='ItemComponent.taxRate' /></div>
												<DropdownMyOrders
													id={ product.product.id }
													unit='%'
													ddvValue={
														selectedTaxValue || selectedTaxValue === 0
															? selectedTaxValue : viewData.ddv_enum
													}
													data={ taxRates }
													getQuantity={ ( quantity ) => getQuantity( quantity ) }
													type='taxRate'
													selectTaxValue={ ( taxValue ) => onTaxChangeUpdatePrice( taxValue ) }
												/>
											</div>
										) : null }
								</div>
							</div>
						</ConditionalWrapper>
						<ConditionalWrapper
							condition={ editable }
							falseConditionComponent={ (
								<div>
									{ window.location.pathname.indexOf( '/my-offers/my-offer' ) === -1
										? (
											<div className='product-view-dropdown-main'>
												<span className='product-view-dropdown-text'>
													<Trans id='ProductView.chooseQuantity' />
													:
												</span>
												<div className='product-view-dropdown-grid-container'>
													<span className='product-view-dropdown'>
														<Dropdown
															unit={ viewData.gs1_brick.unit.unit }
															data={ validQuantities() }
															getQuantity={ ( quantity ) => getQuantity( quantity ) }
															type='quantity'
														/>
													</span>
													<span className='product-view-dropdown-data'>
														{ selectedQuantity ? ` ${ getPriceFormatter( language ).format( multiply( selectedQuantity, viewData.bruto_price ).toFixedNoRounding( 2 ) ) }` : null }
													</span>
													<div
														className={ !lockCartButton ? 'dropdown-image' : 'basket-clicked' }
														role='button'
														tabIndex={ -1 }
														onKeyPress={ () => addProductToCart() }
														onClick={ () => addProductToCart() }
														disable={ lockCartButton.toString() }
													>
														<CartBlueIcon fontSize={ 25 } />
													</div>
												</div>
											</div>
										) : null}
								</div>
							) }
						>
							<div />
						</ConditionalWrapper>
					</div>
				) : null }
			<ConditionalWrapper
				condition={ editable && editable !== null }
				falseConditionComponent={ ( <div /> ) }
			>
				<>
					<div id='dots-row' />
					<div className='product-view-hubs-to-edit'>
						{ errorProductMarket ? <Trans id='ProductView.errorCantGetProductMarket' /> : null }
						{ errorMarkets ? <Trans id='ProductView.errorCanGetProviderMarkets' /> : null }
						{ loadingMarkets ? <Loader /> : null }
						{ markets.length > 0 && !productViewError ? markets.map( ( market ) => (
							<div className='hub-to-edit' key={ market.id }>
								<div
									className='hub-checkpoint'
								>
									<CheckBox
										id={ market.id.toString() }
										name='option-one'
										value={ market.id }
										isChecked={ productAlreadyOnMarket( market.id, product.product.id ) }
										onSelect={ () => addProductOnMarketFunct( market.id, product.product.id ) }
										// eslint-disable-next-line max-len
										onDeselect={ () => removeProductFromMarketFunct( market.id, product.product.id ) }
									/>
								</div>
								<div className='hub-data'>
									<div className='hub-tag'>
										<Trans id='ProductView.market' />
									</div>
									<div className='hub-name'>{ market.name }</div>
									<p>
										<Trans id='ProductViewHubData.marketActive:' />
										{ market.active ? <Trans id='ProductView.marketActive' /> : <Trans id='ProductView.Inactive' /> }
									</p>
									<p>
										<Trans id='ProductViewHubData.marketConfirmed:' />
										{ market.confirmed ? <Trans id='ProductView.marketConfirmed' /> : <Trans id='ProductView.marketUnconfirmed' /> }
									</p>
								</div>
							</div>
						) )
							: (
								<div />
							) }
					</div>
				</>
			</ConditionalWrapper>
			<div id='dots-row' />
		</div>
	)
} )


ProductView.defaultProps = {
	editableFields: false,
	passText: () => {},
	inputData: {
		description: '', quality: '', price: '', quantity: ''
	},
	itemsRefetch: () => {},
	getTaxValue: () => {},
	getProductId: () => {},
	isSuccessfull: false,
	newProduct: false,
	back: () => {}
}

ProductView.propTypes = {
	product: propTypes.object.isRequired,
	editableFields: propTypes.bool,
	passText: propTypes.func,
	inputData: propTypes.object,
	itemsRefetch: propTypes.func,
	getTaxValue: propTypes.func,
	getProductId: propTypes.func,
	isSuccessfull: propTypes.bool,
	newProduct: propTypes.bool,
	back: propTypes.func,
}

export default ProductView
