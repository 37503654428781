/* eslint-disable camelcase */
import React, { useEffect, useState, memo } from 'react'
import { useHistory } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import gql from 'graphql-tag'
import axios from 'axios'
import {
	useMutation, useLazyQuery
} from '@apollo/react-hooks'

import './SuccessfullPayment.css'
import mainStore from '../../../stores/mainStore'
import { ConfirmIcon } from '../../../icons'
import Button from '../../_Globals/Button/Button'

/* global sessionStorage */

const GET_CART_ORDERS = gql`
query getCartOrder(
	$orderIds: [ bigint! ]
){
    cart_orders( where: { id: { _in: $orderIds } } ) {
        id
        total_bruto_price
        items {
			id
            product {
				id
                gs1_brick {
					id
                    translation_sl
                    unit {
						id
                        unit
                    }
                }
            }
            market {
				id
                name
            }
            bruto_price
            quantity
        }
        qr_code {
			id
            base64_data
        }
    }
}
`

const UPDATE_ORDERS_STATUS_TO_PAYED = gql`
	mutation updateOrderToPayed( 
		$ids: [ bigint! ]
	) {
		update_cart_orders( where: { id: { _in: $ids } }, _set: { status_id: 4 } ) {
			affected_rows
		}
	}
`

const SEND_MAIL = gql`
	query sendMail( 
		$template: String!
		$locals: String
		$recipient: String!
		$locale: String
	) {
		sendMail( 
			input: {
				recipient: $recipient,
				template: $template,
				locals: $locals
			}
		)
	}
`

//  Helper function - Check if URL has all the params...
const isUrlFromPaypal = ( queryString ) => {
	const urlParams = new URLSearchParams( queryString )
	if ( urlParams.has( 'paymentId' ) && urlParams.has( 'token' ) && urlParams.has( 'PayerID' ) ) {
		return true
	}
	return false
}

//  Helper function - Get payerId from URL...
const getPayerIdFromUrl = ( queryString ) => {
	const urlParams = new URLSearchParams( queryString )
	return urlParams.get( 'PayerID' )
}

//  Helper function - End transaction at Sunesis
const endTransaction = async ( orderIds, payerId ) => {
	const transactionId = sessionStorage.getItem( 'transactionId' )
	const { REACT_APP_UHUB_CART_END } = process.env
	const answer = await axios.post( `${ REACT_APP_UHUB_CART_END }`,
		{ transactionId, payerId, orderIds } )

	// TODO nothing to do with answer? Should happen something in case it fails

	console.log( '----> endTransaction successful <-----', answer?.statusText )
}

// Helper function - Handle email items string
const mailItemsString = ( cartOrder ) => {
	let itemsString = ''

	for ( let i = 0; i < cartOrder.items.length ?? 0; i += 1 ) {
		const productName = cartOrder.items[ i ]?.product?.gs1_brick?.translation_sl ?? '-'
		const marketName = cartOrder.items[ i ]?.market?.name ?? '-'
		const quantity = `${ cartOrder.items[ i ]?.quantity ?? '-' } ${ cartOrder.items[ i ]?.product?.gs1_brick?.unit?.unit ?? '-' }`
		const price = `€  ${ cartOrder.items[ i ]?.bruto_price ?? '-' }`

		itemsString += `'${ productName } - ${ marketName } - ${ quantity } - ${ price }',`
	}
	itemsString = itemsString.substring( 0, itemsString.length - 1 )

	return itemsString
}

// Helper function - Remove items from sessionStorage and query params from URL.
const completePayment = ( history ) => {
	sessionStorage.removeItem( 'transactionId' )
	sessionStorage.removeItem( 'orderIdsPayParameter' )

	history.push( '/payment-successful' )
}

//  Main component
const SuccessfullPayment = memo( () => {
	const history = useHistory()
	const [ orderIds, setOrderId ] = useState( null )
	const { email } = mainStore?.user
	const queryString = window.location.search
	const orderIdsPayParameter = sessionStorage.getItem( 'orderIdsPayParameter' )

	//  Update orders status
	const [ updateOrdersToPayed ] = useMutation( UPDATE_ORDERS_STATUS_TO_PAYED, {
		onCompleted: () => {
			console.log( '----> updateOrdersToPayed successful <-----' )
		},
		onError: ( err ) => {
			console.log( 'Error at updating order status -->', err )
		}
	} )

	//  Send mail query
	const [ getSendMail ] = useLazyQuery( SEND_MAIL, {
		onCompleted: () => {
			console.log( '----> getSendMail successful <-----' )
		},
		onError: ( err ) => {
			console.log( 'Error at sending mail ---> ', err )
		}
	} )

	// handleSendMail
	async function handleSendMails( orders ) {
		if ( orders.length > 0 ) {
			orders.forEach( ( order ) => {
				const qrCode = order?.qr_code?.base64_data
				const localsString = `{ 'orderId': ${ order?.id }, 'qr_code': '${ qrCode }', 'items': [${ mailItemsString( order ) }] }`

				getSendMail( {
					variables: {
						template: 'orderPaid',
						locals: localsString,
						recipient: email
					}
				} )
			} )
		}
		console.log( '----> handleSendMails successful <----- number of mails:', orders.length )
	}

	//  Get cart orders
	const [
		getCartOrders,
		{ called: ordersCalled }
	] = useLazyQuery( GET_CART_ORDERS, {
		fetchPolicy: 'network-only',

		//  On completed Query end transaction, update order status and send mail.
		onCompleted: async ( data ) => {
			await endTransaction( orderIdsPayParameter, getPayerIdFromUrl( queryString ) )
			await updateOrdersToPayed( {
				variables: {
					ids: orderIds
				}
			} )

			await handleSendMails( data?.cart_orders )

			completePayment( history )
			console.log( '----> end of payment successful <-----' )
		},
		onError: ( err ) => {
			console.log( 'Error from GET_CART_ORDER query --> ', err )
		}
	} )

	//  UseEffect for executing lazyQuery
	useEffect( () => {
		if ( !ordersCalled && orderIds ) {
			if ( isUrlFromPaypal( queryString ) ) {
				console.log( 'Execute query --> getCartOrders. Came from Paypal.' )
				getCartOrders( { variables: { orderIds } } )
			} else {
				console.log( 'Execute query --> getCartOrders. Came from Bankart.' )
				getCartOrders( { variables: { orderIds } } )
			}
		}
	} )

	// Set OrderIds
	if ( orderIdsPayParameter && !orderIds ) {
		setOrderId( orderIdsPayParameter.split( '_' ) )
	}

	return (
		<div className='shopping-cart-success-main-container'>
			<div className='success-main-container'>
				<div className='message-container'>
					<div className='OK_icon'><ConfirmIcon fontSize={ 80 } color='#639676' /></div>
					<div className='OK_message'><Trans id='ShoppingCart.mainOkMessange' /></div>
					<div className='OK_notice_message'><Trans id='ShoppingCart.message' /></div>
				</div>
			</div>
			<div className='button-next-step'>
				<Button
					text={ <Trans id='ShoppingCart.continueWithShopping' /> }
					disabled={ !false }
					tabIndex={ -1 }
					onKeyPress={ () => history.push( '/' ) }
					onClick={ () => history.push( '/' ) }
				/>
			</div>
		</div>
	)
} )

export default SuccessfullPayment
