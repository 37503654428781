/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react'
import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'
import { useQuery, useSubscription } from '@apollo/react-hooks'
import Swiper from 'react-id-swiper'
import { Navigation } from 'swiper/dist/js/swiper.esm'
import ReactPlaceholder from 'react-placeholder'
import ProductComponentMostSelling from '../ProductComponentMostSelling'
import ProductComponentPlaceholder from '../ProductComponent/components/ProductComponentPlaceholder'
import 'react-placeholder/lib/reactPlaceholder.css'

import './SliderRow.css'
import './SliderDefault.css'
import Loader from '../Loader'

import {
	GET_MOST_SELLING_PRODUCTS,
	GET_MOST_SELLING_PRODUCTS_SUBSCRIPTION,
	GET_ALL_DEFAULT_QUANTITIES
} from './SliderRowMostSellingProductsGQL'

const SliderRowMostSellingProducts = ( {
	isHubMenuClosed,
	marketFilters,
	gs1Filters
} ) => {
	const [ swiper, updateSwiper ] = React.useState( null )
	const [ productsDisplay, getNewProductsToDisplay ] = React.useState( [] )
	const [ beginning, isBeginning ] = React.useState( !( swiper && swiper.isBeginning === false ) )
	const [ end, isEnd ] = React.useState( !( swiper && swiper.isEnd === false ) )

	const {
		data: mostSellProducts,
		loading: mostSellProductsLoading,
		error: mostSellProductsError,
		fetchMore: mostSellProductsFetchMore,
	} = useQuery( GET_MOST_SELLING_PRODUCTS, {
		variables: {
			offset: 0,
			limit: 25,
			// marketFilters: marketFilters.length > 0 ? marketFilters : null,
			// gs1Filters: gs1Filters.length > 0 ? gs1Filters : null,
		},
		fetchPolicy: 'cache-and-network',
	} )

	const products = mostSellProducts
		&& mostSellProducts.cart_most_orders
		&& mostSellProducts.cart_most_orders.length > 0
		? mostSellProducts.cart_most_orders : []

	const {
		data: defaultQuantities,
		loading: defaultQuantitiesLoading,
		error: defaultQuantitiesError
	} = useQuery( GET_ALL_DEFAULT_QUANTITIES )


	//	TODO - Create better breakpoints and test
	const params = {
		spaceBetween: 10,
		noSwiping: true,
		threshold: 10000,
		slidesPerView: isHubMenuClosed ? 4 : 3,
		observer: true,
		loop: false,
		shouldSwiperUpdate: true,
		breakpoints: {
			// when window width is <= 480px
			480: {
				slidesPerView: 1,
			},
			// when window width is <= 640px
			640: {
				slidesPerView: 2,
			},
			760: {
				slidesPerView: isHubMenuClosed ? 2 : 1,
			},
			// when window width is <= 1080px
			1080: {
				slidesPerView: isHubMenuClosed ? 3 : 2,
			}
		}
	}

	const {
		data: subscribeHubProducts,
		loading: subscribemostSellProductsLoading,
		error: subscribemostSellProductsError
	} = useSubscription( GET_MOST_SELLING_PRODUCTS_SUBSCRIPTION, {
		variables: {
			limit: products.length,
			marketFilters: marketFilters.length > 0 ? marketFilters : null,
			gs1Filters: gs1Filters.length > 0 ? gs1Filters : null,
		},
		onSubscriptionData: ( data ) => {
			// eslint-disable-next-line camelcase
			getNewProductsToDisplay( data?.subscriptionData?.data?.cart_most_orders ?? [] )
		}
	} )

	function onLoadMoreHubProducts() {
		mostSellProductsFetchMore( {
			variables: {
				offset: mostSellProducts.cart_most_orders.length
			},
			updateQuery: ( prev, { fetchMoreResult } ) => {
				if ( !fetchMoreResult ) return prev
				return {
					...prev,
					cart_most_orders: [
						...prev.cart_most_orders,
						...fetchMoreResult.cart_most_orders
					]
				}
			}
		} )
	}

	// helper that checks if the quantity of the product is less than the lowest purchasable amount
	function productDisplayHelper( product ) {
		const unitId = product.default_unit
		// eslint-disable-next-line camelcase
		if ( defaultQuantities?.code_register_units_default_quantities !== undefined ) {
			// eslint-disable-next-line max-len
			for ( let j = 0; j < defaultQuantities.code_register_units_default_quantities.length; j += 1 ) {
				if ( unitId === defaultQuantities.code_register_units_default_quantities[ j ].unit_id ) {
					if ( product.quantity
						>= defaultQuantities.code_register_units_default_quantities[ j ].quantity ) {
						return true
					}
				}
			}
		}
		return false
	}

	const goNext = () => {
		if ( swiper !== null ) {
			swiper.isBeginning = swiper.realIndex === 0
			isBeginning( false )
			swiper.slideNext()
			if ( swiper.isEnd ) {
				isEnd( swiper.isEnd )
				onLoadMoreHubProducts()
			}
		}
	}

	const goPrev = () => {
		if ( swiper !== null ) {
			swiper.isEnd = false
			swiper.realIndex = swiper.realIndex > 0 ? swiper.realIndex - 1 : 0
			swiper.isBeginning = swiper.realIndex === 0
			isBeginning( swiper.isBeginning )
			isEnd( swiper.isEnd )
			swiper.slidePrev()
		}
	}

	function modifyProductStructureData( product ) {
		return {
			gallery_file_id: product.gallery_file_id,
			gallery_tenant_id: product.gallery_tenant_id,
			market_id: product.market_id,
			product: {
				id: product.product_id,
				gs1_brick: {
					translation_sl: product.translation_sl,
					files: {
						public: product.public,
						file_id: product.file_id,
						tenant_id: product.tenant_id
					},
					unit: {
						id: product.default_unit,
						unit: product.unit
					},
				},
				description: product.product_description,
				bruto_price: product.bruto_price,
				quality: product.quality,
				quantity: product.quantity,
				variety: product.variety_name,
				tenant_id: product.product_tenant_id
			},
			market: {
				id: product.market_id,
				name: product.market_name,
				hub_id: product.hub_id,
				description: product.provider_description,
				hub: {
					description: product.hub_description,

				},
				provider: {
					id: product.provider_id
				}
			}
		}
	}

	/* eslint-disable */

	useEffect( () => {
		if ( products && swiper ) {
			const sliderWidth =  swiper.width

			if ( sliderWidth <= 640 ) {
				isEnd( 	!( products && products.length > 2 ) )
			} else if ( sliderWidth <= 760 ) {
				isEnd( 	!( swiper && products && products.length > 3 ) )
			} else if ( sliderWidth <= 1080 ) {
				isEnd( 	!( products && products.length > 4 ) )
			} else {			
				isEnd( 	!( products && products.length > 4 ) )
			}
		}

	}, [ swiper ] )

	return (
		<div className='shopping-row'>
			<div />
			<div className='shopping-row-title'>
				<Trans id='SliderMostSellingProducts:mostSellingProductsOnHub' />
			</div>
			<div />
			<div className='arrow-left'>
				<button
					id={ beginning ? 'btn-swiper-prev-disabled' : 'btn-swiper-prev' }
					label='button'
					type='button'
					onClick={ goPrev }
				/>
			</div>
			<div className='slider'>
				{ mostSellProductsError ? <div>{ JSON.stringify( mostSellProductsError ) }</div> : null }
				{ subscribemostSellProductsError
					? <div>{ JSON.stringify( subscribemostSellProductsError ) }</div>
					: null }
				{ mostSellProductsLoading || subscribemostSellProductsLoading ? <Loader /> : null }
				{ mostSellProducts ? (
					<Swiper { ...params } getSwiper={ updateSwiper } modules={ [ Navigation ] }>
						{/* eslint-disable-next-line max-len */}
						{ productsDisplay.filter( ( product ) => productDisplayHelper( product ) ).map( ( product ) => (
							<div className='item-holder' key={ `${ product.id } ${ product.hub_id } ${  Math.random().toString( 36 ).substr( 2, 9 ) } most-selling` }>
								<ReactPlaceholder
									showLoadingAnimation
									ready={
										!mostSellProductsLoading
									}
									customPlaceholder={ ProductComponentPlaceholder }
								>
									<ProductComponentMostSelling
										itemData={ modifyProductStructureData( product ) }
										productId={ product.id }
										hubId={ product.hub_id }
									/>
								</ReactPlaceholder>
							</div>
						) ) }
					</Swiper>
				) : null }
			</div>
			<div className='arrow-right'>
				<button
					id={ end ? 'btn-swiper-next-disabled' : 'btn-swiper-next' }
					label='button'
					type='button'
					onClick={ goNext }
				/>
			</div>
		</div>
	)
}

SliderRowMostSellingProducts.defaultProps = {
	isHubMenuClosed: undefined,
}

SliderRowMostSellingProducts.propTypes = {
	isHubMenuClosed: propTypes.bool,
	marketFilters: propTypes.array.isRequired,
	gs1Filters: propTypes.array.isRequired
}
export default SliderRowMostSellingProducts
