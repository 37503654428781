/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'
import gql from 'graphql-tag'
import { useMutation, useSubscription } from '@apollo/react-hooks'
import Button from '../../_Globals/Button/Button'
import getPriceFormatter from '../../../utils/PriceFormatter'
// import ToggleButton from '../../_Globals/ToggleButton'
import Loader from '../../Loader'
import mainStore from '../../../stores/mainStore'

import './PaymentPage.css'
import WhereAreWeButton from './WhereAreWeButton'
import PriceListButton from './PriceListButton'


/* global sessionStorage */

const CART_ORDERS = gql`
subscription getCartOrders(
	$cart_uuid: uuid!
	$customer_id: String
){
	cart_orders( order_by: {id: asc}, where: { customer_id: { _eq: $customer_id }, cart_uuid: { _eq: $cart_uuid }, status_id: {_in: [1, 3] } } ) {
		id
		hub_id
    	customer_pickup
		delivery_price_with_VAT
		total_weight
		hub {
			id
			name
			tenant_id
		}
	}
}`

const CUSTOMER_PICKUP = gql`
mutation customerPickup(
	$hub_id: bigint!
	$customer_id: String!
	$customer_pickup: Boolean!
){
	update_cart_orders( 
		where: { 
			hub_id: {_eq: $hub_id },
			customer_id: {_eq: $customer_id },
			status_id: {_in: [1, 3] }
		}, 
		_set: { customer_pickup: $customer_pickup }
	) {
		affected_rows
	}
}`


const PaymentPage = ( { clickBack, clickNext } ) => {
	const customerId = sessionStorage.getItem( 'uhub_tenantId' )
	const uuidValue = sessionStorage.getItem( 'uuid' )
	const { language } = mainStore

	const {
		data: cartOrdersData,
		loading: cartOrdersLoading,
		error: cartOrdersError,
	} = useSubscription( CART_ORDERS, {
		variables: {
			customer_id: customerId,
			cart_uuid: uuidValue
		},
	} )

	const [ customerPickup ] = useMutation( CUSTOMER_PICKUP )


	const orders = cartOrdersData
		&& cartOrdersData.cart_orders
		&& cartOrdersData.cart_orders.length > 0
		? cartOrdersData.cart_orders : []

	function toggleState( order ) {
		if ( order.customer_pickup ) {
			customerPickup( {
				variables: {
					hub_id: order.hub_id,
					customer_id: customerId,
					customer_pickup: false,
				}
			} )
		} else {
			customerPickup( {
				variables: {
					hub_id: order.hub_id,
					customer_id: customerId,
					customer_pickup: true,
				}
			} )
		}
	}

	function orderRows() {
		return orders.map( ( order ) => (
			<div className='order-hub' key={ order.hub_id }>
				<div className='hub-name-text'>
					{ order.hub.name }
				</div>
				<div className='buyer-pickup-options'>
					<div className='personal-pickup'>
						<div className={ order.customer_pickup ? 'active' : 'inactive' }>
							<Trans id='ShoppingCart.PersonalPickupOnTheHub' />
						</div>
						<WhereAreWeButton
							customerPickup={ order.customer_pickup }
							hubTenantId={ order.hub.tenant_id }
						/>

					</div>
					<div className='switch-button'>
						<input
							type='radio'
							id={ `btn-personal-pickup-${ order.hub_id }` }
							name={ `pickup-option-${ order.hub_id }` }
							checked={ order.customer_pickup }
							value='PERSONAL'
							onChange={ () => toggleState( order ) }
						/>
					</div>
					<div className='delivery-to-address'>
						<div className={ !order.customer_pickup ? 'active' : 'inactive' }>
							<Trans id='ShoppingCart.deliveryToMyAddress' />
						</div>
						<div className={ !order.customer_pickup ? 'prices' : 'prices-inactive' }>
							<PriceListButton
								hubId={ order.hub_id }
							/>
						</div>
						{ !order.customer_pickup
							? (
								<div className='payment-page-delivery-cost'>
									<div>
										<Trans id='ShoppingCart.delivery' />
										:
										{ ` ${ order.total_weight } kg .... ${ getPriceFormatter( language ).format( order.delivery_price_with_VAT ) }` }
									</div>
								</div>
							) : null}
					</div>
					<div className='switch-button'>
						<input
							type='radio'
							id={ `btn-delivery-to-address-${ order.hub_id }` }
							name={ `pickup-option-${ order.hub_id }` }
							checked={ !order.customer_pickup }
							value='DELIVERY'
							onChange={ () => toggleState( order ) }
						/>
					</div>
				</div>
				{/* <div className='switch-button'>
					<ToggleButton
						activate={ !order.customer_pickup }
						rowId={ order.hub_id }
						onClick={ () => { toggleState( order ) } }
					/>
				</div> */}
			</div>
		) )
	}

	return (
		<div className='shopping-cart-payment-main-container'>
			<div className='delivery-payment-title'><Trans id='ShoppingCart.deliveryAndPayment' /></div>
			<div className='delivery-payment-form'>
				{ cartOrdersLoading ? <Loader /> : null }
				{ cartOrdersError ? <div>{JSON.stringify( cartOrdersError ) }</div> : null }
				{ orders ? orderRows() : null }
				<div className='delivery-form'>
					{/* <div className='option-four'><Trans
					id='ShoppingCart.onlinePaymentWithACard' /></div> */}
				</div>
			</div>

			<div className='button-next-step'>
				<Button
					text={ <Trans id='Button.back' /> }
					disabled={ !false }
					onClick={ () => clickBack() }
				/>
				<Button
					text={ <Trans id='ShoppingCart.continueToTheNextStep' /> }
					disabled={ !false }
					onClick={ () => clickNext() }
				/>
			</div>

			<div className='payment-works-title' />
		</div>

	)
}

PaymentPage.propTypes = {
	clickNext: propTypes.func.isRequired,
	clickBack: propTypes.func.isRequired
}

export default PaymentPage
