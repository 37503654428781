/* eslint-disable camelcase */
/* eslint-disable func-names */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import propTypes from 'prop-types'
import { useQuery, useMutation, useSubscription } from '@apollo/react-hooks'
import { Trans } from '@lingui/macro'
import { withRouter } from 'react-router-dom'
import { multiply, round } from 'mathjs'
import { useStateValue } from '../../stores/contextStore/stateStore'
import Dropdown from '../_Globals/Dropdown'
import CartBlueIcon from '../../icons/CartBlueIcon'
import { addNotificationAddProductToCart } from '../../utils/messageHandler/notifierStack'
import Image from './components/image'
import getPriceFormatter from '../../utils/PriceFormatter'
import getNumberFormatter from '../../utils/NumberFormater'
import store from '../../stores/mainStore'

import './ProductComponent.css'


import {
	GET_UNITS_QUANTITIES,
	INSERT_ITEM_TO_CART
} from './productComponentGQL'

import {
	SUBSCRIPTION_QUANTITY
} from '../_Provider/ProductView/ProductViewGQL'

/* global sessionStorage */

// For displaying price without rounding  https://helloacm.com/javascripts-tofixed-implementation-without-rounding/
// eslint-disable-next-line no-extend-native
Number.prototype.toFixedNoRounding = function ( n ) {
	const reg = new RegExp( `^-?\\d+(?:\\.\\d{0,${ n }})?`, 'g' )
	const a = this.toString().match( reg )[ 0 ]
	const dot = a.indexOf( '.' )
	if ( dot === -1 ) { // integer, insert decimal dot and pad up zeros
		return `${ a }.${ '0'.repeat( n ) }`
	}
	const b = n - ( a.length - dot ) + 1
	return b > 0 ? ( a + '0'.repeat( b ) ) : a
}

const ProductComponent = React.memo( ( { itemData, hubId, history } ) => {
	const [ { selectedHub, isProvider }, selectProduct ] = useStateValue()
	const [ selectedQuantity, getQuantity ] = useState( 1 )
	const [ lockCartButton, setLockCartButton ] = useState( false )
	const [ productQuantity, getNewProductQuantity ] = useState( itemData.product.quantity )
	const uuidValue = sessionStorage.getItem( 'uuid' )
	const customerId = sessionStorage.getItem( 'uhub_tenantId' )
	const { language } = store
	const publicImage = true

	// Query for getting dropdown values for specific unit
	const {
		data: quantitiesData,
		error: quantitiesError,
	} = useQuery( GET_UNITS_QUANTITIES, {
		variables: {
			unit_id: itemData.product.gs1_brick.unit.id,
		}
	} )

	// Query for inserting item/product into cart list
	const [ insertItemToCart ] = useMutation( INSERT_ITEM_TO_CART, {
		variables: {
			hub_id: itemData.market.hub_id,
			market_id: itemData.market_id,
			product_id: itemData.product.id,
			quantity: selectedQuantity,
			unit_id: itemData.product.gs1_brick.unit.id,
			status_id: 1,
			cart_uuid: uuidValue,
			customer_id: customerId
		},
		ignoreResults: productQuantity < selectedQuantity,
		onCompleted: ( data ) => {
			if ( productQuantity < selectedQuantity
				&& data.insert_cart_items
				&& data.insert_cart_items.returning[ 0 ].id
			) {
				addNotificationAddProductToCart( 'ERROR', 'NOT_ENOUGH_OF_PRODUCT_ON_STOCK' )
			}

			if ( !( productQuantity < selectedQuantity )
				&& data.insert_cart_items
				&& data.insert_cart_items.returning[ 0 ].id
			) {
				addNotificationAddProductToCart( 'OK', 'PRODUCT_ADDED_TO_CART' )
				setLockCartButton( true )
			}
			if ( data.insert_cart_items.returning[ 0 ].id === undefined
				|| data.insert_cart_items.returning[ 0 ].id === null
			) {
				addNotificationAddProductToCart( 'ERROR', 'CANNOT_ADD_PRODUCT_TO_CART' )
			}
		},
		onError: ( error ) => {
			if ( error ) {
				addNotificationAddProductToCart( 'ERROR', 'CANNOT_ADD_PRODUCT_TO_CART' )
			}
		},
	} )

	// DO NOT REMOVE THIS MUTATION ON ITEMS!!!!!!
	// WE NEED THIS, TO UPDATE QUANTITY FIELD OF A PRODUCT!!!!!!
	const {
		data: subscribeQuantity,
		error: subscribeQuantityError
	} = useSubscription( SUBSCRIPTION_QUANTITY, {
		variables: {
			productId: itemData.product.id
		},
		onSubscriptionData: ( data ) => {
			getNewProductQuantity( data?.subscriptionData?.data?.provider_product[ 0 ]?.quantity ?? 0 )
			setLockCartButton( false )
		}
	} )

	// removes quantities that are more than in stock from the dropdown
	function validQuantities() {
		const validQuantitiesArray = []
		let quantitiesInDropdown = []
		let sortedQuantities = []
		if ( quantitiesData && quantitiesData?.code_register_units_default_quantities
			&& quantitiesData?.code_register_units_default_quantities?.length > 0
			&& subscribeQuantity
			&& subscribeQuantity?.provider_product.length > 0 ) {
			for ( let i = 0; i < quantitiesData?.code_register_units_default_quantities?.length;
				i += 1 ) {
				if ( quantitiesData?.code_register_units_default_quantities[ i ]?.quantity
					<= subscribeQuantity?.provider_product[ 0 ].quantity ) {
					validQuantitiesArray[ i ] = {
						quantity: quantitiesData?.code_register_units_default_quantities[ i ]?.quantity
					}
				}
			}
			quantitiesInDropdown = validQuantitiesArray.map( ( obj ) => obj.quantity )
			sortedQuantities = quantitiesInDropdown.sort( ( a, b ) => a - b )
			const biggestValidQuantity = sortedQuantities[ sortedQuantities.length - 1 ]
			if ( selectedQuantity > biggestValidQuantity ) {
				getQuantity( biggestValidQuantity )
			}
			return validQuantitiesArray
		}
		return validQuantitiesArray
	}

	const quantityValue = subscribeQuantity
	&& subscribeQuantity.provider_product.length > 0
		? subscribeQuantity.provider_product[ 0 ].quantity : false

	if ( quantitiesError || subscribeQuantityError ) {
		return (
			<div>
				{ JSON.stringify( quantitiesError ) }
				{ JSON.stringify( subscribeQuantityError ) }
			</div>
		)
	}

	// get file_id and tenant_id, depending if product has default image o gallery,
	// else get default image of gs1-brick

	function getFileId( item ) {
		if ( item.product.galleries.length > 0 ) {
			return item.product.galleries[ 0 ].file.file_id
		}
		return item.product.gs1_brick.files ? item.product.gs1_brick.files.file_id : 'null'
	}

	function getTenantId( item ) {
		if ( item.product.galleries.length > 0 ) {
			return item.product.galleries[ 0 ].file.tenant_id
		}
		return item.product.gs1_brick.files ? item.product.gs1_brick.files.tenant_id : 'null'
	}

	function getHubProducts() {
		if ( itemData.market.hub_id === selectedHub.id ) {
			return itemData
		}
		return null
	}

	function getProductsNoSelectedHub() {
		return itemData
	}

	function selectProductHandler() {
		if ( !isProvider ) {
			selectProduct( { type: 'isProductSelected', isProductSelected: itemData } )
			selectProduct( { type: 'selectedHubProducts', selectedHubProducts: selectedHub ? getHubProducts() : getProductsNoSelectedHub() } )
			history.push( '/hub-info' )
		}
		selectProduct( { type: 'isProductSelected', isProductSelected: itemData } )
	}

	function addProductToCart() {
		if ( !lockCartButton && !( productQuantity < selectedQuantity ) ) {
			insertItemToCart()
		}
		if ( !lockCartButton && productQuantity < selectedQuantity ) {
			addNotificationAddProductToCart( 'ERROR', 'NOT_ENOUGH_OF_PRODUCT_ON_STOCK' )
		}
	}
	function displayQuality() {
		if ( itemData.product.quality ) {
			return (
				<p>
					<Trans id='ItemComponent.Quality' />
					{ `: ${ itemData.product.quality }. ` }
					<Trans id='ItemComponent.Class' />
				</p>
			)
		}
		return (
			<p>
				<Trans id='ItemComponent.Quality' />
				:
				<Trans id='ItemComponent.noClass' />
			</p>
		)
	}

	function displayProductNameTitle() {
		if ( itemData.product.variety ) {
			return `${ itemData.product.gs1_brick.translation_sl.charAt( 0 ).toUpperCase() + itemData.product.gs1_brick.translation_sl.slice( 1 ) } - ${ itemData.product.variety.name }`
		}
		return `${ itemData.product.gs1_brick.translation_sl.charAt( 0 ).toUpperCase() + itemData.product.gs1_brick.translation_sl.slice( 1 ) }`
	}

	return (
		<div
			className='item-holder-grid'
			key={ `${ itemData.product.id } ${ hubId }` }
			id='itemWidth'
		>
			<div
				className='product-information'
				role='button'
				tabIndex={ 0 }
				onClick={ () => selectProductHandler() }
				onKeyPress={ () => selectProductHandler() }
			>
				<div className='item-image'>
					{ itemData.product.gs1_brick.files ? (
						<Image
							tenantId={ getTenantId( itemData ) }
							fileId={ getFileId( itemData ) }
							publicImage={ itemData.product.gs1_brick.files.public }
							galleries={ itemData.product.galleries }
						/>
					) : null }
					{ !itemData.product.gs1_brick.files ? (
						<img style={ { width: 145, height: 145 } } color='#ececec' src='/img/no_default_picture.png' alt='' />
					) : null }
				</div>
				<div className='item-seller'>{ itemData.market.name }</div>
				<div className='item-product'>{ displayProductNameTitle() }</div>
				<div className='item-data'>
					{ displayQuality() }
					<p>
						<Trans id='ItemComponent.PricePerKilo' />
						{ ` ${ itemData.product.gs1_brick.unit.unit } : ${ getPriceFormatter( language ).format( itemData.product.bruto_price ) }` }
					</p>
					<p>
						<Trans id='ItemComponent.withTax' />
					</p>
					<p>
						<Trans id='ItemComponent.Avalible' />
						{ `: ${ getNumberFormatter( language ).format( round( quantityValue, 1 ) ) } ${ itemData.product.gs1_brick.unit.unit }` }
					</p>
				</div>
				<div>
					{ /* <StarComponent fontSize={ 12 } starCount={ itemData.product.starGrade } /> */ }
				</div>
			</div>
			<div className='dropdown-grid-container'>
				{ validQuantities() ? (
					<div className='dropdown-dropdown'>
						<Dropdown
							unit={ itemData.product.gs1_brick.unit.unit }
							data={ validQuantities() }
							getQuantity={ ( quantity ) => getQuantity( quantity ) }
							type='quantity'
						/>
					</div>
				) : null }
				<div className='dropdown-data'>
					{ selectedQuantity ? ` ${ getPriceFormatter( language ).format( multiply( selectedQuantity, itemData.product.bruto_price ).toFixedNoRounding( 2 ) ) }` : null }
				</div>
				<div
					className={ !lockCartButton ? 'dropdown-image' : 'basket-clicked' }
					role='button'
					tabIndex={ -1 }
					onKeyPress={ () => addProductToCart() }
					onClick={ () => addProductToCart() }
					disable={ lockCartButton.toString() }
				>
					<CartBlueIcon fontSize={ 25 } />
				</div>
			</div>
		</div>
	)
} )

ProductComponent.defaultProps = {
	hubId: 0,
}

ProductComponent.propTypes = {
	itemData: propTypes.object.isRequired,
	history: propTypes.object.isRequired,
	hubId: propTypes.number
}

export default withRouter( ProductComponent )
