/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-redeclare */
import React from 'react'
import { Trans } from '@lingui/macro'
import { useHistory } from 'react-router-dom'
import { ProfileIcon } from '../../../icons/index'

import './ProfileHeader.css'
import store from '../../../stores/mainStore'
import { onLogoutClearStore } from '../../../utils/keycloak'

const ProfileHeader = React.memo( () => {
	const history = useHistory()

	function onLogoutButton() {
		history.push( '/' )
		onLogoutClearStore()
	}
	return (
		<div className='side-menu-header'>
			<div>
				<ProfileIcon fontSize={ 70 } color='#0E5FA6' />
			</div>
			<div>
				<p>{ `${ store.user.name }` }</p>
				<p>{ `${ store.user.email }` }</p>
			</div>
			<button id='btn-profile-logout' type='button' onClick={ () => { onLogoutButton() } }>
				<a href={ store.keycloak.createLogoutUrl() } text='Logout'><Trans id='ProfileMenu.LOGOUT' /></a>
			</button>
		</div>
	)
} )

export default ProfileHeader
