import React, { useState } from 'react'
import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { dateToStringNoHoursMinutes } from '../../../utils/DateToString'
import store from '../../../stores/mainStore'

import './TableHubOrderRow.css'
import Button from '../Button/Button'


const UPDATE_DRIVER_MUTATION = gql`
mutation ($orderId: bigint, $driverKeycloakId: uuid) {
  update_cart_orders(where: {id: {_eq: $orderId}}, _set: {drop_off_driver_id: $driverKeycloakId}) {
    affected_rows
  }
}`

const TableHubOrderRow = React.memo( ( {
	data, onClick, customer, drivers
} ) => {
	function orderStatus( status ) {
		switch ( status ) {
		case 3:
			return <Trans id='myPurchaseOrder.inPaymentProcessing' />
		case 4:
			return <Trans id='myPurchaseOrder.payed' />
		case 5:
			return <Trans id='myPurchaseOrder.orderFulfillment' />
		case 6:
			return <Trans id='myPurchaseOrder.ready' />
		case 7:
			return <Trans id='myPurchaseOrder.inDelivery' />
		case 8:
			return <Trans id='myPurchaseOrder.deliveredToCustomer' />
		case 9:
			return <Trans id='myPurchaseOrder.PaymentFailed' />
		default:
			return 'string'
		}
	}


	const [ driverKeycloakId, setDriverKeycloakId ] = useState( null )
	const [ confirmButton, setConfirmButton ] = useState( !!data.drop_of_driver_id )


	const [ updateDriver ] = useMutation( UPDATE_DRIVER_MUTATION, {
		variables: {
			orderId: data.id,
			driverKeycloakId
		}
	} )
	function getCurrentDriver() {
		if ( drivers !== undefined ) {
			for ( let i = 0; i < drivers.length; i += 1 ) {
				if ( drivers[ i ].keycloakId === data.drop_off_driver_id ) {
					return drivers[ i ]
				}
			}
		}
		return ''
	}

	function canSelectDriver() {
		if ( data !== undefined ) {
			if ( data.status_id === 6 ) {
				return true
			}
		}
		return false
	}

	function dropdownHelper( e ) {
		for ( let i = 0; i < drivers.length; i += 1 ) {
			const driver = drivers[ i ]
			if ( driver.firstName.concat( ' ', driver.lastName ) === e.target.value ) {
				setDriverKeycloakId( driver.keycloakId )
				setConfirmButton( true )
				return
			}
			setConfirmButton( false )

			if ( e.target.value === '' ) {
				setConfirmButton( true )
				setDriverKeycloakId( null )
			}
		}
	}

	return (
		<div
			className='tableHubOrder-row-container'
			role='button'
			tabIndex={ -1 }
		>
			<div className='tableHubOrder-code'>
				{ data.shownId }
			</div>
			<div className='tableHubOrder-provider'>
				{ `${ data.total_bruto_price.toFixed( 2 ) } EUR` }
			</div>
			<div className='tableHubOrder-customer'>
				<span>
					{ `${ customer.owner.firstName } ${ customer.owner.lastName }` }
				</span>
			</div>
			<div className='tableHubOrder-status'>
				{ orderStatus( data.status_id ) }
			</div>
			<div className='tableHubOrder-logistic'>
				{ data.customer_pickup
					? <Trans id='TableHubCycleOrderRow.hub' />
					: <Trans id='TableHubCycleOrderRow.customer_address' /> }
			</div>
			<div className='tableHubOrder-orderAddress'>
				{ `${ data.customer.applications[ 0 ].data.address[ 0 ].address }, ${ data.customer.applications[ 0 ].data.address[ 0 ].zip } ${ data.customer.applications[ 0 ].data.address[ 0 ].city }` }
			</div>
			<div className='tableHubOrder-date'>
				{ dateToStringNoHoursMinutes( data.updated_at, store.language ) }
			</div>
			<div className='tableHubOrder-driver'>
				{/* eslint-disable-next-line no-nested-ternary */}
				{ data.customer_pickup || !canSelectDriver()
					? ( data.customer_pickup ? <Trans id='orderSectionPage.pickup' /> : <Trans id='orderSectionPage.cannotChooseDriverYet' /> )
					: (
						<span>
							<input
								autoComplete='off'
								id='driverSelect'
								list='driversList'
								name='driverSelect'
								defaultValue={ data.drop_off_driver_id ? getCurrentDriver()?.firstName.concat( ' ', getCurrentDriver().lastName ) : null }
								onKeyUp={
									( e ) => {
										dropdownHelper( e )
									}
								}
							/>
							{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
							<datalist id='driversList'>
								{ drivers ? drivers.map( ( driver ) => (
									// eslint-disable-next-line jsx-a11y/control-has-associated-label
									<option
										key={ driver.keycloakId }
										value={ driver.firstName.concat( ' ', driver.lastName ) }
									/>
								) ) : null}
							</datalist>
						</span>


					)}
				{ data.customer_pickup || !canSelectDriver()
					? null
					: (
						<span>
							<Button
								id='confirmButton'
								className='tableHubOrder-confirm'
								onClick={ () => { updateDriver() } }
								text=<Trans id='orderSectionPage.confirm' />
								disabled={ confirmButton }
							/>
						</span>

					)}
			</div>
			<div>
				<Button
					id='detailsButton'
					className='tableHubOrder-details'
					text=<Trans id='orderSectionPage.details' />
					disabled
					onClick={ onClick }
					onKeyPress={ onClick }
				/>
			</div>
		</div>
	)
} )

TableHubOrderRow.propTypes = {
	data: propTypes.object.isRequired,
	customer: propTypes.object.isRequired,
	onClick: propTypes.object.isRequired,
	drivers: propTypes.object.isRequired
}

export default TableHubOrderRow
