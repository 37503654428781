import React from 'react'
import { Trans } from '@lingui/macro'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import './ProviderMainInfoDetail.css'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { useStateValue } from '../../stores/contextStore/stateStore'
import ProviderDetails from './components/ProviderDetails'
import HubDetails from '../HubMainInfoDetail/components/HubDetails'
import ProductComponent from '../../components/ProductComponent'
import Footer from '../../components/Footer'
import ProductView from '../../components/_Provider/ProductView'
import MarketView from '../../components/_Provider/MarketView'
import { ReturnSendIcon } from '../../icons'

const ProviderMainInfoDetail = React.memo( ( { history } ) => {
	const [ {
		getProviderInfo,
		isProductSelected
	}, closeProviderInfo ] = useStateValue()

	const {
		data: defaultQuantities,
		// eslint-disable-next-line no-unused-vars
		loading: defaultQuantitiesLoading,
		// eslint-disable-next-line no-unused-vars
		error: defaultQuantitiesError
	} = useQuery( gql`
			query {
			  code_register_units_default_quantities {
				id
				unit_id
				quantity
			  }
			}` )

	// helper that checks if the quantity of the product is less than the lowest purchasable amount
	function productDisplayHelper( product ) {
		const unitId = product.product.gs1_brick.unit.id
		for ( let j = 0; j < defaultQuantities.code_register_units_default_quantities.length; j += 1 ) {
			if ( unitId === defaultQuantities.code_register_units_default_quantities[ j ].unit_id ) {
				if ( product.product.quantity
					>= defaultQuantities.code_register_units_default_quantities[ j ].quantity ) {
					return true
				}
			}
		}
		return false
	}

	// if selectedProviderDescription is NULL display home page
	if ( getProviderInfo === null ) {
		history.push( '/' )
		return () => {}
	}

	return (
		<div>
			<div className='providerInfoDetails-main-container'>
				<div className='providerInfoDetail-content'>
					<div className='providerInfoDetail-back-to-stands'>
						{
							!isProductSelected ? (
								<div
									role='button'
									className='back-to-store'
									tabIndex={ -1 }
									onClick={ () => {
										closeProviderInfo( { type: 'getProviderInfo', getProviderInfo: null } )
										closeProviderInfo( { type: 'isProductSelected', isProductSelected: null } )
										history.push( '/' )
									} }
									onKeyPress={ () => {
										closeProviderInfo( { type: 'getProviderInfo', getProviderInfo: null } )
										closeProviderInfo( { type: 'isProductSelected', isProductSelected: null } )
										history.push( '/' )
									} }
								>
									<span><ReturnSendIcon fontSize={ 14 } /></span>
									<span className='text'><Trans id='ProviderMainInfoDetail.backToStands' /></span>
								</div>
							) : (
								<div
									role='button'
									className='back-to-store'
									tabIndex={ -1 }
									onClick={ () => {
										closeProviderInfo( { type: 'isProductSelected', isProductSelected: null } )
										history.push( {
											pathname: '/provider-info',
											state: { providerInfo: getProviderInfo }
										} )
									} }
									onKeyPress={ () => {
										closeProviderInfo( { type: 'isProductSelected', isProductSelected: null } )
										history.push( {
											pathname: '/provider-info',
											state: { providerInfo: getProviderInfo }
										} )
									} }
								>
									<span><ReturnSendIcon fontSize={ 14 } /></span>
									<span className='text'><Trans id='ProviderMainInfoDetail.backToStand' /></span>
								</div>
							)

						}

					</div>
					<div className='providerInfoDetail-provider-or-product-component'>
						{
							/* TODO
							--------------------------------------------
							-Povider description component with picture slider &&
							-Product description component with picture slider
							-logic when do display each component
							*/
						}
						{
							isProductSelected
								? <ProductView product={ isProductSelected } />
								: <MarketView marketId={ getProviderInfo.id } />
						}
					</div>
					<div className='providerInfoDetail-provider-details-component'>
						{ /* Show ProviderDetails component only when product is selected */ }
						{
							isProductSelected
								? (
									<div>
										<ProviderDetails data={ isProductSelected } />
										<HubDetails hubId={ getProviderInfo.hub_id } />
									</div>
								) : null
						}
					</div>
					<div className='providerInfoDetail-hub-details-component'>
						{
							!isProductSelected
								? <HubDetails hubId={ getProviderInfo.hub_id } />
								: null
						}
					</div>
					<div className='providerInfoDetail-products'>
						{
							// eslint-disable-next-line max-len
							getProviderInfo.products.filter( ( product ) => productDisplayHelper( product ) ).map( ( product ) => (
								<div key={ product.product.id }>
									<ProductComponent itemData={ product } />
								</div>
							) )
						}
					</div>
				</div>
			</div>
			<div className='providerInfoDetail-footer'>
				<Footer />
			</div>
		</div>
	)
} )

ProviderMainInfoDetail.propTypes = {
	history: propTypes.object.isRequired
}

export default withRouter( ProviderMainInfoDetail )
