import React, { useState } from 'react'
import { Trans } from '@lingui/react'

// import { SliderArrowIcon } from '../../../icons'

import './TableHubCycleHeader.css'

const translations = {
	numCycle: <Trans id='TableHubCycleHeader.numCycle' />,
	startDate: <Trans id='TableHubCycleHeader.startDate' />,
	duration: <Trans id='TableHubCycleHeader.duration' />,
	endDate: <Trans id='TableHubCycleHeader.endDate' />,
	numOffers: <Trans id='TableHubCycleHeader.numOffers' />,
	numOrders: <Trans id='TableHubCycleHeader.numOrders' />
}

const TableHubCycleHeader = React.memo( () => {
	const [ numCycle, sortByNumCycle ] = useState( false )
	const [ startDate, sortByStartDate ] = useState( false )
	const [ duration, sortByDuration ] = useState( false )
	const [ endDate, sortByEndate ] = useState( false )
	// const [ numOffers, sortByNumOffers ] = useState( false )
	const [ numOrders, sortByNumOrders ] = useState( false )

	function numCycleFunct() {
		if ( numCycle ) {
			sortByNumCycle( false )
		} else {
			sortByNumCycle( true )
		}
		sortByStartDate( false )
		sortByDuration( false )
		sortByEndate( false )
		// sortByNumOffers( false )
		sortByNumOrders( false )
	}

	function startDateFunct() {
		if ( startDate ) {
			sortByStartDate( false )
		} else {
			sortByStartDate( true )
		}
		sortByNumCycle( false )
		sortByDuration( false )
		sortByEndate( false )
		// sortByNumOffers( false )
		sortByNumOrders( false )
	}

	function durationFunct() {
		if ( duration ) {
			sortByDuration( false )
		} else {
			sortByDuration( true )
		}
		sortByNumCycle( false )
		sortByStartDate( false )
		sortByEndate( false )
		// sortByNumOffers( false )
		sortByNumOrders( false )
	}

	function endDateFunct() {
		if ( endDate ) {
			sortByEndate( false )
		} else {
			sortByEndate( true )
		}
		sortByNumCycle( false )
		sortByStartDate( false )
		sortByDuration( false )
		// sortByNumOffers( false )
		sortByNumOrders( false )
	}

	// function numOffersFunct() {
	// 	if ( numOffers ) {
	// 		sortByNumOffers( false )
	// 	} else {
	// 		sortByNumOffers( true )
	// 	}
	// 	sortByNumCycle( false )
	// 	sortByStartDate( false )
	// 	sortByDuration( false )
	// 	sortByEndate( false )
	// 	sortByNumOrders( false )
	// }

	function numOrdersFunct() {
		if ( numOrders ) {
			sortByNumOrders( false )
		} else {
			sortByNumOrders( true )
		}
		sortByNumCycle( false )
		sortByStartDate( false )
		sortByDuration( false )
		sortByEndate( false )
		// sortByNumOffers( false )
	}

	return (
		<div className='TableHubCycleHeader-container'>
			<div
				className='numCycle'
				role='button'
				tabIndex={ -1 }
				onClick={ () => numCycleFunct() }
				onKeyPress={ () => numCycleFunct() }
			>
				<span>{ translations.numCycle }</span>
				<span className={ numCycle ? 'sort-arrow-up' : 'sort-arrow-down' }>
					{/* <SliderArrowIcon fontSize={ 12 } /> */}
				</span>
			</div>
			<div
				className='startDate'
				role='button'
				tabIndex={ -1 }
				onClick={ () => startDateFunct() }
				onKeyPress={ () => startDateFunct() }
			>
				<span>{ translations.startDate }</span>
				<span className={ startDate ? 'sort-arrow-up' : 'sort-arrow-down' }>
					{/* <SliderArrowIcon fontSize={ 12 } /> */}
				</span>
			</div>
			<div
				className='duration'
				role='button'
				tabIndex={ -1 }
				onClick={ () => durationFunct() }
				onKeyPress={ () => durationFunct() }
			>
				<span>{ translations.duration }</span>
				<span className={ duration ? 'sort-arrow-up' : 'sort-arrow-down' }>
					{/* <SliderArrowIcon fontSize={ 12 } /> */}
				</span>
			</div>
			<div
				className='endDate'
				role='button'
				tabIndex={ -1 }
				onClick={ () => endDateFunct() }
				onKeyPress={ () => endDateFunct() }
			>
				<span>{ translations.endDate }</span>
				<span className={ endDate ? 'sort-arrow-up' : 'sort-arrow-down' }>
					{/* <SliderArrowIcon fontSize={ 12 } /> */}
				</span>
			</div>
			{/* <div
				className='numOffers'
				role='button'
				tabIndex={ -1 }
				onClick={ () => numOffersFunct() }
				onKeyPress={ () => numOffersFunct() }
			>
				<span>{ translations.numOffers }</span>
				<span className={ numOffers ? 'sort-arrow-up' : 'sort-arrow-down'}>
					<SliderArrowIcon fontSize={ 12 } />
				</span>
			</div> */}
			<div
				className='numOrders'
				role='button'
				tabIndex={ -1 }
				onClick={ () => numOrdersFunct() }
				onKeyPress={ () => numOrdersFunct() }
			>
				<span>{ translations.numOrders }</span>
				<span className={ numOrders ? 'sort-arrow-up' : 'sort-arrow-down' }>
					{/* <SliderArrowIcon fontSize={ 12 } /> */}
				</span>
			</div>
		</div>

	)
} )

export default TableHubCycleHeader
