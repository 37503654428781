import defaults from './defaults'

const resolvers = {
	Mutation: {
		// add mutations
	}
}

export {
	defaults,
	resolvers
}
