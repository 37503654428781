/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import propTypes from 'prop-types'

import { useStateValue } from '../../stores/contextStore/stateStore'
import roleHandler from '../../utils/ContentRoleHandler'

const HubPrivateRoutes = ( { component: Component, ...rest } ) => {
	const [ { isAuth } ] = useStateValue()

	// Handle routes depends on role
	const roles = []
	if ( rest.ownerOnly ) {
		roles.push( 'owner' )
	} else {
		roles.push( 'employee' )
		roles.push( 'owner' )
	}

	return (
		<Route
			{ ...rest }
			// eslint-disable-next-line no-return-assign
			render={ ( props ) => ( roleHandler( isAuth, roles ) ? (
				<Component { ...props } />
			) : (
				<Redirect to='/' />
			) ) }
		/>
	)
}

HubPrivateRoutes.propTypes = {
	component: propTypes.any.isRequired
}

export default HubPrivateRoutes
