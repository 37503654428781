import gql from 'graphql-tag'

const GET_ACTIVE_AND_CONFIRMED_MARKETS = gql`
query ($marketFilters: [bigint!], $gs1Filters: [Int!], $limit: Int, $offset: Int) {
  provider_market(limit: $limit, offset: $offset, where: {confirmed: {_eq: true}, active: {_eq: true}, id: {_in: $marketFilters}, products: {product: {disabled: {_eq: false}, quantity: {_neq: "0"}}}}, order_by: {name: asc}) {
    id
    name
    description
    hub_id
    galleries(where: {default: {_eq: true}}) {
	  id
      file {
		id
        tenant_id
        file_id
      }
    }
    products(where: {product: {disabled: {_eq: false}, gs1_brick: {id: {_in: $gs1Filters}}, quantity: {_neq: 0}}}) {
	  id
      market_id
      product {
        id
        gs1_brick {
		  id
          translation_sl
          files {
			id
            public
            file_id
            tenant_id
          }
          unit {
            id
            unit
          }
        }
        description
        bruto_price
        neto_price
        quality
        quantity
        variety {
		  id
          name
        }
        galleries(where: {default: {_eq: true}}) {
		  id
          file {
			id
            file_id
            tenant_id
          }
        }
        tenant_id
      }
      market {
		id
        name
        hub_id
        description
        hub {
		  id
          description
          tenant_id
        }
        provider {
          id
          average_mark
        }
      }
    }
  }
}
`

const GET_ALL_DEFAULT_QUANTITIES = gql`
query {
  code_register_units_default_quantities {
	id
    unit_id
    quantity
  }
}`

const GET_ALL_ACTIVE_PRODUCTS = gql`
query (
	$marketFilters: [ bigint! ],
	$gs1Filters: [ Int! ],
	$limit: Int,
	$offset: Int,
){
	hub_hub {
		id
		markets(
			where: { 
				active: { _eq: true },
				confirmed: { _eq: true },
			} 
		) {
			id
			active
			confirmed
			hub_id
			products(
				limit: $limit,
				offset: $offset,
				where: {
					product: { disabled: { _eq: false } ,
					gs1_brick: { id: { _in:  $gs1Filters } }, 
					quantity: { _neq: 0 } },
					market_id: {_in: $marketFilters }
				} 
			) {
				id
				market_id
				product {
					id
					gs1_brick {
						id
						translation_sl
						files {
							id
							public
							file_id
							tenant_id
						}
						unit {
							id
							unit
						}
					}
					description
					bruto_price
					neto_price
					quality
					quantity
					variety {
						id
						name
					}
					galleries (where: { default: { _eq: true } } ){
						id							id
						file {
							id
							file_id
							tenant_id
						}
					}
					tenant_id
				}
				market {
					id
					name
					hub_id
					description
					hub {
						id
						description
						tenant_id
						cycles {
							id
						}
					}
					provider {
						id
						average_mark
					}
				}
			}
		}
	}
}`

const GET_ALL_ACTIVE_PRODUCTS_BY_HUB_SUBSCRIPTION = gql`
subscription getProductsByHubId( 
	$hub_id: bigint!,
	$marketFilters: [ bigint! ],
	$gs1Filters: [ Int! ],
	$limit: Int!,
){
    provider_market( where: { 
        hub_id: { _eq: $hub_id }, 
        active: { _eq: true }, 
        confirmed: { _eq: true },
		products: { market_id: { _in: $marketFilters } }
    } ) {
        products(
			limit: $limit,
			where: {
				product: { disabled: { _eq: false },
				gs1_brick: { id: { _in:  $gs1Filters } }, 
				quantity: { _neq: 0 } },
			},
			order_by: { product: { id: asc } }
		) {
			id
			market_id
            product {
                id
                gs1_brick {
					id
                	translation_sl
					files {
						id
						public
						file_id
						tenant_id
					}
					unit {
      					id
      					unit
    				}
                }
                description
                bruto_price
				neto_price
                quality
                quantity
                variety {
					id
                    name
                }
				galleries (where: { default: { _eq: true } } ){
					id
					file {
						id
						file_id
						tenant_id
					}
				}
				tenant_id
            }
            market {
				id
                name
				hub_id
				description
				hub {
					id
            		description
            		tenant_id
					cycles {
						id
					}
          		}
				provider {
					id
					average_mark
				}
            }
        }
    }
}`

const GET_ALL_ACTIVE_PRODUCTS_BY_MARKET = gql`
query getProductsOnMarketByMarketId( 
	$market_id: bigint!,
	$marketFilters: [ bigint! ],
	$gs1Filters: [ Int! ],
	$limit: Int!
){
    provider_market( where: { 
        id: { _eq: $market_id }, 
        active: { _eq: true }, 
        confirmed: { _eq: true } 
    } ) {
		id
        products(
			limit: $limit,
				where: {
					product: { disabled: { _eq: false } ,
					gs1_brick: { id: { _in:  $gs1Filters } }, 
					quantity: { _neq: 0 } } 
				}
			) {
            product {
                id
                gs1_brick {
					id
                	translation_sl
					files {
						id
						public
						file_id
						tenant_id
					}
					unit {
      					id
      					unit
    				}
                }
                description
                bruto_price
				neto_price
                quality
                quantity
                variety {
					id
                    name
                }
				galleries (where: { default: { _eq: true } } ){
					id
					file {
						id
						file_id
						tenant_id
					}
				}
				tenant_id
            }
            market {
				id
                name
				hub_id
				description
				hub {
					id
            		description
            		tenant_id
          		}
				provider {
					id
					average_mark
				}
            }
        }
    }
}`

const GET_PRODUCTS_BY_HUB_ID_AND_MARKET_ID = gql`
query getProductsByHubIdAndMarketId(
	$hub_id: bigint!,
	$market_id: bigint!,
	$marketFilters: [ bigint! ],
	$gs1Filters: [ Int! ],
	$limit: Int!,
	$offset: Int
) {
	hub_hub( where: { id: { _eq: $hub_id }}) {
		id
		markets( 
			where: {
				active: { _eq: true },
				confirmed: { _eq: true },
				id: { _eq: $market_id },

			}
		) {
			id
			active
			confirmed
			hub_id
			products(
				limit: $limit,
				offset: $offset,
				where: {
					product: { disabled: { _eq: false } ,
					gs1_brick: { id: { _in:  $gs1Filters } }, 
					quantity: { _neq: 0 } }
				},
				order_by: { product: { id: asc } } 
			) {
				id
				market_id
				product {
					id
					gs1_brick {
						id
						translation_sl
						files {
							id
							public
							file_id
							tenant_id
						}
						unit {
							id
							unit
						}
					}
					description
					bruto_price
					neto_price
					quality
					quantity
					variety {
						id
						name
					}
					galleries ( where: { default: { _eq: true } } ){
						id
						file {
							id
							file_id
							tenant_id
						}
					}
					tenant_id
				}
				market {
					id
					name
					hub_id
					description
					hub {
						id
						description
						tenant_id
						cycles {
							id
						}
					}
					provider {
						id
						average_mark
					}
				}
			}
		}
  	}
}`

const GET_PRODUCTS_BY_HUB_ID_AND_MARKET_ID_SUBSCRIPTION = gql`
subscription getProductsByHubIdAndMarketIdSubscription(
	$hub_id: bigint!,
	$market_id: bigint!,
	$marketFilters: [ bigint! ],
	$gs1Filters: [ Int! ],
	$limit: Int!,
	$offset: Int
) {
	hub_hub( where: { id: { _eq: $hub_id }}) {
		id
		markets( 
			where: {
				active: { _eq: true },
				confirmed: { _eq: true },
				id: { _eq: $market_id },

			}
		) {
			id
			active
			confirmed
			hub_id
			products(
				limit: $limit,
				offset: $offset,
				where: {
					product: { disabled: { _eq: false } ,
					gs1_brick: { id: { _in:  $gs1Filters } }, 
					quantity: { _neq: 0 } }
				},
				order_by: { product: { id: asc } } 
			) {
				id
				market_id
				product {
					id
					gs1_brick {
						translation_sl
						files {
							id
							public
							file_id
							tenant_id
						}
						unit {
							id
							unit
						}
					}
					description
					bruto_price
					neto_price
					quality
					quantity
					variety {
						id
						name
					}
					galleries ( where: { default: { _eq: true } } ){
						id
						file {
							id
							file_id
							tenant_id
						}
					}
					tenant_id
				}
				market {
					id
					name
					hub_id
					description
					hub {
						id
						description
						tenant_id
						cycles {
							id
						}
					}
					provider {
						id
						average_mark
					}
				}
			}
		}
  	}
}`

const GET_HUBS = gql`{
	hub_hub ( order_by: { name: asc } ){
		id
		name
	}
}`

export {
	GET_ALL_ACTIVE_PRODUCTS,
	GET_ACTIVE_AND_CONFIRMED_MARKETS,
	GET_PRODUCTS_BY_HUB_ID_AND_MARKET_ID,
	GET_ALL_ACTIVE_PRODUCTS_BY_MARKET,
	GET_HUBS,
	GET_ALL_ACTIVE_PRODUCTS_BY_HUB_SUBSCRIPTION,
	GET_PRODUCTS_BY_HUB_ID_AND_MARKET_ID_SUBSCRIPTION,
	GET_ALL_DEFAULT_QUANTITIES
}
