import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'
import { withRouter } from 'react-router-dom'

import {
	StepProgress,
	FooterBottom,
	BasketItem,
	ShoppingCartSignIn,
	AddressPage,
	PaymentPage,
	OrderSummary,
	SuccessfullPayment,
	PaymentFailed
} from '../../components/ShoppingCart'
import { ReturnSendIcon } from '../../icons'
import './ShoppingCartPage.css'

/* global sessionStorage */

const stepStatuses = [ 'basket', 'login', 'address', 'deliveryPayment', 'orderSummary' ]

const translations = {
	basket: <Trans id='ShoppingCart.basket' />,
	login: <Trans id='ShoppingCart.login' />,
	address: <Trans id='ShoppingCart.address' />,
	deliveryPayment: <Trans id='ShoppingCart.deliveryPayment' />,
	orderSummary: <Trans id='ShoppingCart.orderSummary' />
}

const ShoppingCartPage = ( { isAuthenticated, history } ) => {
	const [ currentStep, setStep ] = useState( 0 )
	const numOfSteps = stepStatuses.length - 1 // number of total steps during registration procedure
	const tenantId = sessionStorage.getItem( 'uhub_tenantId' )
	const loggingCartButtonClicked = sessionStorage.getItem( 'loggingCartButton' )

	console.log( '-------------- frontend/src/pages/ShoppingCartPage/index.js' )

	useEffect( () => {
		if ( loggingCartButtonClicked && isAuthenticated ) {
			setStep( currentStep + 2 )
			sessionStorage.removeItem( 'loggingCartButton' )
		}
	}, [ loggingCartButtonClicked, currentStep, isAuthenticated ] )

	function clickNext( ) {
		if ( isAuthenticated ) {
			setStep( currentStep + 2 )
		} else {
			setStep( currentStep + 1 )
		}

		if ( currentStep > 0 && currentStep < numOfSteps + 1 ) {
			setStep( currentStep + 1 )
		}
	}

	function clickNextPaymentFailed( ) {
		if ( isAuthenticated ) {
			setStep( 6 )
		}
	}

	function clickBack( ) {
		if ( currentStep > 0 && currentStep < numOfSteps + 1 ) {
			if ( isAuthenticated && currentStep === 2 ) {
				setStep( currentStep - 2 )
			} else {
				setStep( currentStep - 1 )
			}
		}
	}

	function displayPageStep() {
		switch ( currentStep ) {
		case stepStatuses.indexOf( 'basket' ):
			return (
				<BasketItem
					clickNext={ () => clickNext() }
					customerId={ tenantId }
				/>
			)
		case stepStatuses.indexOf( 'login' ):
			return (
				<ShoppingCartSignIn
					isAuthenticated={ isAuthenticated }
					clickNext={ () => clickNext() }
					clickBack={ () => clickBack() }
				/>
			)
		case stepStatuses.indexOf( 'address' ):
			return (
				<AddressPage
					clickNext={ () => clickNext() }
					clickBack={ () => clickBack() }
				/>
			)
		case stepStatuses.indexOf( 'deliveryPayment' ):
			return (
				<PaymentPage
					clickNext={ () => clickNext() }
					clickBack={ () => clickBack() }
				/>
			)
		case stepStatuses.indexOf( 'orderSummary' ):
			return (
				<OrderSummary
					clickNext={ () => clickNext() }
					clickNextPaymentFailed={ () => clickNextPaymentFailed() }
					clickBack={ () => clickBack() }
				/>
			)
		case 5:
			return ( <SuccessfullPayment /> )
		default:
			return <PaymentFailed />
		}
	}

	return (
		<div className='shopping-cart-container'>
			<div className='picture-banner-cart' />
			<div className='shopping-cart-title-container'>
				<div className='shopping-cart-title'><Trans id='ShoppingCart.myBasket' /></div>
				<div
					role='button'
					className='back-to-store'
					tabIndex={ -1 }
					onKeyPress={ () => history.push( '/' ) }
					onClick={ () => history.push( '/' ) }
				>
					<span><ReturnSendIcon fontSize={ 14 } /></span>
					<span className='text'><Trans id='ShoppingCart.backToStore' /></span>
				</div>
			</div>
			{ currentStep !== 5 && currentStep !== 6
				? 	(
					<div className='progress-step-bar-shopping-cart'>
						<StepProgress
							stepStatuses={ stepStatuses }
							currentStep={ currentStep }
							translations={ translations }
							totalSteps={ numOfSteps }
						/>
					</div>
				)
				: null}
			<div className='shopping-cart-step-content'>
				{ displayPageStep() }
			</div>
			<FooterBottom />
		</div>
	)
}

ShoppingCartPage.propTypes = {
	isAuthenticated: propTypes.bool.isRequired,
	history: propTypes.object.isRequired
}

export default withRouter( ShoppingCartPage )
