import React, { useState, useRef } from 'react'
import {
	useMutation, useLazyQuery, useQuery
} from '@apollo/react-hooks'
import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'
import { withRouter } from 'react-router-dom'

import Input from '../../../../components/_Globals/Input/Input'
import Button from '../../../../components/_Globals/Button/Button'
import Loader from '../../../../components/Loader'
import HeaderRegistration from '../../../../components/HeaderRegistration'
import { addNotification } from '../../../../utils/messageHandler/notifierStack'

import {
	fieldInputsTranslation,
	fieldInputsErrors,
	regex,
	fieldInputsExistingUserEmail,
} from '../../ConstantsVariables'

import {
	REGISTER_TENANT_ON_EXISTING_COMPANY,
	REGISTER_TENANT_ON_NEW_COMPANY,
	GET_USER_TENANTS,
	GET_POST_CODE_OFFICES,
	INSERT_PROVIDER
} from '../../RegistrationPageGQL'

import './RegistrationProviderExistingUser.css'

const RegistrationProviderExistingUser = ( { history } ) => {
	const [ page, registrationPage ] = useState( 0 )
	const [ isValid, isInputsValid ] = useState( false )
	const [ tenantData, setTenantData ] = useState( { data: undefined, loading: false } )
	const [ email, setEmail ] = useState( '' )
	const [ phoneNum, setPhoneNum ] = useState( '' )
	const [ mobileNum, setMobileNum ] = useState( '' )
	const [ taxId, setTaxId ] = useState( '' )
	const [ companyRegistrationNumber, setCompanyRegistrationNumber ] = useState( '' )
	const [ companyName, setCompanyName ] = useState( '' )
	const [ address, setAddress ] = useState( '' )
	const [ postcode, setPostcode ] = useState( '' )
	const [ , setPostCodeData ] = useState( { data: undefined } )
	const [ city, setCity ] = useState( '' )
	const [ country, setCountry ] = useState( 'Slovenija' )
	const [ Iban, setIban ] = useState( '' )
	const [ Bic, setBic ] = useState( '' )
	const [ bankName, setBankName ] = useState( '' )

	const inputValue = useRef( '' )

	let fieldInputs = []
	if ( page === 0 ) {
		fieldInputs = fieldInputsExistingUserEmail
	} else {
		fieldInputs = [
			'phoneNum',
			'mobileNum',
			'taxId',
			'companyRegistrationNumber',
			'companyName',
			'address',
			'postcode',
			'city',
			'country',
			'bankName',
			'Iban',
			'Bic' ]
	}

	let fieldInputsValidation = []
	if ( page === 0 ) {
		fieldInputsValidation = fieldInputsExistingUserEmail
	} else {
		fieldInputsValidation = [
			'phoneNum',
			'mobileNum',
			'taxId',
			'companyRegistrationNumber',
			'companyName',
			'address',
			'bankName',
			'Iban',
			'Bic' ]
	}

	// when user click again 'Register existing user' button, states reset
	// function resetStates() {
	// 	setEmail( '' )
	// 	setTaxId( '' )
	// 	setCompanyName( '' )
	// 	setCompanyRegistrationNumber( '' )
	// 	setAddress( '' )
	// 	setPostcode( '' )
	// 	setCity( '' )
	// 	setIban( '' )
	// 	setBic( '' )
	// 	setBankName( '' )
	// 	registrationPage( 0 )
	// }

	// function runs if user inputs some same data for existing tenant.
	// Page refreshes reseting states, except for email
	// function resetRegExtFields() {
	// 	setEmail( '' )
	// 	setPhoneNum( '' )
	// 	setMobileNum( '' )
	// 	setTaxId( '' )
	// 	setCompanyName( '' )
	// 	setCompanyRegistrationNumber( '' )
	// 	setAddress( '' )
	// 	setPostcode( '' )
	// 	setCity( '' )
	// 	setIban( '' )
	// 	setBic( '' )
	// 	setBankName( '' )
	// 	registrationPage( 0 )
	// }

	// Function with query for getting user tenants ( if they exists ), by email
	const [ getUserTenants, { loading } ] = useLazyQuery( GET_USER_TENANTS, {
		variables: { email },
		onCompleted: ( data ) => {
			if ( data ) {
				setTenantData( {
					data,
					loading
				} )
				if ( data.tenants[ 0 ] !== undefined ) {
					if ( data.tenants[ 0 ].identifiers === null ) {
						registrationPage( 1 )
					} else {
						registrationPage( 3 )
					}
				} else {
					history.push( '/register/provider/newUser' )
				}
			}
		}
	} )

	const [ insertProvider ] = useMutation( INSERT_PROVIDER, {
		onCompleted: () => {
			registrationPage( 4 )
		},
		onError: () => {
			registrationPage( 0 )
		}
	} )

	// Mutation for registering existing user
	const [ registerTenant, {
		data: registerTenantData,
		loading: registerTenantLoading,
	} ] = useMutation( REGISTER_TENANT_ON_EXISTING_COMPANY, {
		onCompleted: ( data ) => {
			if ( data ) {
				insertProvider( {
					variables: {
						tenant_id: data.Tenant_register.id
					}
				} )
			}
		},
		onError: ( { graphQLErrors, networkError } ) => {
			if ( graphQLErrors ) {
				const newError = graphQLErrors[ 0 ]?.extensions?.code
				if ( newError === 'KEYCLOAK_PASSWORD_MISMATCH' ) {
					addNotification( 'ERROR', 'KEYCLOAK_PASSWORD_MISMATCH' )
				} else if ( newError === 'EMAIL_EXISTS' ) {
					addNotification( 'ERROR', 'EMAIL_EXISTS' )
				} else if ( newError === 'USER_EXISTS' ) {
					addNotification( 'ERROR', 'USER_EXISTS' )
				} else if ( newError === 'TENANT_KEYCLOAK_USER_EXISTS' ) {
					addNotification( 'ERROR', 'TENANT_KEYCLOAK_USER_EXISTS' )
				} else if ( newError === 'TENANT_EXISTS' ) {
					addNotification( 'ERROR', 'TENANT_EXISTS' )
				} else {
					(
						addNotification( 'ERROR', 'REGISTER_NEW_USER_FAILED' )
					)
				}
			}

			if ( networkError ) {
				addNotification( 'ERROR', 'REGISTER_NEW_USER_FAILED' )
			}

			registrationPage( 1 )
		},
		variables: {
			email,
			IBAN: Iban,
			BIC: Bic,
			bankName,
			address,
			zip: postcode,
			city,
			country,
		}
	} )

	const [ registerTenantNewCompany, {
		data: registerTenantNewCompanyData,
		loading: registerTenantNewCompanyLoading,
	} ] = useMutation( REGISTER_TENANT_ON_NEW_COMPANY, {
		onCompleted: ( data ) => {
			if ( data ) {
				insertProvider( {
					variables: {
						tenant_id: data.Tenant_register.id
					}
				} )
			}
		},
		onError: ( { graphQLErrors, networkError } ) => {
			if ( graphQLErrors ) {
				const newError = graphQLErrors[ 0 ]?.extensions?.code
				if ( newError === 'KEYCLOAK_PASSWORD_MISMATCH' ) {
					addNotification( 'ERROR', 'KEYCLOAK_PASSWORD_MISMATCH' )
				} else if ( newError === 'EMAIL_EXISTS' ) {
					addNotification( 'ERROR', 'EMAIL_EXISTS' )
				} else if ( newError === 'USER_EXISTS' ) {
					addNotification( 'ERROR', 'USER_EXISTS' )
				} else if ( newError === 'TENANT_KEYCLOAK_USER_EXISTS' ) {
					addNotification( 'ERROR', 'TENANT_KEYCLOAK_USER_EXISTS' )
				} else if ( newError === 'TENANT_EXISTS' ) {
					addNotification( 'ERROR', 'TENANT_EXISTS' )
				} else {
					(
						addNotification( 'ERROR', 'REGISTER_NEW_USER_FAILED' )
					)
				}
			}

			if ( networkError ) {
				addNotification( 'ERROR', 'REGISTER_NEW_USER_FAILED' )
			}
			if (
				( setTenantData.data !== undefined || setTenantData.data !== null )
				&& setTenantData.loading === false
			) {
				registrationPage( 1 )
			} else {
				registrationPage( 1 )
			}
		},
		variables: {
			email,
			phone: phoneNum,
			mobile: mobileNum,
			taxId,
			companyRegistrationNumber,
			companyName,
			IBAN: Iban,
			BIC: Bic,
			bankName,
			address,
			zip: postcode,
			city,
			country,
		}
	} )

	// function to fill fields with data of selected company, if user has one.
	// function handleInputDataExistingUser( tenant ) {
	// 	setEmail( tenant.owner.email )
	// 	setTaxId( tenant.identifiers.taxId )
	// 	setPhoneNum( tenant.applications[ 0 ].data.phone )
	// 	setMobileNum( tenant.applications[ 0 ].data.mobile )
	// 	setCompanyName( tenant.identifiers.companyName )
	// 	setCompanyRegistrationNumber( tenant.identifiers.companyRegistrationNumber )
	// 	setAddress( tenant.applications[ 0 ].data.address[ 0 ].address )
	// 	setPostcode( tenant.applications[ 0 ].data.address[ 0 ].zip )
	// 	setCity( tenant.applications[ 0 ].data.address[ 0 ].city )
	// 	setCountry( tenant.applications[ 0 ].data.address[ 0 ].country )
	// 	setIban( tenant.applications[ 0 ].data.bankAccount.IBAN )
	// 	setBic( tenant.applications[ 0 ].data.bankAccount.BIC )
	// 	setBankName( tenant.applications[ 0 ].data.bankAccount.name )
	// 	registrationPage( 2 )
	// }

	// function runs onClick functions according at what step/page of registration flow user is at
	function buttonAction( currentPage ) {
		switch ( currentPage ) {
		case 0:
			if ( email !== '' && regex.email.test( email ) ) {
				getUserTenants()
			} else {
				addNotification( 'ERROR', 'MISSING_EMAIL_INPUT' )
			}
			break
		case 1:
			registerTenantNewCompany()
			registrationPage( 4 )
			break
		case 2:
			registerTenant()
			registrationPage( 4 )
			break
		case 3:
			history.push( '/register' )
			break
		default:
			return 'something'
		}
		return 'something'
	}

	// function that displays appripriate text, according to current registration flow
	function buttonText() {
		switch ( page ) {
		case 1:
		case 2:
			return <Trans id='Button.register' />
		case 0:
			return <Trans id='Button.next' />
		case 3:
			return <Trans id='Button.back' />
		default:
			return 'something'
		}
	}

	const {
		data: postcodeData,
	} = useQuery( GET_POST_CODE_OFFICES )

	function getValidPostCodes( input ) {
		const validPostcodes = {}
		for ( let i = 0; i < postcodeData.CR_PostOffice.length; i += 1 ) {
			const splitInput = input.code.split( '' )
			const splitCode = postcodeData.CR_PostOffice[ i ].code.toString().split( '' )
			let valid = true
			for ( let j = 0; j < splitInput.length; j += 1 ) {
				if ( splitInput[ j ] !== splitCode[ j ] ) {
					valid = false
				}
			}
			if ( valid ) {
				// eslint-disable-next-line max-len
				validPostcodes[ postcodeData.CR_PostOffice[ i ].code ] = postcodeData.CR_PostOffice[ i ].name
			}
		}
		return validPostcodes
	}

	function postcodesByCode( input ) {
		const validPostcodes = getValidPostCodes( input )

		setPostCodeData( {
			postcodeData
		} )
		if ( Object.keys( validPostcodes ).length === 1 ) {
			setPostcode( Object.keys( validPostcodes )[ 0 ] )
			setCity( validPostcodes[ Number( Object.keys( validPostcodes )[ 0 ] ) ] )
		}
		if ( Object.keys( validPostcodes ).length > 1 ) {
			setCity( '' )
		}
	}

	// function that manages display of a button at different registration steps
	function displayButton() {
		switch ( page ) {
		case 0:
			return	(
				<div>
					<Button
						text={ <Trans id='Button.back' /> }
						disabled
						onClick={ () => history.push( '/register' ) }
					/>
					<Button
						text={ buttonText( page ) }
						disabled={ isValid }
						onClick={ () => buttonAction( page ) }
					/>
				</div>
			)
		case 1:
			return (
				<Button
					text={ buttonText( page ) }
					disabled={ isValid }
					onClick={ () => buttonAction( page ) }
				/>
			)
		case 2:
			return (
				<Button
					text={ buttonText( page ) }
					disabled
					onClick={ () => buttonAction( page ) }
				/>
			)
		case 3:
			return !tenantData.error
			&& tenantData.data !== undefined
			&& tenantData.data.tenants[ 0 ] !== undefined
			&& tenantData.data.tenants[ 0 ].identifiers === null
				? (
					<Button
						text={ buttonText( page ) }
						disabled
						onClick={ () => buttonAction( page ) }
					/>
				)
				: null
		case 4:
			return (
				<Button
					text={ <Trans id='Button.goToUHUB' /> }
					disabled
					onClick={ () => history.push( '/' ) }
				/>
			)
		default:
			return ''
		}
	}

	// function that sets state for individual field that user typed in
	function setValue( value, inputName ) {
		switch ( inputName ) {
		case 'email':
			setEmail( value )
			break
		case 'phoneNum':
			setPhoneNum( value )
			break
		case 'mobileNum':
			setMobileNum( value )
			break
		case 'taxId':
			setTaxId( value )
			break
		case 'companyRegistrationNumber':
			setCompanyRegistrationNumber( value )
			break
		case 'companyName':
			setCompanyName( value )
			break
		case 'address':
			setAddress( value )
			break
		case 'postcode':
			setPostcode( value )
			break
		case 'city':
			setCity( value )
			break
		case 'country':
			setCountry( value )
			break
		case 'Iban':
			setIban( value )
			break
		case 'Bic':
			setBic( value )
			break
		case 'bankName':
			setBankName( value )
			break
		case 'state':
			break
		default:
			return 'something'
		}
		return 'something'
	}

	function getValue( inputName ) {
		switch ( inputName ) {
		case 'email':
			return email
		case 'phoneNum':
			return phoneNum
		case 'mobileNum':
			return mobileNum
		case 'taxId':
			return taxId
		case 'companyRegistrationNumber':
			return companyRegistrationNumber
		case 'companyName':
			return companyName
		case 'address':
			return address
		case 'postcode':
			return postcode
		case 'city':
			return city
		case 'country':
			return country
		case 'Iban':
			return Iban
		case 'Bic':
			return Bic
		case 'bankName':
			return bankName
		default:
			return 'something'
		}
	}

	const regexTest = {
		name: /^[A-Za-zČŠčšŽžĐđĆć-\s]+$/i,
		surname: /^[A-Za-zČŠčšŽžĐđĆć-\s]+$/i,
		// eslint-disable-next-line no-useless-escape
		email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		username: /^([a-z0-9])+$/,
		phoneNum: /^[+]*[0-9\-\s]+$/i,
		mobileNum: /^[+]*[0-9\-\s]+$/i,
		taxId: /^[0-9]{8,}$/,
		companyRegistrationNumber: /\d{10}/,
		companyName: /[A-Za-z0-9.\-\s,]+$/g,
		address: /^[0-9A-Za-zČŠčšŽžĐđĆć-\s,]+$/i,
		postcode: /^\d{4}$/g,
		city: /^[A-Za-z\s]+$/g,
		country: /^[A-Za-z\s]+$/g,
		// eslint-disable-next-line no-useless-escape
		Iban: /^[A-Z]{2}\d{2} ?\d{4} ?\d{4} ?\d{4} ?\ ?[\d]{0,3}$/,
		// eslint-disable-next-line no-useless-escape
		Bic: /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/,
		bankName: /[A-Za-z0-9.\-\s,.]+$/g,
		password: /^.{8,}$/,
		confirmPassword: /^.{8,}$/,
	}
	const initialValueInputStatuses = fieldInputsValidation.map( ( item ) => item === false )

	function validatePostcode() {
		if ( document.getElementById( 'city' ) !== null ) {
			if ( document.getElementById( 'city' ).value !== '' ) {
				return true
			}
		}
		return false
	}


	// function for validating input for specfic field
	function getValidation( validate, inputName ) {
		const allTrue = ( currentValue ) => currentValue === true
		const trueValidate = regexTest[ inputName ].test( getValue( inputName ) )

		const postcodeValid = validatePostcode()
		if ( !postcodeValid && document.getElementById( 'postcode' ) !== null ) {
			document.getElementById( 'postcode' ).style.color = 'red'
		} else if ( document.getElementById( 'postcode' ) !== null ) {
			document.getElementById( 'postcode' ).style.color = 'black'
		}

		initialValueInputStatuses[ fieldInputsValidation.indexOf( inputName ) ] = trueValidate
		if ( document.getElementById( inputName ) !== null && trueValidate === false && inputName !== 'postcode' ) {
			document.getElementById( inputName ).className = 'input-box input-white-background-error'
		}
		if ( document.getElementById( inputName ) !== null && trueValidate === true && inputName !== 'postcode' ) {
			document.getElementById( inputName ).className = 'input-box input-white-background'
		}
		if ( getValue( inputName ) === '' ) {
			if ( document.getElementById( inputName ) !== null && trueValidate === false && inputName !== 'postcode' ) {
				document.getElementById( inputName ).className = 'input-box'
			}
		}
		if ( inputName === 'password' || inputName === 'confirmPassword' ) {
			initialValueInputStatuses[ fieldInputsValidation.indexOf( inputName ) ] = validate
			if ( document.getElementById( inputName ) !== null && validate === false ) {
				document.getElementById( inputName ).className = 'input-box input-white-background-error'
			}
			if ( document.getElementById( inputName ) !== null && validate === true ) {
				document.getElementById( inputName ).className = 'input-box input-white-background'
			}
		}
		if ( page === 0 ) {
			if ( initialValueInputStatuses.every( allTrue ) ) {
				isInputsValid( true )
			} else {
				isInputsValid( false )
			}
		} else if ( initialValueInputStatuses.every( allTrue ) && postcodeValid ) {
			isInputsValid( true )
		} else {
			isInputsValid( false )
		}
	}

	// function that manages display of subtitle at different registration steps
	function returnSubtitle() {
		switch ( page ) {
		case 0:
			return <Trans id='CompanyRow.insertEmail' />
		case 1:
			return <Trans id='CompanyRow.inputCompanyData' />
		case 2:
			return <Trans id='CompanyRow.companyData' />
		case 3:
			return <Trans id='CompanyRow.chooseCompany' />
		default:
			return 'something'
		}
	}

	function placeholderStyle( input ) {
		if ( input ) { // if input is not undefined
			if ( input.length > 0 ) { // if input length > 0
				return 'input-box input-white-background-dropdown' // or this style
			}
		}
		return 'input-box-dropdown' // or this style
	}

	function placeholderText( inputName ) {
		if ( inputName === 'city' ) {
			return postcode !== '' ? <Trans id='RegistrationInput.city' /> : <Trans id='RegistrationInput.city' />
		}
		return country !== '' ? <Trans id='RegistrationInput.country' /> : <Trans id='RegistrationInput.country' />
	}

	function placeholderDisplay( inputName ) {
		if ( inputName === 'city' ) {
			return postcode !== '' ? 'placeholder-top-dropdown' : 'input-box-placeholder-dropdown'
		}
		return country !== '' ? 'placeholder-top-dropdown' : 'input-box-placeholder-dropdown'
	}

	function renderDropdownPostCodes() {
		if ( postcodeData.CR_PostOffice !== null ) {
			return (
				postcodeData.CR_PostOffice.map( ( post ) => (
					<option
						label={ `${ post.code } - ${ post.name }` }
						key={ post.code }
						value={ post.code }
						onClick={ () => postcodesByCode( { code: post.code } ) }
					/>
				) ) )
		}
		return null
	}

	// function that manages display of content at current registration step
	function displayPageStep() {
		switch ( page ) {
		case 0:
			return (
				<div className='form'>
					<div className='choose-company-subtitle'>
						{ returnSubtitle() }
					</div>
					{ fieldInputs.map( ( inputName ) => (
						<Input
							key={ inputName }
							inputName={ fieldInputsTranslation[ inputName ] }
							id={ inputName }
							regex={ regex[ inputName ] }
							error={ fieldInputsErrors[ inputName ] }
							getValidation={ ( e ) => getValidation( e, inputName ) }
							placeholders={ fieldInputsTranslation[ inputName ] }
							setValue={ ( e ) => setValue( e, inputName ) }
						/>
					) ) }
				</div>
			)
		case 1:
			return (
				<div className='form'>
					<div className='choose-company-subtitle'>
						{ returnSubtitle() }
					</div>
					{ fieldInputs.map( ( inputName ) => {
						if ( inputName === 'postcode' ) {
							return (
								<div
									key={ inputName }
									className='input auto-fill'
								>
									<div className={ postcode !== '' ? 'placeholder-top-dropdown' : 'input-box-placeholder-dropdown' }>
										<div className='placeholder-text-dropdown'>
											{ postcode !== '' ? <Trans id='RegistrationInput.postcode' /> : <Trans id='RegistrationInput.postcode' /> }
										</div>
									</div>
									<input
										ref={ inputValue }
										className={ placeholderStyle( postcode ) }
										key='postCode'
										id='postcode'
										list='postCodeList'
										name='postCodeList'
										value={ postcode }
										onChange={ ( e ) => {
											setPostcode( e.currentTarget.value )
											postcodesByCode( { code: e.currentTarget.value } )
										} }
										onKeyUp={ ( e ) => {
											getValidation( e, inputName )
										} }
									/>
									<datalist id='postCodeList'>
										{ postcodeData !== undefined
											? renderDropdownPostCodes() : null }
									</datalist>
								</div>
							)
						}

						if ( inputName === 'city' ) {
							return (
								<div
									key={ inputName }
									className='input auto-fill'
								>
									<div className={ placeholderDisplay( inputName ) }>
										<div className='placeholder-text-dropdown'>
											{ placeholderText( inputName ) }
										</div>
									</div>
									<input
										id='city'
										ref={ inputValue }
										className={ placeholderStyle( postcode ) }
										value={ getValue( inputName ) }
										disabled
									/>
								</div>
							)
						}
						if ( inputName === 'country' ) {
							return (
								<div
									key={ inputName }
									className='input auto-fill'
								>
									<div className={ placeholderDisplay( inputName ) }>
										<div className='placeholder-text-dropdown'>
											{ placeholderText( inputName ) }
										</div>
									</div>
									<input
										ref={ inputValue }
										className={ placeholderStyle( postcode ) }
										value={ getValue( inputName ) }
										disabled
									/>
								</div>
							)
						}
						return (
							<Input
								key={ inputName }
								inputName={ fieldInputsTranslation[ inputName ] }
								id={ inputName }
								regex={ regex[ inputName ] }
								error={ fieldInputsErrors[ inputName ] }
								getValidation={ ( e ) => getValidation( e, inputName ) }
								placeholders={ fieldInputsTranslation[ inputName ] }
								setValue={ ( e ) => setValue( e, inputName ) }
							/>
						)
					} ) }
				</div>
			)
		case 2:
			return (
				<div className='form'>
					<div className='choose-company-subtitle'>
						{ returnSubtitle() }
					</div>
					{ fieldInputs.map( ( inputName ) => (
						<Input
							key={ inputName }
							inputName={ fieldInputsTranslation[ inputName ] }
							id={ inputName }
							regex={ regex[ inputName ] }
							error={ fieldInputsErrors[ inputName ] }
							getValidation={ ( e ) => getValidation( e, inputName ) }
							placeholders={ fieldInputsTranslation[ inputName ] }
							setValue={ ( e ) => setValue( e, inputName ) }
							input={ getValue( inputName ) }
							disabled
						/>
					) )}
				</div>
			)
		case 3:
			return (
				<div className='user-companies'>
					{ tenantData.loading
						? (
							<Loader />
						)
						// eslint-disable-next-line max-len
						// commented out because it's currently not in use and should probably be under some other case, when we implement it again
						/* (
							<div className='choose-company-subtitle'>
								{ !tenantData.error
									&& tenantData.data !== undefined
									&& tenantData.data.tenants[ 0 ] !== undefined
									&& tenantData.data.tenants[ 0 ].identifiers !== null
									? returnSubtitle() : null }
							</div>
						)}
					{ !tenantData.error
						&& tenantData.data !== undefined
					&& tenantData.data.tenants[ 0 ] !== undefined
						&& tenantData.data.tenants[ 0 ].identifiers !== null
						? tenantData.data.tenants.map( ( tenant ) => (
							<div
								className='company'
								key={ tenant.id }
								role='button'
								tabIndex={ -1 }
								onKeyPress={ () => handleInputDataExistingUser( tenant ) }
								onClick={ () => handleInputDataExistingUser( tenant ) }
							>
								<span className='company-row'>
									<strong><Trans id='CompanyRow.firstName:' /></strong>
									{ tenant.owner.firstName }
								</span>
								<span className='company-row'>
									<strong><Trans id='CompanyRow.lastName:' /></strong>
									{ tenant.owner.lastName }
								</span>
								<span className='company-row'>
									<strong><Trans id='CompanyRow.email:' /></strong>
									{ tenant.owner.email }
								</span>
								<span className='company-row'>
									<strong><Trans id='CompanyRow.companyName:' /></strong>
									{ tenant.identifiers.companyName }
								</span>
							</div>
						) ) */
						: <Trans id='RegistrationProviderExistingUser.noCompany' /> }
				</div>
			)
		case 4:
			return (
				<div className='ok-message'>
					{ registerTenantLoading || registerTenantNewCompanyLoading === undefined ? <div style={ { margin: 'auto', width: '70px' } }><Loader /></div> : null }
					{ registerTenantData || registerTenantNewCompanyData !== undefined ? <Trans id='Button.successful' /> : null }
				</div>
			)
		default:
			return ( <div className='ok-message'>Last Page</div> )
		}
	}

	// rendering component
	return (
		<div>
			<HeaderRegistration />
			<div className='content-registration'>
				<div className='registration-container'>
					<div className='title'>
						<Trans id='Registration.titleProvider' />
					</div>
					<div className='provider-mode-registration'>
						<div
							className='button-registration-mode-inactive'
							role='button'
							tabIndex={ -1 }
							onKeyPress={ () => history.push( '/register/provider/newUser' ) }
							onClick={ () => history.push( '/register/provider/newUser' ) }
						>
							<Trans id='Button.newProvider' />
						</div>
						<div
							className='button-registration-mode-active'
							role='button'
							tabIndex={ -1 }
							onKeyPress={ () => {
								if ( page !== 0 ) {
									window.location.reload()
								}
							} }
							onClick={ () => {
								if ( page !== 0 ) {
									window.location.reload()
								}
							} }
						>
							<Trans id='Button.existingProvider' />
						</div>
					</div>
					<div className='step-content'>
						{ displayPageStep() }
					</div>
					<div className='buttons-container'>
						{ displayButton() }
					</div>
				</div>
			</div>
		</div>
	)
}

RegistrationProviderExistingUser.propTypes = {
	history: propTypes.object.isRequired
}

export default withRouter( RegistrationProviderExistingUser )
