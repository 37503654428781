import React from 'react'
import { TextRow, RectShape } from 'react-placeholder/lib/placeholders'

const ProductRowPlaceholder = (
	<div id='offer-row-line'>
		<RectShape style={ { width: 165, height: 145 } } color='#ececec' />
		<div className='offer-row-line-info'>
			<TextRow style={ { height: 12 } } color='#ececec' />
			<TextRow style={ { height: 12 } } color='#ececec' />
			<TextRow style={ { height: 12 } } color='#ececec' />
		</div>
		<div className='actions-container'>
			<TextRow style={ { height: 12 } } color='#ececec' />
			<TextRow style={ { height: 12 } } color='#ececec' />
		</div>
		<div className='offer-row-dots-row' />
	</div>
)

export default ProductRowPlaceholder
