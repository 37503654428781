/* eslint-disable no-unused-vars */
import React, { useState, Suspense, lazy } from 'react'
import { Trans } from '@lingui/macro'
import propTypes from 'prop-types'

import { LocationIcon } from '../../../../icons'
import ToggleButton from '../../../_Globals/ToggleButton'

import './StandRow.css'

const Image = lazy( () => import( './image' ) )


const StandRow = React.memo( ( { market, setSelectedMarket, updateMarketActiveStatus } ) => {
	const [ activate, setActiveStatus ] = useState( market.active )
	const fileId = market.galleries.length > 0 ? market.galleries[ 0 ].file.file_id : null
	const tenantId = market.galleries.length > 0 ? market.galleries[ 0 ].file.tenant_id : null

	function toggleState() {
		if ( activate ) {
			setActiveStatus( false )
			updateMarketActiveStatus( false )
		} else {
			setActiveStatus( true )
			updateMarketActiveStatus( true )
		}
	}

	return (
		<div id='stand-row-line'>
			<div className={ `stands-row-hub ${ market.confirmed ? null : 'stand-row-hub-deactive' }` }>
				<span>
					<Trans id='StandRow.standHub' />
				</span>
				{ market.hub.name }
				<span>: </span>
				{ !market.confirmed
				&& (
					<span style={ { fontSize: '13px' } }>
						<Trans id='StandRow.( productsWillNotBeShownYet )' />
					</span>
				) }
			</div>
			{ market.galleries.length > 0 ? (
				<Suspense fallback={ <div style={ { width: 165, height: 145, background: '#ececec' } } /> }>
					<Image
						tenantId={ tenantId }
						fileId={ fileId }
						activate={ activate }
					/>
				</Suspense>
			) : (
				<img
					className={ !activate ? 'icon disabled-product' : 'icon' }
					style={ { width: 165, height: 145 } }
					src='/img/market_default.png'
					alt=''
				/>
			) }
			<div className='stand-row-line-info'>
				<span className='stand-row-text'>
					<Trans id='StandRow.stand' />
				</span>
				<span className='stand-row-text-name'>{ market.name }</span>
				<div className='stand-row-description'>{ market.description }</div>
				<hr />
				<p className={ `stand-row-market-status  ${ market.confirmed ? null : 'deactive' }` }>
					<Trans id='StandRow.status: ' />
					{
						market.confirmed
							? <Trans id='StandRow.confirmed' />
							: <Trans id='StandRow.notConfirmed' />
					}
				</p>
			</div>
			<div className='actions-container'>
				<div className='icon-distance'>
					{/* <div className='icon-map'>
						<LocationIcon fontSize={ 25 } />
					</div>
					<div className='distance'>
						<div className='distance-text'>
							<Trans id='StandRow.distance' />
						</div>
						<div className='distance-text-value'>
							TODO km
						</div>
					</div> */}
				</div>
				<div className='toggle-activate-deactivate'>
					<ToggleButton
						activate={ !activate }
						rowId={ market.id }
						onClick={ () => toggleState() }
						textActive={ <Trans id='StandRow.activate' /> }
						textDeactive={ <Trans id='StandRow.deactivate' /> }
					/>
				</div>
				{/* <div className='delete-stand'>
					<div className='delete-text'>
						<Trans id='StandRow.deleteStand' />
					</div>
					<div className='delete-icon'>
						<DeleteIcon fontSize={ 20 } />
					</div>
                </div> */}
				<div
					className='stand-row-line-show-stand'
					role='button'
					tabIndex={ 0 }
					onKeyPress={ () => setSelectedMarket( market.id ) }
					onClick={ () => setSelectedMarket( market.id ) }
				>
					<div className='button-text'>
						<Trans id='StandRow.lookMoreEdit' />
					</div>
					<div className='stand-row-line-arrow-right' />
				</div>
			</div>
			<div className='stand-dots-row' />
		</div>
	)
} )

StandRow.propTypes = {
	market: propTypes.object.isRequired,
	setSelectedMarket: propTypes.func.isRequired,
	updateMarketActiveStatus: propTypes.func.isRequired
}

export default StandRow
