import React, { useState } from 'react'
import { Trans } from '@lingui/react'
import Tooltip from 'react-tooltip-lite'

// import { SliderArrowIcon } from '../../../icons'

import './TableHubCycleOrderHeader.css'

const translations = {
	code: <Trans id='TableHubCycleHeader.code' />,
	aggregation: <Trans id='TableHubCycleHeader.aggregation' />,
	product: <Trans id='TableHubCycleHeader.product' />,
	provider: <Trans id='TableHubCycleHeader.provider' />,
	quantity: <Trans id='TableHubCycleHeader.quantity' />,
	unit: <Trans id='TableHubCycleHeader.unit' />,
	price: <Trans id='TableHubCycleHeader.price' />,
	delivery: <Trans id='TableHubCycleHeader.delivery' />,
	packaging: <Trans id='TableHubCycleHeader.packaging' />,
	deliverability: <Trans id='TableHubCycleHeader.deliverability' />,
	status: <Trans id='TableHubCycleHeader.status' />,
	storageLocation: <Trans id='TableHubCycleHeader.storageLocation' />,
}

const TableHubCycleOrderHeader = React.memo( () => {
	const [ code, sortByCode ] = useState( false )
	const [ aggregation, sortByAggregation ] = useState( false )
	const [ product, sortByProduct ] = useState( false )
	const [ provider, sortByProvider ] = useState( false )
	const [ quantity, sortByQuantity ] = useState( false )
	const [ unit, sortByUnit ] = useState( false )
	const [ price, sortByPrice ] = useState( false )
	const [ delivery, sortByDelivery ] = useState( false )
	const [ packaging, sortByPackaging ] = useState( false )
	const [ deliverability, sortByDeliverability ] = useState( false )
	const [ status, sortByStatus ] = useState( false )
	const [ storageLocation, sortByStorageLocation ] = useState( false )

	function codeFunct() {
		if ( code ) {
			sortByCode( false )
		} else {
			sortByCode( true )
		}
		sortByProduct( false )
		sortByProvider( false )
		sortByQuantity( false )
		sortByUnit( false )
		sortByPrice( false )
		sortByDelivery( false )
		sortByPackaging( false )
		sortByDeliverability( false )
		sortByStatus( false )
		sortByStorageLocation( false )
	}

	function aggregationFunct() {
		if ( aggregation ) {
			sortByAggregation( false )
		} else {
			sortByAggregation( true )
		}
		sortByCode( false )
		sortByProduct( false )
		sortByProvider( false )
		sortByQuantity( false )
		sortByUnit( false )
		sortByPrice( false )
		sortByDelivery( false )
		sortByPackaging( false )
		sortByDeliverability( false )
		sortByStatus( false )
		sortByStorageLocation( false )
	}

	function productFunct() {
		if ( product ) {
			sortByProduct( false )
		} else {
			sortByProduct( true )
		}
		sortByCode( false )
		sortByAggregation( false )
		sortByProvider( false )
		sortByQuantity( false )
		sortByUnit( false )
		sortByPrice( false )
		sortByDelivery( false )
		sortByPackaging( false )
		sortByDeliverability( false )
		sortByStatus( false )
		sortByStorageLocation( false )
	}

	function providerFunct() {
		if ( provider ) {
			sortByProvider( false )
		} else {
			sortByProvider( true )
		}
		sortByCode( false )
		sortByAggregation( false )
		sortByProduct( false )
		sortByQuantity( false )
		sortByUnit( false )
		sortByPrice( false )
		sortByDelivery( false )
		sortByPackaging( false )
		sortByDeliverability( false )
		sortByStatus( false )
		sortByStorageLocation( false )
	}

	function quantityFunct() {
		if ( quantity ) {
			sortByQuantity( false )
		} else {
			sortByQuantity( true )
		}
		sortByCode( false )
		sortByAggregation( false )
		sortByProduct( false )
		sortByProvider( false )
		sortByUnit( false )
		sortByPrice( false )
		sortByDelivery( false )
		sortByPackaging( false )
		sortByDeliverability( false )
		sortByStatus( false )
		sortByStorageLocation( false )
	}

	function unitFunct() {
		if ( unit ) {
			sortByUnit( false )
		} else {
			sortByUnit( true )
		}
		sortByCode( false )
		sortByAggregation( false )
		sortByProduct( false )
		sortByProvider( false )
		sortByQuantity( false )
		sortByPrice( false )
		sortByDelivery( false )
		sortByPackaging( false )
		sortByDeliverability( false )
		sortByStatus( false )
		sortByStorageLocation( false )
	}

	function priceFunct() {
		if ( price ) {
			sortByPrice( false )
		} else {
			sortByPrice( true )
		}
		sortByCode( false )
		sortByAggregation( false )
		sortByProduct( false )
		sortByProvider( false )
		sortByQuantity( false )
		sortByUnit( false )
		sortByDelivery( false )
		sortByPackaging( false )
		sortByDeliverability( false )
		sortByStatus( false )
		sortByStorageLocation( false )
	}

	function deliveryFunct() {
		if ( delivery ) {
			sortByDelivery( false )
		} else {
			sortByDelivery( true )
		}
		sortByCode( false )
		sortByAggregation( false )
		sortByProduct( false )
		sortByProvider( false )
		sortByQuantity( false )
		sortByPrice( false )
		sortByPackaging( false )
		sortByDeliverability( false )
		sortByStatus( false )
		sortByStorageLocation( false )
	}


	function packagingFunct() {
		if ( packaging ) {
			sortByPackaging( false )
		} else {
			sortByPackaging( true )
		}
		sortByCode( false )
		sortByAggregation( false )
		sortByProduct( false )
		sortByProvider( false )
		sortByQuantity( false )
		sortByPrice( false )
		sortByDelivery( false )
		sortByDeliverability( false )
		sortByStatus( false )
		sortByStorageLocation( false )
	}

	function deliverabilityFunct() {
		if ( deliverability ) {
			sortByDeliverability( false )
		} else {
			sortByDeliverability( true )
		}
		sortByCode( false )
		sortByAggregation( false )
		sortByProduct( false )
		sortByProvider( false )
		sortByQuantity( false )
		sortByPrice( false )
		sortByDelivery( false )
		sortByPackaging( false )
		sortByStatus( false )
		sortByStorageLocation( false )
	}

	function statusFunct() {
		if ( status ) {
			sortByStatus( false )
		} else {
			sortByStatus( true )
		}
		sortByCode( false )
		sortByAggregation( false )
		sortByProduct( false )
		sortByProvider( false )
		sortByQuantity( false )
		sortByPrice( false )
		sortByDelivery( false )
		sortByPackaging( false )
		sortByDeliverability( false )
		sortByStorageLocation( false )
	}

	function storageLocationFunct() {
		if ( storageLocation ) {
			sortByStorageLocation( false )
		} else {
			sortByStorageLocation( true )
		}
		sortByCode( false )
		sortByAggregation( false )
		sortByProduct( false )
		sortByProvider( false )
		sortByQuantity( false )
		sortByPrice( false )
		sortByDelivery( false )
		sortByPackaging( false )
		sortByStatus( false )
		sortByDeliverability( false )
	}

	return (
		<div className='TableHubCycleOrderHeader-container'>
			<div
				className='code-hub-orders'
				role='button'
				tabIndex={ -1 }
				onClick={ () => codeFunct() }
				onKeyPress={ () => codeFunct() }
			>
				<Tooltip
					id='code'
					arrowSize={ 5 }
					padding='5px'
					content={ <Trans id='TableHubCycleHeader.code' /> }
					direction='top'
				>
					<div className='code-text-hub-orders'>{ translations.code }</div>

				</Tooltip>
				<div className={ code ? 'sort-arrow-up' : 'sort-arrow-down' }>
					{/* <SliderArrowIcon fontSize={ 12 } /> */}
				</div>
			</div>
			<div
				className='aggregation-hub-orders'
				role='button'
				tabIndex={ -1 }
				onClick={ () => aggregationFunct() }
				onKeyPress={ () => aggregationFunct() }
			>
				<Tooltip
					id='aggregation'
					arrowSize={ 5 }
					padding='5px'
					content={ <Trans id='TableHubCycleHeader.aggregation' /> }
					direction='top'
				>
					<div className='aggregation-text-hub-orders'>{ translations.aggregation }</div>

				</Tooltip>
				<div className={ aggregation ? 'sort-arrow-up' : 'sort-arrow-down' }>
					{/* <SliderArrowIcon fontSize={ 12 } /> */}
				</div>
			</div>
			<div
				className='product-hub-orders'
				role='button'
				tabIndex={ -1 }
				onClick={ () => productFunct() }
				onKeyPress={ () => productFunct() }
			>
				<Tooltip
					id='product'
					arrowSize={ 5 }
					padding='5px'
					content={ <Trans id='TableHubCycleHeader.product' /> }
					direction='top'
				>
					<div className='product-text-hub-orders'>{ translations.product }</div>

				</Tooltip>
				<div className={ product ? 'sort-arrow-up' : 'sort-arrow-down' }>
					{/* <SliderArrowIcon fontSize={ 12 } /> */}
				</div>
			</div>
			<div
				className='provider-hub-orders'
				role='button'
				tabIndex={ -1 }
				onClick={ () => providerFunct() }
				onKeyPress={ () => providerFunct() }
			>
				<Tooltip
					id='provider'
					arrowSize={ 5 }
					padding='5px'
					content={ <Trans id='TableHubCycleHeader.provider' /> }
					direction='top'
				>
					<div className='provider-text-hub-orders'>{ translations.provider }</div>

				</Tooltip>
				<div className={ provider ? 'sort-arrow-up' : 'sort-arrow-down' }>
					{/* <SliderArrowIcon fontSize={ 12 } /> */}
				</div>
			</div>
			<div
				className='header-quantity-hub-orders'
				role='button'
				tabIndex={ -1 }
				onClick={ () => quantityFunct() }
				onKeyPress={ () => quantityFunct() }
			>
				<Tooltip
					id='header-quantity'
					arrowSize={ 5 }
					padding='5px'
					content={ <Trans id='TableHubCycleHeader.quantity' /> }
					direction='top'
				>
					<div className='header-quantity-text-hub-orders'>{ translations.quantity }</div>

				</Tooltip>
				<div className={ quantity ? 'sort-arrow-up' : 'sort-arrow-down' }>
					{/* <SliderArrowIcon fontSize={ 12 } /> */}
				</div>
			</div>
			<div
				className='unit-hub-orders'
				role='button'
				tabIndex={ -1 }
				onClick={ () => unitFunct() }
				onKeyPress={ () => unitFunct() }
			>
				<Tooltip
					id='unit'
					arrowSize={ 5 }
					padding='5px'
					content={ <Trans id='TableHubCycleHeader.unit' /> }
					direction='top'
				>
					<div className='unit-text-hub-orders'>{ translations.unit }</div>

				</Tooltip>
				<div className={ unit ? 'sort-arrow-up' : 'sort-arrow-down' }>
					{/* <SliderArrowIcon fontSize={ 12 } /> */}
				</div>
			</div>
			<div
				className='header-price-hub-orders'
				role='button'
				tabIndex={ -1 }
				onClick={ () => priceFunct() }
				onKeyPress={ () => priceFunct() }
			>
				<Tooltip
					id='header-price'
					arrowSize={ 5 }
					padding='5px'
					content={ <Trans id='TableHubCycleHeader.price' /> }
					direction='top'
				>
					<div className='header-price-text-hub-orders'>{ translations.price }</div>

				</Tooltip>
				<div className={ price ? 'sort-arrow-up' : 'sort-arrow-down' }>
					{/* <SliderArrowIcon fontSize={ 12 } /> */}
				</div>
			</div>
			<div
				className='delivery-hub-orders'
				role='button'
				tabIndex={ -1 }
				onClick={ () => deliveryFunct() }
				onKeyPress={ () => deliveryFunct() }
			>
				<Tooltip
					id='delivery'
					arrowSize={ 5 }
					padding='5px'
					content={ <Trans id='TableHubCycleHeader.delivery' /> }
					direction='top'
				>
					<div className='delivery-text2-hub-orders'>{ translations.delivery }</div>

				</Tooltip>
				<div className={ delivery ? 'sort-arrow-up' : 'sort-arrow-down' }>
					{/* <SliderArrowIcon fontSize={ 12 } /> */}
				</div>
			</div>
			<div
				className='packaging-hub-orders'
				role='button'
				tabIndex={ -1 }
				onClick={ () => packagingFunct() }
				onKeyPress={ () => packagingFunct() }
			>
				<Tooltip
					id='packaging'
					arrowSize={ 5 }
					padding='5px'
					content={ <Trans id='TableHubCycleHeader.packaging' /> }
					direction='top'
				>
					<div className='packaging-text-hub-orders'>{ translations.packaging }</div>

				</Tooltip>
				<div className={ packaging ? 'sort-arrow-up' : 'sort-arrow-down' }>
					{/* <SliderArrowIcon fontSize={ 12 } /> */}
				</div>
			</div>
			<div
				className='deliverability-hub-orders'
				role='button'
				tabIndex={ -1 }
				onClick={ () => deliverabilityFunct() }
				onKeyPress={ () => deliverabilityFunct() }
			>
				<Tooltip
					id='deliverability'
					arrowSize={ 5 }
					padding='5px'
					content={ <Trans id='TableHubCycleHeader.deliverability' /> }
					direction='top'
				>
					<div className='deliverability-text-hub-orders'>{ translations.deliverability }</div>

				</Tooltip>
				<div className={ deliverability ? 'sort-arrow-up' : 'sort-arrow-down' }>
					{/* <SliderArrowIcon fontSize={ 12 } /> */}
				</div>
			</div>
			<div
				className='status-hub-orders'
				role='button'
				tabIndex={ -1 }
				onClick={ () => statusFunct() }
				onKeyPress={ () => statusFunct() }
			>
				<Tooltip
					id='status'
					arrowSize={ 5 }
					padding='5px'
					content={ <Trans id='TableHubCycleHeader.status' /> }
					direction='top'
				>
					<div className='status-text2-hub-orders'>{ translations.status }</div>

				</Tooltip>
				<div className={ status ? 'sort-arrow-up' : 'sort-arrow-down' }>
					{/* <SliderArrowIcon fontSize={ 12 } /> */}
				</div>
			</div>
			<div
				className='storageLocation-hub-orders'
				role='button'
				tabIndex={ -1 }
				onClick={ () => storageLocationFunct() }
				onKeyPress={ () => storageLocationFunct() }
			>
				<Tooltip
					id='storageLocation'
					arrowSize={ 5 }
					padding='5px'
					content={ <Trans id='TableHubCycleHeader.storageLocation' /> }
					direction='top'
				>
					<div className='storageLocation-text-hub-orders'>{ translations.storageLocation }</div>

				</Tooltip>
				<div className={ storageLocation ? 'sort-arrow-up' : 'sort-arrow-down' }>
					{/* <SliderArrowIcon fontSize={ 12 } /> */}
				</div>
			</div>
		</div>

	)
} )

export default TableHubCycleOrderHeader
