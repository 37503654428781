import React from 'react'
import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'

import CheckBox from '../../../../_Globals/CheckBox'

import '../FilterOrderBySomething.css'

// eslint-disable-next-line arrow-body-style
const FilterOrderByStatus = React.memo( ( { visible } ) => {
	/* Logic code goes here */

	return (
		<div className={ visible ? 'visible' : 'hidden' }>
			<div className='filter-code-container'>
				<div className='arrow' />
				<div className='filter-content'>
					<div className='header bottom-line'>
						<Trans id='FilterOrderByStatus.title' />
					</div>
					<div className='content-list filter-status'>
						<div className='filter-content-row'>
							<div className='row-text'>
								<Trans id='FilterOrderByStatus.inPreparation' />
							</div>
							<div className='filter-checkbox'>
								<CheckBox id='status' value={ 1 } />
							</div>
						</div>
						<div className='filter-content-row'>
							<div className='row-text'>
								<Trans id='FilterOrderByStatus.inPreparation' />
							</div>
							<div className='filter-checkbox'>
								<CheckBox id='status' value={ 2 } />
							</div>
						</div>
						<div className='filter-content-row'>
							<div className='row-text'>
								<Trans id='FilterOrderByStatus.inPreparation' />
							</div>
							<div className='filter-checkbox'>
								<CheckBox id='status' value={ 3 } />
							</div>
						</div>
						<div className='filter-content-row'>
							<div className='row-text'>
								<Trans id='FilterOrderByStatus.inPreparation' />
							</div>
							<div className='filter-checkbox'>
								<CheckBox id='status' value={ 4 } />
							</div>
						</div>
						<div className='filter-content-row'>
							<div className='row-text'>
								<Trans id='FilterOrderByStatus.inPreparation' />
							</div>
							<div className='filter-checkbox'>
								<CheckBox id='status' value={ 5 } />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
} )

FilterOrderByStatus.propTypes = {
	visible: propTypes.bool.isRequired
}

export default FilterOrderByStatus
