
const parseObjectForMutations = ( firstMutationFields, secondMutationFields, object ) => {
	const newData = { firstMutation: {}, secondMutation: {} }

	Object.keys( object ).forEach( ( key ) => {
		if ( !key.hubId ) {
			if ( firstMutationFields.includes( key ) ) {
				newData.firstMutation[ key ] = object[ key ]
			}
			if ( secondMutationFields.includes( key ) ) {
				newData.secondMutation[ key ] = object[ key ]
			}
		}
	} )

	return newData
}

export default parseObjectForMutations
