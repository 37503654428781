import React, { useState } from 'react'
import Autosuggest from 'react-autosuggest'
import propTypes from 'prop-types'
// import { Trans } from '@lingui/macro'

import theme from './AutosuggestionInput.module.css'
import store from '../../../stores/mainStore'

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = ( suggestion ) => suggestion.name || suggestion.translation_sl

// Use your imagination to render suggestions.
const renderSuggestion = ( suggestion ) => (
	<div>
		{ suggestion.name || suggestion.translation_sl }
	</div>
)

const AutosuggestionInput = ( { suggestions, handleShoppingList, isMyOffer } ) => {
	const [ inputValue, setInputValue ] = useState( '' )
	const [ _suggestions, setSuggestions ] = useState( suggestions )

	const onChange = ( _event, { newValue } ) => {
		setInputValue( newValue )
	}

	// Teach Autosuggest how to calculate suggestions for any given input value.
	const getSuggestions = ( value ) => {
		const inputText = value?.trim()?.toLowerCase()
		const inputTextLength = inputText?.length

		return suggestions?.filter(
			( suggestion ) => ( suggestion?.name
				? suggestion?.name?.toLowerCase()?.slice( 0, inputTextLength ) === inputText
				// eslint-disable-next-line camelcase
				: suggestion?.translation_sl?.toLowerCase()?.slice( 0, inputTextLength ) === inputText
			)
		)
	}

	// Autosuggest will call this function every time you need to update suggestions.
	// You already implemented this logic above, so just use it.
	const onSuggestionsFetchRequested = ( { value } ) => {
		setSuggestions( getSuggestions( value ) )
	}

	const onSuggestionSelected = ( _event, {
		suggestion /* , suggestionValue, suggestionIndex, sectionIndex, method */
	} ) => {
		//	Add suggestion to filter
		handleShoppingList( { type: 'addItemToShoppingList', addedItem: { ...suggestion, disabled: false } } )
		//	Clear input
		setInputValue( '' )
	}

	// TODO check this one if working ok!
	// Autosuggest will call this function every time you need to clear suggestions.
	const onSuggestionsClearRequested = () => {
		setSuggestions( suggestions )
	}

	// Autosuggest will pass through all these props to the input.
	const inputProps = {
		placeholder: store.language === 'sl' ? 'Poišči izdelek ali stojnico' : 'Search for product or market',
		// TODO!! <Trans id='Autosuggest.placeholder' />,
		value: inputValue,
		type: 'search',
		onChange
	}

	const inputPropsForMyOffer = {
		placeholder: store.language === 'sl' ? 'Poišči izdelek' : 'Search for product',
		// TODO!! <Trans id='Autosuggest.placeholder' />,
		value: inputValue,
		type: 'search',
		onChange
	}

	// Finally, render it!
	return (
		<Autosuggest
			theme={ theme }
			suggestions={ _suggestions }
			highlightFirstSuggestion
			onSuggestionsFetchRequested={ onSuggestionsFetchRequested }
			onSuggestionsClearRequested={ onSuggestionsClearRequested }
			onSuggestionSelected={ onSuggestionSelected }
			getSuggestionValue={ getSuggestionValue }
			renderSuggestion={ renderSuggestion }
			inputProps={ isMyOffer ? inputPropsForMyOffer : inputProps }
		/>
	)
}

AutosuggestionInput.defaultProps = {
	isMyOffer: false
}

AutosuggestionInput.propTypes = {
	suggestions: propTypes.array.isRequired,
	handleShoppingList: propTypes.func.isRequired,
	isMyOffer: propTypes.bool
}

export default AutosuggestionInput
