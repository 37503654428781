/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import Tooltip from 'react-tooltip-lite'
import { useStateValue } from '../../stores/contextStore/stateStore'
import HeaderActionIcons from './components/HeaderActionIcons'
import './Header.css'

const Header = React.memo( ( { isAuthenticated, history } ) => {
	const [ { galleryOpened }, updateType ] = useStateValue()

	function closeGalleryWhenClickOutside( e ) {
		if ( e.target.id === 'headerOverlay' ) {
			updateType( { type: 'galleryOpened', galleryOpened: false } )
		}
	}

	function homeNavigator() {
		updateType( { type: 'isProductSelected', isProductSelected: null } )
		history.push( '/' )
	}

	return (
		<div id='uhub-header'>
			{ galleryOpened
				// eslint-disable-next-line react/jsx-wrap-multilines
				? <div
					id='headerOverlay'
					className='header-overlay'
					onKeyPress={ ( e ) => closeGalleryWhenClickOutside( e ) }
					onClick={ ( e ) => closeGalleryWhenClickOutside( e ) }
				/> : null }
			<div
				className='uhub-icon'
				role='button'
				tabIndex={ -1 }
				onKeyPress={ () => homeNavigator() }
				onClick={ () => homeNavigator() }
			>
				<Tooltip
					id='tooltipLogo'
					arrowSize={ 5 }
					padding='5px'
					content={ <Trans id='Tooltip.home' /> }
					direction='bottom'
				>
					<p className='uhub-title'>Uhub</p>
				</Tooltip>
			</div>
			<div />
			<HeaderActionIcons isAuthenticated={ isAuthenticated } />
		</div>
	)
} )


Header.propTypes = {
	isAuthenticated: propTypes.bool.isRequired,
	history: propTypes.object.isRequired,
}

export default withRouter( Header )
