import React from 'react'
import propTypes from 'prop-types'

import './Button.css'

const Button = ( { text, disabled, onClick } ) => (
	<div
		className={ `button ${ !disabled ? 'btn-disabled' : '' }` }
		onClick={ !disabled ? () => {} : onClick }
		onKeyPress={ !disabled ? () => {} : onClick }
		tabIndex={ 0 }
		role='button'
	>
		{ text }
	</div>
)

Button.propTypes = {
	text: propTypes.object.isRequired,
	disabled: propTypes.bool.isRequired,
	onClick: propTypes.func.isRequired
}

export default Button
