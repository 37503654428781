/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Trans } from '@lingui/macro'

import './OrdersSectionPage.css'
import TableHubOrderHeader from '../../_Globals/TableHubOrderHeader'
import TableHubOrderRow from '../../_Globals/TableHubOrderRow'
import ConditionalWrapper from '../../_Globals/ConditionalWrapper'
import OrderDisplay from './components/OrderDisplay'
import Loader from '../../Loader'
import { useStateValue } from '../../../stores/contextStore/stateStore'

/* global sessionStorage */

const GET_DISTINCT_CYCLE_NUMS = gql`
query ($hubId: bigint) {
    cart_orders(distinct_on: cycle_id, order_by: {cycle_id: asc}, where: {hub_id: {_eq: $hubId}}) {
        id
        cycle_id
    }
}`

const GET_ORDERS_WITH_LOGISTIC = gql`
query getOrdersWithLogistics($hub_id: bigint!, $cycle_id: bigint, $pickup: Boolean,$status_id: Int) {
  cart_orders(where: {hub_id: {_eq: $hub_id}, cycle_id: {_eq: $cycle_id}, customer_pickup: {_eq: $pickup}, _and: {status_id: {_in: [4, 5, 6, 7, 8]}, _or: {status_id: {_eq: $status_id}}}}, order_by: {cycle_id: desc, created_at: desc}) {
    id
    cycle_id
    customer_pickup
    status_id
    total_bruto_price
    updated_at
    customer {
	  id
      owner {
		keycloakId
        firstName
        lastName
      }
      applications {
		id
        data {
          address {
            address
            zip
            city
          }
          mobile
          phone
        }
      }
    }
    drop_off_driver_id
  }
}`

const GET_HUB_BY_TENANT_ID = gql`
query GetHubByTenantIdOrderSectionPage( $tenantId: String! ) {
    hub_hub( where: { tenant_id: { _eq: $tenantId } } ) {
        id
	}
}`

const GET_ALL_TENANT_CYCLES = gql`query ($tenantId: String) {
  hub_hub(where: {tenant_id: {_eq: $tenantId}}) {
    cycles {
      id
    }
  }
}`


const GET_USERS_BY_TENANT_ID = gql`
query ($tenantId: String) {
  tenant(input: {id: $tenantId}) {
    applications {
	  id
      accounts {
		id
        user {
          firstName
          lastName
          roles
          keycloakId
        }
      }
    }
  }
}`

const OrdersSectionPage = ( props ) => {
	const tenantId = sessionStorage.getItem( 'uhub_tenantId' )
	const [ selectedOrder, selectOrder ] = useState( null )
	const [ details, showDetails ] = useState( false )
	const [ windowWidth, setWidth ] = useState( window.innerWidth > 937 )
	const [ , togglePrintOverlay ] = useStateValue()

	const {
		data: hubData,
		error: hubError,
		loading: hubLoading
	} = useQuery( GET_HUB_BY_TENANT_ID, { variables: { tenantId }, fetchPolicy: 'no-cache' } )


	const hubById = hubData
        && hubData.hub_hub
		&& hubData.hub_hub.length > 0 ? hubData.hub_hub[ 0 ] : false

	const {
		data: OrdersCycleData,
		loading: OrdersCycleLoading,
		error: OrdersCycleError,
		refetch: OrdersCyclesRefetch,
	} = useQuery( GET_ORDERS_WITH_LOGISTIC, {
		variables: {
			hub_id: hubById.id ? hubById.id : 0,
		},
		fetchPolicy: 'no-cache'
	} )


	// returns true if pickup, false if deliver and null if both are checked or unchecked
	function checkboxClick() {
		const pickupChecked = document.getElementById( 'pickup' ).checked
		const deliverChecked = document.getElementById( 'deliver' ).checked
		document.getElementById( 'deliverableOrders' ).checked = false
		if ( pickupChecked && !deliverChecked ) {
			return true
		} if ( !pickupChecked && deliverChecked ) {
			return false
		}
		return null
	}


	const {
		data: allCycleData,
	} = useQuery( GET_ALL_TENANT_CYCLES, { variables: { tenantId }, fetchPolicy: 'no-cache' } )

	function getCycleInputValue() {
		const { value } = document.getElementById( 'cycleListNum' )
		if ( value !== '' ) {
			return value
		}
		return null
	}

	function deliverableCheckboxHelper() {
		const deliverable = document.getElementById( 'deliverableOrders' ).checked
		if ( deliverable ) {
			document.getElementById( 'pickup' ).checked = false
			document.getElementById( 'deliver' ).checked = false
			OrdersCyclesRefetch(
				{
					hub_id: hubById.id ? hubById.id : 0,
					cycle_id: getCycleInputValue(),
					pickup: false,
					status_id: 6
				}
			)
		} else {
			OrdersCyclesRefetch(
				{
					hub_id: hubById.id ? hubById.id : 0,
					cycle_id: getCycleInputValue(),
					pickup: null,
					status_id: null
				}
			)
		}
	}

	const {
		data: UsersData,
		error: UsersError,
		loading: UsersLoading
	} = useQuery( GET_USERS_BY_TENANT_ID, { variables: { tenantId }, fetchPolicy: 'no-cache' } )

	function getDrivers() {
		const finalArray = []
		const users = UsersData?.tenant.applications[0].accounts
		if ( users !== undefined && !UsersError && !UsersLoading ) {
			for ( let i = 0; i < users.length; i += 1 ) {
				const { user } = users[ i ]
				if ( user.roles.includes( 'driver' ) ) {
					finalArray.push( user )
				}
			}
		}
		return finalArray
	}


	const orderCycles = OrdersCycleData
		&& OrdersCycleData.cart_orders
		? OrdersCycleData.cart_orders : []

	const {
		data: cycleData,
	} = useQuery( GET_DISTINCT_CYCLE_NUMS, {
		variables: {
			hubId: hubById.id ? hubById.id : 0,
		},
		fetchPolicy: 'no-cache'
	} )

	const distinctCycles = cycleData
	&& cycleData.cart_orders
		? cycleData.cart_orders : []

	function makeCyclePairs() {
		const cycles = []
		if ( allCycleData !== undefined ) {
			for ( let i = 0; i < allCycleData.hub_hub[ 0 ].cycles.length; i += 1 ) {
				for ( let j = 0; j < distinctCycles?.length; j += 1 ) {
					if ( allCycleData.hub_hub[ 0 ].cycles[ i ].id === distinctCycles[ j ].cycle_id ) {
						// eslint-disable-next-line max-len
						const cycle = { actualCycleId: allCycleData.hub_hub[ 0 ].cycles[ i ].id, shownCycleId: i + 1, _typename: 'cart_orders' }
						cycles.push( cycle )
					}
				}
			}
		}
		return cycles.reverse()
	}

	const cyclePairs = makeCyclePairs()

	function addShownIdsToCycles() {
		if ( OrdersCycleData ) {
			for ( let i = 0; i < OrdersCycleData.cart_orders.length; i += 1 ) {
				for ( let j = 0; j < cyclePairs.length; j += 1 ) {
					if ( OrdersCycleData.cart_orders[ i ].cycle_id === cyclePairs[ j ].actualCycleId ) {
						OrdersCycleData.cart_orders[ i ].shownId = cyclePairs[ j ].shownCycleId
					}
				}
			}
		}
	}


	function orderDisplayHelper( data ) {
		if ( data.location.state !== undefined ) {
			OrdersCyclesRefetch(
				{
					hub_id: hubById.id ? hubById.id : 0,
					cycle_id: data.location.state.cycle_id
				}
			)
		}
	}

	function shownIdHelper( cycleId ) {
		for ( let i = 0; i < cyclePairs.length; i += 1 ) {
			if ( cyclePairs[ i ].actualCycleId === cycleId ) {
				return cyclePairs[ i ].shownCycleId
			}
		}
		return null
	}


	useEffect( () => {
		const handleResize = () => {
			setWidth( window.innerWidth > 937 )
		}
		window.addEventListener( 'resize', handleResize )
		return () => { window.removeEventListener( 'resize', handleResize ) }
	}, [] )

	if ( hubLoading ) {
		return <Loader />
	}

	if ( hubError ) {
		return (
			<div>
				{ JSON.stringify( hubError ) }
			</div>
		)
	}

	return (
		<ConditionalWrapper
			condition={ windowWidth }
			falseConditionComponent={ (
				<div className='warning'>
					<Trans id='orderSectionPage.Warning' />
				</div>
			) }
		>
			<ConditionalWrapper
				condition={ details === false }
				falseConditionComponent={ (
					<OrderDisplay
						selectedOrder={ selectedOrder }
						selectOrder={ ( selected ) => selectOrder( selected ) }
						showDetails={ ( detail ) => showDetails( detail ) }
					/>
				) }
			>
				<div className='order-section-page-container'>
					{addShownIdsToCycles()}
					{ OrdersCycleLoading ? <Loader /> : null }
					{ OrdersCycleError ? <div>{ JSON.stringify( OrdersCycleError ) }</div> : null }
					{ orderCycles ? (
						<div className='data-range-selector'>
							<span className='cycle-num-text'>
								<Trans id='orderSectionPage.cycleNum' />
							</span>
							<span>
								<input
									autoComplete='off'
									id='cycleListNum'
									list='cycleNums'
									name='cycles'
									onKeyUp={ ( e ) => {
										let cycleId = null
										for ( let i = 0; i < cyclePairs.length; i += 1 ) {
											if ( cyclePairs[ i ].shownCycleId.toString() === e.target.value ) {
												cycleId = cyclePairs[ i ].actualCycleId
											}
										}
										OrdersCyclesRefetch(
											{
												hub_id: hubById.id ? hubById.id : 0,
												cycle_id: cycleId || null
											}
										)
										// eslint-disable-next-line no-param-reassign,react/prop-types
										props.location.state = undefined
									} }


								/>
								<datalist id='cycleNums'>
									{/* Later fix key value */}
									{ orderCycles ? cyclePairs.map( ( order ) => (
										<option
											key={ order.actualCycleId }
											value={ order.shownCycleId }
											onKeyPress={ () => OrdersCyclesRefetch(
												{
													hub_id: hubById.id ? hubById.id : 0,
													cycle_id: order.actualCycleId,
												}
											) }
										/>
									) ) : null}
								</datalist>
							</span>
							<span>
								<input
									id='pickup'
									type='checkbox'
									name='pickup'
									onClick={ () => OrdersCyclesRefetch(
										{
											hub_id: hubById.id ? hubById.id : 0,
											cycle_id: getCycleInputValue(),
											pickup: checkboxClick(),
											status_id: null
										}
									) }
								/>
							</span>

							<span className='pickup-at-hub-text'><Trans id='orderSectionPage.pickup' /></span>
							<span>
								<input
									id='deliver'
									type='checkbox'
									name='deliver'
									onClick={ () => OrdersCyclesRefetch(
										{
											hub_id: hubById.id ? hubById.id : 0,
											cycle_id: getCycleInputValue(),
											pickup: checkboxClick(),
											status_id: null
										}
									) }
								/>
							</span>

							<span className='deliver-to-address-text'><Trans id='orderSectionPage.deliver' /></span>
							<span>
								<input
									id='deliverableOrders'
									type='checkbox'
									name='deliverableOrders'
									onClick={ () => deliverableCheckboxHelper() }
								/>
							</span>

							<span className='deliverable-orders-text'><Trans id='orderSectionPage.deliverableOrders' /></span>

							<div
								id='btn-send-to-print'
								role='button'
								tabIndex={ 0 }
								onKeyPress={ () => togglePrintOverlay( { type: 'togglePrintOverlayMenu', printOverlayMenu: true } ) }
								onClick={ () => togglePrintOverlay( { type: 'togglePrintOverlayMenu', printOverlayMenu: true } ) }
							>
								<Trans id='PrintOrdersCode' />
							</div>
						</div>
					) : null }
					<div className='hub-orders'>
						<TableHubOrderHeader />
						{/* Later fix key value */}
						{ OrdersCycleLoading ? <Loader /> : null }
						{ OrdersCycleError ? <div>{ JSON.stringify( OrdersCycleError ) }</div> : null }
						{orderDisplayHelper( props )}
						{ orderCycles ? orderCycles.map( ( order ) => (
							<TableHubOrderRow
								key={ order.id }
								hubId={ hubById.id }
								customer={ order.customer }
								data={ order }
								drivers={ getDrivers() }
								onClick={ () => {
									selectOrder( {
										order_id: order.id,
										hub_id: hubById.id,
										cycle_id: order.cycle_id,
										shownId: shownIdHelper( order.cycle_id ),
										customer: order.customer
									} )
									showDetails( !details )
								} }
							/>
						) ) : null }
					</div>
				</div>
			</ConditionalWrapper>
		</ConditionalWrapper>
	)
}

OrdersSectionPage.defaultProps = {
	props: null
}


export default OrdersSectionPage
