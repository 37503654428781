/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import propTypes from 'prop-types'
import Swiper from 'react-id-swiper'
import { Navigation, Pagination } from 'swiper/dist/js/swiper.esm'
import Image from './components/image'
import './ImageSliderRow.css'


const ImageSlider = ( {
	galleryItems,
	galleryType,
	product
} ) => {
	const [ swiper, updateSwiper ] = React.useState( null )

	// for product image gallery
	const fileId = product && product.gs1_brick.files ? product.gs1_brick.files.file_id : null
	const tenantId = product && product.gs1_brick.files ? product.gs1_brick.files.tenant_id : null

	//	TODO - Create better breakpoints and test
	const params = {
		spaceBetween: 10,
		slidesPerView: 1,
		observer: true,
		loop: false,
		centeredSlides: true,
		shouldSwiperUpdate: true,
		pagination: {
			el: '.swiper-pagination',
			type: 'bullets',
			clickable: true
		}
	}

	const goNext = () => {
		if ( swiper !== null ) {
			swiper.slideNext()
		}
	}

	const goPrev = () => {
		if ( swiper !== null ) {
			swiper.slidePrev()
		}
	}

	return (
		<div className='image-slider-row'>
			<div className='image-slider-arrow-left'>
				<button id='image-slider-btn-swiper-prev' type='button' onClick={ goPrev } />
			</div>
			<div className='image-slider'>
				<Swiper { ...params } getSwiper={ updateSwiper } modules={ [ Navigation, Pagination ] }>
					{ galleryItems.length > 0 ? galleryItems.map( ( pic ) => (
						<div className='image-slider-item-holder' key={ pic.file.file_id }>
							<Image
								tenantId={ pic.file.tenant_id }
								fileId={ pic.file.file_id }
							/>
						</div>
					) )
						: (
							<div className='image-slider-item-holder'>
								{ galleryType === 'product' && product.gs1_brick.files ? (
									<Image
										tenantId={ tenantId }
										fileId={ fileId }
										publicImage={ product.gs1_brick.files.public }
										galleries={ product.galleries }
									/>
								) : null }
								{ galleryType === 'product' && !product.gs1_brick.files ? (
									<img style={ { width: 'auto', height: 'auto' } } color='#ececec' src='/img/no_default_picture.png' alt='' />
								) : null }
								{ galleryType === 'market' ? <img className='image-slider-image' src='/img/market_default.png' alt='' /> : null }
								{ galleryType === 'hub' ? <img className='image-slider-image' src='/img/hub_default.png' alt='' /> : null }
							</div>
						) }
				</Swiper>
			</div>
			<div className='image-slider-arrow-right'>
				<button id='image-slider-btn-swiper-next' type='button' onClick={ goNext } />
			</div>
		</div>
	)
}

ImageSlider.defaultProps = {
	product: null,
}

ImageSlider.propTypes = {
	galleryItems: propTypes.array.isRequired,
	galleryType: propTypes.string.isRequired,
	product: propTypes.object,
}
export default ImageSlider
