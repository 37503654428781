/* eslint-disable max-len */
import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import './MarketView.css'
import { useStateValue } from '../../../stores/contextStore/stateStore'
import roleHandler from '../../../utils/ContentRoleHandler'

import mainStore from '../../../stores/mainStore'
import EditArea from '../../_Globals/EditArea'
import ImageSlider from '../../ImageSlider'
import Button from '../../_Globals/Button/Button'
import ImageGallery from '../../_Globals/ImageGallery'
import Loader from '../../Loader'
import StarComponent from '../../_Globals/StarComponent'

const GET_PROVIDER_RATING = gql`
query getProviderRatingMarketView( $id: bigint! ){
	provider_provider( where: { id: { _eq: $id } } ) {
		id
    	average_mark
  	}
}`

const GET_MARKET_BY_ID = gql`
query getMarketById( $id: bigint! ) {
    provider_market( where: { id: {_eq: $id } } ) {
		id
		provider_id
        active
        description
        name
		galleries( where: { default: { _eq: true } } ) {
			id
      		file {
				id
        		tenant_id
        		file_id
      		}
    	}
    }
}`

const GET_MARKET_GALLERY = gql`
	query get_market_gallery(
		$market_id: bigint
	){
		media_gallery(where: { market_id: { _eq: $market_id } } ) {
			id
			file {
				id
				file_id
				tenant_id
			}
		}
	}
`

const MarketView = React.memo( ( {
	marketId, editableFields, passText, itemsRefetch
} ) => {
	const { language } = mainStore
	const [ { isAuth } ] = useStateValue()
	const [ { galleryOpened }, openGallery ] = useStateValue()
	const [ editable, toggleEditable ] = useState( false )
	const [ editedText, setEditedText ] = useState( {} )
	const {
		data: marketViewData,
		error: marketViewError,
		loading: marketViewLoading,
	} = useQuery( GET_MARKET_BY_ID,
		{ variables: { id: marketId } } )

	const viewData = marketViewData
		&& marketViewData.provider_market ? marketViewData.provider_market[ 0 ] : false

	const {
		data: galleryMarketData,
		loading: galleryMarketLoading,
		error: galleryMarketError,
		refetch: refetchMarketGallery
	} = useQuery( GET_MARKET_GALLERY, {
		variables: {
			market_id: marketId,
		}
	} )

	const galleryItems = galleryMarketData
		&& galleryMarketData.media_gallery
		&& galleryMarketData.media_gallery.length > 0
		? galleryMarketData.media_gallery : []

	const {
		data: dataProviderRating,
		error: errorProviderRating,
	} = useQuery( GET_PROVIDER_RATING, {
		variables: {
			id: viewData ? viewData.provider_id : 0
		}
	} )

	const providerRating = dataProviderRating
			&& dataProviderRating.provider_provider
			&& dataProviderRating.provider_provider.length > 0
		? dataProviderRating.provider_provider[ 0 ].average_mark : null


	useEffect( () => {
		if ( roleHandler( isAuth, [ 'provider' ] ) ) {
			toggleEditable( editableFields )
		}
	}, [ editableFields, isAuth ] )

	useEffect( () => {
		if ( Object.keys( editedText ).length > 0 ) {
			passText( editedText )
			setEditedText( {} )
		}
	}, [ editedText, passText ] )

	function getText( id, text ) {
		const objectCopy = editedText

		if ( !objectCopy.id ) {
			objectCopy.id = marketId
		}

		objectCopy[ id ] = text
		setEditedText( objectCopy )
	}

	if ( marketViewError || !viewData ) {
		return <div>{ JSON.stringify( marketViewError ) }</div>
	}

	if ( marketViewLoading ) {
		return <Loader />
	}

	return (
		<div id='provider-view-container'>
			<div id='provider-view-picture-slider' style={ { backgroundColor: '#fffffff' } }>
				{ galleryMarketLoading ? <Loader /> : null }
				{ galleryMarketError ? <Trans id='ProductView.cannotDisplayMarketImages' /> : null }
				{ galleryMarketData ? <ImageSlider galleryItems={ galleryItems } galleryType='market' /> : null }
				<div className='gallery-button'>
					{ editable
						? (
							<Button
								text={ <Trans id='UploadPic.button' /> }
								disabled
								onClick={ () => openGallery( { type: 'galleryOpened', galleryOpened: true } ) }
							/>
						) : null }
				</div>
			</div>
			{ galleryOpened
				? (
					<ImageGallery
						gallery='ProviderView'
						title={ <Trans id='ImageGallery.titleMyProvider' /> }
						productId={ null }
						marketId={ marketId }
						hubId={ null }
						itemsRefetch={ () => itemsRefetch() }
						galleryRefetch={ () => refetchMarketGallery() }
					/>
				) : null }
			<div id='provider-view-info'>
				<div id='provider-view-first-row'>
					<div className='provider-view-first-row-name'>
						<span id='title' />
						<EditArea
							id='name'
							isInput
							myStyle='provider-view-title-editable'
							getText={ getText }
							text={ viewData.name }
							editable={ editable }
							placeholder={ language === 'sl' ? 'Ime stojnice...' : 'Name of stand...' }
						/>
					</div>
					<div className='title-actions'>
						{ /* <div className='provider-comments' style={ { margin: '0 0 0 5px' } }>Komentarji??</div> */ }
						{ /* <div className='provider-nakupi' style={ { margin: '0 5px 0 5px' } }>5nakupov??</div> */ }
						<div className='provider-starGrading' style={ { margin: '0 0 0 15px' } }>
							{ !errorProviderRating ? (
								<StarComponent
									fontSize={ 16 }
									starCount={ providerRating }
									providerId={ viewData.provider_id }
								/>
							) : <div>{ JSON.stringify( errorProviderRating ) }</div> }
						</div>
					</div>
				</div>
				<div className='provider-description'>
					<EditArea
						id='description'
						getText={ getText }
						text={ viewData.description }
						editable={ editable }
						placeholder={ language === 'sl' ? 'Opis...' : 'Description...' }
					/>
				</div>
				{/* <div className='providerDetails-typeOfProducts'>
					<Trans id='ProviderDetails.typeOfProducts' />
				</div> */}
				<div className='providerDetails-filters'>
					{/* { data.providerDescription.filters.map( ( item ) => (
						<div key={ item.id } className='providerDetails-products-item'>{ item.item }</div>
                    ) )} */}
					{/* TODO */}
				</div>
				<div style={ { marginTop: '30px' } } />
			</div>
			<div id='dots-row' />
		</div>
	)
} )

MarketView.defaultProps = {
	editableFields: false,
	passText: () => {},
	itemsRefetch: () => {},
}

MarketView.propTypes = {
	marketId: propTypes.number.isRequired,
	editableFields: propTypes.bool,
	passText: propTypes.func,
	itemsRefetch: propTypes.func
}

export default MarketView
