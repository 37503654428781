import React from 'react'
import { Trans } from '@lingui/macro'

import './PreviousOrdersHeader.css'

const PreviousOrdersHeader = React.memo( () => (
	<div id='previous-orders-header'>
		<span className='previous-orders-text'><Trans id='MyPurchasesPage.previousOrders' /></span>
	</div>
) )

export default PreviousOrdersHeader
