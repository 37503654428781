import React from 'react'
import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'

import { useStateValue } from '../../../../../stores/contextStore/stateStore'
import { SearchIcon, Clear } from '../../../../../icons'
import Button from '../../../../_Globals/Button/Button'

import '../FilterOrderBySomething.css'


const FilterOrderByProvider = React.memo( ( { visible } ) => {
	const [ , closeProviderFilter ] = useStateValue()

	return (
		<div className={ visible ? 'visible' : 'hidden' }>
			<div className='filter-code-container'>
				<div className='arrow' />
				<div className='filter-content'>
					<div className='header'>
						<Trans id='FilterOrderByCustomer.title' />
					</div>
					<div className='search-bar'>
						<SearchIcon fontSize={ 14 } color='grey' />
						<input
							className='search-input'
							type='text'
						/>
						<Clear fontSize={ 14 } color='grey' />
					</div>
					<div className='content-list'>
						<div className='filter-content-row code-row'>
							<div className='row-text'>
								Podjetje 1
							</div>
						</div>
						<div className='filter-content-row code-row'>
							<div className='row-text'>
								Podjetje 2
							</div>
						</div>
						<div className='filter-content-row code-row'>
							<div className='row-text'>
								Podjetje 3
							</div>
						</div>
						<div className='filter-content-row code-row'>
							<div className='row-text'>
								Podjetje 4
							</div>
						</div>
						<div className='filter-content-row code-row'>
							<div className='row-text'>
								Podjetje 5
							</div>
						</div>
						<div className='filter-content-row code-row'>
							<div className='row-text'>
								Podjetje 6
							</div>
						</div>
						<div className='filter-content-row code-row'>
							<div className='row-text'>
								Podjetje 7
							</div>
						</div>
					</div>
					<div className='button-container'>
						<div className='ok-button'>
							<Button
								text={ <Trans id='FilterOrderByCode.buttonOk' /> }
								disabled
								onClick={ () => {} }
							/>
						</div>
						<div className='cancel-button'>
							<Button
								text={ <Trans id='FilterOrderByCode.buttonCancel' /> }
								disabled
								onClick={ () => { closeProviderFilter( { type: 'displayProviderFilter', displayProviderFilter: false } ) }}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
} )

FilterOrderByProvider.propTypes = {
	visible: propTypes.bool.isRequired
}

export default FilterOrderByProvider
