import React from 'react'
import { Trans } from '@lingui/macro'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import propTypes from 'prop-types'

import './PopupChooseHub.css'
import Button from '../../../_Globals/Button/Button'
import Loader from '../../../Loader'

const GET_ALL_HUBS = gql`{
    hub_hub {
        id
        name
    }
}`

const GET_MARKET_BY_TENANT_ID = gql`
query getMarketByTenantIdMyStands( $tenantId: String! ) {
    provider_market( where: { tenant_id: { _eq: $tenantId } } ) {
		id
        active
        confirmed
        description
        hub_id
        name
        sla_id
        hub {
			id
            name
        }
    }
}`

const INSERT_NEW_EMPTY_MARKET = gql`
    mutation insertEmptyMarket( $providerId: bigint!, $hubId: bigint!, $tenantId: String! ) {
    insert_provider_market( objects: { 
            active: false, 
            confirmed: false, 
            description: "",
            name: "",
            sla_status: NO_SLA,
            provider_id: $providerId, 
            hub_id: $hubId,
            tenant_id: $tenantId
        } ) {
            returning {
                id
            }
    }
}`

const PopupChooseHub = React.memo( ( {
	togglePopup, myMarkets, setSelectedMarket, tenantId, providerId, toggleEditable
} ) => {
	const { data, loading, error } = useQuery( GET_ALL_HUBS )
	const [ insertEmptyMarket ] = useMutation( INSERT_NEW_EMPTY_MARKET, {
		refetchQueries: () => [ {
			query: gql`${ GET_MARKET_BY_TENANT_ID }`,
			variables: {
				tenantId
			}
		} ]
	} )

	let chooseHub = data && data.hub_hub ? data.hub_hub : []
	chooseHub = chooseHub.filter(
		( hub ) => ( myMarkets.findIndex( ( m ) => m.hub_id === hub.id ) === -1 )
	)
	const isThereHubToChoose = chooseHub.length > 0

	function selectHub() {
		const e = document.getElementById( 'slct' )
		const getHubId = e.options[ e.selectedIndex ].value

		if ( tenantId && providerId && getHubId ) {
			insertEmptyMarket( {
				variables: {
					providerId,
					hubId: getHubId,
					tenantId
				}
			} ).then( ( res ) => {
				if ( res.data && res.data.insert_provider_market ) {
					setSelectedMarket( res.data.insert_provider_market.returning[ 0 ].id )
					toggleEditable( true )
				}
			} )
		}
		togglePopup( false )
	}

	if ( error ) {
		return null
	}

	return (
		<div className='popup-main-container'>
			<div className='popup-window'>
				<div className='popup-instructions'>
					<Trans id='PopupChooseHub.instruction' />
				</div>
				{ loading ? <Loader /> : (
					<>
						<div className='popup-dropDown'>
							{/* <div className='Triangle' /> */}
							<select
								className='popup-select'
								name='slct'
								id='slct'
							>
								{ isThereHubToChoose
									&& chooseHub.map( ( hub ) => (
										<option
											key={ hub.id }
											value={ hub.id }
										>
											{ hub.name }
										</option>
									) ) }
							</select>
						</div>
						<div className='popup-buttons'>
							{ isThereHubToChoose && (
								<Button
									text={ <Trans id='PopupChooseHub.okButton' /> }
									disabled
									onClick={ () => selectHub() }
								/>
							)}
							<Button
								text={ <Trans id='PopupChooseHub.cancelButton' /> }
								disabled
								onClick={ () => togglePopup( false ) }
							/>
						</div>
					</>
				)}
			</div>
		</div>
	)
} )

PopupChooseHub.propTypes = {
	togglePopup: propTypes.func.isRequired,
	myMarkets: propTypes.array.isRequired,
	setSelectedMarket: propTypes.func.isRequired,
	tenantId: propTypes.string.isRequired,
	providerId: propTypes.number.isRequired,
	toggleEditable: propTypes.func.isRequired
}

export default PopupChooseHub
