/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import { Trans } from '@lingui/macro'
import DatePicker from 'react-datepicker'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import propTypes from 'prop-types'
import './CyclePopup.css'
import moment from 'moment'
import Button from '../../../_Globals/Button/Button'
import CycleDropdown from './CycleDropdown'
import 'react-datepicker/dist/react-datepicker.css'
import { addNotification } from '../../../../utils/messageHandler/notifierStack'
import ConditionalWrapper from '../../../_Globals/ConditionalWrapper'


const INSERT_NEW_CYCLE_TO_HUB_ID = gql`
mutation insertNewCycleToHubId($hub_id: bigint!, $duration: smallint!, $closedTime: timestamptz!, $startTime: timestamptz!) {
    insert_hub_cycles(objects: {closed_time: $closedTime, start_time: $startTime, duration: $duration, finished: false, hub_id: $hub_id}) {
        affected_rows
        returning {
			id
            finished
        }
    }
}`

const GET_HUB_BY_TENANT_ID_HUB_ID = gql`
query GetHubByTenantIdHubView( $tenant_id: String, $id: bigint ){
    hub_hub( where: { tenant_id: { _eq: $tenant_id }, id: { _eq: $id } } ) {
        id
		name
		storage_capacity
		delivery_radius
		description
		cycle_length
		cycles {
			id
			finished
			duration
			closed_time
			start_time
		}
		tenant {
			id
			applications {
				id
				data {
                    phone
                    mobile
					address {
						address
						city
						zip
					}
				}
			}
			identifiers {
				taxId
			}
		}
		galleries( where: { default: { _eq: true } } ) {
			id
            file {
				id
                tenant_id
                file_id
            }
        }
    }
}`


const UPDATE_HUB_CYCLE_LENGTH = gql`
mutation updateHubCycleLength($hub_id: bigint!, $cycle_length: smallint!) {
  update_hub_hub(where: {id: {_eq: $hub_id}}, _set: {cycle_length: $cycle_length}) {
    returning {
      cycle_length
    }
  }
}
`

const UPDATE_CYCLE_CRON = gql`
mutation updateCycleCron($hub_id: bigint!) {
  update_hub_hub(where: {id: {_eq: $hub_id}}, _set: {cycle_cron: "TRUE"}) {
    affected_rows
  }
}
`

const CyclePopup = React.memo( ( { toggleCyclePopup, data, tenantId } ) => {
	const [ dropdownOpen, toggleDropdown ] = useState( false )
	const [ dropdownValue, setDropdownValue ] = useState( data.cycle_length )
	const [ editedText, setEditedText ] = useState( {} )
	const [ startDate, setStartDate ] = useState( null )
	const [ confirmButton, setConfrimButton ] = useState( false )
	const [ minTime, setMinTime ] = useState( new Date() )

	const [ insertNewCycle ] = useMutation( INSERT_NEW_CYCLE_TO_HUB_ID, {
		refetchQueries: () => [ {
			query: gql`${ GET_HUB_BY_TENANT_ID_HUB_ID }`,
			variables: !tenantId ? { id: data.id } : { tenant_id: tenantId }
		} ]
	} )

	useEffect( () => {
		if ( data.cycles.length === 0 ) {
			if ( startDate ) {
				setConfrimButton( true )
			} else {
				setConfrimButton( false )
			}
		} else {
			setConfrimButton( true )
		}
	}, [ startDate, data.cycles.length ] )

	const [ updateCycleLength ] = useMutation( UPDATE_HUB_CYCLE_LENGTH, {
		variables: {
			hub_id: data.id,
			cycle_length: dropdownValue
		},
		refetchQueries: () => [ {
			query: gql`${ GET_HUB_BY_TENANT_ID_HUB_ID }`,
			variables: !tenantId ? { id: data.id } : { tenant_id: tenantId }
		} ]
	} )

	const [ updateCycleCron ] = useMutation( UPDATE_CYCLE_CRON, {
		variables: {
			hub_id: data.id
		}
	} )

	function getText( id, text ) {
		const objectCopy = editedText

		if ( !objectCopy.hubId ) {
			objectCopy.hubId = data.id || false
		}

		objectCopy[ id ] = text
		setEditedText( objectCopy )
	}

	function setDropdownVal( value ) {
		setDropdownValue( value )
		getText( 'cycle_length', value )
	}

	function addMonths( date, addedDate ) {
		const newDate = new Date( date.setMonth( date.getMonth() + addedDate ) )
		return newDate
	}

	function addDays( date, days ) {
		const result = new Date( date )
		result.setDate( result.getDate() + days )
		return result
	}

	function updateLength() {
		updateCycleLength( {
			variables: {
				cycle_length: dropdownValue,
				hub_id: data.id
			}
		} ).then( ( res ) => {
			if ( res.data && res.data ) {
				addNotification( 'OK', 'CYCLE_LENGTH_UPDATED' )
			}
		} )
	}

	const calculateMinTime = ( date ) => {
		const isToday = moment( date ).isSame( moment(), 'day' )
		if ( isToday ) {
			return moment( new Date() ).toDate()
		}
		return moment().startOf( 'day' ).toDate()
	}

	const handle = ( date ) => {
		setMinTime( calculateMinTime( date ) )
		setStartDate( date )
	}

	function startNewCycle() {
		const startTime = startDate
		const closedTime = addDays( startTime, dropdownValue )

		insertNewCycle( {
			variables: {
				closedTime,
				startTime,
				duration: dropdownValue,
				hub_id: data.id
			}
		} ).then( ( res ) => {
			if ( res.data && res.data.insert_hub_cycles ) {
				addNotification( 'OK', 'CYCLE_STARTED' )
			}
		} )
		updateCycleCron().then()

		updateLength()
	}

	return (
		<div className='cyclePopup-main-container'>
			<div className='popup-window'>
				<div className='cycle-title-container'>
					<p className='popup-window-title'>
						{data.cycles.length === 0 ? <Trans id='cyclePopup.title' />
							: <Trans id='cyclePopup.editTitle' />}
					</p>
				</div>
				<div className='inputs-container'>
					{/* eslint-disable-next-line max-len */}
					<ConditionalWrapper condition={ data.cycles.length === 0 } falseConditionComponent={ <div /> }>
						<div className='datePicker-hub-cycle'>
							<span><Trans id='CyclePopup.inputDate' /></span>
							<DatePicker
								selected={ startDate }
								onChange={ handle }
								minDate={ new Date() }
								excludeOut
								maxDate={ addMonths( new Date(), 1 ) }
								showDisabledMonthNavigation
								showTimeSelect
								placeholderText=''
								minTime={ minTime }
								maxTime={ moment().endOf( 'day' ).toDate() }
								timeFormat='HH:mm'
								timeIntervals={ 15 }
								timeCaption='Time'
								dateFormat='MMMM d, yyyy HH:mm'
							/>
						</div>
					</ConditionalWrapper>
					<div className='datePicker-hub-cycle'>
						<Trans id='UploadPic.cycleLength:' />
						<CycleDropdown
							selectedValue={ dropdownValue || data.cycle_length }
							showDropdown={ dropdownOpen }
							toggleDropdown={ () => toggleDropdown( !dropdownOpen ) }
							setDropdownValue={ setDropdownVal }
						/>
					</div>

				</div>
				<div className='buttons-container'>
					<Button
						text={ <Trans id='CyclePopup.close' /> }
						disabled
						onClick={ () => toggleCyclePopup( false ) }
					/>
					<Button
						text={ <Trans id='CyclePopup.confirm' /> }
						disabled={ confirmButton }
						onClick={ () => {
							if ( data.cycles.length === 0 ) {
								startNewCycle()
								toggleCyclePopup( false )
							} else {
								updateLength()
								toggleCyclePopup( false )
							}
						} }
					/>
				</div>

			</div>

		</div>
	)
} )


CyclePopup.propTypes = {
	toggleCyclePopup: propTypes.func.isRequired,
	data: propTypes.object.isRequired,
	tenantId: propTypes.string.isRequired
}

export default CyclePopup
