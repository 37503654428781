/* eslint-disable no-unused-vars */
import React from 'react'
import propTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Trans } from '@lingui/macro'
import Tooltip from 'react-tooltip-lite'

import './ShoppingList.css'
import AutosuggestionInput from '../_Globals/AutosuggestionInput'
import ResetShoppingList from './components/ResetShoppingList'
import ShoppingListFilterItem from '../_Globals/ShoppingListFilterItem'

import { GET_ACTIVE_AND_CONFIRMED_MARKETS } from '../ShoppingContent/ShoppingContentGQL'

const GET_GS1_BRICK_PRODUCTS = gql` {
	code_register_gs1_brick {
		id
    	translation_sl
  	}
}`

//	TODO Only placeholder! Data will come from backend.
let suggestionsForAutoSuggestPlaceholder = []

const ShoppingList = React.memo( ( {
	shoppingList,
	handleShoppingList,
	shoppingListVisibility,
	toggleShoppingList,
	isMyOffer
} ) => {
	const {
		data: marketData,
		loading: marketLoading,
		error: marketError
	} = useQuery( GET_ACTIVE_AND_CONFIRMED_MARKETS )

	const {
		data: gs1BrickData,
		loading: gs1BrickLoading,
		error: gs1BrickError
	} = useQuery( GET_GS1_BRICK_PRODUCTS )

	const markets = marketData && marketData.provider_market ? marketData.provider_market : []

	const products = gs1BrickData
		&& gs1BrickData.code_register_gs1_brick.length > 0
		? gs1BrickData.code_register_gs1_brick : []

	if ( markets && products ) {
		suggestionsForAutoSuggestPlaceholder = markets.concat( products )
	}

	if ( marketError || gs1BrickError ) {
		return (
			<div>
				{ JSON.stringify( marketError ) }
				{ JSON.stringify( gs1BrickError ) }

			</div>
		)
	}

	if ( !shoppingListVisibility ) {
		return (
			<div
				id='shopping-list-open'
				role='button'
				tabIndex={ 0 }
				onKeyPress={ () => toggleShoppingList() }
				onClick={ () => toggleShoppingList() }
			>
				<Tooltip
					id='tooltipArrowShoppingListOpen'
					content={ isMyOffer ? <Trans id='ShoppingList.openFindProductsMyOffer' /> : <Trans id='Tooltip.openShoppingList' /> }
					direction='bottom'
					arrowSize={ 5 }
					padding='5px'
					distance={ 20 }
				>
					<div className='shopping-list-name'>
						<div id='toggle-arrow-shopping-list-left' />
						<div>
							{ isMyOffer
								? (
									<Trans id='ShoppingList.findProductsMyOffer' />
								)
								: (
									<Trans id='ShoppingList.title' />
								) }
						</div>
						<div id='toggle-arrow-shopping-list-right' />
					</div>
				</Tooltip>
			</div>
		)
	}

	return (
		<div id='shopping-list'>
			<div id='autosuggest-input'>
				<AutosuggestionInput
					suggestions={ suggestionsForAutoSuggestPlaceholder }
					handleShoppingList={ handleShoppingList }
					isMyOffer={ isMyOffer }
				/>
				{ shoppingList.length === 12
					? <div id='warning-cant-add-more-items'><Trans id='ShoppingList.cantAddMore' /></div>
					: null}
			</div>
			<div id='shopping-list-title'>
				{ isMyOffer
					? (
						<Trans id='ShoppingList.findProductsMyOffer' />
					)
					: (
						<Trans id='ShoppingList.title' />
					) }

			</div>
			<div id='shopping-list-actions'>
				<ResetShoppingList
					handleShoppingList={ handleShoppingList }
					isMyOffer={ isMyOffer }
				/>
				{/* <SaveShoppingList /> */}
			</div>
			<div id='shopping-list-filters'>
				{ shoppingList.map(
					( filterItem ) => (
						<ShoppingListFilterItem
							key={ filterItem.name || filterItem.translation_sl }
							id={ filterItem.id }
							itemName={ filterItem.name || filterItem.translation_sl }
							disabled={ filterItem.disabled }
							handleShoppingList={ handleShoppingList }
						/>
					)
				) }

			</div>
			<div
				id='shopping-list-close'
				role='button'
				tabIndex={ 0 }
				onKeyPress={ () => toggleShoppingList() }
				onClick={ () => toggleShoppingList() }
			>
				<Tooltip
					id='tooltipArrowShoppingListOpen'
					content={ isMyOffer ? <Trans id='ShoppingList.closeFindProductsMyOffer' /> : <Trans id='Tooltip.closeShoppingList' /> }
					direction='bottom'
					arrowSize={ 5 }
					padding='5px'
					distance={ 10 }
				>
					<div
						id='toggle-arrow-shopping-list'
						className='toggle-arrow-shopping-list-down'
					/>
				</Tooltip>
			</div>
		</div>
	)
} )

ShoppingList.defaultProps = {
	isMyOffer: false
}

ShoppingList.propTypes = {
	shoppingList: propTypes.array.isRequired,
	handleShoppingList: propTypes.func.isRequired,
	shoppingListVisibility: propTypes.bool.isRequired,
	toggleShoppingList: propTypes.func.isRequired,
	isMyOffer: propTypes.bool,
}

export default ShoppingList
