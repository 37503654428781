import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'
import gql from 'graphql-tag'
import { useMutation, useQuery } from '@apollo/react-hooks'
import EditArea from '../../../../components/_Globals/EditArea'
import mainStore from '../../../../stores/mainStore'

import { addNotification } from '../../../../utils/messageHandler/notifierStack'
import './UserProfileSettings.css'

const UPDATE_PROFILE = gql`
mutation Tenant_update(  $input: UpdateTenantInput ) {
	Tenant_update(input: $input) {
	  id
	}
  }
  
`

const UPDATE_USER_SETTINGS = gql`
mutation User_update( $input: User_updateInput ) {
	User_update(input: $input) {
	  username
	}
  }
  
`

const GET_POST_CODE_OFFICES = gql`
	query {
  		CR_PostOffice {
    		code
    		name
  		}
	}
`

/* global sessionStorage */

const UserProfileSettings = ( { userData, deliveryAddresses, passText } ) => {
	const tenantId = sessionStorage.getItem( 'uhub_tenantId' )
	const [ isEditable, toggleIsEditable ] = useState( false )
	const [ editedText, setEditedText ] = useState( {} )

	const keycloakIdMutation = mainStore.user.sub

	const [ updateProfileSettings ] = useMutation( UPDATE_PROFILE )
	const [ updateSettings ] = useMutation( UPDATE_USER_SETTINGS )
	const {
		data: postcodeData
	} = useQuery( GET_POST_CODE_OFFICES )

	const [ newCity, setNewCity ] = useState( deliveryAddresses.city )

	const regexName = new RegExp( /^[A-Za-zČŠčšŽž-\s]+$/, 'i' )
	const regexAddress = new RegExp( /^[A-Za-zČŠčšŽž0-9-\s,]+$/, 'i' )
	const regexPostCode = new RegExp( /^[0-9]{4}$/, 'i' )

	useEffect( () => {
		if ( Object.keys( editedText ).length > 0 ) {
			passText( editedText )
			setEditedText( {} )
		}
	}, [ editedText, passText ] )

	function getText( id, text ) {
		const objectCopy = editedText

		objectCopy[ id ] = text
		setEditedText( objectCopy )
	}

	function checkNew( ) {
		toggleIsEditable( !isEditable )
		let keys = null
		if ( Object.keys( editedText ).length > 0 ) {
			keys = Object.entries( editedText )
			let allValuesValid = true
			// eslint-disable-next-line no-restricted-syntax
			for ( const [ key, value ] of keys ) {
				if ( ( ( key === 'firstName' || key === 'lastName' ) && !regexName.test( value ) )
						|| ( key === 'address' && !regexAddress.test( value ) )
						|| ( key === 'postCode' && !regexPostCode.test( value ) ) ) {
					allValuesValid = false
					break
				}
			}

			let foundPostCode = true
			if ( editedText.postCode ) {
				foundPostCode = postcodeData
					? postcodeData.CR_PostOffice.find( ( element ) => element.code === editedText.postCode )
					: false
			}

			if ( allValuesValid && foundPostCode ) {
				if ( editedText.address || editedText.zip ) {
					setNewCity( foundPostCode.name || deliveryAddresses.city )

					updateProfileSettings( {
						variables: {
							input: {
								id: tenantId,
								address: {
									zip: editedText.postCode,
									address: editedText.address,
									city: foundPostCode.name || deliveryAddresses.city
								}
							}

						}
					} ).catch( ( ) => {} )
				}

				if ( editedText.firstName || editedText.lastName ) {
					updateSettings( {
						variables: {
							input: {
								appId: 'UHUB',
								firstName: editedText.firstName,
								lastName: editedText.lastName,
								id: keycloakIdMutation
							}

						}
					} ).catch( ( ) => {} )
				}

				addNotification( 'OK', 'USER_PROFILE_SETTINGS_UPDATED' )
			} else {
				addNotification( 'ERROR', 'INVALID_INPUTS_USER_PROFILE_SETTINGS' )
			}
		} else {
			addNotification( 'OK', 'USER_PROFILE_SETTINGS_UPDATED' )
		}
	}

	return (
		<div className='user-profile-container'>
			<div className='name-settings'>
				<Trans id='UserProfileSettings.Username' />
			</div>
			<EditArea
				id='username'
				isInput
				myStyle='hub-view-hub-title-editable edit-area-settings'
				text={ userData.username }
			/>
			<div className='name-settings'>
				<Trans id='UserProfileSettings.Name' />
			</div>
			<EditArea
				id='firstName'
				isInput
				myStyle='hub-view-hub-title-editable edit-area-settings'
				text={ userData.firstName }
				editable={ isEditable }
				getText={ getText }
				regex={ regexName }
			/>
			<div className='name-settings'>
				<Trans id='UserProfileSettings.Surname' />
			</div>
			<EditArea
				id='lastName'
				isInput
				myStyle='hub-view-hub-title-editable edit-area-settings'
				text={ userData.lastName }
				editable={ isEditable }
				getText={ getText }
				regex={ regexName }
			/>
			<div className='name-settings'>
				<Trans id='UserProfileSettings.Addresses' />
			</div>
			<div>
				<EditArea
					id='address'
					isInput
					myStyle='hub-view-hub-title-editable edit-area-settings'
					text={ deliveryAddresses.address }
					editable={ isEditable }
					getText={ getText }
					regex={ regexAddress }
				/>
			</div>
			<div className='name-settings'>
				<Trans id='UserProfileSettings.zip' />
			</div>
			<div>
				<EditArea
					id='postCode'
					isInput
					myStyle='hub-view-hub-title-editable edit-area-settings'
					text={ deliveryAddresses.zip }
					editable={ isEditable }
					getText={ getText }
					regex={ regexPostCode }
				/>
			</div>
			<div className='name-settings'>
				<Trans id='UserProfileSettings.city' />
			</div>
			<div>
				<EditArea
					id='city'
					isInput
					myStyle='hub-view-hub-title-editable edit-area-settings'
					text={ newCity }
				/>
			</div>

			<div className='my-profile-button-container'>
				<button
					className='settings-button'
					type='button'
					onClick={ isEditable ? () => checkNew()
						:						() => toggleIsEditable( !isEditable ) }
				>
					{isEditable ? <p><Trans id='UserProfileSettings.SaveButton' /></p> : <p><Trans id='UserProfileSettings.EditButton' /></p>}
				</button>
			</div>
		</div>
	)
}

UserProfileSettings.defaultProps = {
	passText: () => {},
}

UserProfileSettings.propTypes = {
	userData: propTypes.object.isRequired,
	deliveryAddresses: propTypes.object.isRequired,
	passText: propTypes.func,
}

export default UserProfileSettings
