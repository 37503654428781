import React from 'react'
import { Trans } from '@lingui/macro'
import propTypes from 'prop-types'

import './HubRow.css'
import { ReturnSendIcon } from '../../../../icons'
import PdfRender from '../../../_Globals/PdfRender'

const HubSLAView = React.memo( ( {
	pdf, setHub, setPdf
} ) => (
	<div id='sla-view-container'>
		<div
			role='button'
			className='back-to-hubs button-back'
			tabIndex={ -1 }
			onKeyPress={ () => {
				setHub( null )
				setPdf( false )
			} }
			onClick={ () => {
				setHub( null )
				setPdf( false )
			} }
		>
			<span><ReturnSendIcon fontSize={ 14 } /></span>
			<span className='text'><Trans id='ShoppingCart.backToMyHubs' /></span>
		</div>

		<div>
			{
				pdf
					? <PdfRender pdf={ pdf } />
					: null
			}
		</div>

	</div>
) )

HubSLAView.propTypes = {
	pdf: propTypes.object.isRequired,
	setHub: propTypes.func.isRequired,
	setPdf: propTypes.func.isRequired
}

export default HubSLAView
