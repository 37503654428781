import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'
import { withRouter } from 'react-router-dom'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

import './CycleSectionPage.css'
import { useStateValue } from '../../../stores/contextStore/stateStore'
import TableHubCycleHeader from '../../_Globals/TableHubCycleHeader'
import TableHubCurrentCycleHeader from '../../_Globals/TableHubCurrentCycleHeader'
import TableHubCycleRow from '../../_Globals/TableHubCycleRow'
import ConditionalWrapper from '../../_Globals/ConditionalWrapper'
// import DayPickerComponent from '../../DayPicker'
import Loader from '../../Loader'

/* global sessionStorage */

const GET_HUB_BY_TENANT_ID = gql`
query GetHubByTenantId( $tenantId: String! ) {
    hub_hub( where: { tenant_id: { _eq: $tenantId } } ) {
        id
        name
        cycles {
            id
            finished
            duration
            closed_time
            start_time
            orders {
				id
				status_id
            }
        }
    }
}`

const CycleSectionPage = React.memo( ( { history } ) => {
	const [ windowWidth, setWidth ] = useState( window.innerWidth > 768 )
	const [ , showCycleOrders ] = useStateValue()
	const tenantId = sessionStorage.getItem( 'uhub_tenantId' )

	const {
		data: hubData,
		error: hubError,
		loading: hubLoading
	} = useQuery( GET_HUB_BY_TENANT_ID, { variables: { tenantId }, 	fetchPolicy: 'no-cache' } )

	const hubById = hubData
        && hubData.hub_hub
        && hubData.hub_hub.length > 0 ? hubData.hub_hub[ 0 ] : false

	function addShownIdsToCycles() {
		const { cycles } = hubData.hub_hub[ 0 ]
		if ( cycles ) {
			for ( let i = 0; i < cycles.length; i += 1 ) {
				cycles[ i ].shownId = i + 1
			}
		}
	}

	useEffect( () => {
		const handleResize = () => {
			setWidth( window.innerWidth > 768 )
		}
		window.addEventListener( 'resize', handleResize )
		return () => { window.removeEventListener( 'resize', handleResize ) }
	}, [] )

	if ( hubLoading ) {
		return <Loader />
	}

	if ( hubError ) {
		return (
			<div>
				{ JSON.stringify( hubError ) }
			</div>
		)
	}

	return (
		<ConditionalWrapper
			condition={ windowWidth }
			falseConditionComponent={ (
				<div className='warning'>
					<Trans id='CycleSectionPage.Warning' />
				</div>
			) }
		>
			<div className='cycle-section-page-container'>
				{addShownIdsToCycles()}
				<div className='date-range-selector'>
					{ /* <DayPickerComponent /> */ }
				</div>
				<div className='current-hub-cycle'>
					<div className='current-hub-cycle-title'>
						<span>
							<Trans id='CycleSectionPage.currentCycleOfHub' />
						</span>
						<span>{ ` ${ hubById.name }` }</span>
					</div>
					<TableHubCurrentCycleHeader />
					{ hubById && hubById.cycles.length > 0
						? (
							<TableHubCycleRow
								data={ hubById.cycles.filter( ( cy ) => !cy.finished )[ 0 ] }
								hubId={ hubById.id }
								onClick={ () => {
									showCycleOrders( {
										type: 'selectedHubCycle',
										selectedHubCycle: { id: hubById.id, numCycle: hubById.id }
									} )
									history.push( {
										pathname: '/hub/orders',
										state: { cycle_id: hubById.cycles.filter( ( cy ) => !cy.finished )[ 0 ].id }
									} )
								} }
							/>
						) : null }
				</div>
				<div className='hub-cycles'>
					<div className='hub-cycles-title'>
						<span>
							<Trans id='CycleSectionPage.CyclesOfHub' />
						</span>
						<span>{ ` ${ hubById.name }` }</span>
					</div>
					<TableHubCycleHeader />
					{ hubById.cycles.filter( ( cy ) => cy.finished ).map( ( cycle ) => (
						<TableHubCycleRow
							key={ cycle.id }
							hubId={ hubById.id }
							data={ cycle }
							onClick={ () => {
								showCycleOrders( {
									type: 'selectedHubCycle',
									selectedHubCycle: { id: hubById.id, numCycle: hubById.id }
								} )
								history.push( {
									pathname: '/hub/orders',
									state: { cycle_id: cycle.id }
								} )
							} }
						/>
					) ) }
				</div>
			</div>
		</ConditionalWrapper>
	)
} )

CycleSectionPage.propTypes = {
	history: propTypes.object.isRequired,
}

export default withRouter( CycleSectionPage )
