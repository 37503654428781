/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useReducer } from 'react'
import { Trans } from '@lingui/macro'

import MarketingBanner from '../../components/MarketingBanner'
import ShoppingList from '../../components/ShoppingList'
import ShoppingContent from '../../components/ShoppingContent'
import ArticlesBanner from '../../components/ArticlesBanner'
import { useStateValue } from '../../stores/contextStore/stateStore'
import FooterBottom from '../../components/Footer/components/FooterBottom'

import './ShoppingPage.css'

const initStates = {
	shoppingList: []
}

function addItemToshoppingList( shoppingList, addedItem ) {
	if ( shoppingList.findIndex( ( el ) => el.id === addedItem.id ) === -1 && shoppingList.length < 12 ) {
		shoppingList.push( addedItem )
	}
	return shoppingList
}

function removeAllItemsFromShoppingList( shoppingList ) {
	while ( shoppingList.length > 0 ) {
		shoppingList.pop()
	}
	return shoppingList
}

function removeItemFromShoppingList( shoppingList, itemIdToRemove ) {
	for ( let i = 0; i < shoppingList.length; i += 1 ) {
		if ( shoppingList[ i ].id === itemIdToRemove ) {
			shoppingList.splice( i, 1 )
			break
		}
	}
	return shoppingList
}

function reducer( state, action ) {
	switch ( action.type ) {
	case 'toggleShoppingListDisable':
		return {
			...state,
			shoppingList: state.shoppingList.map( ( shoppingItem ) => {
				//  If shopping item match id then toggle disabled field
				if ( shoppingItem.id === action.id ) {
					return { ...shoppingItem, disabled: !shoppingItem.disabled }
				}
				return shoppingItem
			} )
		}
	case 'removeItemFromShoppingList':
		//  Remove element on id from array
		return {
			...state,
			shoppingList: removeItemFromShoppingList( state.shoppingList, action.id )
		}
	case 'addItemToShoppingList':
		//  Add element to end of array
		return {
			...state,
			//  Check if item alreadyExist and shoppingList.length is lower then 13.
			shoppingList: addItemToshoppingList( state.shoppingList, action.addedItem )
		}
	case 'resetShoppingList':
		//  Reset shopping list to empty
		return {
			...state,
			shoppingList: removeAllItemsFromShoppingList( state.shoppingList )
		}

	default:
		return state
	}
}

const ShoppingPage = React.memo( ( ) => {
	const [ state, setState ] = useReducer( reducer, initStates )
	const [ { shoppingListVisibility }, toggleMenus ] = useStateValue()
	const [ { fatalError } ] = useStateValue()

	const marketsShoppingList = state.shoppingList.filter( ( item ) => item.name )
	const gs1ShoppingList = state.shoppingList.filter( ( item ) => item.translation_sl )

	return (
		<>
			{ !fatalError
				? (
					<>
						<MarketingBanner />

						<ShoppingList
							shoppingList={ state.shoppingList }
							handleShoppingList={ setState }
							shoppingListVisibility={ shoppingListVisibility }
							toggleShoppingList={ () => toggleMenus( { type: 'toggleShoppingListVisibility' } ) }
						/>

						<ShoppingContent
							marketsShoppingList={ marketsShoppingList }
							gs1ShoppingList={ gs1ShoppingList }
						/>

						<ArticlesBanner
							marketsShoppingList={ marketsShoppingList }
							gs1ShoppingList={ gs1ShoppingList }
						/>
					</>
				) : (
					<div className='fatalError'>
						<div className='errorMessage'>
							<Trans id='ShoppingPage.fatalError' />
						</div>
					</div>
				) }
			<FooterBottom />
		</>
	)
} )

ShoppingPage.propTypes = {
}

export default ShoppingPage
