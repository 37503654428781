import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Trans } from '@lingui/macro'

import './HubSectionPage.css'
import HubView from '../HubView'
import roleHandler from '../../../utils/ContentRoleHandler'
import { useStateValue } from '../../../stores/contextStore/stateStore'
import parseObject from '../../../utils/parseObjectForMutations'
import { addNotification } from '../../../utils/messageHandler/notifierStack'

/*	global sessionStorage */

const UPDATE_HUB = gql`
mutation updateHub( $hubId: bigint!, $changes: hub_hub_set_input ) {
    update_hub_hub( where: { id: { _eq: $hubId } }, _set: $changes ) {
        affected_rows
    }
}
`

const UPDATE_HUB_TENANT = gql`
mutation Tenant_update( $input: UpdateTenantInput ) {
    Tenant_update( input: $input ) {
        id
    }
}
`

const availableInputUpdateHub = [
	'delivery_radius',
	'description',
	'has_logistics',
	'has_packaging',
	'location',
	'name',
	'storage_capacity',
	'cycle_length'
]
const availableInputUpdateTenant = [
	'address',
	'zip',
	'city'
]

const HubSectionPage = React.memo( () => {
	const tenantId = sessionStorage.getItem( 'uhub_tenantId' )
	const [ editable, toggleEditable ] = useState( false )
	const [ { isAuth } ] = useStateValue()
	const [ updateHub ] = useMutation( UPDATE_HUB )
	const [ updateHubTenant ] = useMutation( UPDATE_HUB_TENANT )


	function getChangedText( changedText ) {
		if ( roleHandler( isAuth, [ 'owner' ] ) ) {
			const { hubId } = changedText
			const { firstMutation, secondMutation } = parseObject(
				availableInputUpdateHub,
				availableInputUpdateTenant,
				changedText
			)
			updateHub( { variables: { hubId, changes: firstMutation } } )

			const input = { id: tenantId, address: secondMutation }

			if ( changedText?.mobile ) {
				input.mobile = changedText?.mobile
			}

			if ( changedText?.phone ) {
				input.phone = changedText?.phone
			}

			updateHubTenant( { variables: { input } } )
		} else {
			addNotification( 'ERROR', 'NO_PERMISSIONS' )
		}
	}

	return (
		<div>
			<HubView tenantId={ tenantId } editableFields={ editable } passText={ getChangedText } />
			{ roleHandler( isAuth, [ 'owner' ] )
				? (
					<div id='hub-section-edit'>
						<div />
						<div className='hubView-btn-container'>
							<button
								type='button'
								className='hub-section-edit-button'
								onClick={ () => toggleEditable( !editable ) }
							>
								{ editable ? <Trans id='HubSection.btnSave' /> : <Trans id='HubSection.btnEdit' /> }
							</button>
							<button
								type='button'
								className='hub-section-edit-button'
								onClick={ () => toggleEditable( false ) }
							>
								<Trans id='HubSection.btnCancel' />
							</button>
						</div>
					</div>
				)
				: null }
		</div>
	)
} )

export default HubSectionPage
