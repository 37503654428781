/* eslint-disable no-redeclare */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ApolloProvider } from '@apollo/react-hooks'
import { I18nProvider } from '@lingui/react'

import registerServiceWorker from './utils/registerServiceWorker'
import catalogs from './utils/languageSettings'
import mainStore from './stores/mainStore'
import client from './utils/apollo'

import MainPage from './MainPage'
import RegistrationProviderNewUser from './pages/RegistrationPage/RegistrationProvider/RegistrationProviderNewUser/RegistrationProviderNewUser'
import RegistrationProviderExistingUser from './pages/RegistrationPage/RegistrationProvider/RegistrationProviderExistingUser/RegistrationProviderExistingUser'
import RegistrationBuyer from './pages/RegistrationPage/RegistrationBuyer/RegistrationBuyer'
import './LandingPage.css'
import Loader from './components/Loader'
import RegistrationType from './pages/RegistrationPage/index'
import NotifierHandler from './utils/messageHandler/notifierHandler'
import NotifierHandlerAddProductToCart from './utils/messageHandler/notifierHandlerAddProductToCart'

/*	global document */

//	Render message is Keycloak is not running. In this case you cant do anything!
const renderKeycloakIsNotRunning = () => (
	<div className='main-keycloak-not-running'>
		<Loader />
	</div>
)

//	Render UHUB project pass isAuthenticated parameter
const renderUHUB = ( isAuthenticated ) => (
	<ApolloProvider client={ client }>
		<BrowserRouter>
			<Switch>
				<Route exact path='/register' component={ RegistrationType } />
				<Route exact path='/register/buyer/newUser' component={ RegistrationBuyer } />
				<Route exact path='/register/provider/newUser' component={ RegistrationProviderNewUser } />
				<Route exact path='/register/provider/existingUser' component={ RegistrationProviderExistingUser } />
				<Route
					path='/'
					render={ ( props ) => <MainPage { ...props } isAuthenticated={ isAuthenticated } /> }
				/>
			</Switch>
		</BrowserRouter>
	</ApolloProvider>
)

//	Landing page which render right components
const renderLandingPage = ( isKeycloakAuthenticated ) => {
	function renderContent() {
		if ( isKeycloakAuthenticated === null ) {
			return renderKeycloakIsNotRunning()
		}
		return (
			<div>
				{ renderUHUB( isKeycloakAuthenticated ) }
				<NotifierHandler />
				<NotifierHandlerAddProductToCart />
			</div>
		)
	}
	ReactDOM.render(
		<I18nProvider language={ mainStore.language } catalogs={ catalogs }>
			{ renderContent() }
		</I18nProvider>,
		document.getElementById( 'root' )
	)
	registerServiceWorker()
}

export default renderLandingPage
