import gql from 'graphql-tag'

const GET_PRODUCT_BY_ID = gql`
query getProductById( $id: bigint! ) {
    provider_product( where: { id: { _eq: $id } } ) {
        id
		description
		bruto_price
		neto_price
		ddv_value
		quality
		quantity
		gs1_brick {
			id
      		translation_sl
			files {
				id
				public
				file_id
				tenant_id
			}
			unit {
      			id
      			unit
    		}
    	}
		variety {
			id
			name
		}
		galleries {
			id
			file {
				id
				file_id
				tenant_id
			}
		}
    }
}`

const SUBSCRIPTION_BRUTO_PRICE = gql`
subscription subscriptionBrutoPrice( $id: bigint ) {
    provider_product( where: { id: { _eq: $id } } ) {
		id
		bruto_price
    }
}`

const GET_MARKETS = gql`
	query provider_market( $tenant_id: String! ) {
    	provider_market(
			where: { 
				tenant_id: { _eq: $tenant_id },
				active: { _eq: true },
				confirmed: { _eq: true }
			}
		) {
			id
			name
    		active
    		confirmed
  		}
	}
`

const GET_PRODUCT_MARKET = gql`
	query provider_market_has_product( $product_id: bigint! ) {
		provider_market_has_product(where: {product_id: {_eq: $product_id }}) {
			id
			product_id
			market_id
		}
	}
`

const HASURA_UPDATE_PRODUCT_DATA = gql`
	mutation update_provider_product(
		$id: bigint!,
		$changes: provider_product_set_input
	) { 
		update_provider_product( where: { id: { _eq: $id } }, _set: $changes ) {
			affected_rows
		} 
	}
`

const HASURA_ADD_PRODUCT_TO_MARKET = gql`
	mutation insert_provider_market_has_product( 
		$market_id: bigint!,
		$product_id: bigint!,
	) {
		insert_provider_market_has_product( 
			objects: { 
				market_id: $market_id,
				product_id: $product_id
				}
		) {
			affected_rows
		}
	}
`

const HASURA_REMOVE_PRODUCT_FROM_MARKET = gql`
	mutation delete_provider_market_has_product( 
		$market_id: bigint!,
		$product_id: bigint!,
	) {
		delete_provider_market_has_product( 
			where: { 
				market_id: {_eq: $market_id },
				product_id: { _eq: $product_id }
			}
		) {
			affected_rows
		}
	}
`
const INSERT_ITEM_TO_CART = gql`
mutation insertItemToCart(
	$hub_id: bigint!,
	$market_id: bigint!,
	$product_id: bigint!,
	$quantity: float8!,
	$unit_id: bigint!
	$status_id: Int!,
	$cart_uuid: uuid!,
	$customer_id: String,
) {
	insert_cart_items(
		objects: { 
			hub_id: $hub_id,
			market_id: $market_id,
			product_id: $product_id,
			quantity: $quantity,
			unit_id: $unit_id,
			status_id: $status_id,
			cart_uuid: $cart_uuid,
			customer_id: $customer_id
	}) {
    	returning {
      		id
			quantity
    	}
  	}
}`

const GET_UNITS_QUANTITIES = gql`
query get_units_quantities_product_view( $unit_id: Int ) {
	code_register_units_default_quantities( where: { unit_id: { _eq: $unit_id } },  order_by: { quantity: asc } ) {
		id
		quantity
	}
}`

const GET_TAX_RATES = gql` {
	provider_ddv_enum( order_by: { value: asc } ) {
    	value
  	}
}`

// DO NOT REMOVE THIS MUTATION ON ORDERS!!!!!!
// WE NEED THIS, TO UPDATE created_at FIELD!!!!!!
// LATER TRIGGER SET CORRECT TIME OF AN UPDATE

const SUBSCRIPTION_QUANTITY = gql`
subscription subscriptionProductQuantity(
	$productId: bigint!
){
	provider_product(where: { id: { _eq: $productId } } ) {
		id
		quality
		quantity
	}
}`


export {
	GET_PRODUCT_BY_ID,
	SUBSCRIPTION_BRUTO_PRICE,
	GET_MARKETS,
	GET_PRODUCT_MARKET,
	HASURA_UPDATE_PRODUCT_DATA,
	HASURA_ADD_PRODUCT_TO_MARKET,
	HASURA_REMOVE_PRODUCT_FROM_MARKET,
	GET_UNITS_QUANTITIES,
	INSERT_ITEM_TO_CART,
	SUBSCRIPTION_QUANTITY,
	GET_TAX_RATES
}
