import React, { useState, useEffect } from 'react'
import { useMutation, useLazyQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import propTypes from 'prop-types'

import './StarComponent.css'
import StarIconBlue from '../../../icons/StarIconBlue'
import StarIconOrange from '../../../icons/StarIconOrange'
import StartIconTransparent from '../../../icons/StarIconTransparent'
import { addNotification } from '../../../utils/messageHandler/notifierStack'

import GET_PROVIDER_RATING from '../../../pages/ProviderMainInfoDetail/components/ProviderDetails/ProviderDetailsGQL'
import {
	GET_USER_RATING,
	NEW_MARK,
	UPDATE_MARK,
	GET_AVERAGE_MARK,
	UPDATE_PROVIDER_MARK,
	GET_ORDER
} from './StarComponentGQL'

/* globals sessionStorage */

const StarComponent = React.memo( ( {
	starCount, fontSize, providerId
} ) => {
	const roundedNumber = Math.round( starCount )
	const [ mark, setMark ] = useState( null )
	const [ hoverMark, setHoverMark ] = useState( 0 )
	const customerId = sessionStorage.getItem( 'uhub_tenantId' )

	const [ updateAverageMark ] = useMutation( UPDATE_PROVIDER_MARK, {
		fetchPolicy: 'no-cache',
		onCompleted: () => {
			addNotification( 'OK', 'PROVIDER_RATED' )
			setMark( null )
		},
		refetchQueries: () => [ {
			query: gql`${ GET_PROVIDER_RATING }`,
			variables: {
				id: providerId
			}
		} ]
	} )

	const [ getAverageMark ] = useLazyQuery( GET_AVERAGE_MARK, {
		fetchPolicy: 'no-cache',
		onCompleted: ( data ) => {
			if ( data.provider_marks_aggregate.aggregate.avg.mark ) {
				updateAverageMark( {
					variables: {
						id: providerId,
						average_mark: data.provider_marks_aggregate.aggregate.avg.mark
					}
				} )
			}
		}
	} )


	const [ newMark ] = useMutation( NEW_MARK, {
		fetchPolicy: 'no-cache',
		onCompleted: ( data ) => {
			if ( data.insert_provider_marks.affected_rows ) {
				getAverageMark( {
					variables: {
						provider_id: providerId
					}
				} )
			}
		}
	} )


	const [ updateMark ] = useMutation( UPDATE_MARK, {
		fetchPolicy: 'no-cache',
		onCompleted: ( data ) => {
			if ( data.update_provider_marks.affected_rows ) {
				getAverageMark( {
					variables: {
						provider_id: providerId,
					}
				} )
			}
		}
	} )


	const [ getUserRating ] = useLazyQuery( GET_USER_RATING, {
		fetchPolicy: 'no-cache',
		onCompleted: ( data ) => {
			if ( data.provider_marks.length === 0 ) {
				newMark( {
					variables: {
						provider_id: providerId,
						mark,
						customer_id: customerId,
					}
				} )
			}
			if ( data.provider_marks.length > 0 && data.provider_marks[ 0 ].mark !== mark ) {
				updateMark( {
					variables: {
						provider_id: providerId,
						mark,
						customer_id: customerId,
					}
				} )
			}

			if ( data.provider_marks.length > 0 && data.provider_marks[ 0 ].mark === mark ) {
				addNotification( 'WARNING', 'YOU_ALREADY_RATED_PROVIDER_WITH_SAME_RATING_CHOOSE_ANOTHER_RATING_VALUE' )
			}
		}
	} )

	const [ getOrder ] = useLazyQuery( GET_ORDER, {
		fetchPolicy: 'no-cache',
		variables: { customer_id: customerId },
		onCompleted: ( data ) => {
			if ( data.cart_orders.length > 0 && data.cart_orders[ 0 ].id ) {
				getUserRating( {
					variables: {
						provider_id: providerId,
						customer_id: customerId
					}
				} )
			}
			if ( data.cart_orders.length === 0 ) {
				addNotification( 'WARNING', 'ONLY_LOGGED_USER_CAN_RATE_PROVIDER_ORDER_DELIVERED' )
			}
		},
		onError: ( error ) => {
			if ( error ) {
				addNotification( 'WARNING', 'ONLY_LOGGED_USER_CAN_RATE_PROVIDER_ORDER_DELIVERED' )
			}
		}
	} )

	useEffect( () => {
		if ( mark && customerId ) {
			getOrder()
		}
		if ( mark && !customerId ) {
			addNotification( 'WARNING', 'ONLY_LOGGED_USER_CAN_RATE_PROVIDER_ORDER_DELIVERED' )
		}
	}, [ mark, getOrder, customerId ] )

	function displayStar( num ) {
		if ( roundedNumber > num ) {
			if ( hoverMark > num ) {
				return <StarIconOrange fontSize={ fontSize } />
			}
			return <StarIconBlue fontSize={ fontSize } />
		}

		if ( roundedNumber || roundedNumber === 0 ) {
			if ( hoverMark > num ) {
				return <StarIconOrange fontSize={ fontSize } />
			}
			return <StartIconTransparent fontSize={ fontSize } />
		}
		return <StartIconTransparent fontSize={ fontSize } />
	}

	return (
		<div
			id='star-container'
			onMouseLeave={ () => setHoverMark( 0 ) }
		>
			<div
				className='singluar-star'
				role='button'
				tabIndex={ -1 }
				onMouseEnter={ () => setHoverMark( 1 ) }
				onKeyPress={ () => {
					setMark( 1 )
				} }
				onClick={ () => {
					setMark( 1 )
				} }
			>
				{ displayStar( 0 ) }
			</div>
			<div
				className='singluar-star'
				role='button'
				tabIndex={ -1 }
				onMouseEnter={ () => setHoverMark( 2 ) }
				onKeyPress={ () => {
					setMark( 2 )
				} }
				onClick={ () => {
					setMark( 2 )
				} }
			>
				{ displayStar( 1 ) }
			</div>
			<div
				className='singluar-star'
				role='button'
				tabIndex={ -1 }
				onMouseEnter={ () => setHoverMark( 3 ) }
				onKeyPress={ () => {
					setMark( 3 )
				} }
				onClick={ () => {
					setMark( 3 )
				} }
			>
				{ displayStar( 2 ) }
			</div>
			<div
				className='singluar-star'
				role='button'
				tabIndex={ -1 }
				onMouseEnter={ () => setHoverMark( 4 ) }
				onKeyPress={ () => {
					setMark( 4 )
				} }
				onClick={ () => {
					setMark( 4 )
				} }
			>
				{ displayStar( 3 ) }
			</div>
			<div
				className='singluar-star'
				role='button'
				tabIndex={ -1 }
				onMouseEnter={ () => setHoverMark( 5 ) }
				onKeyPress={ () => {
					setMark( 5 )
				} }
				onClick={ () => {
					setMark( 5 )
				} }
			>
				{ displayStar( 4 ) }
			</div>
		</div>
	)
} )

StarComponent.defaultProps = {
	starCount: 0,
}

StarComponent.propTypes = {
	starCount: propTypes.number,
	fontSize: propTypes.number.isRequired,
	providerId: propTypes.number.isRequired,
}

export default StarComponent
