import React from 'react'
import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'

import './ResetShoppingList.css'
import { Clear } from '../../../icons'

const ResetShoppingList = React.memo( ( { handleShoppingList, isMyOffer } ) => (
	<div
		id='reset-shopping-list'
		onClick={ () => handleShoppingList( { type: 'resetShoppingList' } ) }
		onKeyPress={ () => handleShoppingList( { type: 'resetShoppingList' } ) }
		tabIndex={ 0 }
		role='button'
	>
		<div className='reset-shopping-list-text'>
			{ isMyOffer
				? <Trans id='ShoppingList.resetFilterMyOffer' /> : <Trans id='ShoppingList.resetShoppingList' /> }
		</div>
		<div><Clear fontSize={ 12 } /></div>
	</div>
) )

ResetShoppingList.defaultProps = {
	isMyOffer: false
}

ResetShoppingList.propTypes = {
	handleShoppingList: propTypes.func.isRequired,
	isMyOffer: propTypes.bool
}

export default ResetShoppingList
