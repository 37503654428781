/* eslint-disable consistent-return */
import React from 'react'
import propTypes from 'prop-types'

import ReactPlaceholder from 'react-placeholder'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import ProviderComponent from '../../../ProviderComponent'
import ProductComponent from '../../../ProductComponent'
import { useStateValue } from '../../../../stores/contextStore/stateStore'
import ProductComponentPlaceholder from '../../../ProductComponent/components/ProductComponentPlaceholder'
import ProviderComponentPlaceholder from '../../../ProviderComponent/components/ProviderComponentPlaceholder'
import 'react-placeholder/lib/reactPlaceholder.css'
import './ItemsGrid.css'

const ItemsGrid = React.memo( ( {
	items,
	setProvider,
	productsByHubLoading,
	productsByHubTenantIdDataLoading,
	marketLoading,
	productsLoading
} ) => {
	const [ { hubMenu, isProvider, selectedHub } ] = useStateValue()

	const {
		data: defaultQuantities,
		// eslint-disable-next-line no-unused-vars
		loading: defaultQuantitiesLoading,
		// eslint-disable-next-line no-unused-vars
		error: defaultQuantitiesError
	} = useQuery( gql`
			query {
			  code_register_units_default_quantities {
				id
				unit_id
				quantity
			  }
			}` )

	// helper that checks if the quantity of the product is less than the lowest purchasable amount
	function productDisplayHelper( product ) {
		const unitId = product.product.gs1_brick.unit.id
		for ( let j = 0; j < defaultQuantities.code_register_units_default_quantities.length; j += 1 ) {
			if ( unitId === defaultQuantities.code_register_units_default_quantities[ j ].unit_id ) {
				if ( product.product.quantity
					>= defaultQuantities.code_register_units_default_quantities[ j ].quantity ) {
					return true
				}
			}
		}
		return false
	}

	React.useEffect( () => {
		if ( !hubMenu ) {
			document.documentElement.style.setProperty(
				'--item-grid-columns',
				( window.getComputedStyle( document.documentElement ).getPropertyValue( '--item-grid-columns' ) - 1 )
			)
		} else {
			document.documentElement.style.removeProperty( '--item-grid-columns' )
		}

		return () => { document.documentElement.style.removeProperty( '--item-grid-columns' ) }
	}, [ hubMenu ] )

	function getMarketsAndProductsByHUB() {
		if ( selectedHub && isProvider ) {
			const marketsByHub = items.filter( ( item ) => ( item.hub_id === selectedHub.id ) )
			return (
				marketsByHub.map( ( item ) => (
					<div key={ item.id }>
						<ReactPlaceholder
							showLoadingAnimation
							ready={
								!marketLoading
								|| !productsLoading
								|| !productsByHubLoading
								|| !productsByHubTenantIdDataLoading
							}
							customPlaceholder={ ProviderComponentPlaceholder }
						>
							<ProviderComponent
								setProvider={ setProvider }
								itemData={ item }
							/>
						</ReactPlaceholder>
					</div>
				) )
			)
		}

		if ( selectedHub && !isProvider && items ) {
			return (
				items.filter( ( product ) => productDisplayHelper( product ) ).map( ( item ) => (
					<div key={ item.product.id }>
						<ReactPlaceholder
							showLoadingAnimation
							ready={
								!marketLoading
							|| !productsLoading
							|| !productsByHubLoading
							|| !productsByHubTenantIdDataLoading
							}
							customPlaceholder={ ProductComponentPlaceholder }
						>
							<ProductComponent
								itemData={ item }
							/>
						</ReactPlaceholder>
					</div>
				) )
			)
		}

		if ( items ) {
			return (
				items.map( ( item ) => (
					isProvider
						? (
							<div key={ item.id }>
								<ReactPlaceholder
									showLoadingAnimation
									ready={
										!marketLoading
								|| !productsLoading
								|| !productsByHubLoading
								|| !productsByHubTenantIdDataLoading
									}
									customPlaceholder={ ProviderComponentPlaceholder }
								>
									<ProviderComponent
										setProvider={ setProvider }
										itemData={ item }
									/>
								</ReactPlaceholder>
							</div>
						)
						: (
							<div key={ item.product.id }>
								<ReactPlaceholder
									showLoadingAnimation
									ready={
										!marketLoading
								|| !productsLoading
								|| !productsByHubLoading
								|| !productsByHubTenantIdDataLoading
									}
									customPlaceholder={ ProductComponentPlaceholder }
								>
									<ProductComponent
										itemData={ item }
									/>
								</ReactPlaceholder>
							</div>
						)
				) )
			)
		}
	}


	return (
		<div id='shopping-list-items-grid'>
			{
				getMarketsAndProductsByHUB()
			}
		</div>
	)
} )

ItemsGrid.defaultProps = {
	productsByHubLoading: null,
	productsByHubTenantIdDataLoading: null,
	marketLoading: null,
	productsLoading: null,
	items: null,
}

ItemsGrid.propTypes = {
	items: propTypes.array,
	setProvider: propTypes.func.isRequired,
	productsByHubLoading: propTypes.bool,
	productsByHubTenantIdDataLoading: propTypes.bool,
	marketLoading: propTypes.bool,
	productsLoading: propTypes.bool,
}

export default ItemsGrid
