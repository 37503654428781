import gql from 'graphql-tag'

const GET_DELIVERY_ADDRESS = gql`
	query getDeliveryAddress( $customer_id: String ) {
		cart_orders( where: { customer_id: { _eq: $customer_id } } ){
			id
			customer {
				id
				owner {
					keycloakId
					firstName
					lastName
				}
				applications {
					id
					data {
						address {
							address
							zip
							city
						}
						mobile
						phone
					}
				}
			}
		}
	}
`

export default GET_DELIVERY_ADDRESS
