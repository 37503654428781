import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import propTypes from 'prop-types'
import getNumberFormatter from '../../../utils/NumberFormater'
import store from '../../../stores/mainStore'
import './Dropdown.css'


const Dropdown = React.memo( ( {
	data,
	unit,
	getQuantity,
	type,
	translations
} ) => {
	const { language } = store
	const [ displayDropDown, showDropDown ] = useState( false )
	const [ selectedItem, chooseItem ] = useState()

	function selectItem( item ) {
		showDropDown( !displayDropDown )
		chooseItem( item )
		getQuantity( item )
	}


	const allQuantities = data.map( ( obj ) => obj.quantity )
	const sortedQuantities = allQuantities.sort( ( a, b ) => a - b )
	const biggestValidQuantity = sortedQuantities[ sortedQuantities.length - 1 ]


	function displayDropdownList() {
		if ( displayDropDown ) {
			if ( data ) {
				return (
					<div
						className='dropdown-item-list'
						id='dropdownItemList'
						onMouseLeave={ () => showDropDown( !displayDropDown ) }
					>
						<div label={ selectItem ? selectedItem : '' } />
						{ data.map( ( item ) => (
							<div
								className='item'
								key={ item.quantity }
								name={ item.quantity }
								role='button'
								tabIndex={ 0 }
								onKeyPress={ () => selectItem( item.quantity ) }
								onClick={ () => selectItem( item.quantity ) }
							>
								{ getNumberFormatter( language ).format( item.quantity ) }
								{ unit }
							</div>
						) ) }
					</div>
				)
			}
			return null
		}
		return null
	}

	function displaySelectedItemName() {
		if ( selectedItem ) {
			if ( selectedItem > biggestValidQuantity ) {
				chooseItem( biggestValidQuantity )
			}
			return (
				<div
					className='Value'
					role='button'
					tabIndex={ -1 }
					onKeyPress={ () => showDropDown( !displayDropDown ) }
					onClick={ () => showDropDown( !displayDropDown ) }
				>
					{ `${ getNumberFormatter( language ).format( selectedItem ) } ${ unit }` }
				</div>
			)
		}
		getQuantity( data[ 0 ].quantity )
		return (
			<div
				className='Value'
				role='button'
				tabIndex={ -1 }
				onKeyPress={ () => showDropDown( !displayDropDown ) }
				onClick={ () => showDropDown( !displayDropDown ) }
			>
				{ `${ getNumberFormatter( language ).format( data[ 0 ].quantity ) } ${ unit }` }
			</div>
		)
	}

	function displayDropdownListStatus() {
		if ( displayDropDown ) {
			if ( data ) {
				return (
					<div
						className='dropdown-item-list'
						id='dropdownItemList'
					>
						<div label={ selectItem ? selectedItem : '' } />
						{ data.map( ( item ) => (
							<div
								className='item'
								key={ item }
								name={ item }
								role='button'
								tabIndex={ 0 }
								onKeyPress={ () => selectItem( translations[ item ] ) }
								onClick={ () => selectItem( translations[ item ] ) }
							>
								{ translations[ item ] }
							</div>
						) ) }
					</div>
				)
			}
			return null
		}
		return null
	}

	function displaySelectedItemNameStatus() {
		if ( selectedItem ) {
			return (
				<div
					className='Value'
					role='button'
					tabIndex={ -1 }
					onKeyPress={ () => showDropDown( !displayDropDown ) }
					onClick={ () => showDropDown( !displayDropDown ) }
				>
					{ selectedItem }
				</div>
			)
		}
		return (
			<div
				className='Value'
				role='button'
				tabIndex={ -1 }
				onKeyPress={ () => showDropDown( !displayDropDown ) }
				onClick={ () => showDropDown( !displayDropDown ) }
			>
				<Trans id='Dropdown.selectMonth' />

			</div>
		)
	}

	return (
		<div
			className='dropdown-select'
			name='dropdown-select'
			id='productClass'
		>
			<div className='Triangle' />
			{ data.length > 0 && type === 'quantity' ? displaySelectedItemName() : null }
			{ data.length > 0 && type === 'quantity' ? displayDropdownList() : null }
			{ data.length > 0 && type === 'status' ? displaySelectedItemNameStatus() : null }
			{ data.length > 0 && type === 'status' ? displayDropdownListStatus() : null }

		</div>
	)
} )

Dropdown.defaultProps = {
	unit: null,
	translations: null,
	getQuantity: () => {},
}

Dropdown.propTypes = {
	data: propTypes.array.isRequired,
	unit: propTypes.string,
	getQuantity: propTypes.func,
	type: propTypes.string.isRequired,
	translations: propTypes.object,
}

export default Dropdown
