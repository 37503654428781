/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react'
import { Trans } from '@lingui/macro'
import axios from 'axios'

import './HubWarehouse.css'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import HubWarehouseRow from './components/HubWarehouseRow'
import ConditionalWrapper from '../../_Globals/ConditionalWrapper'
import Loader from '../../Loader'

/*	global sessionStorage navigator Blob */

const GET_HUB_ID_BY_TENANT = gql`
	query gethubIdByTenantIdWarehouse(
		$tenant_id: String!,
	) {
		hub_hub( where: { tenant_id: { _eq: $tenant_id } } ) {
            id
        }
	}
`

const GET_WAREHOUSE_DRAWERS_BY_HUB = gql`
	query getWarehouseDrawersByHub(
		$hub_id: bigint!,
	)
	{
		hub_warehouse( where: { hub_id: { _eq: $hub_id } } ) {
			id
			drawer_code
			occupied
            qr_code_id
			order_id
		}
	}
`

function helperLoaderFunctions( loadingObject, errorObject ) {
	if ( loadingObject ) {
		return <Loader />
	}

	if ( errorObject ) {
		return (
			<div>
				{ JSON.stringify( errorObject ) }
			</div>
		)
	}

	return null
}

const HubWarehouse = () => {
	const warehousePlaceholder = []
	const [ windowWidth, setWidth ] = useState( window.innerWidth > 768 )
	const tenantId = sessionStorage.getItem( 'uhub_tenantId' )

	useEffect( () => {
		const handleResize = () => {
			setWidth( window.innerWidth > 768 )
		}
		window.addEventListener( 'resize', handleResize )
		return () => { window.removeEventListener( 'resize', handleResize ) }
	}, [] )

	const {
		data: hubData,
		loading: tenantLoading,
		error: hubError
	} = useQuery( GET_HUB_ID_BY_TENANT, {
		variables: {
			tenant_id: tenantId
		}
	} )
	helperLoaderFunctions( tenantLoading, hubError )

	const hubId = hubData
		&& hubData.hub_hub
		&& hubData.hub_hub[ 0 ]
		&& hubData.hub_hub[ 0 ].id
		? hubData.hub_hub[ 0 ].id : null

	const {
		data: drawersData,
		loading: drawersLoading,
		error: drawersError
	} = useQuery( GET_WAREHOUSE_DRAWERS_BY_HUB, {
		variables: {
			hub_id: hubId
		},
		skip: hubId === null,
		fetchPolicy: 'no-cache'
	} )
	helperLoaderFunctions( drawersLoading, drawersError )

	function formattedCurrentDate() {
		const today = new Date()
		let dd = today.getDate()
		let mm = today.getMonth() + 1
		const yyyy = today.getFullYear()

		if ( dd < 10 ) dd = `0${ dd }`
		if ( mm < 10 ) mm = `0${ mm }`

		return `${ dd }-${ mm }-${ yyyy }`
	}

	function createAndDownloadBlobFile( byteArray, filename, extension = 'pdf' ) {
		const blob = new Blob( [ byteArray ] )
		const fileName = `${ filename }-${ formattedCurrentDate() }.${ extension }`
		if ( navigator.msSaveBlob ) {
			// IE 10+
			navigator.msSaveBlob( blob, fileName )
		} else {
			// Other browsers
			const link = document.createElement( 'a' )
			if ( link.download !== undefined ) {
				const url = URL.createObjectURL( blob )
				link.setAttribute( 'href', url )
				link.setAttribute( 'download', fileName )
				link.style.visibility = 'hidden'
				document.body.appendChild( link )
				link.click()
				document.body.removeChild( link )
			}
		}
	}

	function printQrCodes() {
		const warehouseQRCodes = []

		if ( drawersData && drawersData.hub_warehouse && drawersData.hub_warehouse.length > 0 ) {
			drawersData.hub_warehouse.forEach( ( item ) => {
				warehouseQRCodes.push( {
					id: item.id,
					storage_space: item.drawer_code,
					qr_code_id: item.qr_code_id
				} )
			} )
		}

		axios.post(
			`${ process.env.REACT_APP_BASE_QR_CODE_SERVIS }/api/v1/QRcode/Storage`,
			warehouseQRCodes
		)
			.then( ( res ) => {
				const byteString = window.atob( res?.data?.result )
				const bytes = new Uint8Array( byteString.length )
				const arrayBuffer = bytes.map( ( byte, i ) => byteString.charCodeAt( i ) )
				createAndDownloadBlobFile( arrayBuffer, 'qr_kode_skladisc' )
			} ).catch( ( er ) => { console.log( 'create warehouse PDF error', er ) } )
	}

	if ( drawersData ) {
		drawersData.hub_warehouse.forEach( ( drawer ) => {
			warehousePlaceholder.push(
				{
					id: drawer.id,
					code: drawer.drawer_code,
					occupied: drawer.occupied,
					order: drawer.order_id
				}
			)
		} )
	}

	return (
		<ConditionalWrapper
			condition={ windowWidth }
			falseConditionComponent={ (
				<div className='warning'>
					<Trans id='CycleSectionPage.Warning' />
				</div>
			) }
		>
			<div>
				<div className='hub-warehouse-container'>
					<div className='gridWarehouse-title'>
						<div className='gridWarehouse-title-text'>
							<Trans id='HubWarehouse.WarehouseLocations' />
						</div>
						<div className='gridWarehouse-title-btn'>
							<button type='button' onClick={ () => printQrCodes() }>
								<Trans id='HubWarehouse.PrintQRCodesOfWarehouse' />
							</button>
						</div>
					</div>
					<div className='hub-warehouse-header'>
						<div className='gridWarehouse-item-header'><Trans id='HubWarehouse.Code' /></div>
						<div className='gridWarehouse-item-header'><Trans id='HubWarehouse.Occupied' /></div>
						<div className='gridWarehouse-item-header'><Trans id='HubWarehouse.Order' /></div>
					</div>
					<div className='hub-warehouse-rows-main'>
						<div>
							{
								warehousePlaceholder.map( ( warehouseRow ) => (
									<HubWarehouseRow key={ warehouseRow.id } warehouseRow={ warehouseRow } /> ) )
							}
						</div>
					</div>
				</div>
			</div>
		</ConditionalWrapper>
	)
}

export default HubWarehouse
