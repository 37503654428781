import React from 'react'
import propTypes from 'prop-types'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { Trans } from '@lingui/macro'
import { withRouter } from 'react-router-dom'
import store from '../../../../stores/mainStore'

import { ReturnSendIcon } from '../../../../icons'
import { useStateValue } from '../../../../stores/contextStore/stateStore'
import './HubBannerInfo.css'

/* global sessionStorage */

const GET_DISTANCE = gql`
query GetDistance( $originTenant: String!, $tenant: String! ) {
    Util_GeoTenantDistanceToTenant( input: { originTenant: $originTenant, tenant: $tenant } )
}`

const HubBannerInfo = React.memo( ( { hubMenu, selectedHub, history } ) => {
	const [ { isAuth }, changeHub ] = useStateValue()
	const myTenantId = sessionStorage.getItem( 'uhub_tenantId' )

	const {
		data: distanceData,
		// loading: distanceLoading,
		// error: distanceError,
	} = useQuery( GET_DISTANCE, {
		variables: {
			originTenant: selectedHub.tenant_id,
			tenant: myTenantId || selectedHub.tenant_id
		}
	} )

	const distance = distanceData != null
    && distanceData.Util_GeoTenantDistanceToTenant != null
    && distanceData.Util_GeoTenantDistanceToTenant > 0
		? ( distanceData.Util_GeoTenantDistanceToTenant / 1000 ).toFixed( 1 ) : 0

	function onLoginClick( e ) {
		e.preventDefault()
		window.location.href = store.keycloak.createLoginUrl()
	}

	// const cycleString = ` ${ selectedHub.HubCycle }:
	// ${ selectedHub.FirstDate }; 24:00 - ${ selectedHub.SecondDate }; 24:00`

	return (
		<div className={ !hubMenu ? 'shopping-list-info-hub-full' : 'shopping-list-info-hub' }>
			<div
				className='hubInfo-back-to-hubs'
				role='button'
				tabIndex={ 0 }
				onKeyPress={ () => {
					changeHub( { type: 'changeSelectedHub', selectedHub: null } )
					changeHub( { type: 'selectedHubProducts', selectedHubProducts: null } )
				} }
				onClick={ () => {
					changeHub( { type: 'changeSelectedHub', selectedHub: null } )
					changeHub( { type: 'selectedHubProducts', selectedHubProducts: null } )
				} }
			>
				<span><ReturnSendIcon fontSize={ 14 } /></span>
				<span className='text'><Trans id='HubInfo.backToHubs' /></span>
			</div>
			<div className='hub-title'>
				<span>
					<Trans id='HubInfo.hub' />
				</span>
				<span style={ { textTransform: 'capitalize' } }><b>{ selectedHub?.name?.toLowerCase() ?? '' }</b></span>
			</div>
			{/* <div className='hub-cycle-info'>
				<p className='cycle-data'>
					<Trans id='HubComponent.Cycle' />
					{ cycleString }
				</p>
				<LengthAndPricesComponent distanceData={ selectedHub.HubPriceData } />
			</div> */}
			<div className='hub-description'>
				<p>
					{ selectedHub.description }
				</p>
			</div>
			<div className='hub-distance-container'>
				<p className='distance-text'>
					<Trans id='HubComponentIndex.Distance' />
				</p>
				<p className='distance-value-text'>
					{	isAuth
						? <span>{`${ distance } km`}</span>
						: (
							<button
								type='button'
								className='hub-component-login'
								onClick={ ( e ) => onLoginClick( e ) }
							>
								<Trans id='HubComponentIndex.login' />
							</button>
						)}
				</p>
			</div>
			<div
				className='shopping-list-button-show-more'
				role='button'
				tabIndex={ -1 }
				onKeyPress={ () => {
					history.push( '/hub-info' )
				} }
				onClick={ () => {
					history.push( '/hub-info' )
				} }
			>
				<Trans id='Button.showMore' />
			</div>
		</div>
	)
} )

HubBannerInfo.propTypes = {
	selectedHub: propTypes.object.isRequired,
	hubMenu: propTypes.bool.isRequired,
	history: propTypes.object.isRequired,
}

export default withRouter( HubBannerInfo )
