import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import gql from 'graphql-tag'
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import propTypes from 'prop-types'

import '../MyHubs.css'
import { addNotification } from '../../../../utils/messageHandler/notifierStack'
import mainStore from '../../../../stores/mainStore'

import Button from '../../../_Globals/Button/Button'
import Loader from '../../../Loader'

/* global sessionStorage XMLHttpRequest */

const GET_MARKET_BY_TENANT_ID = gql`
query getMarketByTenantId( $tenantId: String! ) {
    provider_market( where: { tenant_id: { _eq: $tenantId } } ) {
		id
		confirmed
		hub_id
		sla_id
		sla_status
		sla {
			id
			file_id
		}
    }
}`

const GET_UPLOAD_URL = gql`
mutation getUploadUrl( $tenantId: String!, $fileId: String! ) {
    Storage_createUploadURL( appId: "uhub", fileId: $fileId, tenantId: $tenantId ) {
        url
        fileId
    }
}`

const INSERT_TO_FILES = gql`
mutation insertToFilesMyHubs( $tenant_id: String!, $uploader_id: String!, $filename: String!, $sla_file_id: uuid!, $hub_id: bigint! ) {
    insert_media_files( objects: { 
            public: false, 
            tenant_id: $tenant_id, 
            type: SLA, 
            uploader_id: $uploader_id, 
            filename: $filename, 
			file_id: $sla_file_id,
			hub_id: $hub_id
        } ) {
        affected_rows
        returning {
            id
        }
    }
}
`
const UPDATE_FILES = gql`
mutation updateFileForTentnIdAndId( 
    $tenantId: String!, 
    $fileId: bigint!, 
    $uploader_id: String!, 
    $filename: String!, 
	$sla_file_id: uuid!,
	$hub_id: bigint!
	) {
    update_media_files(
        where: { id: { _eq: $fileId } }	, 
        _set: { 
            file_id:  $sla_file_id, 
            filename: $filename, 
			uploader_id: $uploader_id, 
			hub_id: $hub_id
        } ) {
        affected_rows
    }
}`


const UPDATE_MARKET_BY_TENANT_ID = gql`
mutation updateProviderMarketByTenantId( $tenantId: String!, $sla_id: bigint!, $hub_id: bigint!, $sla_status: provider_sla_status_enum_enum! ) {
    update_provider_market( where: { tenant_id: { _eq: $tenantId }, hub_id: {_eq: $hub_id} }, _set: { sla_id: $sla_id, sla_status: $sla_status } ) {
        affected_rows
    }
}`

const SEND_MAIL = gql`
query sendMail( 
	$input: MailInput
) {
	sendMail( 
		input: $input
	)
}`


const UploadSLAMarketPopup = React.memo( ( {
	fileId, slaId, toggleSlaMenu, selectedHub
} ) => {
	const [ file, setFile ] = useState( false )

	const tenantId = sessionStorage.getItem( 'uhub_tenantId' )
	const keycloakId = mainStore.user.sub
	const { email } = mainStore.user

	const [ getSendMail ] = useLazyQuery( SEND_MAIL )

	const [ getUploadUrl ] = useMutation( GET_UPLOAD_URL )
	const [ insertToFiles ] = useMutation( INSERT_TO_FILES )
	const [ updateSlaIdAtMarket ] = useMutation( UPDATE_MARKET_BY_TENANT_ID, {
		refetchQueries: () => [ {
			query: gql`${ GET_MARKET_BY_TENANT_ID }`,
			variables: {
				tenantId
			}
		} ],
		onCompleted: () => {
			getSendMail( {
				variables: {
					input: {
						recipient: email,
						template: 'SlaIsWaitingForConfirmation',
						locals: '{}'
					}
				}
			} )

			addNotification( 'OK', 'SLA_WAITING_FOR_CONFIRMATION_BY_HUB' )
			toggleSlaMenu( false )
		}
	} )

	const [ updateFilesTable ] = useMutation( UPDATE_FILES, {
		refetchQueries: () => [ {
			query: gql`${ GET_MARKET_BY_TENANT_ID }`,
			variables: {
				tenantId
			}
		} ]
	} )

	const {
		loading: marketResponseLoading,
		error: marketResponseError
	} = useQuery( GET_MARKET_BY_TENANT_ID, { variables: { tenantId } } )

	// const marketId = marketResponseData ? marketResponseData.id : false

	if ( marketResponseLoading ) { return <Loader /> }
	if ( marketResponseError ) { return <div>{JSON.stringify( marketResponseError )}</div> }

	function isFilePDF( checkFile ) {
		if ( checkFile.type === 'application/pdf' ) return true
		if ( checkFile.name.split( '.' ).pop() === 'pdf' ) return true
		return false
	}

	function handleUploadFileChange( e ) {
		const newFile = e.target.files.length > 0 ? e.target.files[ 0 ] : false
		if ( newFile && isFilePDF( newFile ) ) {
			setFile( newFile )
		} else {
			addNotification( 'ERROR', 'FILE_MUST_BE_PDF' )
		}
	}

	function uploadSLAtoMarket() {
		if ( file && tenantId ) {
			getUploadUrl( { variables: { tenantId, fileId: fileId || 'false' } } ).then( ( uploadSLAResponse ) => {
				const { url, fileId: resFileId } = uploadSLAResponse.data.Storage_createUploadURL
				const xhr = new XMLHttpRequest()
				xhr.open( 'PUT', url, true )
				xhr.send( file )
				xhr.onload = () => {
					if ( xhr.status === 200 ) {
						if ( slaId ) {
							// Update filename, uploader_id, file_id and updatedAt
							updateFilesTable( {
								variables: {
									tenantId,
									fileId: slaId,
									uploader_id: keycloakId,
									filename: file.name,
									sla_file_id: resFileId,
									hub_id: selectedHub.id
								}
							} )
						} else {
							// Insert to files table and update SLA id on hub.
							insertToFiles( {
								variables: {
									tenant_id: tenantId,
									uploader_id: keycloakId,
									filename: file.name,
									sla_file_id: resFileId,
									hub_id: selectedHub.id
								}
							} ).then( ( filesResponse ) => {
								const { id } = filesResponse.data.insert_media_files.returning[ 0 ]
								updateSlaIdAtMarket( {
									variables: {
										tenantId,
										sla_id: id,
										hub_id: selectedHub.id,
										sla_status: 'IN_PROGRESS'
									}
								} )
							} )
						}
						addNotification( 'OK', 'FILE_WAS_UPDATED' )
					} else {
						addNotification( 'ERROR', 'SOMETHING_WENT_WRONG_AT_FILE_UPLOAD' )
					}
				}
			} )
		}
	}

	function uploadSLAButton() {
		uploadSLAtoMarket()
	}

	return (
		<div className='popupSLA-main-container'>
			<div className='popup-window'>
				<p className='popup-window-title'><Trans id='PopupUploadSla.pleaseUploadPDFFile' /></p>
				<div className='popup-window-input-container'>
					<input id='input-btn' type='file' accept='.pdf' onChange={ ( e ) => handleUploadFileChange( e ) } />
				</div>
				<div className='popup-windows-close-btn'>
					<Button
						text={ <Trans id='PopupUploadSla.upload' /> }
						disabled={ !!file }
						onClick={ () => uploadSLAButton() }
					/>
					<Button
						text={ <Trans id='PopupUploadSla.close' /> }
						disabled
						onClick={ () => toggleSlaMenu( false ) }
					/>
				</div>
			</div>
		</div>
	)
} )

UploadSLAMarketPopup.propTypes = {
	fileId: propTypes.any.isRequired,
	slaId: propTypes.any.isRequired,
	toggleSlaMenu: propTypes.func.isRequired,
	selectedHub: propTypes.object.isRequired
}

export default UploadSLAMarketPopup
