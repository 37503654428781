import React from 'react'
import { Trans } from '@lingui/macro'
import Tooltip from 'react-tooltip-lite'
import { useQuery } from '@apollo/react-hooks'
import propTypes from 'prop-types'
import gql from 'graphql-tag'

import './HubMenu.css'
import HubComponent from '../HubComponent'
import Loader from '../Loader'

const GET_HUBS = gql`{
    hub_hub ( order_by: { name: asc } ) {
		id
        description
        name
        cycles ( where: { finished: { _eq: false } } ) {
			id
            closed_time
            start_time
        }
		galleries( where: { default: { _eq: true } } ) {
			id
      		file {
				id
        		tenant_id
        		file_id
      		}
    	}
		tenant_id
    }
}`

const HubMenu = React.memo( ( { resetProvider } ) => {
	const { loading, error, data } = useQuery( GET_HUBS )

	const hubsData = data && data.hub_hub && data.hub_hub.length > 0 ? data.hub_hub : []
	const numOfHubs = data && data.hub_hub && data.hub_hub.length > 0 ? data.hub_hub.length : null

	if ( loading ) {
		return <Loader />
	}

	if ( error ) {
		return ( <div>{ JSON.stringify( error ) }</div> )
	}

	return (
		<div id='hub-menu-container'>
			<div className='choose-hub-banner'><Trans id='HubMenu.chooseHub' /></div>
			<div className='text-banner'>
				<span><Trans id='HubMenu.numOfHubsToChooseFrom.first' /></span>
				<span>{numOfHubs}</span>
				<span><Trans id='HubMenu.numOfHubsToChooseFrom.hubs' /></span>
			</div>
			<div className='blue-banner' />
			<div className='show-hubs-container'>
				{ hubsData.map( ( hub ) => (
					<div key={ hub.id }>
						<Tooltip
							id='hub-menu-tooltip'
							arrowSize={ 5 }
							padding='5px'
							content={ <Trans id='Tooltip.selectHub' /> }
							direction='right'
						>
							<HubComponent hubData={ hub } resetProvider={ resetProvider } />
						</Tooltip>
						<div className='hub-component-space-between-hubs' />
					</div>
				) ) }
			</div>
		</div>
	)
} )

HubMenu.propTypes = {
	resetProvider: propTypes.func.isRequired
}

export default HubMenu
