import React from 'react'
import { TextRow, RectShape } from 'react-placeholder/lib/placeholders'

const ProviderComponentPlaceholder = (
	<div
		className='seller-grid-container'
	>
		<RectShape style={ { width: 165, height: 145 } } color='#ececec' />
		<TextRow style={ { width: 200, height: 12 } } color='#ececec' />
		<TextRow style={ { width: 200, height: 12 } } color='#ececec' />
	</div>
)

export default ProviderComponentPlaceholder
