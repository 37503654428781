import React from 'react'
import propTypes from 'prop-types'

import './notifierAddProductToCart.css'

const Notifier = ( {
	id, icon, message, backgroundColor, fontColor
} ) => (
	<div id={ `Notifier_${ id }` } className='message-handler-addToProduct' style={ { bottom: `calc( 65px * ${ id })`, backgroundColor, color: fontColor } }>
		<div className='icon-item'>{ icon }</div>
		<div>{ message }</div>
	</div>
)

Notifier.defaultProps = {
	id: 0,
	icon: {},
	message: 'UNHANDLED_CODE',
	backgroundColor: 'ff9933',
	fontColor: 'black'
}
Notifier.propTypes = {
	id: propTypes.number,
	icon: propTypes.object,
	message: propTypes.any,
	backgroundColor: propTypes.any,
	fontColor: propTypes.any
}

export default Notifier
