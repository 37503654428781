import gql from 'graphql-tag'

const GET_ALL_ACTIVE_PRODUCTS_BY_HUB = gql`
query getProductsOnMarketByHubId( 
	$hub_id: bigint!,
	$marketFilters: [ bigint! ],
	$gs1Filters: [ Int! ],
	$limit: Int!,
	$offset: Int,
){
    provider_market( where: { 
        hub_id: { _eq: $hub_id }, 
        active: { _eq: true }, 
        confirmed: { _eq: true },
		products: { market_id: { _in: $marketFilters } }
    } ) {
		id
        products(
			limit: $limit,
			offset: $offset,
			where: {
				product: { disabled: { _eq: false } ,
				gs1_brick: { id: { _in:  $gs1Filters } },
				quantity: { _neq: 0 } },
			},
			order_by: { product: { id: asc } }
		) {
			id
			market_id
            product {
                id
                gs1_brick {
					id
                	translation_sl
					files {
						id
						public
						file_id
						tenant_id
					}
					unit {
      					id
      					unit
    				}
                }
                description
                bruto_price
				neto_price
                quality
                quantity
                variety {
					id
                    name
                }
				galleries (where: { default: { _eq: true } } ){
					id
					file {
						id
						file_id
						tenant_id
					}
				}
				tenant_id
            }
            market {
				id
                name
				hub_id
				description
				hub {
					id
            		description
            		tenant_id
					cycles {
						id
					}
          		}
				provider {
					id
					average_mark
				}
            }
        }
    }
}`

const GET_ALL_ACTIVE_PRODUCTS_BY_HUB_SUBSCRIPTION = gql`
subscription getProductsOnMarketByHubIdSubscription( 
	$hub_id: bigint!,
	$marketFilters: [ bigint! ],
	$gs1Filters: [ Int! ],
	$limit: Int!,
){
    provider_market( where: { 
        hub_id: { _eq: $hub_id }, 
        active: { _eq: true }, 
        confirmed: { _eq: true },
		products: { market_id: { _in: $marketFilters } }
    } ) {
		id
        products(
			limit: $limit,
			where: {
				product: { disabled: { _eq: false },
				gs1_brick: { id: { _in:  $gs1Filters } },
				quantity: { _neq: 0 } },
			},
			order_by: { product: { id: asc } }
		) {
			id
			market_id
            product {
                id
                gs1_brick {
					id
                	translation_sl
					files {
						id
						public
						file_id
						tenant_id
					}
					unit {
      					id
      					unit
    				}
                }
                description
                bruto_price
				neto_price
                quality
                quantity
                variety {
					id
                    name
                }
				galleries (where: { default: { _eq: true } } ){
					id
					file {
						id
						file_id
						tenant_id
					}
				}
				tenant_id
            }
            market {
				id
                name
				hub_id
				description
				hub {
					id
            		description
            		tenant_id
					cycles {
						id
					}
          		}
				provider {
					id
					average_mark
				}
            }
        }
    }
}`

const GET_ALL_DEFAULT_QUANTITIES = gql`
query {
  code_register_units_default_quantities {
	id
    unit_id
    quantity
  }
}`

export {
	GET_ALL_ACTIVE_PRODUCTS_BY_HUB,
	GET_ALL_ACTIVE_PRODUCTS_BY_HUB_SUBSCRIPTION,
	GET_ALL_DEFAULT_QUANTITIES
}
