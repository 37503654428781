/* eslint-disable dot-notation */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, {
	useState, useEffect, useReducer
} from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Trans } from '@lingui/macro'
import ReactPlaceholder from 'react-placeholder'
import 'react-placeholder/lib/reactPlaceholder.css'

import ProductRowPlaceholder from './components/ProductRowPlaceholder'
import ProductRow from './components/ProductRow'
import PopupAddProduct from './components/PopupAddProduct'
import ProductView from '../ProductView'
import Button from '../../_Globals/Button/Button'
import ConditionalWrapper from '../../_Globals/ConditionalWrapper'
import ShoppingList from '../../ShoppingList'
import { ReturnSendIcon } from '../../../icons'
import Loader from '../../Loader'
import { addNotification } from '../../../utils/messageHandler/notifierStack'

import { useStateValue } from '../../../stores/contextStore/stateStore'

import { GET_ALL_ACTIVE_PRODUCTS } from '../../ShoppingContent/ShoppingContentGQL'
import { GET_PRODUCTS, HASURA_UPDATE_PRODUCT_DATA } from './MyOffersGQL'

import './MyOffers.css'

const initStates = {
	shoppingList: []
}

function addItemToshoppingList( shoppingList, addedItem ) {
	// eslint-disable-next-line max-len
	if ( shoppingList.findIndex( ( el ) => el.id === addedItem.id ) === -1 && shoppingList.length < 12 ) {
		shoppingList.push( addedItem )
	}
	return shoppingList
}

function removeAllItemsFromShoppingList( shoppingList ) {
	while ( shoppingList.length > 0 ) {
		shoppingList.pop()
	}
	return shoppingList
}

function removeItemFromShoppingList( shoppingList, itemIdToRemove ) {
	for ( let i = 0; i < shoppingList.length; i += 1 ) {
		if ( shoppingList[ i ].id === itemIdToRemove ) {
			shoppingList.splice( i, 1 )
			break
		}
	}
	return shoppingList
}

function reducer( state, action ) {
	switch ( action.type ) {
	case 'toggleShoppingListDisable':
		return {
			...state,
			shoppingList: state.shoppingList.map( ( shoppingItem ) => {
				//  If shopping item match id then toggle disabled field
				if ( shoppingItem.id === action.id ) {
					return { ...shoppingItem, disabled: !shoppingItem.disabled }
				}
				return shoppingItem
			} )
		}
	case 'removeItemFromShoppingList':
		//  Remove element on id from array
		return {
			...state,
			shoppingList: removeItemFromShoppingList( state.shoppingList, action.id )
		}
	case 'addItemToShoppingList':
		//  Add element to end of array
		return {
			...state,
			//  Check if item alreadyExist and shoppingList.length is lower then 13.
			shoppingList: addItemToshoppingList( state.shoppingList, action.addedItem )
		}
	case 'resetShoppingList':
		//  Reset shopping list to empty
		return {
			...state,
			shoppingList: removeAllItemsFromShoppingList( state.shoppingList )
		}

	default:
		return state
	}
}


/*	global sessionStorage */

const MyOffers = React.memo( () => {
	const tenantId = sessionStorage.getItem( 'uhub_tenantId' )

	const [ state, setState ] = useReducer( reducer, initStates )
	const [ { myOfferListVisibility }, toggleMenus ] = useStateValue()

	const [ editable, toggleEditable ] = useState( null )
	const [ popupAddProduct, openPopup ] = useState( false )
	const [ selectedProduct, selectProduct ] = useState( null )
	const [ addProduct, handleAddProduct ] = useState( null )
	const [ responseData, handleResponse ] = useState( null )
	const [ inputData, getInputDataBack ] = useState( {
		description: '', quality: '', neto_price: '', quantity: ''
	} )
	const [ currentLenght, getCurrentLegth ] = useState( 1 )

	/*
	we need to know if this is a new product to disable unneccesary messages when subscriptions fire
	*/

	const [ newProduct, isNewProduct ] = useState( false )
	const gs1ShoppingList = state.shoppingList.filter( ( item ) => item.translation_sl )

	// constants needed for successfully saving new data for selected product
	const [ taxValue, getTaxValue ] = useState()
	const [ objectData, getObjectData ] = useState( {} )
	const [ productId, getProductId ] = useState( null )

	const [ isSuccessfull, success ] = useState()

	const [ clickedBackToOffers, back ] = useState( false )

	const {
		data: productsData,
		loading: productsLoading,
		error: productsError,
		refetch: productsRefetch,
		fetchMore: productsFetchMore
	} = useQuery( GET_PRODUCTS, {
		variables: {
			tenant_id: tenantId,
			offset: 0,
			limit: 10
		},
		fetchPolicy: 'cache-and-network'
	} )

	const {
		data: allProductsData,
		loading: allProductsLoading,
		error: allProductsError
	} = useQuery( GET_PRODUCTS, {
		variables: {
			tenant_id: tenantId,
			offset: 0,
			limit: 100
		},
		fetchPolicy: 'cache-and-network'
	} )

	const [ updateProductData ] = useMutation( HASURA_UPDATE_PRODUCT_DATA, {
		refetchQueries: () => [ {
			query: gql`${ GET_PRODUCTS }`,
			variables: {
				tenant_id: tenantId,
				limit: 10
			}
		},
		{
			query: gql`${ GET_ALL_ACTIVE_PRODUCTS }`,
		} ],
		onCompleted: ( data ) => {
			if ( data.update_provider_product.returning[ 0 ].id ) {
				success( true )
			}
		}
	} )

	const products = productsData
		&& productsData.provider_product.length > 0
		? productsData.provider_product : []

	const allProducts = allProductsData
		&& allProductsData.provider_product.length > 0
		? allProductsData.provider_product : []


	const gs1Filters = gs1ShoppingList
		.map( ( gs1Product ) => gs1Product )

	const bids = gs1Filters.map( ( filter ) => filter.id )

	const allValues = allProducts.filter( ( product ) => bids.includes( product.brick_code ) )

	// Function for saving edited or new data for selected product

	useEffect( () => {
		if ( editable === false && productId !== null && !clickedBackToOffers ) {
			const id = productId
			const object = objectData
			// eslint-disable-next-line dot-notation
			object[ 'ddv_enum' ] = taxValue

			// making sure to update data for selected product
			if ( id === object.id ) {
				updateProductData( { variables: { id, changes: object } } )
					.then(
						( res ) => {
							if ( res
								&& res.data
								&& res.data.update_provider_product
								&& res.data.update_provider_product.returning.length > 0 ) {
								getInputDataBack( object )
								getProductId( id )
								toggleEditable( null )
								getCurrentLegth( 1 )
								getInputDataBack( {
									description: '', quality: '', neto_price: '', quantity: ''
								} )
								addNotification( 'OK', 'PRODUCT_UPDATED' )
							}
						}
					)
					.catch( ( err ) => {
						if ( err ) {
							addNotification( 'ERROR', 'CANNOT_UPDATE_PRODUCT' )
						}
					} )
			}
		}
		// eslint-disable-next-line max-len
	}, [ editable, taxValue, updateProductData, productId, objectData, clickedBackToOffers ] )

	function getChangedText( changedText ) {
		const { id } = changedText
		getProductId( id )

		const object = changedText

		getInputDataBack( object )
		object[ 'ddv_enum' ] = taxValue

		if ( !( 'id' in object ) ) {
			object[ 'id' ] = productId
		}

		getObjectData( object )
	}

	function updateProductActiveStatus( disabled, id ) {
		const object = { disabled }
		updateProductData( { variables: { id, changes: object } } )
			.then(
				( res ) => {
					if ( res
                    && res.data
                    && res.data.update_provider_product
                    && res.data.update_provider_product.affected_rows > 0 ) {
						addNotification( 'OK', 'PRODUCT_STATUS_UPDATED' )
					}
				}
			)
			.catch( ( err ) => {
				if ( err ) {
					addNotification( 'ERROR', 'CANNOT_UPDATE_PRODUCT_STATUS' )
				}
			} )
	}

	function getFileId( product ) {
		if ( product.galleries.length > 0 ) {
			return product.galleries[ 0 ].file.file_id
		}
		return product.gs1_brick.files ? product.gs1_brick.files.file_id : 'null'
	}

	function getProductsFunct() {
		if ( productsError ) {
			return <Trans id='MyOffers.cannotDisplayProducts' />
		}

		if ( allValues.length > 0 ) {
			return allValues.map( ( product ) => (
				<div key={ `${ product.id } ${ product.brick_code }` }>
					<ReactPlaceholder
						showLoadingAnimation
						ready={ !productsLoading }
						customPlaceholder={ ProductRowPlaceholder }
					>
						<ProductRow
							product={ product }
							disabled={ product.disabled }
							fileId={ getFileId( product ) }
							tenantId={ tenantId }
							updateProductActiveStatus={
								( prodDisabled, prodId ) => updateProductActiveStatus( prodDisabled, prodId )
							}
							selectProduct={ ( prod ) => selectProduct( prod ) }
						/>
					</ReactPlaceholder>
				</div>
			) )
		}

		return products.map( ( product ) => (
			<div key={ `${ product.id } ${ product.brick_code }` }>
				<ReactPlaceholder
					showLoadingAnimation
					ready={ !productsLoading }
					customPlaceholder={ ProductRowPlaceholder }
				>
					<ProductRow
						product={ product }
						disabled={ product.disabled }
						fileId={ getFileId( product ) }
						tenantId={ tenantId }
						updateProductActiveStatus={
							( prodDisabled, prodId ) => updateProductActiveStatus( prodDisabled, prodId )
						}
						selectProduct={ ( prod ) => selectProduct( prod ) }
					/>
				</ReactPlaceholder>
			</div>
		) )
	}

	function onLoadMoreProducts() {
		productsFetchMore( {
			variables: {
				offset: productsData.provider_product.length
			},
			updateQuery: ( prev, { fetchMoreResult } ) => {
				if ( !fetchMoreResult ) return prev
				getCurrentLegth( fetchMoreResult.provider_product.length )

				return {
					prev,
					provider_product: [
						...prev.provider_product,
						...fetchMoreResult.provider_product,
					]
				}
			}
		} )
	}

	function setEditableToFalse() {
		if ( !newProduct ) {
			// if existed product in productView component is in mode for edit,
			// on click we make sure that is not,
			// so that component CheckBox is properly refreshed,
			// when displaying if product is on market
			toggleEditable( false )
		}
	}

	return (
		<ConditionalWrapper
			condition={ !!selectedProduct || !!addProduct }
			falseConditionComponent={
				(
					<div>
						{ popupAddProduct
							? (
								<PopupAddProduct
									closePopUp={ () => openPopup() }
									handleAddProduct={ ( addProd ) => handleAddProduct( addProd ) }
									handleResponse={ ( response ) => handleResponse( response ) }
									isNewProduct={ ( newProd ) => isNewProduct( newProd ) }
									toggleEditable={ toggleEditable }
								/>
							)
							: <div /> }
						<div>
							<ShoppingList
								shoppingList={ state.shoppingList }
								handleShoppingList={ setState }
								shoppingListVisibility={ myOfferListVisibility }
								toggleShoppingList={ () => toggleMenus( { type: 'toggleMyOfferListVisibility' } ) }
								isMyOffer
							/>
							<div className='button-add-product'>
								<Button
									text={ <Trans id='MyStands.addProduct' /> }
									disabled
									onClick={ () => {
										openPopup( true )
									} }
								/>
							</div>
							<div className='product-rows-container'>
								{ getProductsFunct() }
								{ productsLoading ? <Loader /> : null }
							</div>
							<div className='button-container-fetch-more'>
								<div
									className={ currentLenght !== 0 ? 'fetch-more' : 'fetch-more-disabled' }
									role='button'
									tabIndex={ -1 }
									onKeyPress={ () => ( currentLenght !== 0 ? onLoadMoreProducts() : {} ) }
									onClick={ () => ( currentLenght !== 0 ? onLoadMoreProducts() : {} ) }
								>
									<Trans id='MyOffer.ShowMore' />
								</div>
							</div>
						</div>
					</div>
				)
			}
		>
			<div id='my-hubs-offer'>
				<div
					role='button'
					className='back-to-hubs'
					tabIndex={ -1 }
					onKeyPress={ () => {
						setEditableToFalse()

						// for existed product in productView component in edit mode,
						// making sure that component CheckBox is properly refreshed,
						// when displaying if product is on market
						isNewProduct( false )
						back( true )

						selectProduct( false )
						getInputDataBack( {
							description: '', quality: '', neto_price: '', quantity: ''
						} )
						handleAddProduct( null )
						productsRefetch()
					} }
					onClick={ () => {
						setEditableToFalse()

						// for existed product in productView component in edit mode,
						// making sure that component CheckBox is properly refreshed,
						// when displaying if product is on market
						isNewProduct( false )
						back( true )

						selectProduct( false )
						getInputDataBack( {
							description: '', quality: '', neto_price: '', quantity: ''
						} )
						handleAddProduct( null )
						productsRefetch()
					} }
				>
					<span><ReturnSendIcon fontSize={ 14 } /></span>
					<span className='text'><Trans id='ShoppingCart.backToMyOffer' /></span>
				</div>
				{
					( selectedProduct || ( addProduct && responseData !== null ) )
					&& (
						<ProductView
							product={ selectedProduct || { product: responseData } }
							editableFields={ editable }
							passText={ ( data ) => getChangedText( data ) }
							inputData={ inputData }
							itemsRefetch={ () => productsRefetch() }
							getTaxValue={ ( tax ) => getTaxValue( tax ) }
							getProductId={ ( id ) => getProductId( id ) }
							isSuccessfull={ isSuccessfull }
							newProduct={ newProduct }
							back={ ( val ) => back( val ) }
						/>
					)
				}
				<div id='my-hubs-actions-container'>
					<div />
					<div id='my-offers-actions'>
						<button
							type='button'
							className='hub-section-edit-button'
							onClick={ () => { toggleEditable( !editable ) } }
						>
							{ editable ? <Trans id='HubSection.btnSave' /> : <Trans id='HubSection.btnEdit' />}
						</button>
					</div>
				</div>
			</div>
		</ConditionalWrapper>
	)
} )

export default MyOffers
