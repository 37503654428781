/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'
import { withRouter } from 'react-router-dom'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'

import './HubView.css'
import Loader from '../../Loader'
import { useStateValue } from '../../../stores/contextStore/stateStore'
import roleHandler from '../../../utils/ContentRoleHandler'
import EditArea from '../../_Globals/EditArea'
import ImageSlider from '../../ImageSlider'
import Button from '../../_Globals/Button/Button'
import ImageGallery from '../../_Globals/ImageGallery'
import { dateToString } from '../../../utils/DateToString'
import store from '../../../stores/mainStore'
import CyclePopup from './components/CyclePopup'
import CommissionFeeDropdown from './components/CommissionFeeDropdown'

import { addNotification } from '../../../utils/messageHandler/notifierStack'

/* global sessionStorage */

const GET_HUB_BY_TENANT_ID_HUB_ID = gql`
query GetHubByTenantIdHubView( $tenant_id: String, $id: bigint ){
    hub_hub( where: { tenant_id: { _eq: $tenant_id }, id: { _eq: $id } } ) {
		id
		commission_percent
		name
		storage_capacity
		delivery_radius
		description
		cycle_length
		cycles {
			id
			finished
			duration
			closed_time
			start_time
		}
		tenant {
			id
			applications {
				id
				data {
                    mobile
                    phone
					address {
						address
						city
						zip
					}
				}
			}
			identifiers {
				taxId
			}
		}
		galleries( where: { default: { _eq: true } } ) {
			id
            file {
				id
                tenant_id
                file_id
            }
        }
    }
}`


const GET_HUB_GALLERY = gql`
query get_hub_gallery(
    $hub_id: bigint
){
    media_gallery(where: { hub_id: { _eq: $hub_id } } ) {
		id
        file {
			id
            file_id
            tenant_id
        }
    }
}
`


const GET_DISTANCE = gql`
query GetDistance( $originTenant: String!, $tenant: String! ) {
    Util_GeoTenantDistanceToTenant( input: { originTenant: $originTenant, tenant: $tenant } )
}`

const UPDATE_HUB_COMMISSION = gql`
mutation updateHubCommission ($id: bigint!, $commission_percent: float8!){
	update_hub_hub(where: {id: {_eq: $id}}, _set: {commission_percent: $commission_percent}) {
        affected_rows
	}
}`

const HubView = React.memo( ( {
	tenantId, editableFields, passText, history
} ) => {
	const [ { isAuth, selectedHub } ] = useStateValue()

	const [ { galleryOpened }, openGallery ] = useStateValue()
	const [ editable, toggleEditable ] = useState( false )
	const [ dropdownValue ] = useState( null )
	const [ editedText, setEditedText ] = useState( {} )
	const [ cyclePopup, toggleCyclePopup ] = useState( false )
	const myTenantId = sessionStorage.getItem( 'uhub_tenantId' )
	const [ dropdownFeeOpen, toggleDropdownFee ] = useState( false )

	const [ dropdownFeeValue, setDropdownFeeValue ] = useState( 0 )


	const [ updateHubCommission ] = useMutation( UPDATE_HUB_COMMISSION )

	const {
		data: hubData,
		loading: hubLoading,
		error: hubError,
		refetch: hubRefetch,
	} = useQuery( GET_HUB_BY_TENANT_ID_HUB_ID, {
		variables: !tenantId ? { id: selectedHub?.id } : { tenant_id: tenantId },
		skip: selectedHub === null && tenantId === null,
		onCompleted: ( data ) => {
			if ( data ) {
				setDropdownFeeValue( Math.round( ( data.hub_hub[ 0 ]?.commission_percent ) * 100 ) )
			}
		}
	} )

	const {
		data: distanceData,
		loading: distanceLoading,
		error: distanceError,
	} = useQuery( GET_DISTANCE, {
		variables: {
			originTenant: tenantId || selectedHub?.tenant_id,
			tenant: myTenantId || tenantId || selectedHub?.tenant_id
		}
	} )

	const distance = distanceData != null
    && distanceData.Util_GeoTenantDistanceToTenant != null
    && distanceData.Util_GeoTenantDistanceToTenant > 0
		? ( distanceData.Util_GeoTenantDistanceToTenant / 1000 ).toFixed( 1 ) : 0

	const hubById = hubData
		&& hubData.hub_hub
		&& hubData.hub_hub.length > 0
		? hubData.hub_hub[ 0 ] : []

	const {
		data: galleryHubData,
		loading: galleryHubLoading,
		error: galleryHubError,
		refetch: refetchHubGallery,
	} = useQuery( GET_HUB_GALLERY, {
		variables: {
			hub_id: hubById.id,
		}
	} )


	async function needNewCycle() {
		const fetchData = await hubRefetch()
		if ( fetchData.data !== undefined ) {
			const { cycles } = fetchData.data.hub_hub[ 0 ]
			if ( cycles[ 0 ] === undefined ) {
				return true
			}
			for ( let i = 0; i < cycles.length; i += 1 ) {
				if ( cycles[ i ].finished === false ) {
					return false
				}
			}
		}
		return true
	}

	const [ cycleState, setCycleState ] = useState( () => {
		needNewCycle().then( ( res ) => {
			setCycleState( res )
		} )
	} )

	const galleryItems = galleryHubData
		&& galleryHubData.media_gallery
		&& galleryHubData.media_gallery.length > 0
		? galleryHubData.media_gallery : []


	useEffect( () => {
		if ( roleHandler( isAuth, [ 'owner' ] ) ) {
			toggleEditable( editableFields )
		}
	}, [ editableFields, isAuth ] )

	useEffect( () => {
		if ( Object.keys( editedText ).length > 0 ) {
			passText( editedText )
			setEditedText( {} )
		}
	}, [ editedText, passText ] )

	function getText( id, text ) {
		const objectCopy = editedText

		if ( !objectCopy.hubId ) {
			objectCopy.hubId = hubById.id || false
		}

		objectCopy[ id ] = text
		setEditedText( objectCopy )
	}

	// if selectedHub is NULL display home page
	if ( selectedHub === null && !tenantId ) {
		history.push( '/' )
		return () => {}
	}

	function onLoginClick( e ) {
		e.preventDefault()
		window.location.href = store.keycloak.createLoginUrl()
	}

	if ( hubLoading || cycleState === undefined || distanceLoading ) {
		return <Loader />
	}

	if ( hubError || distanceError ) {
		return (
			<div>
				{ JSON.stringify( hubError || distanceError ) }
			</div>
		)
	}

	function setDropdownFeeVal( value ) {
		setDropdownFeeValue( value )
	}


	function changeCommision() {
		updateHubCommission( {
			variables: {
				id: hubById?.id,
				commission_percent: dropdownFeeValue / 100
			}
		} )
		addNotification( 'OK', 'HUB_COMMISSION_UPDATED' )
	}

	return (
		<div id='hub-view-container'>
			<div>
				{ cyclePopup
					? (
						<CyclePopup
							toggleCyclePopup={ toggleCyclePopup }
							data={ hubById }
							tenantId={ tenantId }
						/>
					)
					: null }
			</div>
			<div id='hub-view-picture-slider'>
				{ galleryHubLoading ? <Loader /> : null }
				{ galleryHubError ? <Trans id='ProductView.cannotDisplayHubImages' /> : null }
				{ galleryHubData ? <ImageSlider galleryItems={ galleryItems } galleryType='hub' /> : null }
				<div className='hub-view-cycle-setup'>
					<div className='hub-view-cycle-length'>
						<Trans id='UploadPic.cycleLength:' />
						:
						<div style={ { color: 'rgb(84, 84, 84)' } }>
							{ `${ dropdownValue || hubById.cycle_length } ` }
							<Trans id='UploadPic.days' />
						</div>
					</div>
					{ roleHandler( isAuth, [ 'owner' ] )
						? (
							<>
								<div
									className='hub-view-start-cycle-button'
									role='button'
									id='cycle-button-start'
									tabIndex={ 0 }
									onKeyUp={ () => toggleCyclePopup( true ) }
									onClick={ () => toggleCyclePopup( true ) }

								>
									{hubById.cycles.length === 0 ? <Trans id='HubView.NewCyclePopup' /> : <Trans id='HubView.editCycleLength' />}
								</div>

								<div
									className='hub-cost-for-intervention-container'
								>
									<p className='hub-cost-for-intervention-text'><Trans id='CommissionFeeMyOrders' /></p>

									<div className='hub-cost-for-intervention-dropdown-box'>
										<CommissionFeeDropdown
											selectedValue={ dropdownFeeValue }
											showDropdown={ dropdownFeeOpen }
											toggleDropdown={ () => toggleDropdownFee( !dropdownFeeOpen ) }
											setDropdownValue={ setDropdownFeeVal }
										/>
									</div>
									<div className='hub-price-list-buttons-add-commision'>
										<button type='button' id='addButton' label='button' className='hub-section-edit-button' onClick={ () => changeCommision( ) }><Trans id='HubViewCommission.Change' /></button>
									</div>
								</div>
								{' '}
							</>
						) : null }
				</div>
			</div>
			{ galleryOpened
				? (
					<ImageGallery
						gallery='HubView'
						title={ <Trans id='ImageGallery.titleMyHub' /> }
						productId={ null }
						marketId={ null }
						hubId={ hubById.id }
						itemsRefetch={ () => hubRefetch() }
						galleryRefetch={ () => refetchHubGallery() }
					/>
				) : null }
			<div className='gallery-button'>
				{ editable
					? (
						<Button
							text={ <Trans id='UploadPic.button' /> }
							disabled
							onClick={ () => openGallery( { type: 'galleryOpened', galleryOpened: true } ) }
						/>
					) : null }
			</div>
			<div id='hub-view-info'>
				<div id='hub-view-first-row'>
					<div className='hub-view-first-row-name'>
						<span className='hub-view-hub-title'>hub </span>
						<EditArea
							id='name'
							isInput
							myStyle='hub-view-hub-title-editable'
							getText={ getText }
							text={ hubById.name }
							editable={ editable }
						/>
					</div>
				</div>
				<EditArea
					id='description'
					getText={ getText }
					text={ hubById.description }
					editable={ editable }
				/>
				<div id='hub-view-distance'>
					<div className='hub-distance-container'>
						<p className='distance-text'>
							<Trans id='HubComponentIndex.Distance' />
						</p>
						<p className='distance-value-text'>
							{
								isAuth
									? <span>{`${ distance } km`}</span>
									: (
										<button
											type='button'
											className='hub-component-login'
											onClick={ ( e ) => onLoginClick( e ) }
										>
											<Trans id='HubComponentIndex.login' />
										</button>
									)
							}
						</p>
					</div>
					<div />
				</div>
				<div id='dots-row' />
				<div id='hub-view-base-info'>
					<div className='hub-view-one-line'>
						<span><Trans id='HubView.address:' /></span>
						<EditArea
							id='address'
							isInput
							getText={ getText }
							text={ hubById.tenant.applications[ 0 ].data.address[ 0 ].address }
							editable={ editable }
						/>
					</div>
					<div className='hub-view-one-line'>
						<span><Trans id='HubView.zip:' /></span>
						<EditArea
							id='zip'
							isInput
							getText={ getText }
							text={ hubById.tenant.applications[ 0 ].data.address[ 0 ].zip }
							editable={ editable }
						/>
					</div>
					<div className='hub-view-one-line'>
						<span><Trans id='HubView.city:' /></span>
						<EditArea
							id='city'
							isInput
							getText={ getText }
							text={ hubById.tenant.applications[ 0 ].data.address[ 0 ].city }
							editable={ editable }
						/>
					</div>
					<div className='hub-view-one-line'>
						<span><Trans id='HubView.idDDV:' /></span>
						<EditArea
							id='taxId'
							isInput
							text={ hubById.tenant.identifiers.taxId }
						/>
					</div>
					<div className='hub-view-one-line'>
						<span><Trans id='HubView.phone:' /></span>
						<EditArea
							id='phone'
							isInput
							getText={ getText }
							text={ hubById.tenant.applications[ 0 ].data.phone }
							editable={ editable }
						/>
					</div>
					<div className='hub-view-one-line'>
						<span><Trans id='HubView.mobilePhone:' /></span>
						<EditArea
							id='mobile'
							isInput
							getText={ getText }
							text={ hubById.tenant.applications[ 0 ].data.mobile }
							editable={ editable }
						/>
					</div>
					<div style={ { height: '15px' } } />
					<div className='hub-view-one-line' />
					<div className='hub-view-one-line'>
						<span><Trans id='HubView.deliveryRadius:' /></span>
						<EditArea
							id='delivery_radius'
							isInput
							getText={ getText }
							text={ hubById.delivery_radius.toString() }
							editable={ editable }
						/>
					</div>
					<div className='hub-view-one-line'>
						<span><Trans id='HubView.storageCapacity:' /></span>
						<EditArea
							id='storage_capacity'
							isInput
							getText={ getText }
							text={ hubById.storage_capacity.toString() }
							editable={ editable }
						/>
					</div>
				</div>
				<div id='dots-row' />
				<div>
					<Trans id='HubView.currentCycle:' />
					{
						hubById.cycles.map( ( cycle ) => {
							if ( !cycle.finished ) {
								return (
									<div key={ cycle.id }>
										{`
                                        ${ dateToString( cycle.start_time ) } 
                                        - 
                                        ${ dateToString( cycle.closed_time ) }`}
									</div>
								)
							}
							return false
						} )
					}
				</div>
				<div id='dots-row' />
				<div style={ { marginTop: '30px' } } />
			</div>
		</div>
	)
} )

HubView.defaultProps = {
	editableFields: false,
	passText: () => {},
	tenantId: '',
}

HubView.propTypes = {
	tenantId: propTypes.string,
	editableFields: propTypes.bool,
	passText: propTypes.func,
	history: propTypes.object.isRequired,
}

export default withRouter( HubView )
