import React from 'react'
import { Trans } from '@lingui/macro'
import { withRouter, useHistory } from 'react-router-dom'

import Button from '../../_Globals/Button/Button'
import './PaymentFailed.css'
import { Clear } from '../../../icons'

const PaymentFailed = ( ) => {
	const history = useHistory()

	return (
		<div className='shopping-cart-success-main-container'>
			<div className='success-main-container'>
				<div className='message-container'>
					<div className='Error_icon'><Clear fontSize={ 123 } color='#d14949' /></div>
					<div className='Error_message'><Trans id='ShoppingCart.paymentFailedMessange' /></div>
					<div className='Error_notice_message'><Trans id='ShoppingCart.paymentFailedTryAgain' /></div>
				</div>
			</div>
			<div className='button-next-step'>
				<Button
					text={ <Trans id='ShoppingCart.continueWithShopping' /> }
					disabled={ !false }
					tabIndex={ -1 }
					onKeyPress={ () => history.push( '/' ) }
					onClick={ () => history.push( '/' ) }
				/>
			</div>
		</div>
	)
}

export default withRouter( PaymentFailed )
