/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import propTypes from 'prop-types'

import './UploadSLAPopup.css'
import Button from '../Button/Button'
import { addNotification } from '../../../utils/messageHandler/notifierStack'
import mainStore from '../../../stores/mainStore'

/* global sessionStorage XMLHttpRequest */

const GET_HUB_BY_TENANT_ID = gql`
	query getHubByTenantIdUploadSla(
		$tenantId: String!
	) {
		hub_hub( where: { tenant_id: { _eq: $tenantId } } ) {
			id
            name
            sla_id
			sla {
				id
				file_id
			}
		}
	}
`

const GET_UPLOAD_URL = gql`
mutation getUploadUrl( $tenantId: String!, $fileId: String! ) {
    Storage_createUploadURL( appId: "uhub", fileId: $fileId, tenantId: $tenantId ) {
        url
        fileId
    }
}`

const INSERT_TO_FILES = gql`
mutation insertToFiles( $tenant_id: String!, $uploader_id: String!, $filename: String!, $sla_file_id: uuid! ) {
    insert_media_files( objects: { 
            public: false, 
            tenant_id: $tenant_id, 
            type: SLA, 
            uploader_id: $uploader_id, 
            filename: $filename, 
            file_id: $sla_file_id, 
        } ) {
        affected_rows
        returning {
            id
        }
    }
}
`
const UPDATE_FILES = gql`
mutation updateFileForTentnIdAndId( 
    $tenantId: String!, 
    $fileId: bigint!, 
    $uploader_id: String!, 
    $filename: String!, 
    $sla_file_id: uuid! ) {
    update_media_files(
        where: { tenant_id: { _eq: $tenantId }, _and: { id: { _eq: $fileId } } }, 
        _set: { 
            file_id:  $sla_file_id, 
            filename: $filename, 
            uploader_id: $uploader_id, 
        } ) {
        affected_rows
    }
}`

const UPDATE_HUB_BY_TENANT_ID = gql`
mutation updateHubByTenantId( $tenantId: String!, $sla_id: bigint! ) {
    update_hub_hub( where: { tenant_id: { _eq: $tenantId } }, _set: { sla_id: $sla_id } ) {
        affected_rows
    }
}`

const UploadSLAPopup = React.memo( ( { fileId, slaId, toggleSlaMenu } ) => {
	const [ file, setFile ] = useState( false )

	const tenantId = sessionStorage.getItem( 'uhub_tenantId' )
	const keycloakId = mainStore.user.sub

	const [ getUploadUrl ] = useMutation( GET_UPLOAD_URL )
	const [ insertToFiles ] = useMutation( INSERT_TO_FILES )
	const [ updateSlaIdAtHub ] = useMutation( UPDATE_HUB_BY_TENANT_ID, {
		refetchQueries: () => [ {
			query: gql`${ GET_HUB_BY_TENANT_ID }`,
			variables: {
				tenantId
			}
		} ]
	} )
	const [ updateFilesTable ] = useMutation( UPDATE_FILES, {
		refetchQueries: () => [ {
			query: gql`${ GET_HUB_BY_TENANT_ID }`,
			variables: {
				tenantId
			}
		} ]
	} )

	function isFilePDF( checkFile ) {
		if ( checkFile.type === 'application/pdf' ) return true
		if ( checkFile.name.split( '.' ).pop() === 'pdf' ) return true
		return false
	}

	function handleUploadFileChange( e ) {
		const newFile = e.target.files.length > 0 ? e.target.files[ 0 ] : false
		if ( newFile && isFilePDF( newFile ) ) {
			setFile( newFile )
		} else {
			addNotification( 'ERROR', 'FILE_MUST_BE_PDF' )
		}
	}

	function uploadSLAtoHub() {
		if ( file && tenantId ) {
			getUploadUrl( { variables: { tenantId, fileId: fileId || 'false' } } ).then( ( uploadSLAResponse ) => {
				const { url, fileId: resFileId } = uploadSLAResponse.data.Storage_createUploadURL
				const xhr = new XMLHttpRequest()
				xhr.open( 'PUT', url, true )
				xhr.send( file )
				xhr.onload = () => {
					if ( xhr.status === 200 ) {
						if ( slaId ) {
							// Update filename, uploader_id, file_id and updatedAt
							updateFilesTable( {
								variables: {
									tenantId,
									fileId: slaId,
									uploader_id: keycloakId,
									filename: file.name,
									sla_file_id: resFileId
								}
							} )
						} else {
							// Insert to files table and update SLA id on hub.
							insertToFiles( {
								variables: {
									tenant_id: tenantId,
									uploader_id: keycloakId,
									filename: file.name,
									sla_file_id: resFileId
								}
							} ).then( ( filesResponse ) => {
								const { id } = filesResponse.data.insert_media_files.returning[ 0 ]
								updateSlaIdAtHub( {
									variables: {
										tenantId,
										sla_id: id
									}
								} )
							} )
						}
						addNotification( 'OK', 'FILE_WAS_UPDATED' )
					} else {
						addNotification( 'ERROR', 'SOMETHING_WENT_WRONG_AT_FILE_UPLOAD' )
					}
				}
			} )
		}
	}

	function uploadSLAButton() {
		uploadSLAtoHub()
		toggleSlaMenu( false )
	}

	return (
		<div className='popupSLA-main-container'>
			<div className='popup-window'>
				<p className='popup-window-title'><Trans id='PopupUploadSla.pleaseUploadPDFFile' /></p>
				<div className='popup-window-input-container'>
					<input id='input-btn' type='file' accept='.pdf' onChange={ ( e ) => handleUploadFileChange( e ) } />
				</div>
				<div className='popup-windows-close-btn'>
					<Button
						text={ <Trans id='PopupUploadSla.upload' /> }
						disabled={ !!file }
						onClick={ () => uploadSLAButton() }
					/>
					<Button
						text={ <Trans id='PopupUploadSla.close' /> }
						disabled
						onClick={ () => toggleSlaMenu( false ) }
					/>
				</div>
			</div>
		</div>
	)
} )

UploadSLAPopup.propTypes = {
	fileId: propTypes.any.isRequired,
	slaId: propTypes.any.isRequired,
	toggleSlaMenu: propTypes.func.isRequired
}

export default UploadSLAPopup
