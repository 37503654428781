import React, {
	useState, useCallback, useEffect, Suspense, lazy
} from 'react'
import gql from 'graphql-tag'
import { useMutation, useQuery } from '@apollo/react-hooks'
import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'
import ReactCrop from 'react-image-crop'

import { useStateValue } from '../../../stores/contextStore/stateStore'
import Button from '../Button/Button'
import Loader from '../../Loader'
import { addNotification } from '../../../utils/messageHandler/notifierStack'
import mainStore from '../../../stores/mainStore'
import './ImageGallery.css'
import 'react-image-crop/dist/ReactCrop.css'

/* global sessionStorage XMLHttpRequest */

/* global File */

const UPLOAD_IMAGE_TO_GALLERY = gql`
mutation uploadImageToGallery( 
	$file_id: uuid!
	$filename: String!
	$tenant_id: String!
	$uploader_id: String!
	$product_id: bigint
	$hub_id: bigint
	$market_id: bigint
 ){
  insert_media_files( objects: { 
		file_id: $file_id,
		filename: $filename,
		tenant_id: $tenant_id,
		public: false,
		type: IMAGE,
		uploader_id: $uploader_id,
		product_id: $product_id,
		market_id: $market_id,
		hub_id: $hub_id,
	}) {
		affected_rows
	}
}`

const GET_UPLOAD_URL = gql`
mutation getUploadUrl( $tenantId: String!, $fileId: String! ) {
    Storage_createUploadURL( appId: "uhub", fileId: $fileId, tenantId: $tenantId ) {
        url
        fileId
    }
}`

const GET_IMAGES = gql`
query getImages( 
	$tenant_id: String!
	$product_id: bigint
	$hub_id: bigint
	$market_id: bigint
){
	media_files(
		where: {
			tenant_id: { _eq: $tenant_id }, 
			market_id: { _eq: $market_id }, 
			product_id: { _eq: $product_id }, 
			hub_id: { _eq: $hub_id }
		}
	) {
		id
		file_id
		galleries {
			id
			default
			files_id
		}
	}
}`

const INSERT_INTO_PROPER_GALLERY = gql`
mutation insert_into_gallery(
	$files_id: bigint! 
	$product_id: bigint
	$market_id: bigint
	$hub_id: bigint
){
	insert_media_gallery( objects: {
		files_id: $files_id,
		product_id: $product_id,
		market_id: $market_id,
		hub_id: $hub_id,
	} ) {
		returning {
			id
			product_id
			market_id
			hub_id
			default
		}
	}
}`

const REMOVE_FROM_GALLERY = gql`
mutation delete_from_gallery(
	$files_id: bigint! 
	$product_id: bigint
	$market_id: bigint
	$hub_id: bigint
){
	delete_media_gallery( where: {
		files_id: { _eq: $files_id },
		product_id: { _eq: $product_id },
		market_id: { _eq: $market_id },
		hub_id: { _eq: $hub_id },
	} ) {
	  affected_rows
	}
}`

const SET_PICTURE_AS_DEFAULT = gql`
mutation set_picture_as_default(
	$files_id: bigint! 
	$product_id: bigint
	$market_id: bigint
	$hub_id: bigint
	$default: Boolean!
) {
	update_media_gallery( 
		where: { 
			files_id: {_eq:  $files_id }, 
			product_id: { _eq: $product_id }
			market_id: { _eq: $market_id },
			hub_id: { _eq: $hub_id },
		}, 
		_set: { default: $default } 
	) {
		affected_rows
	}
}`

const DELETE_PICTURE_FROM_DATABASE = gql`
mutation deletePictureFromDatabase(
	$tenant_id: String!,
	$file_id: uuid!,
) {
	delete_media_files(
		where: {
			tenant_id: { _eq: $tenant_id },
			file_id: { _eq: $file_id },
			public: { _eq: false } 
		}
	) {
		affected_rows
	}
}`

const ImageContainer = lazy( () => import( './components/imageContainer' ) )

const ImageGallery = React.memo( ( {
	title,
	productId,
	hubId,
	marketId,
	itemsRefetch,
	galleryRefetch,
} ) => {
	const [ , closeGallery ] = useStateValue()
	const [ isPopPupOpened, openPopUp ] = useState( false )
	const [ file, setFile ] = useState( false )

	const tenantId = sessionStorage.getItem( 'uhub_tenantId' )
	const keycloakId = mainStore.user.sub

	const [ windowWidth, setWidth ] = useState( window.innerWidth > 1024 )

	const [ upImg, setUpImg ] = useState()
	const [ imgRef, setImgRef ] = useState( null )
	const [ crop, setCrop ] = useState( { unit: '%', width: 30, aspect: 1 / 1 } )
	const [ previewUrl, setPreviewUrl ] = useState()

	const [ cropedPicture, setCropedPicture ] = useState()


	const {
		data: getImagesData,
		loading: getImagesLoading,
		error: getImagesError,
	} = useQuery( GET_IMAGES, {
		variables: {
			tenant_id: tenantId,
			product_id: productId,
			hub_id: hubId,
			market_id: marketId,
		}
	} )

	const minioImages = getImagesData
		&& getImagesData.media_files.length > 0
		? getImagesData.media_files : []

	const [ getUploadUrl ] = useMutation( GET_UPLOAD_URL )

	const [ uploadImageToGallery ] = useMutation( UPLOAD_IMAGE_TO_GALLERY, {
		refetchQueries: () => [ {
			query: gql`${ GET_IMAGES }`,
			variables: {
				tenant_id: tenantId,
				product_id: productId,
				hub_id: hubId,
				market_id: marketId,
			}
		} ]
	} )

	const [ insertIntoProperGallery ] = useMutation( INSERT_INTO_PROPER_GALLERY, {
		refetchQueries: () => [ {
			query: gql`${ GET_IMAGES }`,
			variables: {
				tenant_id: tenantId,
				product_id: productId,
				hub_id: hubId,
				market_id: marketId,
			}
		} ]
	} )

	const [ insertIntoProperGalleryImageNoDefault ] = useMutation( INSERT_INTO_PROPER_GALLERY, {
		refetchQueries: () => [ {
			query: gql`${ GET_IMAGES }`,
			variables: {
				tenant_id: tenantId,
				product_id: productId,
				hub_id: hubId,
				market_id: marketId,
			}
		} ]
	} )

	const [ deleteFromGallery ] = useMutation( REMOVE_FROM_GALLERY, {
		refetchQueries: () => [ {
			query: gql`${ GET_IMAGES }`,
			variables: {
				tenant_id: tenantId,
				product_id: productId,

			}
		} ]
	} )

	const [ setPictureAsDefault ] = useMutation( SET_PICTURE_AS_DEFAULT, {
		refetchQueries: () => [ {
			query: gql`${ GET_IMAGES }`,
			variables: {
				tenant_id: tenantId,
				product_id: productId,
				hub_id: hubId,
				market_id: marketId,
			}
		} ]
	} )

	const [ deletePictureFromDatabase ] = useMutation( DELETE_PICTURE_FROM_DATABASE, {
		refetchQueries: () => [ {
			query: gql`${ GET_IMAGES }`,
			variables: {
				tenant_id: tenantId,
				product_id: productId,
				hub_id: hubId,
				market_id: marketId,
			}
		} ]
	} )

	useEffect( () => {
		const handleResize = () => {
			setWidth( window.innerWidth > 1024 )
		}
		window.addEventListener( 'resize', handleResize )
		return () => { window.removeEventListener( 'resize', handleResize ) }
	}, [] )

	/* Later method for adding pictures */

	function selectImage( picId ) {
		minioImages.forEach( ( pic ) => {
			if ( pic.file_id === picId ) {
				if ( pic.galleries.length > 0
					&& pic.galleries[ 0 ].files_id
				) {
					deleteFromGallery( {
						variables: {
							files_id: pic.id,
							product_id: productId,
							hub_id: hubId,
							market_id: marketId,
						}
					} ).then( ( res ) => {
						if ( res ) {
							itemsRefetch()
							galleryRefetch()
						}
					} ).catch( ( ) => {} )
				} else {
					insertIntoProperGallery( {
						variables: {
							files_id: pic.id,
							product_id: productId,
							hub_id: hubId,
							market_id: marketId,
						}
					} ).then( ( res ) => {
						if ( res ) {
							galleryRefetch()
						}
					} )
				}
			}
		} )
	}

	function removeFromDatabase( picId ) {
		minioImages.forEach( ( pic ) => {
			if ( pic.file_id === picId ) {
				if ( pic.galleries.length > 0
					&& pic.galleries[ 0 ].files_id
					&& pic.public !== true
				) {
					deleteFromGallery( {
						variables: {
							files_id: pic.id,
							product_id: productId,
							hub_id: hubId,
							market_id: marketId,
						}
					} ).then( ( res ) => {
						if ( res ) {
							itemsRefetch()
							galleryRefetch()
						}
					} ).catch( ( ) => {} )
				}
				deletePictureFromDatabase( {
					variables: {
						file_id: pic.file_id,
						tenant_id: tenantId,
					}
				} )
			}
		} )
	}

	function setPicAsDefault( picId ) {
		minioImages.forEach( ( pic ) => {
			if ( pic.galleries.length > 0
				&& pic.galleries[ 0 ].default
			) {
				setPictureAsDefault( {
					variables: {
						files_id: pic.id,
						product_id: productId,
						hub_id: hubId,
						market_id: marketId,
						default: false,
					}
				} ).then( ( res ) => {
					if ( res ) {
						itemsRefetch()
						galleryRefetch()
					}
				} )
			}
		} )

		minioImages.forEach( ( pic ) => {
			if ( pic.file_id === picId
				&& pic.galleries.length === 0
			) {
				insertIntoProperGalleryImageNoDefault( {
					variables: {
						files_id: pic.id,
						product_id: productId,
						hub_id: hubId,
						market_id: marketId,
					}
				} ).then(
					( res ) => {
						if ( res ) {
							setPictureAsDefault( {
								variables: {
									files_id: pic.id,
									product_id: res.data.insert_media_gallery.returning[ 0 ].product_id,
									hub_id: res.data.insert_media_gallery.returning[ 0 ].hub_id,
									market_id: res.data.insert_media_gallery.returning[ 0 ].market_id,
									default: true,
								}
							} )
							itemsRefetch()
							galleryRefetch()
						}
					}
				)
			}
			if ( pic.file_id === picId
				&& pic.galleries.length > 0
			) {
				setPictureAsDefault( {
					variables: {
						files_id: pic.id,
						product_id: productId,
						hub_id: hubId,
						default: true,
					}
				} ).then( ( res ) => {
					if ( res ) {
						itemsRefetch()
						galleryRefetch()
					}
				} )
			}
		} )
	}

	function closeGalleryWhenClickOutside( e ) {
		if ( e.target.id === 'imageGalleryOverlay' ) {
			closeGallery( { type: 'galleryOpened', galleryOpened: false } )
		}
	}

	function setClass( item, classDesktop, classMobile ) {
		if ( windowWidth ) {
			return classDesktop
		}

		if ( item.galleries.length > 0 && item.galleries[ 0 ].files_id === item.id ) {
			return classMobile
		}
		return classDesktop
	}

	// Methods in popUp

	function isFileImage( checkFile ) {
		if (
			checkFile.type === 'application/jpg'
            || checkFile.type === 'application/jpeg'
			|| checkFile.type === 'application/bmp'
			|| checkFile.type === 'application/png'
			|| checkFile.type === 'application/tiff'
			|| checkFile.type === 'application/gif'
		) return true
		if (
			checkFile.name.split( '.' ).pop() === 'jpg'
            || checkFile.name.split( '.' ).pop() === 'jpeg'
			|| checkFile.name.split( '.' ).pop() === 'bmp'
			|| checkFile.name.split( '.' ).pop() === 'png'
			|| checkFile.name.split( '.' ).pop() === 'tiff'
			|| checkFile.name.split( '.' ).pop() === 'gif'
		) return true
		return false
	}

	function handleUploadFileChange( e ) {
		const newFile = e.target.files.length > 0 ? e.target.files[ 0 ] : false
		if ( newFile && isFileImage( newFile ) ) {
			setFile( newFile )
			// eslint-disable-next-line no-undef
			const reader = new FileReader()
			reader.addEventListener( 'load', () => setUpImg( reader.result ) )
			reader.readAsDataURL( e.target.files[ 0 ] )
		} else {
			addNotification( 'ERROR', 'FILE_MUST_BE_IMAGE' )
		}
	}

	function uploadPictureToGallery() {
		if ( file && tenantId ) {
			getUploadUrl(
				{
					variables: {
						tenantId,
						fileId: 'false'
					}
				}
			).then( ( response ) => {
				const { url, fileId: resFileId } = response.data.Storage_createUploadURL
				const xhr = new XMLHttpRequest()
				xhr.open( 'PUT', url, true )
				xhr.send( cropedPicture )
				xhr.onload = () => {
					if ( xhr.status === 200 ) {
						uploadImageToGallery(
							{
								variables: {
									file_id: resFileId,
									filename: file.name,
									tenant_id: tenantId,
									uploader_id: keycloakId,
									product_id: productId,
									market_id: marketId,
									hub_id: hubId,
								}
							}
						)
						addNotification( 'OK', 'FILE_WAS_UPDATED' )
					} else {
						addNotification( 'ERROR', 'SOMETHING_WENT_WRONG_AT_FILE_UPLOAD' )
					}
				}
			} )
		}
	}

	function uploadPictureClosePopup() {
		uploadPictureToGallery()
		openPopUp( false )
		setUpImg()
	}

	const onLoad = useCallback( ( img ) => {
		setImgRef( img )
	}, [] )

	const createCropPreview = async ( image, crops, fileName ) => {
		const canvas = document.createElement( 'canvas' )
		const scaleX = image.naturalWidth / image.width
		const scaleY = image.naturalHeight / image.height
		canvas.width = crops.width
		canvas.height = crops.height
		const ctx = canvas.getContext( '2d' )

		ctx.drawImage(
			image,
			crops.x * scaleX,
			crops.y * scaleY,
			crops.width * scaleX,
			crops.height * scaleY,
			0,
			0,
			crops.width,
			crops.height
		)

		return new Promise( ( resolve, reject ) => {
			canvas.toBlob( ( blob ) => {
				if ( !blob ) {
					reject( new Error( 'Canvas is empty' ) )
					return
				}
				// eslint-disable-next-line no-param-reassign
				blob.name = fileName
				// eslint-disable-next-line no-param-reassign
				blob.lastModifiedDate = new Date()
				window.URL.revokeObjectURL( previewUrl )
				setPreviewUrl( window.URL.createObjectURL( blob ) )
				const newfile = new File( [ blob ], 'file.png', { type: 'image/png' } )
				setCropedPicture( newfile )
			}, 'image/jpeg', 1 )
		} )
	}

	const makeClientCrop = async ( cropi ) => {
		if ( imgRef && cropi.width && cropi.height ) {
			createCropPreview( imgRef, cropi, 'newFile.jpeg' )
		}
	}

	function closePopupDeleteImg() {
		openPopUp( false )
		setUpImg()
	}

	return (
		<div
			id='imageGalleryOverlay'
			className='image-gallery-overlay'
			role='button'
			tabIndex={ -1 }
			onKeyPress={ ( e ) => closeGalleryWhenClickOutside( e ) }
			onClick={ ( e ) => closeGalleryWhenClickOutside( e ) }
		>
			{ isPopPupOpened ? (
				<div className='popupSLA-main-container'>
					<div className='popup-window'>
						<p className='popup-window-title'><Trans id='PopupUploadSla.pleaseUploadPicture' /></p>
						<div className='popup-window-input-container'>
							<input id='input-btn' type='file' accept='image/*' onChange={ ( e ) => handleUploadFileChange( e ) } />
						</div>
						<ReactCrop
							src={ upImg }
							onImageLoaded={ onLoad }
							crop={ crop }
							onChange={ ( c ) => setCrop( c ) }
							onComplete={ makeClientCrop }
						/>
						{/* {previewUrl && <img alt='Crop preview' src={ previewUrl } />} */}
						<div className='popup-windows-close-btn'>
							<Button
								text={ <Trans id='PopupUploadGallery.upload' /> }
								disabled={ !!upImg }
								onClick={ () => uploadPictureClosePopup() }
							/>
							<Button
								text={ <Trans id='PopupUploadGallery.close' /> }
								disabled
								onClick={ () => closePopupDeleteImg() }
							/>
						</div>
					</div>
				</div>
			) : null }
			<div id='image-gallery-container'>
				<div className='image-gallery-title'>
					<div className='title-gallery'>
						<Trans id='ImageGallery.title' />
					</div>
					<div className='title-text'>
						{ title }
					</div>
				</div>
				<div className='images-scrollable'>
					<div
						key='upload'
						className='image-gallery-div-upload'
						role='button'
						label='open'
						tabIndex={ -1 }
						onKeyPress={ () => openPopUp( true ) }
						onClick={ () => openPopUp( true ) }
					/>
					{ minioImages.map( ( item ) => (
						<div
							className={ `image-gallery-div ${ item.test && 'image-gallery-div-selected' }` }
							key={ item.file_id }
							tabIndex={ item.file_id }
							role='button'
						>
							{ getImagesLoading ? <Loader /> : null }
							{ getImagesError ? <Trans id='ImageGallery.cannotGetPicturesForDisplay' /> : null }
							{ getImagesData
								? (
									<Suspense fallback={ <div><Loader /></div> }>
										<ImageContainer
											fileId={ item.file_id }
											tenantId={ tenantId }
											item={ item }
											setClass={ () => setClass() }
										/>
									</Suspense>
								) : null }
							<div
								id={ setClass( item, 'image-gallery-div-circle', 'image-gallery-div-circle-visible' ) }
								className={ item.galleries.length > 0 && item.galleries[ 0 ].files_id ? 'image-gallery-div-circle-selected' : null }
								role='button'
								label='selectImage'
								tabIndex={ -1 }
								onKeyPress={ () => {
									selectImage( item.file_id )
								} }
								onClick={ () => {
									selectImage( item.file_id )
								} }
							>
								<div
									id='image-gallery-div-circle-check'
									className={ item.galleries.length > 0 && item.galleries[ 0 ].files_id ? 'image-gallery-div-circle-check-selected' : null }
								/>
							</div>
							<div
								id='image-gallery-remove-btn'
								role='button'
								label='RemoveImage'
								tabIndex={ -1 }
								onKeyPress={ () => {
									removeFromDatabase( item.file_id )
								} }
								onClick={ () => {
									removeFromDatabase( item.file_id )
								} }
							/>
							<div
								id={ setClass( item, 'image-galery-default-tag', 'image-galery-default-tag-visible' ) }
								className={ item.galleries.length > 0 && item.galleries[ 0 ].default ? 'image-galery-default-tag-selected' : null }
								role='button'
								tabIndex={ -1 }
								onKeyPress={ () => {
									setPicAsDefault( item.file_id )
								} }
								onClick={ () => {
									setPicAsDefault( item.file_id )
								} }
							>
								<Trans id='ImageGallery.default' />
							</div>
						</div>
					) )}
				</div>
				<div className='save-button'>
					<Button
						text={ <Trans id='ImageGallery.buttonClose' /> }
						disabled
						onClick={ () => closeGallery( { type: 'galleryOpened', galleryOpened: false } ) }
					/>
				</div>
			</div>
		</div>
	)
} )

ImageGallery.defaultProps = {
	productId: null,
	hubId: null,
	marketId: null,
}

ImageGallery.propTypes = {
	title: propTypes.object.isRequired,
	productId: propTypes.number,
	hubId: propTypes.number,
	marketId: propTypes.number,
	itemsRefetch: propTypes.func.isRequired,
	galleryRefetch: propTypes.func.isRequired,
}

export default ImageGallery
