/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
import React from 'react'
import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'

import './MyPurchaseOrder.css'
import { dateToStringNoHoursMinutes } from '../../utils/DateToString'
import getPriceFormatter from '../../utils/PriceFormatter'
import store from '../../stores/mainStore'

const MyPurchaseOrder = React.memo( ( { order, current } ) => {
	const { language } = store

	function getOrderSatusText( status ) {
		switch ( status ) {
		case 'IN_PREPARATION':
			return <Trans id='myPurchaseOrder.inPreparation' />
		case 'EXPIRED':
			return <Trans id='myPurchaseOrder.expired' />
		case 'IN_PAYMENT_PROCESSING':
			return <Trans id='myPurchaseOrder.inPaymentProcessing' />
		case 'PAYED':
			return <Trans id='myPurchaseOrder.payed' />
		case 'ORDER_FULFILLMENT':
			return <Trans id='myPurchaseOrder.orderFulfillment' />
		case 'ORDER_READY':
			return <Trans id='myPurchaseOrder.ready' />
		case 'IN_DELIVERY':
			return <Trans id='myPurchaseOrder.inDelivery' />
		case 'DELIVERED_TO_CUSTOMER':
			return <Trans id='myPurchaseOrder.deliveredToCustomer' />
		case 'PAYMENT_FAILED':
			return <Trans id='myPurchaseOrder.PaymentFailed' />
		default:
			return 'state'
		}
	}

	/** Calculate price of ordered products */
	function totalPaymentWithTax( products ) {
		let total = 0
		products.forEach( ( product ) => total += product.bruto_price )
		// return getPriceFormatter( language ).format( total )
		return total
	}

	function parsingDate( dateString ) {
		let miliseconds = Date.parse( dateString )
		miliseconds += 259200000
		const newDate = new Date( miliseconds )
		const formattedNewDate = newDate.toISOString()
		return formattedNewDate
	}

	function displayProductNameTitle( orderedProduct ) {
		if ( orderedProduct.product.variety ) {
			return ` ${ orderedProduct.product.gs1_brick.translation_sl.charAt( 0 ).toUpperCase() + orderedProduct.product.gs1_brick.translation_sl.slice( 1 ) } - ${ orderedProduct.product.variety.name }`
		}
		return ` ${ orderedProduct.product.gs1_brick.translation_sl.charAt( 0 ).toUpperCase() + orderedProduct.product.gs1_brick.translation_sl.slice( 1 ) }`
	}

	return (
		<div id='my-purchase-order'>
			<div id='QR-image-background'>
				<img alt='qr-code' width='120' height='120' src={ `data:image/png;base64, ${ order.qr_code.base64_data }` } />
			</div>
			<div id='my-purchase-order-info'>
				<p className='order-blue-text' key={ 1 }>
					<Trans id='MyPurchaseOrder.orderNumber' />
					<span className='order-blue-bold-text'>{ `: ${ order.id }` }</span>
				</p>
				<p className='order-blue-text' key={ 2 }>
					<Trans id='MyPurchaseOrder.hub' />
					<span className='order-normal-text'>{ `: ${ order?.hub?.name }` }</span>
				</p>
				<p className='order-blue-text' key={ 3 }>
					<Trans id='MyPurchaseOrder.status' />
					<span className='order-normal-text'>{ getOrderSatusText( order?.orders_status_enum?.value ) }</span>
				</p>
				<p className='order-blue-text' key={ 4 }>
					<Trans id='MyPurchaseOrder.orderTime' />
					<span className='order-normal-text'>{ `: ${ dateToStringNoHoursMinutes( order?.created_at, store?.language ) }` }</span>
				</p>
				{ order.items.map( ( orderedProduct, key ) => (
					// eslint-disable-next-line react/no-array-index-key
					<p key={ key } className='ordered-products-text'>
						<span key={ 1 } className='ordered-item-margin-right'>{ `${ orderedProduct?.quantity }` }</span>
						<span key={ 2 } className='ordered-item-margin-right'>{ `${ orderedProduct?.unit?.unit }` }</span>
						<span key={ 3 } className='ordered-item-margin-right'>{ displayProductNameTitle( orderedProduct ) }</span>
						<span key={ 4 } className='ordered-item-margin-right'>{ `${ orderedProduct?.market?.name }` }</span>
						<span id='ordered-item-price'>{ `${ getPriceFormatter( language ).format( orderedProduct?.bruto_price ) }` }</span>
					</p>
				) ) }
				{
					!order?.customer_pickup ? (
						<p className='ordered-products-text'>
							<span key={ 1 } className='ordered-item-margin-right'>1</span>
							<span key={ 2 } className='ordered-item-margin-right'>x</span>
							<span key={ 3 } className='ordered-item-margin-right'><Trans id='Delivery' /></span>
							<span key={ 4 } className='ordered-item-margin-right' />
							<span id='ordered-item-price'>
								{
									`${ getPriceFormatter( language ).format(
										order?.total_bruto_price - totalPaymentWithTax( order?.items )
									) }`
								}
							</span>
						</p>
					) : null
				}
				<p className='ordered-products-text'>
					<span key={ 1 } className='ordered-item-margin-right'>1</span>
					<span key={ 2 } className='ordered-item-margin-right'>x</span>
					<span key={ 3 } className='ordered-item-margin-right'><Trans id='CommissionFeeMyOrders' /></span>
					<span key={ 4 } className='ordered-item-margin-right' />
					<span id='ordered-item-price'>
						{ `${ getPriceFormatter( language ).format( order?.commission_fee ) }` }
					</span>
				</p>
				<p id='line' />
				<p id='ordered-item-price'>{ `${ getPriceFormatter( language ).format( order?.total_bruto_price ) }` }</p>
			</div>
			<div id='my-purchase-order-delivery'>
				{/* }
				{ current ? null
					: (
						<button type='button' id='order-again-text'>
							<Trans id='MyPurchaseOrder.orderAgain' />
						</button>
					)}
					*/}
				<p className='my-purchase-order-delivery-text'>
					{ current
						? <Trans id='MyPurchaseOrder.scheduledDelivery' />
						: <Trans id='MyPurchaseOrder.deliveredDate' /> }
				</p>
				<p>
					{
						current
							? dateToStringNoHoursMinutes(
								parsingDate( order.hub.cycles.find( ( cycle ) => !cycle.finished ).closed_time ),
								store.language
							)
							: dateToStringNoHoursMinutes(
								parsingDate( order?.updated_at ),
								store.language
							)
					}
				</p>
			</div>
		</div>
	)
} )

MyPurchaseOrder.propTypes = {
	order: propTypes.object.isRequired,
	current: propTypes.bool.isRequired,
}

export default MyPurchaseOrder
