import React from 'react'
import { Trans } from '@lingui/macro'
import propTypes from 'prop-types'

import { withRouter } from 'react-router-dom'

import './RegistrationType.css'

const RegistrationType = ( { history } ) => (
	<div>
		<div id='header'>
			<div
				className='uhub-icon'
				role='button'
				tabIndex={ -1 }
				onKeyPress={ () => history.push( '/' ) }
				onClick={ () => history.push( '/' ) }
			>
				<p className='uhub-title'>Uhub</p>
			</div>
		</div>
		<div className='content-registrationtype'>
			<div className='registrationtype-container'>
				<p className='registration-title'>
					<Trans id='RegistrationType.Registration' />
				</p>
				<div className='box-container '>
					<div
						className='type-box'
						onKeyPress={ () => history.push( '/register/buyer/newUser' ) }
						onClick={ () => history.push( '/register/buyer/newUser' ) }
						tabIndex={ 0 }
						role='button'
					>
						<Trans id='RegistrationType.Buyer' />
					</div>
					<div
						className='type-box provider-box'
						onKeyPress={ () => history.push( '/register/provider/newUser' ) }
						onClick={ () => history.push( '/register/provider/newUser' ) }
						tabIndex={ 0 }
						role='button'
					>
						<Trans id='RegistrationType.Provider' />
					</div>
				</div>
			</div>
		</div>

	</div>
)

RegistrationType.propTypes = {
	history: propTypes.object.isRequired
}

export default withRouter( RegistrationType )
