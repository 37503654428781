import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import ReactPlaceholder from 'react-placeholder'
import StandRowPlaceholder from './components/StandRowPlaceholder'

import 'react-placeholder/lib/reactPlaceholder.css'

import './MyStands.css'
import StandRow from './components/StandRow'
import MarketView from '../MarketView'
import { ReturnSendIcon } from '../../../icons'
import Button from '../../_Globals/Button/Button'
import PopupChooseHub from './components/PopupChooseHub'
import Loader from '../../Loader'
import ConditionalWrapper from '../../_Globals/ConditionalWrapper'
import { addNotification } from '../../../utils/messageHandler/notifierStack'

/*	global sessionStorage */

const GET_MARKET_BY_TENANT_ID = gql`
query getMarketByTenantIdMyStandsIndex( $tenantId: String! ) {
    provider_market( 
		where: { tenant_id: { _eq: $tenantId } }, 
		order_by: { id: asc }
	) {
		id
        active
        confirmed
        description
        hub_id
        name
        sla_id
        hub {
			id
            name
        }
		galleries ( where: { default: { _eq: true} } ){
			id
			file {
				id
				file_id
				tenant_id
			}
    	}
    }
}`

const GET_PROVIDER_ID = gql`
query getProviderIdMyStands( $tenantId: String! ) {
    provider_provider( where: { tenant_id: { _eq: $tenantId } } ) {
        id
    }
}`

const UPDATE_MARKET_BY_ID = gql`
mutation updateMarketById( $marketId: bigint!, $changes: provider_market_set_input ) {
    update_provider_market( where: { id: { _eq: $marketId } }, _set: $changes ) {
        affected_rows
    }
}`

const MyStands = React.memo( () => {
	const [ popupAddStand, togglePopup ] = useState()
	const [ selectedMarket, setSelectedMarket ] = useState( false )
	const [ isEditable, toggleEditable ] = useState( false )
	const tenantId = sessionStorage.getItem( 'uhub_tenantId' )
	const {
		data, loading, error, refetch: marketRefetch
	} = useQuery( GET_MARKET_BY_TENANT_ID, {
		variables: { tenantId },
		fetchPolicy: 'no-cache'
	} )
	const providerData = useQuery( GET_PROVIDER_ID, {
		variables: { tenantId },
	} )
	const [ updateMarketById ] = useMutation( UPDATE_MARKET_BY_ID, {
		refetchQueries: () => [ {
			query: gql`${ GET_MARKET_BY_TENANT_ID }`,
			variables: { tenantId }
		} ]
	} )

	const providerId = providerData.data
		&& providerData.data.provider_provider
		&& providerData.data.provider_provider.length > 0
		? providerData.data.provider_provider[ 0 ].id : false
	const myMarkets = data && data.provider_market ? data.provider_market : []

	function getChangedText( changedText ) {
		const { id } = changedText
		const object = changedText
		delete object.id

		updateMarketById( { variables: { marketId: id, changes: object } } ).then(
			( res ) => {
				if ( res
                    && res.data
                    && res.data.update_provider_market
                    && res.data.update_provider_market.affected_rows > 0 ) {
					addNotification( 'OK', 'MARKET_UPDATED' )
				}
			}
		)
	}

	function updateMarketActiveStatus( active, marketId ) {
		const object = { active }
		updateMarketById( { variables: { marketId, changes: object } } ).then(
			( res ) => {
				if ( res
                    && res.data
                    && res.data.update_provider_market
                    && res.data.update_provider_market.affected_rows > 0 ) {
					addNotification( 'OK', 'MARKET_UPDATED' )
				}
			}
		)
	}

	if ( error || providerData.error ) {
		return <div>{ JSON.stringify( error ) }</div>
	}

	if ( loading || providerData.loading ) {
		return <Loader />
	}

	return (
		<ConditionalWrapper
			condition={ !!selectedMarket }
			falseConditionComponent={ (
				<div>
					{ popupAddStand
						? (
							<PopupChooseHub
								tenantId={ tenantId }
								providerId={ providerId }
								togglePopup={ togglePopup }
								myMarkets={ myMarkets }
								setSelectedMarket={ setSelectedMarket }
								toggleEditable={ toggleEditable }
							/>
						)
						: <div /> }
					<div className='button-add-stand'>
						<Button
							text={ <Trans id='MyStands.addStand' /> }
							disabled
							onClick={ () => togglePopup( true ) }
						/>
					</div>
					{
						myMarkets.map( ( market ) => (
							<div key={ market.id }>
								<ReactPlaceholder
									showLoadingAnimation
									ready={ !loading || !providerData.loading }
									customPlaceholder={ StandRowPlaceholder }
								>
									<StandRow
										market={ market }
										updateMarketActiveStatus={
											( status ) => updateMarketActiveStatus( status, market.id )
										}
										setSelectedMarket={ ( id ) => setSelectedMarket( id ) }
									/>
								</ReactPlaceholder>
							</div>
						) )

					}
				</div>
			) }
		>
			<div id='my-hubs-hub'>
				<div
					role='button'
					className='back-to-hubs'
					tabIndex={ -1 }
					onKeyPress={ () => { setSelectedMarket( false ); marketRefetch() } }
					onClick={ () => { setSelectedMarket( false ); marketRefetch() } }
				>
					<span><ReturnSendIcon fontSize={ 14 } /></span>
					<span className='text'><Trans id='ShoppingCart.backToMyStands' /></span>
				</div>
				{
					selectedMarket && (
						<MarketView
							marketId={ selectedMarket }
							editableFields={ isEditable }
							passText={ getChangedText }
							itemsRefetch={ () => marketRefetch() }
						/>
					)
				}
				<div id='my-hubs-actions-container'>
					<div />
					<div id='my-providers-actions'>
						<button
							type='button'
							className='hub-section-edit-button'
							onClick={ () => toggleEditable( !isEditable ) }
						>
							{ isEditable ? <Trans id='HubSection.btnSave' />
								: <Trans id='HubSection.btnEdit' /> }
						</button>
					</div>
				</div>
			</div>
		</ConditionalWrapper>
	)
} )

export default MyStands
