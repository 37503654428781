import React, { useState, useRef } from 'react'
import gql from 'graphql-tag'
import { useMutation, useQuery } from '@apollo/react-hooks'

import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'
import { withRouter } from 'react-router-dom'

import Input from '../../../../components/_Globals/Input/Input'
import Button from '../../../../components/_Globals/Button/Button'
import Loader from '../../../../components/Loader'
import HeaderRegistration from '../../../../components/HeaderRegistration'
import { addNotification } from '../../../../utils/messageHandler/notifierStack'

import {
	fieldInputsTranslation,
	fieldInputsErrors,
	regex,
	fieldInputs
} from '../../ConstantsVariables'

import {
	GET_POST_CODE_OFFICES,
	INSERT_PROVIDER
} from '../../RegistrationPageGQL'


import './RegistrationProviderNewUser.css'

const REGISTER_TENANT = gql`
    mutation registerTenant(
	   $userName: String!,
	   $userSurname: String!,
	   $email: String!,
	   $username: String!,
	   $taxId: String!,
	   $companyRegistrationNumber: String!,
	   $companyName: String!,
	   $IBAN: String!,
	   $BIC: String!,
	   $bankName: String!,
	   $address: String!,
	   $zip: String!,
	   $city: String!,
	   $country: String!,
	   $password: String!,
	   $confirmPassword: String!,
	   $phone: String!,
	   $mobile: String!,
    ) {
        Tenant_register( input: {
      		appId: "UHUB",
     		userData: {
				name: $userName,
				surname: $userSurname,
				email: $email,
				username: $username,
				password: $password,
				confirmPassword: $confirmPassword,
				type: "TENANT"
			},
			uhub: {
				identifiers: {
					taxId: $taxId,
					companyRegistrationNumber: $companyRegistrationNumber,
					companyName: $companyName
				},
				data: {
					bankAccount: {
						IBAN: $IBAN,
						BIC: $BIC,
						name: $bankName
					},
					address: [
						{
							address: $address,
							zip: $zip,
							city: $city,
							country: $country
						}
					],
					phone: $phone,
					mobile: $mobile
        		},
        		registrationType: "PROVIDER"
      		}
    	}
        ) {
            id
        }
    }
`

const RegistrationProviderNewUser = ( { history } ) => {
	const [ page, registrationPage ] = useState( 0 )
	const [ isValid, isInputsValid ] = useState( false )
	const [ name, setName ] = useState( '' )
	const [ surname, setSurname ] = useState( '' )
	const [ email, setEmail ] = useState( '' )
	const [ username, setUsername ] = useState( '' )
	const [ phoneNum, setPhoneNum ] = useState( '' )
	const [ mobileNum, setMobileNum ] = useState( '' )
	const [ taxId, setTaxId ] = useState( '' )
	const [ companyRegistrationNumber, setCompanyRegistrationNumber ] = useState( '' )
	const [ companyName, setCompanyName ] = useState( '' )
	const [ address, setAddress ] = useState( '' )
	const [ postcode, setPostcode ] = useState( '' )
	const [ postCode, setPostCodeData ] = useState( { data: undefined } )
	const [ city, setCity ] = useState( '' )
	const [ country, setCountry ] = useState( 'Slovenija' )
	const [ Iban, setIban ] = useState( '' )
	const [ Bic, setBic ] = useState( '' )
	const [ bankName, setBankName ] = useState( '' )
	const [ password, setPassword ] = useState( '' )
	const [ confirmPassword, setConfirmPassword ] = useState( '' )
	const [ registerNewUserData, setRegisterUserData ] = useState( null )

	const inputValue = useRef( '' )

	function resetStates() {
		setName( '' )
		setSurname( '' )
		setEmail( '' )
		setUsername( '' )
		setPhoneNum( '' )
		setMobileNum( '' )
		setTaxId( '' )
		setCompanyName( '' )
		setCompanyRegistrationNumber( '' )
		setAddress( '' )
		setPostcode( '' )
		setCity( '' )
		setIban( '' )
		setBic( '' )
		setBankName( '' )
		setPassword( '' )
		setConfirmPassword( '' )
		registrationPage( 0 )
	}

	const {
		data: postcodeData,
	} = useQuery( GET_POST_CODE_OFFICES )

	function getValidPostCodes( input ) {
		const validPostcodes = {}
		for ( let i = 0; i < postcodeData.CR_PostOffice.length; i += 1 ) {
			const splitInput = input.code.split( '' )
			const splitCode = postcodeData.CR_PostOffice[ i ].code.toString().split( '' )
			let valid = true
			for ( let j = 0; j < splitInput.length; j += 1 ) {
				if ( splitInput[ j ] !== splitCode[ j ] ) {
					valid = false
				}
			}
			if ( valid ) {
				// eslint-disable-next-line max-len
				validPostcodes[ postcodeData.CR_PostOffice[ i ].code ] = postcodeData.CR_PostOffice[ i ].name
			}
		}
		return validPostcodes
	}

	function postcodesByCode( input ) {
		const validPostcodes = getValidPostCodes( input )

		setPostCodeData( {
			postcodeData
		} )
		if ( Object.keys( validPostcodes ).length === 1 ) {
			setPostcode( Object.keys( validPostcodes )[ 0 ] )
			setCity( validPostcodes[ Number( Object.keys( validPostcodes )[ 0 ] ) ] )
		}
		if ( Object.keys( validPostcodes ).length > 1 ) {
			setCity( '' )
		}
	}

	const [ insertProvider ] = useMutation( INSERT_PROVIDER, {
		onCompleted: () => {
			resetStates()
			registrationPage( 1 )
		},
		onError: () => {
			registrationPage( 0 )
		}
	} )

	const [ registerTenant ] = useMutation( REGISTER_TENANT, {
		onCompleted: ( data ) => {
			setRegisterUserData( data )
			insertProvider( {
				variables: {
					tenant_id: data.Tenant_register.id
				}
			} )
		},
		onError: ( { graphQLErrors, networkError } ) => {
			if ( graphQLErrors ) {
				const newError = graphQLErrors[ 0 ]?.extensions?.code
				if ( newError === 'KEYCLOAK_PASSWORD_MISMATCH' ) {
					addNotification( 'ERROR', 'KEYCLOAK_PASSWORD_MISMATCH' )
				} else if ( newError === 'EMAIL_EXISTS' ) {
					addNotification( 'ERROR', 'EMAIL_EXISTS' )
				} else if ( newError === 'USER_EXISTS' ) {
					addNotification( 'ERROR', 'USER_EXISTS' )
				} else if ( newError === 'TENANT_KEYCLOAK_USER_EXISTS' ) {
					addNotification( 'ERROR', 'TENANT_KEYCLOAK_USER_EXISTS' )
				} else if ( newError === 'TENANT_EXISTS' ) {
					addNotification( 'ERROR', 'TENANT_EXISTS' )
				} else {
					(
						addNotification( 'ERROR', 'REGISTER_NEW_USER_FAILED' )
					)
				}
			}

			if ( networkError ) {
				addNotification( 'ERROR', 'REGISTER_NEW_USER_FAILED' )
			}
		},
		variables: {
			userName: name,
			userSurname: surname,
			email,
			username,
			phone: phoneNum,
			mobile: mobileNum,
			taxId,
			companyRegistrationNumber,
			companyName,
			IBAN: Iban,
			BIC: Bic,
			bankName,
			address,
			zip: postcode,
			city,
			country,
			password,
			confirmPassword
		}
	} )

	const fieldInputsValidation = [
		'name',
		'surname',
		'email',
		'username',
		'phoneNum',
		'mobileNum',
		'taxId',
		'companyRegistrationNumber',
		'companyName',
		'address',
		'bankName',
		'Iban',
		'Bic',
		'password',
		'confirmPassword',
	]

	function setValue( value, inputName ) {
		switch ( inputName ) {
		case 'name':
			setName( value )
			break
		case 'surname':
			setSurname( value )
			break
		case 'email':
			setEmail( value )
			break
		case 'username':
			setUsername( value )
			break
		case 'phoneNum':
			setPhoneNum( value )
			break
		case 'mobileNum':
			setMobileNum( value )
			break
		case 'taxId':
			setTaxId( value )
			break
		case 'companyRegistrationNumber':
			setCompanyRegistrationNumber( value )
			break
		case 'companyName':
			setCompanyName( value )
			break
		case 'address':
			setAddress( value )
			break
		case 'postcode':
			setPostcode( value )
			break
		case 'city':
			setCity( value )
			break
		case 'country':
			setCountry( value )
			break
		case 'Iban':
			setIban( value )
			break
		case 'Bic':
			setBic( value )
			break
		case 'bankName':
			setBankName( value )
			break
		case 'password':
			setPassword( value )
			break
		case 'confirmPassword':
			setConfirmPassword( value )
			break
		default:
			return 'something'
		}
		return 'something'
	}

	function getValue( inputName ) {
		switch ( inputName ) {
		case 'name':
			return name
		case 'surname':
			return surname
		case 'email':
			return email
		case 'username':
			return username
		case 'phoneNum':
			return phoneNum
		case 'mobileNum':
			return mobileNum
		case 'taxId':
			return taxId
		case 'companyRegistrationNumber':
			return companyRegistrationNumber
		case 'companyName':
			return companyName
		case 'address':
			return address
		case 'postcode':
			return postCode
		case 'city':
			return city
		case 'country':
			return country
		case 'Iban':
			return Iban
		case 'Bic':
			return Bic
		case 'bankName':
			return bankName
		case 'password':
			return ''
		case 'confirmPassword':
			return ''
		default:
			return ''
		}
	}

	const regexTest = {
		name: /^[A-Za-zČŠčšŽžĐđĆć-\s]+$/i,
		surname: /^[A-Za-zČŠčšŽžĐđĆć-\s]+$/i,
		// eslint-disable-next-line no-useless-escape
		email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		username: /^([a-z0-9])+$/,
		phoneNum: /^[+]*[0-9\-\s]+$/i,
		mobileNum: /^[+]*[0-9\-\s]+$/i,
		taxId: /^[0-9]{8,}$/,
		companyRegistrationNumber: /\d{10}/,
		companyName: /[A-Za-z0-9.\-\s,]+$/g,
		address: /^[0-9A-Za-zČŠčšŽžĐđĆć-\s,]+$/i,
		postcode: /^\d{4}$/g,
		city: /^[A-Za-z\s]+$/g,
		country: /^[A-Za-z\s]+$/g,
		// eslint-disable-next-line no-useless-escape
		Iban: /^[A-Z]{2}\d{2} ?\d{4} ?\d{4} ?\d{4} ?\ ?[\d]{0,3}$/,
		// eslint-disable-next-line no-useless-escape
		Bic: /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/,
		bankName: /[A-Za-z0-9.\-\s,.]+$/g,
		password: /^.{8,}$/,
		confirmPassword: /^.{8,}$/,
	}

	function validatePostcode() {
		if ( document.getElementById( 'city' ) !== null ) {
			if ( document.getElementById( 'city' ).value !== '' ) {
				return true
			}
		}
		return false
	}

	const initialValueInputStatuses = fieldInputsValidation.map( ( item ) => item === false )

	function getValidation( validate, inputName ) {
		const allTrue = ( currentValue ) => currentValue === true
		const trueValidate = regexTest[ inputName ].test( getValue( inputName ) )

		const postcodeValid = validatePostcode()
		if ( !postcodeValid && document.getElementById( 'postcode' ) !== null ) {
			document.getElementById( 'postcode' ).style.color = 'red'
		} else if ( document.getElementById( 'postcode' ) !== null ) {
			document.getElementById( 'postcode' ).style.color = 'black'
		}

		initialValueInputStatuses[ fieldInputsValidation.indexOf( inputName ) ] = trueValidate
		if ( document.getElementById( inputName ) !== null && trueValidate === false && inputName !== 'postcode' ) {
			document.getElementById( inputName ).className = 'input-box input-white-background-error'
		}
		if ( document.getElementById( inputName ) !== null && trueValidate === true && inputName !== 'postcode' ) {
			document.getElementById( inputName ).className = 'input-box input-white-background'
		}
		if ( getValue( inputName ) === '' ) {
			if ( document.getElementById( inputName ) !== null && trueValidate === false && inputName !== 'postcode' ) {
				document.getElementById( inputName ).className = 'input-box'
			}
		}
		if ( inputName === 'password' || inputName === 'confirmPassword' ) {
			initialValueInputStatuses[ fieldInputsValidation.indexOf( inputName ) ] = validate
			if ( document.getElementById( inputName ) !== null && validate === false ) {
				document.getElementById( inputName ).className = 'input-box input-white-background-error'
			}
			if ( document.getElementById( inputName ) !== null && validate === true ) {
				document.getElementById( inputName ).className = 'input-box input-white-background'
			}
		}
		if ( initialValueInputStatuses.every( allTrue ) && postcodeValid ) {
			isInputsValid( true )
		} else {
			isInputsValid( false )
		}
	}

	function registerNewTenant() {
		if (
			name === ''
		|| surname === ''
		|| email === ''
		|| username === ''
		|| phoneNum === ''
		|| mobileNum === ''
		|| taxId === ''
		|| companyRegistrationNumber === ''
		|| companyName === ''
		|| Iban === ''
		|| Bic === ''
		|| bankName === ''
		|| address === ''
		|| postcode === ''
		|| city === ''
		|| country === ''
		|| password === ''
		|| confirmPassword === '' ) {
			addNotification( 'ERROR', 'MISSING_INPUTS' )
		} else {
			registrationPage( 1 )
			registerTenant()
		}
	}

	function displayButton() {
		switch ( page ) {
		case 0:
			return (
				<div>
					<Button
						text={ <Trans id='Button.back' /> }
						disabled
						onClick={ () => history.push( '/register' ) }
					/>
					<Button
						text={ <Trans id='Button.register' /> }
						disabled={ isValid }
						onClick={ () => {
							registerNewTenant()
						} }
					/>
				</div>
			)
		case 1:
			return (
				<Button
					text={ <Trans id='Button.goToUHUB' /> }
					disabled
					onClick={ () => history.push( '/' ) }
				/>
			)
		default:
			return ''
		}
	}

	function placeholderStyle( input ) {
		if ( input ) { // if input is not undefined
			if ( input.length > 0 ) { // if input length > 0
				return 'input-box input-white-background-dropdown' // or this style
			}
		}
		return 'input-box-dropdown' // or this style
	}

	function placeholderText( inputName ) {
		if ( inputName === 'city' ) {
			return postcode !== '' ? <Trans id='RegistrationInput.city' /> : <Trans id='RegistrationInput.city' />
		}
		return country !== '' ? <Trans id='RegistrationInput.country' /> : <Trans id='RegistrationInput.country' />
	}

	function placeholderDisplay( inputName ) {
		if ( inputName === 'city' ) {
			return postcode !== '' ? 'placeholder-top-dropdown' : 'input-box-placeholder-dropdown'
		}
		return country !== '' ? 'placeholder-top-dropdown' : 'input-box-placeholder-dropdown'
	}

	function renderDropdownPostCodes() {
		if ( postcodeData.CR_PostOffice !== null ) {
			return (
				postcodeData.CR_PostOffice.map( ( post ) => (
					<option
						label={ `${ post.code } - ${ post.name }` }
						key={ post.code }
						value={ post.code }
						onClick={ () => postcodesByCode( { code: post.code } ) }
					/>
				) ) )
		}
		return null
	}

	function displayPageStep() {
		switch ( page ) {
		case 0:
			return (
				<div className='form'>
					{ fieldInputs.map( ( inputName ) => {
						if ( inputName === 'postcode' ) {
							return (
								<div
									key={ inputName }
									className='input auto-fill'
								>
									<div className={ postcode !== '' ? 'placeholder-top-dropdown' : 'input-box-placeholder-dropdown' }>
										<div className='placeholder-text-dropdown'>
											{ postcode !== '' ? <Trans id='RegistrationInput.postcode' /> : <Trans id='RegistrationInput.postcode' /> }
										</div>
									</div>
									<input
										ref={ inputValue }
										className={ placeholderStyle( postcode ) }
										maxLength='4'
										id='postcode'
										list='postCodeList'
										name='postCodeList'
										value={ postcode }
										onChange={ ( e ) => {
											setPostcode( e.currentTarget.value )
											postcodesByCode( { code: e.currentTarget.value } )
										} }
										onKeyUp={ ( e ) => {
											getValidation( e, inputName )
										} }
									/>
									<datalist id='postCodeList'>
										{ postcodeData !== undefined
											? renderDropdownPostCodes() : null }
									</datalist>
								</div>
							)
						}

						if ( inputName === 'city' ) {
							return (
								<div
									key={ inputName }
									className='input auto-fill'
								>
									<div className={ placeholderDisplay( inputName ) }>
										<div className='placeholder-text-dropdown'>
											{ placeholderText( inputName ) }
										</div>
									</div>
									<input
										id='city'
										ref={ inputValue }
										className={ placeholderStyle( postcode ) }
										value={ getValue( inputName ) }
										disabled
									/>
								</div>
							)
						}
						if ( inputName === 'country' ) {
							return (
								<div
									key={ inputName }
									className='input auto-fill'
								>
									<div className={ placeholderDisplay( inputName ) }>
										<div className='placeholder-text-dropdown'>
											{ placeholderText( inputName ) }
										</div>
									</div>
									<input
										ref={ inputValue }
										className={ placeholderStyle( postcode ) }
										value={ getValue( inputName ) }
										disabled
									/>
								</div>
							)
						}
						return (
							<Input
								key={ inputName }
								inputName={ fieldInputsTranslation[ inputName ] }
								id={ inputName }
								regex={ regex[ inputName ] }
								error={ fieldInputsErrors[ inputName ] }
								getValidation={ ( e ) => getValidation( e, inputName ) }
								placeholders={ fieldInputsTranslation[ inputName ] }
								setValue={ ( e ) => setValue( e, inputName ) }
								input={ getValue( inputName ) }
							/>
						)
					} ) }
				</div>
			)
		case 1:
			return (
				<div className='ok-message'>
					{ !registerNewUserData ? <Loader /> : null }
					{ registerNewUserData ? <Trans id='Registration.successful' /> : null }
				</div>
			)
		default:
			return ( <div className='ok-message'>Last Page</div> )
		}
	}

	return (
		<div>
			<HeaderRegistration />
			<div className='content-registration'>
				<div className='registration-container'>
					<div className='title'>
						<Trans id='Registration.titleProvider' />
					</div>
					<div className='provider-mode-registration'>
						<div
							className='button-registration-mode-active'
							role='button'
							tabIndex={ -1 }
							onKeyPress={ () => {
								resetStates()
								registrationPage( 0 )
							} }
							onClick={ () => {
								resetStates()
								registrationPage( 0 )
							} }
						>
							<Trans id='Button.newProvider' />
						</div>
						<div
							className='button-registration-mode-inactive'
							role='button'
							tabIndex={ -1 }
							onKeyPress={ () => history.push( '/register/provider/existingUser' ) }
							onClick={ () => history.push( '/register/provider/existingUser' ) }
						>
							<Trans id='Button.existingProvider' />
						</div>
					</div>
					<div className='step-content'>
						{ displayPageStep() }
					</div>
					<div className='buttons-container'>
						{ displayButton() }
					</div>
				</div>
			</div>
		</div>
	)
}

RegistrationProviderNewUser.propTypes = {
	history: propTypes.object.isRequired
}

export default withRouter( RegistrationProviderNewUser )
