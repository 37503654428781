import mainStore from '../stores/mainStore'

const dateToString = ( date, language = mainStore.language ) => {
	let langCode = 'sl-SI'
	if ( language === 'en' ) { langCode = 'en-US' }
	const options = {
		day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit'
	}
	return new Date( date ).toLocaleDateString( langCode, options )
}

const dateToStringNoHoursMinutes = ( date, language = mainStore.language ) => {
	let langCode = 'sl-SI'
	if ( language === 'en' ) { langCode = 'en-US' }
	const options = {
		day: '2-digit', month: '2-digit', year: '2-digit',
	}
	return new Date( date ).toLocaleDateString( langCode, options )
}

export {
	dateToString,
	dateToStringNoHoursMinutes
}
