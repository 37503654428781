/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import propTypes from 'prop-types'

import { useStateValue } from '../../stores/contextStore/stateStore'
import roleHandler from '../../utils/ContentRoleHandler'

const ProviderPrivateRoute = ( { component: Component, ...rest } ) => {
	const [ { isAuth } ] = useStateValue()

	return (
		<Route
			{ ...rest }
			// eslint-disable-next-line no-return-assign
			render={ ( props ) => ( roleHandler( isAuth, [ 'provider' ] ) ? (
				<Component { ...props } />
			) : (
				<Redirect to='/' />
			) ) }
		/>
	)
}

ProviderPrivateRoute.propTypes = {
	component: propTypes.any.isRequired
}

export default ProviderPrivateRoute
