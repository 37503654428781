import React, { useState, useEffect } from 'react'
import { Trans } from '@lingui/macro'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

import './ProvidersSectionPage.css'
import MarketView from '../../_Provider/MarketView'
import ProviderInfoRow from './components/ProviderInfoRow'
// import ProviderSearch from './components/ProvidersSearch'
import ConditionalWrapper from '../../_Globals/ConditionalWrapper'
import { ReturnSendIcon } from '../../../icons'
import Loader from '../../Loader'
import HubSLAView from './components/HubSLAView'

/*	global sessionStorage */

const GET_HUBID_BY_TENANTID = gql`
query getHubIdByTenantIdProviderSectionPage( $tenantId: String! ){
    hub_hub( where: { tenant_id: { _eq: $tenantId } } ) {
        id
        name
    }
}`

const GET_ALL_MARKETS = gql`
query getAllMarkets( $hubId: bigint! ){
    provider_market( where: { hub_id: { _eq: $hubId } } ) {
		id
		hub_id
		hub {
      		id
    	}
		galleries( where: { default: { _eq: true } } ) {
			id
			file {
				id
				tenant_id
				file_id
			}
		}
        active
        name
        confirmed
        description
        sla_id
		sla {
			id
      		file_id
    	}
    	tenant_id
		tenant {
			id
			applications {
				id
				data {
					bankAccount {
						IBAN
						BIC
						name
					}
					address {
						address
						zip
						city
						country
					}
				phone
				mobile
				}
			}
		}
  	}
}`

const ProvidersSectionPage = React.memo( () => {
	const [ selectedMarketId, setMarket ] = useState( false )
	const [ selectedHub, setHub ] = useState( null )
	// eslint-disable-next-line no-unused-vars
	const [ showSLA, setShowSLA ] = useState( false )
	const [ pdf, setPdf ] = useState( false )
	const [ windowWidth, setWidth ] = useState( window.innerWidth > 768 )
	const tenantId = sessionStorage.getItem( 'uhub_tenantId' )
	const {
		data: hubIdData,
		loading: hubIdLoading,
		error: hubIdError
	} = useQuery( GET_HUBID_BY_TENANTID, {
		variables: { tenantId }
	} )
	const hubInfo = hubIdData
        && hubIdData.hub_hub
        && hubIdData.hub_hub.length > 0 ? hubIdData.hub_hub[ 0 ] : false
	const {
		data: marketsData,
		loading: marketsLoading,
		error: marketsError
	} = useQuery( GET_ALL_MARKETS, {
		variables: { hubId: hubInfo.id },
		skip: !hubInfo.id,
		fetchPolicy: 'no-cache'
	} )
	const markets = marketsData && marketsData.provider_market ? marketsData.provider_market : []

	useEffect( () => {
		const handleResize = () => {
			setWidth( window.innerWidth > 768 )
		}
		window.addEventListener( 'resize', handleResize )
		return () => { window.removeEventListener( 'resize', handleResize ) }
	}, [] )

	function getProviders( confirmed ) {
		return markets.map( ( market ) => (
			market.confirmed === confirmed
				? (
					<ProviderInfoRow
						key={ market.id }
						market={ market }
						selectMarket={ ( id ) => setMarket( id ) }
						hub={ market.hub }
						hubId={ hubInfo.id }
						setHub={ setHub }
						showSLA={ setShowSLA }
						setPdf={ setPdf }
					/>
				) : false
		) )
	}

	if ( hubIdLoading || marketsLoading ) {
		return <Loader />
	}

	if ( hubIdError || marketsError ) {
		return (
			<div>
				<p>{ `hubIdError--> ${ JSON.stringify( hubIdError ) } ` }</p>
				<p>{ `marketsError--> ${ JSON.stringify( marketsError ) } ` }</p>
			</div>
		)
	}

	return (
		<ConditionalWrapper
			condition={ windowWidth }
			falseConditionComponent={ (
				<div className='warning'>
					<Trans id='CycleSectionPage.Warning' />
				</div>
			) }
		>
			<ConditionalWrapper
				condition={ !!selectedMarketId }
				falseConditionComponent={ (
					<div>
						{ !selectedHub
							? (
								<div>
									{ /* <ProviderSearch /> */ }
									<div className='other-providers'>
										<p>{ hubInfo.name }</p>
									</div>
									{ getProviders( true ) }
									<div className='other-providers'>
										<Trans id='ProvidersSectionPage.marketsToActivate:' />
									</div>
									{ getProviders( false ) }
								</div>
							)
							: <HubSLAView pdf={ pdf } setHub={ setHub } setPdf={ setPdf } /> }
					</div>
				) }
			>
				<div id='my-hubs-hub'>
					<div
						role='button'
						className='back-to-hubs'
						tabIndex={ -1 }
						onKeyPress={ () => setMarket( false ) }
						onClick={ () => setMarket( false ) }
					>
						<span><ReturnSendIcon fontSize={ 14 } /></span>
						<span className='text'><Trans id='ShoppingCart.backToMyProviders' /></span>
					</div>
					{ selectedMarketId && (
						<MarketView
							marketId={ selectedMarketId }
						/>
					)}
				</div>

			</ConditionalWrapper>
		</ConditionalWrapper>
	)
} )

export default ProvidersSectionPage
