import React from 'react'
import { Switch } from 'react-router-dom'

import ProviderPrivateRoute from './ProviderPrivateRoute'
import {
	MyHubs,
	MyStands,
	MyOffers,
	MyOrders
} from '../../components/_Provider'

const ProviderRoutes = () => (
	<Switch>
		<ProviderPrivateRoute exact path='/my-offers/my-hubs' component={ MyHubs } />
		<ProviderPrivateRoute exact path='/my-offers/my-orders' component={ MyOrders } />
		<ProviderPrivateRoute exact path='/my-offers/my-offer' component={ MyOffers } />
		<ProviderPrivateRoute exact path='/my-offers/my-stands' component={ MyStands } />
	</Switch>
)

export default ProviderRoutes
