import React, { useState } from 'react'
import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'
import { withRouter } from 'react-router-dom'

import './MyOffers.css'
import FooterBottom from '../components/Footer/components/FooterBottom'
import UniversalTabs from '../components/_Globals/UniversalTabs'
import ReturnSendIcon from '../icons/ReturnSendIcon'
import roleHandler from '../utils/ContentRoleHandler'
import ProviderRoutes from '../routes/ProviderRoutes/ProviderRoutes'
import { useStateValue } from '../stores/contextStore/stateStore'
import { myOfferTabs, myOfferTranslations } from './Tabs'


// TEST ONLY
// import PdfViewer from '../components/PdfViewer'

const MyOffers = React.memo( ( { history } ) => {
	const [ { isAuth } ] = useStateValue()
	const [ currentTab, setTab ] = useState( 0 )
	const [ pageTitle, setPageTitle ] = useState( <Trans id='MyOffers::titleMyHubs' /> )

	if ( !roleHandler( isAuth, [ 'provider' ] ) ) {
		return null
	}

	return (
		<div className='my-offer-container'>
			<div className='picture-banner-myOffer' />
			<div className='my-offer-title-container'>
				<div className='my-offer-title'>{ pageTitle }</div>
				<div
					role='button'
					className='back-to-store'
					tabIndex={ -1 }
					onKeyPress={ () => history.push( '/' ) }
					onClick={ () => history.push( '/' ) }
				>
					<span><ReturnSendIcon fontSize={ 14 } /></span>
					<span className='text'><Trans id='ShoppingCart.backToStore' /></span>
				</div>
			</div>
			<div className='universal-bar-my-offers'>
				<UniversalTabs
					isAuth={ isAuth }
					tabs={ myOfferTabs }
					currentTab={ currentTab }
					translations={ myOfferTranslations }
					action={ setTab }
					setPageTitle={ setPageTitle }
				/>
			</div>
			<div className='my-offer-page-content'>
				<ProviderRoutes />
			</div>
			<FooterBottom />
		</div>
	)
} )

MyOffers.propTypes = {
	history: propTypes.object.isRequired
}

export default withRouter( MyOffers )
