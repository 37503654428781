import React, { Suspense, lazy } from 'react'
import { Trans } from '@lingui/macro'
import propTypes from 'prop-types'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

// import HearthIcon from '../../icons/HeartIcon'
// import LocationIcon from '../../icons/LocationIcon'
// import MarketIcon from '../../icons/MarketIcon'

import store from '../../stores/mainStore'
import { useStateValue } from '../../stores/contextStore/stateStore'
import { dateToString } from '../../utils/DateToString'

import './HubComponent.css'

/* global sessionStorage */

const GET_DISTANCE = gql`
query GetDistance( $originTenant: String!, $tenant: String! ) {
    Util_GeoTenantDistanceToTenant( input: { originTenant: $originTenant, tenant: $tenant } )
}`

const Image = lazy( () => import( './components/image' ) )

const HubComponent = React.memo( ( { hubData, resetProvider } ) => {
	const [ { selectedHub, isAuth }, changeHub ] = useStateValue()
	const myTenantId = sessionStorage.getItem( 'uhub_tenantId' )
	const fileId = hubData.galleries.length > 0 ? hubData.galleries[ 0 ].file.file_id : null
	const tenantId = hubData.galleries.length > 0 ? hubData.galleries[ 0 ].file.tenant_id : null
	const hubTenant = hubData.tenant_id ? hubData.tenant_id : null
	let distance = 0

	if ( isAuth ) {
		const {
			data: distanceData,
			// loading: distanceLoading,
			// error: distanceError,
		// eslint-disable-next-line react-hooks/rules-of-hooks
		} = useQuery( GET_DISTANCE, {
			variables: {
				originTenant: hubTenant,
				tenant: myTenantId || '0'
			}
		} )

		distance = distanceData != null
        && distanceData.Util_GeoTenantDistanceToTenant != null
        && distanceData.Util_GeoTenantDistanceToTenant > 0
			? ( distanceData.Util_GeoTenantDistanceToTenant / 1000 ).toFixed( 1 ) : 0
	}

	function onLoginClick( e ) {
		e.preventDefault()
		window.location.href = store.keycloak.createLoginUrl()
	}

	function compareHubId() {
		if ( selectedHub !== null ) {
			if ( selectedHub.id === hubData.id ) {
				return 'hub-selected'
			}
		}
		return null
	}

	function selectHub() {
		resetProvider()
		changeHub( { type: 'changeSelectedHub', selectedHub: hubData } )
	}

	return (
		<div
			id='hub-component-holder'
			className={ compareHubId() }
			key={ hubData.id }
			role='button'
			tabIndex={ 0 }
			onClick={ () => {
				selectHub( hubData.id )
			} }
			onKeyPress={ () => selectHub() }
		>
			<div id='hub-component-hub-info'>
				<div className='hub-base-info'>
					<p className='hub-text'>
						<Trans id='HubComponentIndex.hub' />
					</p>
					<p className='hub-location-text'>
						{ hubData.name }
					</p>
				</div>
				<div className='hub-distance-container'>
					<p className='distance-text'>
						<Trans id='HubComponentIndex.Distance' />
					</p>
					<p className='distance-value-text'>
						{
							isAuth
								? <span>{`${ distance } km`}</span>
								: (
									<button
										type='button'
										className='hub-component-login'
										onClick={ ( e ) => onLoginClick( e ) }
									>
										<Trans id='HubComponentIndex.login' />
									</button>
								)
						}
					</p>
				</div>
			</div>
			<span className='description-text'>{ hubData.description }</span>
			{ hubData.galleries.length > 0 ? (
				<Suspense fallback={ <div style={ { width: 165, height: 145, backgroundColor: '#ececec' } } /> }>
					<Image
						tenantId={ tenantId }
						fileId={ fileId }
					/>
				</Suspense>
			) : (
				<Suspense fallback={ <div style={ { width: 165, height: 145, backgroundColor: '#ececec' } } /> }>
					<img
						style={ { width: 165, height: 145 } }
						src='/img/hub_default.png'
						alt=''
					/>
				</Suspense>

			) }
			<Trans id='HubComponent.CycleDate' />
			<p className='cycle-data'>
				{ hubData.cycles.length > 0
					? (
						<span>
							{ ` ${ dateToString( hubData.cycles[ 0 ] && hubData.cycles[ 0 ].start_time ) } 
                    - 
                    ${ dateToString( hubData.cycles[ 0 ] && hubData.cycles[ 0 ].closed_time ) }`}
						</span>
					) : <Trans id='HubComponent.noActiveCycles' /> }
			</p>
			{/* <div className='hub-component-bottom-icons'>
				<HearthIcon />
				<LocationIcon />
			</div> */}
		</div>
	)
} )

HubComponent.defaultProps = {
	hubData: null
}

HubComponent.propTypes = {
	hubData: propTypes.object,
	resetProvider: propTypes.func.isRequired
}

export default HubComponent
