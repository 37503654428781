import React from 'react'

import './ProfileBody.css'

const ProfileBody = React.memo( () => (
	<div className='side-menu-body'>
		{ /* <div>Links - TODO -</div>
		<div>Links - TODO -</div>
		<div>Links - TODO -</div>
		<div>Links - TODO -</div>
		<div>Links - TODO -</div>
		<div>Links - TODO -</div>
		<div>Links - TODO -</div>
		<div>Links - TODO -</div>
		<div>Links - TODO -</div>
		<div>Links - TODO -</div>
		<div>Links - TODO -</div> */ }
	</div>
) )

export default ProfileBody
