import gql from 'graphql-tag'

const INSERT_ITEM_TO_CART = gql`
mutation insertItemToCart(
	$hub_id: bigint!,
	$market_id: bigint!,
	$product_id: bigint!,
	$quantity: float8!,
	$unit_id: bigint!
	$status_id: Int!,
	$cart_uuid: uuid!,
	$customer_id: String,
) {
	insert_cart_items(
		objects: { 
			hub_id: $hub_id,
			market_id: $market_id,
			product_id: $product_id,
			quantity: $quantity,
			unit_id: $unit_id,
			status_id: $status_id,
			cart_uuid: $cart_uuid,
			customer_id: $customer_id
	}) {
    	returning {
      		id
			quantity
    	}
  	}
}`

const GET_UNITS_QUANTITIES = gql`
query get_units_quantities( $unit_id: Int ) {
	code_register_units_default_quantities( where: { unit_id: { _eq: $unit_id } }, order_by: { quantity: asc } ) {
		id
		quantity
	}
}`

export {
	GET_UNITS_QUANTITIES,
	INSERT_ITEM_TO_CART,
}
