/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react'
import { Trans } from '@lingui/macro'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import './CategoriesMenu.css'
import { AppsIcon } from '../../icons'
import {
	myOfferTabs,
	myOfferTranslations,
	hubPageTabs,
	hubPageTranslations
} from '../../pages/Tabs'
import store from '../../stores/mainStore'
import { useStateValue } from '../../stores/contextStore/stateStore'
import ConditionalWrapper from '../_Globals/ConditionalWrapper'
import roleHandler from '../../utils/ContentRoleHandler'
import DropdownContent from './components/DropdownContent'
import CategoriesMenuMobile from './components/CategoriesMenuMobile'

const CategoriesMenu = React.memo( ( { isAuthenticated, history } ) => {
	const [ { galleryOpened }, closeGallery ] = useStateValue()
	const [ windowWidth, setWidth ] = useState( window.innerWidth > 768 )

	useEffect( () => {
		const handleResize = () => {
			setWidth( window.innerWidth > 768 )
		}
		window.addEventListener( 'resize', handleResize )
		return () => { window.removeEventListener( 'resize', handleResize ) }
	}, [] )
	function closeGalleryWhenClickOutside( e ) {
		if ( e.target.id === 'categoriesMenuOverlay' ) {
			closeGallery( { type: 'galleryOpened', galleryOpened: false } )
		}
	}

	return (
		<ConditionalWrapper
			condition={ windowWidth }
			falseConditionComponent={ (
				<CategoriesMenuMobile
					isAuthenticated={ isAuthenticated }
					mobileDisplay
				/>
			) }
		>
			<div id='categories-menu-container'>
				{ galleryOpened
					? (
						<div
							id='categoriesMenuOverlay'
							className='categories-menu-overlay'
							onKeyPress={ ( e ) => closeGalleryWhenClickOutside( e ) }
							onClick={ ( e ) => closeGalleryWhenClickOutside( e ) }
						/>
					) : null }
				<div id='appsIcon'>
					<AppsIcon fontSize={ 31 } />
				</div>
				<ConditionalWrapper condition={ isAuthenticated } falseConditionComponent={ <div /> }>
					<div id='category-dropdown'>
						<button
							className='btn-category'
							type='button'
							onClick={ () => history.push( '/my-purchases' ) }
						>
							<Trans id='CategoryMenu.myPurchases' />
						</button>
					</div>
				</ConditionalWrapper>
				<ConditionalWrapper
					condition={ roleHandler( isAuthenticated, [ 'provider' ] ) }
					falseConditionComponent={ <div /> }
				>
					<div id='category-dropdown'>
						<button
							className='btn-category'
							type='button'
							onClick={ () => history.push( '/my-offers/my-hubs' ) }
						>
							<Trans id='CategoryMenu.myOffers' />
						</button>
						<DropdownContent
							isAuth={ isAuthenticated }
							tabs={ myOfferTabs }
							translations={ myOfferTranslations }
						/>
					</div>
				</ConditionalWrapper>
				<div />
				<div />
				<div />
				<ConditionalWrapper
					condition={ roleHandler( isAuthenticated, [ 'owner', 'employee' ] ) }
					falseConditionComponent={ <div /> }
				>
					<div id='category-dropdown'>
						<button
							className='btn-category'
							type='button'
							onClick={ () => history.push( '/hub/hub' ) }
						>
							<Trans id='CategoryMenu.hub' />
						</button>
						<DropdownContent
							isAuth={ isAuthenticated }
							tabs={ hubPageTabs }
							translations={ hubPageTranslations }
						/>
					</div>

				</ConditionalWrapper>
				<ConditionalWrapper
					condition={ isAuthenticated }
					falseConditionComponent={ (
						<div
							className='login-register-container'
							role='button'
							tabIndex={ -1 }
							onKeyPress={ () => history.push( '/register' ) }
							onClick={ () => history.push( '/register' ) }
						>
							<Trans id='CategoryMenu.login/register' />
						</div>
					) }
				>
					<div className='authenticated-container'>{ `${ store.user.name }` }</div>
				</ConditionalWrapper>
			</div>
		</ConditionalWrapper>
	)
} )

CategoriesMenu.propTypes = {
	isAuthenticated: propTypes.bool.isRequired,
	history: propTypes.object.isRequired
}

export default withRouter( CategoriesMenu )
