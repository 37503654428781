import gql from 'graphql-tag'

const REGISTER_TENANT_ON_EXISTING_COMPANY = gql`
    mutation registerTenantOnExistingCompany(
	   $email: String!,
	   $IBAN: String!,
	   $BIC: String!,
	   $bankName: String!,
	   $address: String!,
	   $zip: String!,
	   $city: String!,
	   $country: String!,
    ) {
        Tenant_register( input: {
      		appId: "UHUB",
     		userData: {
				email: $email,
			},
			uhub: {
				data: {
					bankAccount: {
						IBAN: $IBAN,
						BIC: $BIC,
						name: $bankName
					},
					address: [
						{
							address: $address,
							zip: $zip,
							city: $city,
							country: $country
						}
					]
        		},
        		registrationType: "PROVIDER"
      		},
			existing: true
    	}
        ) {
            id
        }
    }
`

const REGISTER_TENANT_ON_NEW_COMPANY = gql`
    mutation registerTenantOnNewCompany(
	   $email: String!,
	   $IBAN: String!,
	   $BIC: String!,
	   $bankName: String!,
	   $address: String!,
	   $zip: String!,
	   $city: String!,
	   $country: String!,
	   $taxId: String!,
	   $companyRegistrationNumber: String!,
	   $companyName: String!,
	   $phone: String!,
	   $mobile: String!,
    ) {
        Tenant_register( input: {
      		appId: "UHUB",
     		userData: {
				email: $email,
			},
			uhub: {
				identifiers: {
					taxId: $taxId,
					companyRegistrationNumber: $companyRegistrationNumber,
					companyName: $companyName
				},
				data: {
					bankAccount: {
						IBAN: $IBAN,
						BIC: $BIC,
						name: $bankName
					},
					address: [
						{
							address: $address,
							zip: $zip,
							city: $city,
							country: $country
						}
					],
					phone: $phone,
					mobile: $mobile
        		},
        		registrationType: "PROVIDER"
      		},
			existing: true
    	}
        ) {
            id
        }
    }
`

const REGISTER_TENANT_BUYER = gql`
    mutation registerTenantBuyer(
	   $userName: String!,
	   $userSurname: String!,
	   $email: String!,
	   $username: String!,
	   $taxId: String,
	   $companyRegistrationNumber: String,
	   $companyName: String,
	   $IBAN: String!,
	   $BIC: String!,
	   $bankName: String!,
	   $address: String!,
	   $zip: String!,
	   $city: String!,
	   $country: String!,
	   $password: String!,
	   $confirmPassword: String!,
	   $phone: String!,
	   $mobile: String!,
    ) {
        Tenant_register( input: {
      		appId: "UHUB",
     		userData: {
				name: $userName,
				surname: $userSurname,
				email: $email,
				username: $username,
				password: $password,
				confirmPassword: $confirmPassword,
				type: "USER"
			},
			uhub: {
				identifiers: {
					taxId: $taxId,
					companyRegistrationNumber: $companyRegistrationNumber,
					companyName: $companyName
				},
				data: {
					bankAccount: {
						IBAN: $IBAN,
						BIC: $BIC,
						name: $bankName
					},
					address: [
						{
							address: $address,
							zip: $zip,
							city: $city,
							country: $country
						}
					],
					phone: $phone,
					mobile: $mobile
        		},
        		registrationType: "BUYER"
      		}
    	}
        ) {
            id
        }
    }
`

const REGISTER_TENANT_BUYER_NO_LEGAL_PERSON = gql`
    mutation registerTenantNoLegalPerson(
	   $userName: String!,
	   $userSurname: String!,
	   $email: String!,
	   $username: String!,
	   $address: String!,
	   $zip: String!,
	   $city: String!,
	   $country: String!,
	   $password: String!,
	   $confirmPassword: String!,
	   $phone: String!,
	   $mobile: String!,
    ) {
        Tenant_register( input: {
      		appId: "UHUB",
     		userData: {
				name: $userName,
				surname: $userSurname,
				email: $email,
				username: $username,
				password: $password,
				confirmPassword: $confirmPassword,
				type: "USER"
			},
			uhub: {
				data: {
					address: [
						{
							address: $address,
							zip: $zip,
							city: $city,
							country: $country
						}
					],
					phone: $phone,
					mobile: $mobile
        		},
        		registrationType: "BUYER"
      		}
    	}
        ) {
            id
        }
    }
`

const GET_USER_TENANTS = gql`
	query tenants(
		$email: String!,
	) {
		tenants ( input: {
			email: $email
		}) {
			id
			owner {
				keycloakId
				email
				firstName
				lastName
			},
			identifiers {
				taxId
				companyRegistrationNumber
				companyName
			}
			applications {
				id
				data {
					bankAccount {
						IBAN
						BIC
						name
					}
					address {
						address
						zip
						city
						country
					}
					phone
					mobile
				}
			}
		}
	}
`
const GET_POST_CODE_OFFICES = gql`
	query {
  		CR_PostOffice {
    		code
    		name
  		}
	}
`

const INSERT_PROVIDER = gql`
	mutation insertProvider( $tenant_id: String! ) {
		insert_provider_provider( objects: { tenant_id: $tenant_id } ) {
    		affected_rows
  		}
	}
`
export {
	REGISTER_TENANT_ON_EXISTING_COMPANY,
	REGISTER_TENANT_ON_NEW_COMPANY,
	REGISTER_TENANT_BUYER,
	GET_USER_TENANTS,
	GET_POST_CODE_OFFICES,
	INSERT_PROVIDER,
	REGISTER_TENANT_BUYER_NO_LEGAL_PERSON
}
