import gql from 'graphql-tag'

// For checking if user already rate a provider
const GET_USER_RATING = gql`
query getUserRating( 
		$provider_id: bigint!,
		$customer_id: String!,
) {
	provider_marks( where: { provider_id: { _eq: $provider_id }, customer_id: { _eq: $customer_id } } ) {
		id
    	mark
  	}
}`

const NEW_MARK = gql`
mutation newMark( 
	$provider_id: bigint!,
	$mark: Int!,
	$customer_id: String!,
) {
	insert_provider_marks(
		objects: {
			provider_id: $provider_id,
			mark: $mark,
			customer_id: $customer_id
		}
	) {
		affected_rows
	}
}`

const UPDATE_MARK = gql`
mutation updateMark( 
	$provider_id: bigint!,
	$mark: Int!,
	$customer_id: String!,
) {
	update_provider_marks(
		where: {
			provider_id: { _eq: $provider_id },
			customer_id: { _eq: $customer_id },
		},
		_set: { mark: $mark }
	) {
		affected_rows
	}
}`

const GET_AVERAGE_MARK = gql`
query getAverageMark( $provider_id: bigint! ){
	provider_marks_aggregate(where: { provider_id: { _eq: $provider_id } } ) {
    	aggregate {
      		avg {
        		mark
      		}
    	}
  	}
}`

const UPDATE_PROVIDER_MARK = gql`
mutation updateProviderMark(
	$id: bigint!,
	$average_mark: float8!
) {
	update_provider_provider(
		where: { id: { _eq: $id } }, 
		_set: { average_mark: $average_mark } ) {
    	affected_rows
  	}
}`


// for cheking is order is completed.
// User can rate provider only if an order is delivered to customer

const GET_ORDER = gql`
query getOrderForStarComponent(
    $customer_id: String,
) {
    cart_orders(
        where: { 
            customer_id: { _eq: $customer_id }, status_id: { _eq: 8 }
        }
    ) {
        id
    }
}`

export {
	GET_USER_RATING,
	NEW_MARK,
	UPDATE_MARK,
	GET_AVERAGE_MARK,
	UPDATE_PROVIDER_MARK,
	GET_ORDER
}
