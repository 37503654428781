import React from 'react'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import { Trans } from '@lingui/macro'
import store from '../../../stores/mainStore'

import Button from '../../_Globals/Button/Button'
import './ShoppingCartSignIn.css'

/* global sessionStorage localStorage */

const ShoppingCartSignIn = (
	{
		isAuthenticated,
		history,
		clickBack
	}
) => {
	function signIn() {
		//  If user is not authenticated, go to Keycloak login page

		if ( !isAuthenticated ) {
			window.location.href = store.keycloak.createLoginUrl()
			sessionStorage.setItem( 'loggingCartButton', true )
		}
	}

	function singUp() {
		//  For keeping Cart items
		localStorage.setItem( 'temp-cart-id', sessionStorage.getItem( 'uuid' ) )

		//  Push to register user
		history.push( '/register/buyer/newUser' )
	}
	return (
		<div className='shopping-cart-login-main-container'>
			<div className='already-registered-title'><Trans id='ShoppingCart.imAlreadyRegistered' /></div>
			<div className='already-registered-form'>
				<div
					role='button'
					className='already-registered-login-signIn'
					tabIndex={ -1 }
					onKeyPress={ () => signIn() }
					onClick={ () => signIn() }
				>
					<Trans id='ShoppingCart.signIn' />
				</div>
			</div>

			<div className='first-time-shopping-title'><Trans id='ShoppingCart.buyingForTheFirstTime' /></div>
			<div className='first-time-shopping-form'>
				<div
					role='button'
					className='first-time-shopping-register'
					tabIndex={ -1 }
					onKeyPress={ () => singUp() }
					onClick={ () => singUp() }
				>
					<Trans id='ShoppingCart.registerNow' />
				</div>
			</div>

			<div className='button-next-step'>
				<Button
					text={ <Trans id='Button.back' /> }
					disabled={ !false }
					onClick={ () => clickBack() }
				/>
			</div>

			<div className='shopping-pros-registration-title'><Trans id='ShoppingCart.advantagesOfRegistration' /></div>
			<div className='shopping-pros-registration' />
		</div>
	)
}

ShoppingCartSignIn.propTypes = {
	isAuthenticated: propTypes.bool.isRequired,
	history: propTypes.object.isRequired,
	clickBack: propTypes.func.isRequired
}

export default withRouter( ShoppingCartSignIn )
