import React, { useState } from 'react'
import { Trans } from '@lingui/macro'

import './TableHubPackagesHeader.css'
import { SliderArrowIcon } from '../../../../icons'

const TableHubPackagesHeader = React.memo( () => {
	const [ packetagcode, sortByPacketAgCode ] = useState( false )
	const [ packetcode, sortByPacketCode ] = useState( false )
	const [ quantity, sortByQuantity ] = useState( false )
	const [ warelocagpack, sortByWareLocAgPack ] = useState( false )
	const [ warelocpack, sortByWareLocPack ] = useState( false )
	const [ driver, sortByDriver ] = useState( false )
	const [ supplier, sortBySupplier ] = useState( false )
	const [ buyer, sortByBuyer ] = useState( false )
	const [ status, sortByStatus ] = useState( false )
	const [ packer, sortByPacker ] = useState( false )
	const [ date, sortByDate ] = useState( false )

	function packetagcodeFunct() {
		if ( packetagcode ) {
			sortByPacketAgCode( false )
		} else {
			sortByPacketAgCode( true )
		}
		sortByPacketCode( false )
		sortByQuantity( false )
		sortByWareLocAgPack( false )
		sortByWareLocPack( false )
		sortByDriver( false )
		sortBySupplier( false )
		sortByBuyer( false )
		sortByStatus( false )
		sortByPacker( false )
		sortByDate( false )
	}

	function packetcodeFunct() {
		if ( packetcode ) {
			sortByPacketCode( false )
		} else {
			sortByPacketCode( true )
		}
		sortByPacketAgCode( false )
		sortByQuantity( false )
		sortByWareLocAgPack( false )
		sortByWareLocPack( false )
		sortByDriver( false )
		sortBySupplier( false )
		sortByBuyer( false )
		sortByStatus( false )
		sortByPacker( false )
		sortByDate( false )
	}

	function quantityFunct() {
		if ( quantity ) {
			sortByQuantity( false )
		} else {
			sortByQuantity( true )
		}
		sortByPacketAgCode( false )
		sortByPacketCode( false )
		sortByWareLocAgPack( false )
		sortByWareLocPack( false )
		sortByDriver( false )
		sortBySupplier( false )
		sortByBuyer( false )
		sortByStatus( false )
		sortByPacker( false )
		sortByDate( false )
	}

	function warelocagpackFunct() {
		if ( warelocagpack ) {
			sortByWareLocAgPack( false )
		} else {
			sortByWareLocAgPack( true )
		}
		sortByPacketAgCode( false )
		sortByPacketCode( false )
		sortByQuantity( false )
		sortByWareLocPack( false )
		sortByDriver( false )
		sortBySupplier( false )
		sortByBuyer( false )
		sortByStatus( false )
		sortByPacker( false )
		sortByDate( false )
	}

	function warelocpackFunct() {
		if ( warelocpack ) {
			sortByWareLocPack( false )
		} else {
			sortByWareLocPack( true )
		}
		sortByPacketAgCode( false )
		sortByPacketCode( false )
		sortByQuantity( false )
		sortByWareLocAgPack( false )
		sortByDriver( false )
		sortBySupplier( false )
		sortByBuyer( false )
		sortByStatus( false )
		sortByPacker( false )
		sortByDate( false )
	}

	function driverFunct() {
		if ( driver ) {
			sortByDriver( false )
		} else {
			sortByDriver( true )
		}
		sortByPacketAgCode( false )
		sortByPacketCode( false )
		sortByQuantity( false )
		sortByWareLocAgPack( false )
		sortByWareLocPack( false )
		sortBySupplier( false )
		sortByBuyer( false )
		sortByStatus( false )
		sortByPacker( false )
		sortByDate( false )
	}

	function supplierFunct() {
		if ( supplier ) {
			sortBySupplier( false )
		} else {
			sortBySupplier( true )
		}
		sortByPacketAgCode( false )
		sortByPacketCode( false )
		sortByQuantity( false )
		sortByWareLocAgPack( false )
		sortByWareLocPack( false )
		sortByDriver( false )
		sortByBuyer( false )
		sortByStatus( false )
		sortByPacker( false )
		sortByDate( false )
	}

	function buyerFunct() {
		if ( buyer ) {
			sortByBuyer( false )
		} else {
			sortByBuyer( true )
		}
		sortByPacketAgCode( false )
		sortByPacketCode( false )
		sortByQuantity( false )
		sortByWareLocAgPack( false )
		sortByWareLocPack( false )
		sortByDriver( false )
		sortBySupplier( false )
		sortByStatus( false )
		sortByPacker( false )
		sortByDate( false )
	}

	function statusFunct() {
		if ( status ) {
			sortByStatus( false )
		} else {
			sortByStatus( true )
		}
		sortByPacketAgCode( false )
		sortByPacketCode( false )
		sortByQuantity( false )
		sortByWareLocAgPack( false )
		sortByWareLocPack( false )
		sortByDriver( false )
		sortByBuyer( false )
		sortBySupplier( false )
		sortByPacker( false )
		sortByDate( false )
	}

	function packerFunct() {
		if ( packer ) {
			sortByPacker( false )
		} else {
			sortByPacker( true )
		}
		sortByPacketAgCode( false )
		sortByPacketCode( false )
		sortByQuantity( false )
		sortByWareLocAgPack( false )
		sortByWareLocPack( false )
		sortByDriver( false )
		sortByBuyer( false )
		sortBySupplier( false )
		sortByStatus( false )
		sortByDate( false )
	}

	function dateFunct() {
		if ( date ) {
			sortByDate( false )
		} else {
			sortByDate( true )
		}
		sortByPacketAgCode( false )
		sortByPacketCode( false )
		sortByQuantity( false )
		sortByWareLocAgPack( false )
		sortByWareLocPack( false )
		sortByDriver( false )
		sortByBuyer( false )
		sortBySupplier( false )
		sortByStatus( false )
		sortByPacker( false )
	}

	return (
		<div className='hub-packages-header'>
			<div
				className='gridPackages-item-header'
				role='button'
				tabIndex={ -1 }
				onClick={ () => packetagcodeFunct() }
				onKeyPress={ () => packetagcodeFunct() }
			>
				<span><Trans id='HubPackages.PacketAgCode' /></span>
				<span className={ packetagcode ? 'sort-arrow-up' : 'sort-arrow-down' }>
					<SliderArrowIcon fontSize={ 12 } />
				</span>
			</div>
			<div
				className='gridPackages-item-header'
				role='button'
				tabIndex={ -1 }
				onClick={ () => packetcodeFunct() }
				onKeyPress={ () => packetcodeFunct() }
			>
				<span><Trans id='HubPackages.PacketCode' /></span>
				<span className={ packetcode ? 'sort-arrow-up' : 'sort-arrow-down' }>
					<SliderArrowIcon fontSize={ 12 } />
				</span>
			</div>
			<div
				className='gridPackages-item-header'
				role='button'
				tabIndex={ -1 }
				onClick={ () => quantityFunct() }
				onKeyPress={ () => quantityFunct() }
			>
				<span><Trans id='HubPackages.Quantity' /></span>
				<span className={ quantity ? 'sort-arrow-up' : 'sort-arrow-down' }>
					<SliderArrowIcon fontSize={ 12 } />
				</span>
			</div>
			<div
				className='gridPackages-item-header'
				role='button'
				tabIndex={ -1 }
				onClick={ () => warelocagpackFunct() }
				onKeyPress={ () => warelocagpackFunct() }
			>
				<span><Trans id='HubPackages.WarehouseLocationAgPacket' /></span>
				<span className={ warelocagpack ? 'sort-arrow-up' : 'sort-arrow-down' }>
					<SliderArrowIcon fontSize={ 12 } />
				</span>
			</div>
			<div
				className='gridPackages-item-header'
				role='button'
				tabIndex={ -1 }
				onClick={ () => warelocpackFunct() }
				onKeyPress={ () => warelocpackFunct() }
			>
				<span><Trans id='HubPackages.WarehouseLocationPacket' /></span>
				<span className={ warelocpack ? 'sort-arrow-up' : 'sort-arrow-down' }>
					<SliderArrowIcon fontSize={ 12 } />
				</span>
			</div>
			<div
				className='gridPackages-item-header'
				role='button'
				tabIndex={ -1 }
				onClick={ () => driverFunct() }
				onKeyPress={ () => driverFunct() }
			>
				<span><Trans id='HubPackages.Driver' /></span>
				<span className={ driver ? 'sort-arrow-up' : 'sort-arrow-down' }>
					<SliderArrowIcon fontSize={ 12 } />
				</span>
			</div>
			<div
				className='gridPackages-item-header'
				role='button'
				tabIndex={ -1 }
				onClick={ () => supplierFunct() }
				onKeyPress={ () => supplierFunct() }
			>
				<span><Trans id='HubPackages.Supplier' /></span>
				<span className={ supplier ? 'sort-arrow-up' : 'sort-arrow-down' }>
					<SliderArrowIcon fontSize={ 12 } />
				</span>
			</div>
			<div
				className='gridPackages-item-header'
				role='button'
				tabIndex={ -1 }
				onClick={ () => buyerFunct() }
				onKeyPress={ () => buyerFunct() }
			>
				<span><Trans id='HubPackages.Buyer' /></span>
				<span className={ buyer ? 'sort-arrow-up' : 'sort-arrow-down' }>
					<SliderArrowIcon fontSize={ 12 } />
				</span>
			</div>
			<div
				className='gridPackages-item-header'
				role='button'
				tabIndex={ -1 }
				onClick={ () => statusFunct() }
				onKeyPress={ () => statusFunct() }
			>
				<span><Trans id='HubPackages.Status' /></span>
				<span className={ status ? 'sort-arrow-up' : 'sort-arrow-down' }>
					<SliderArrowIcon fontSize={ 12 } />
				</span>
			</div>
			<div
				className='gridPackages-item-header'
				role='button'
				tabIndex={ -1 }
				onClick={ () => packerFunct() }
				onKeyPress={ () => packerFunct() }
			>
				<span><Trans id='HubPackages.Packer' /></span>
				<span className={ packer ? 'sort-arrow-up' : 'sort-arrow-down' }>
					<SliderArrowIcon fontSize={ 12 } />
				</span>
			</div>
			<div
				className='gridPackages-item-header'
				role='button'
				tabIndex={ -1 }
				onClick={ () => dateFunct() }
				onKeyPress={ () => dateFunct() }
			>
				<span><Trans id='HubPackages.Date' /></span>
				<span className={ date ? 'sort-arrow-up' : 'sort-arrow-down' }>
					<SliderArrowIcon fontSize={ 12 } />
				</span>
			</div>
		</div>

	)
} )

export default TableHubPackagesHeader
