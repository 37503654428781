import React, { useState, useEffect } from 'react'
import { Trans } from '@lingui/macro'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'
import UploadSLAMarketPopup from './components/UploadSLAMarketPopup'

import './MyHubs.css'
import ConditionalWrapper from '../../_Globals/ConditionalWrapper'
import HubRow from './components/HubRow'
import HubView from '../../_Hub/HubView'
import { ReturnSendIcon } from '../../../icons'
import Loader from '../../Loader'
import Button from '../../_Globals/Button/Button'
import HubSLAView from './components/HubSLAView'
import { addNotification } from '../../../utils/messageHandler/notifierStack'

// import { file } from '@babel/types'

/*	global sessionStorage */

const GET_ALL_HUBS = gql`
query get_all_hubs {
	hub_hub {
		id
		name
		description
		tenant_id
		sla {
			id
			file_id
		}
		sla_id
	}
}
`

const GET_MARKET_BY_TENANT_ID = gql`
query getMarketByTenantIdMyHubs( $tenantId: String! ) {
    provider_market( where: { tenant_id: { _eq: $tenantId } } ) {
		id
		confirmed
		hub_id
		sla_id
		sla_status
		sla {
			id
			file_id
		}
    }
}`

const GET_SAVED_SLA = gql`
mutation createDownloadURL( $appId: String!, $fileId: String!, $tenantId: String! ) {
	Storage_createDownloadURL( appId: $appId, fileId: $fileId, tenantId: $tenantId ) {
		url
	}
}
`


const MyHubs = React.memo( () => {
	const [ windowWidth, setWidth ] = useState( window.innerWidth > 768 )
	const [ selectedHub, setHub ] = useState( null )
	const [ showSLA, setShowSLA ] = useState( false )

	const [ uploadSlaMenu, toggleSlaMenu ] = useState( false )
	const [ pdf, setPdf ] = useState( {} )
	const [ pdfUrl, setPdfUrl ] = useState( false )
	let [ downloadFile ] = useState( false )

	const { data: allHubsData, error: hubError, loading: hubLoading } = useQuery( GET_ALL_HUBS, { fetchPolicy: 'no-cache' } )
	const tenantId = sessionStorage.getItem( 'uhub_tenantId' )

	const [ savedSlaUrl ] = useMutation( GET_SAVED_SLA )
	const {
		data: marketResponseData,
		loading: marketResponseLoading,
		error: marketResponseError
	} = useQuery( GET_MARKET_BY_TENANT_ID, { variables: { tenantId }, fetchPolicy: 'no-cache' } )

	const marketData = marketResponseData
		&& marketResponseData.provider_market ? marketResponseData.provider_market : []

	function getArrayIndexOfSelectedHUB( markets, hub ) {
		let market = []

		const hubId = ( hub ) ? hub.id : null

		if ( !hubId ) {
			return market
		}

		const data = ( markets && markets.provider_market ) ? markets.provider_market : []

		for ( let i = 0; i < data.length; i += 1 ) {
			if ( data[ i ].hub_id === hubId ) {
				market = data[ i ]
				break
			}
		}

		return market
	}

	const hubData = getArrayIndexOfSelectedHUB( marketResponseData, selectedHub )

	const slaId = hubData
		&& hubData.sla
		? hubData.sla_id
		: false

	const fileId = hubData
		&& hubData.sla
		? hubData.sla.file_id
		: false

	const allHubs = allHubsData && allHubsData.hub_hub ? allHubsData.hub_hub : []

	if ( selectedHub ) {
		downloadFile = selectedHub.sla !== null ? selectedHub.sla.file_id : fileId
	}

	useEffect( () => {
		const handleResize = () => {
			setWidth( window.innerWidth > 768 )
		}

		if ( downloadFile ) {
			savedSlaUrl( {
				variables: {
					appId: 'uhub',
					fileId: `${ downloadFile }`,
					tenantId: selectedHub !== null ? selectedHub.tenant_id : ''
				}
			} ).then( ( response ) => {
				const { url } = response.data.Storage_createDownloadURL
				setPdfUrl( url )

				// window.pdfjsLib.getDocument( url ).then( ( pdfRes ) => {
				// 	setPdf( pdfRes )
				// } )
			} ).catch( ( ) => {
				addNotification( 'ERROR', 'CURRENTLY_UNAVAILABLE' )
			} )
		}

		if ( fileId ) {
			savedSlaUrl( {
				variables: {
					appId: 'uhub',
					fileId: `${ fileId }`,
					tenantId
				}
			} ).then( ( response ) => {
				const { url } = response.data.Storage_createDownloadURL
				// setPdfUrl( url )

				window.pdfjsLib.getDocument( url ).then( ( pdfRes ) => {
					setPdf( pdfRes )
				} )
			} ).catch( ( ) => {
				addNotification( 'ERROR', 'CURRENTLY_UNAVAILABLE' )
			} )
		}

		window.addEventListener( 'resize', handleResize )
		return () => { window.removeEventListener( 'resize', handleResize ) }
	}, [ downloadFile, fileId, savedSlaUrl, selectedHub, setWidth, tenantId ] )

	if ( hubLoading || marketResponseLoading ) { return <Loader /> }
	if ( hubError ) { return <div>{JSON.stringify( hubError )}</div> }
	if ( marketResponseError ) { return <div>{JSON.stringify( marketResponseError )}</div> }


	function checkHubs( input ) {
		return allHubs.map( ( hub ) => {
			const index = marketData.findIndex( ( market ) => market.hub_id === hub.id )
			// hub without market
			if ( index === -1 && input === null ) {
				return (
					<HubRow
						key={ hub.id }
						hub={ hub }
						setHub={ setHub }
						showSLA={ setShowSLA }
						pending={ false }
						slaExist={ false }
						marketExist={ false }
					/>
				)
			}
			if ( input === null
				&& marketData[ index ]
				&& !marketData[ index ].sla_id
			) {
				return (
					// Other hubs, no showSLA button
					<HubRow
						key={ hub.id }
						hub={ hub }
						setHub={ setHub }
						showSLA={ setShowSLA }
						pending={ false }
						slaExist={ false }
						marketExist
					/>
				)
			}
			if ( input
				&& marketData[ index ]
				&& marketData[ index ].sla_id
				&& marketData[ index ].confirmed ) {
				return (
					<HubRow
						key={ hub.id }
						hub={ hub }
						setHub={ setHub }
						showSLA={ setShowSLA }
						pending={ false }
						slaExist
						marketExist
					/>
				)
			}
			// Pending hubs
			if ( input === false
				&& marketData[ index ]
				&& marketData[ index ].sla_id
				&& !marketData[ index ].confirmed ) {
				return (
					<HubRow
						key={ hub.id }
						hub={ hub }
						setHub={ setHub }
						showSLA={ setShowSLA }
						pending
						slaExist
						marketExist
					/>
				)
			}
			return null
		} )
	}

	function resetHubAndSla() {
		setPdf( {} )
		setHub( null )
	}

	return (
		<>
			{selectedHub === null ? (
				<>
					<div>
						{checkHubs( true )}
					</div>
					<div key='signed-dots' id='dots-row' />
					<span
						role='button'
						className='back-to-hubs'
						tabIndex={ -1 }
						onKeyPress={ () => checkHubs( null ) }
						onClick={ () => checkHubs( null ) }
					>
						<Trans key='pending-hubs' id='MyHubs.pendingHubs' />

					</span>
					<div>
						{checkHubs( false )}
					</div>
					<div key='pending-dots' id='dots-row' />
					<span className='my-hubs-text'><Trans key='other-hubs' id='MyHubs.otherHubs' /></span>
					<div>
						{checkHubs( null )}
					</div>
					<div key='other-dots' id='dots-row' />
				</>
			)
				: (
					<ConditionalWrapper
						condition={ windowWidth }
						falseConditionComponent={ (
							<div className='warning'>
								<Trans id='CycleSectionPage.Warning' />
							</div>
						) }
					>
						{showSLA === false
							? (
								<div id='my-hubs-hub'>
									<div
										role='button'
										className='back-to-hubs'
										tabIndex={ -1 }
										onKeyPress={ () => resetHubAndSla() }
										onClick={ () => resetHubAndSla() }
									>
										<span><ReturnSendIcon fontSize={ 14 } /></span>
										<span className='text'><Trans id='ShoppingCart.backToMyHubs' /></span>
									</div>
									<HubView tenantId={ selectedHub.tenant_id } />
									<div id='my-hubs-actions-container'>
										<div />
										<div id='my-hubs-actions-sla'>
											<span className='my-hub-actions-title'><Trans id='MyHubs.SLA' /></span>
											<div className='my-hub-actions-sla'>
												<span className='my-hub-actions-sla-status'><Trans id='MyHubs.status:' /></span>
												<span className='my-hub-actions-sla-template'><Trans id='MyHubs.SLATemplate' /></span>
											</div>
										</div>
									</div>
									{uploadSlaMenu
										? (
											<UploadSLAMarketPopup
												fileId={ fileId }
												slaId={ slaId }
												toggleSlaMenu={ toggleSlaMenu }
												selectedHub={ selectedHub }
											/>
										)
										: null}
									<div className='SLA-selection-buttons'>
										<div className='SLA-buttons-container'>
											<Button
												text={ <Trans id='SLASectionPage.uploadSLA' /> }
												disabled
												onClick={ () => toggleSlaMenu( true ) }
											/>
											{
												pdfUrl
													? (
														<Button
															className='gumb-sla'
															text={ <Trans id='SLASectionPage.downloadSLA' /> }
															disabled
															onClick={ () => window.location.assign( pdfUrl ) }
														/>
													)
													: null
											}
										</div>
									</div>
								</div>
							) : ( <HubSLAView pdf={ pdf } resetHubAndSla={ resetHubAndSla } /> )}
					</ConditionalWrapper>
				)}
		</>
	)
} )

export default MyHubs
