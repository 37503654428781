import React from 'react'
import propTypes from 'prop-types'
import { Trans } from '@lingui/react'
import Helmet from 'react-helmet'
import DayPicker, { DateUtils } from 'react-day-picker'
import MomentLocaleUtils from 'react-day-picker/moment'

import mainStore from '../../../../../stores/mainStore'
import Button from '../../../../_Globals/Button/Button'
import Navbar from './calendarNavBar'
import 'moment/locale/sl'
import 'react-day-picker/lib/style.css'
import './calendar.css'

class FilterOrderByDate extends React.Component {
	constructor( props ) {
		super( props )

		this.handleDayClick = this.handleDayClick.bind( this )
		this.handleDayMouseEnter = this.handleDayMouseEnter.bind( this )
		this.handleResetClick = this.handleResetClick.bind( this )
		this.state = this.getInitialState()
	}

	getInitialState() {
		return {
			from: null,
			to: null,
			enteredTo: null, // Keep track of the last day for mouseEnter.
		}
	}

	// eslint-disable-next-line class-methods-use-this
	isSelectingFirstDay( from, to, day ) {
		const isBeforeFirstDay = from && DateUtils.isDayBefore( day, from )
		const isRangeSelected = from && to
		return !from || isBeforeFirstDay || isRangeSelected
	}

	handleDayClick( day ) {
		const { from, to } = this.state
		if ( from && to && day >= from && day <= to ) {
			this.handleResetClick()
			return
		}
		if ( this.isSelectingFirstDay( from, to, day ) ) {
			this.setState( {
				from: day,
				to: null,
				enteredTo: null,
			} )
		} else {
			this.setState( {
				to: day,
				enteredTo: day,
			} )
		}
	}

	handleDayMouseEnter( day ) {
		const { from, to } = this.state
		if ( !this.isSelectingFirstDay( from, to, day ) ) {
			this.setState( {
				enteredTo: day,
			} )
		}
	}

	handleResetClick() {
		this.setState( this.getInitialState() )
	}

	render() {
		const { language } = mainStore
		const { visible } = this.props
		const { from, enteredTo } = this.state
		const modifiers = { start: from, end: enteredTo }
		const disabledDays = { before: from }
		const selectedDays = [ from, { from, to: enteredTo } ]

		return (
			<div className={ visible ? 'visible' : 'hidden' }>
				<div className='filter-date-container'>
					<div
						className='filter-date-arrow'
						style={
							{
								position: 'absolute',
								top: -5,
								left: 20,
								width: 15,
								height: 15,
								backgroundColor: 'white',
								borderTop: '1px solid var( --deafult-dark-grey-color )',
								borderLeft: '1px solid var( --deafult-dark-grey-color )',
								borderRight: 0,
								borderBottom: 0,
								zIndex: -2,
								textAlign: 'center',
								transform: 'rotate(45deg)'
							}
						}
					/>
					<div>
						<div>
							<DayPicker
								className='Range'
								firstDayOfWeek={1}
								numberOfMonths={1}
								fromMonth={from}
								selectedDays={selectedDays}
								disabledDays={disabledDays}
								modifiers={modifiers}
								onDayClick={this.handleDayClick}
								onDayMouseEnter={this.handleDayMouseEnter}
								navbarElement={<Navbar />}
								localeUtils={MomentLocaleUtils}
								locale={ language }
							/>
							<Helmet>
								<style>
									{`
    .Range .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
        background-color: #51A0FA30 !important;
        color: #black;
    }
    .Range .DayPicker-Day {
    border-bottom: 1px solid white;
        border-radius: 0 !important;
    }

    .Range .DayPicker-Day--start:not(.DayPicker-Day--outside) {
        background-color: #2e64b8 !important;
        color: #white !important;
    }

    .Range .DayPicker-Day--end:not(.DayPicker-Day--outside) {
        background-color: #2e64b8 !important;
        color: #black !important;
    }
    `}
								</style>
							</Helmet>
						</div>
					</div>
					<div
						className='DayPicker-button'
						style={
							{
								position: 'absolute',
								marginTop: -20,
								marginLeft: 10,
								marginRight: 10,
								width: 180,
								lineHeight: '8px',
							}
						}
					>
						<Button
							text={ <Trans id='DayPickerInput.buttonCancel' /> }
							disabled
							onClick={ () => {} }
						/>
					</div>
				</div>
			</div>
		)
	}
}

FilterOrderByDate.propTypes = {
	visible: propTypes.bool.isRequired,
}

export default FilterOrderByDate
