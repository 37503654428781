import React, { useState, useRef } from 'react'
import propTypes from 'prop-types'
import './Input.css'

const Input = ( {
	inputName,
	id, regex,
	error,
	placeholders,
	getValidation,
	setValue,
	input,
	disabled,
} ) => {
	const [ placeholder, togglePlaceholder ] = useState( '' )
	const [ isInputValid, checkInput ] = useState( '' )
	const [ placeholderText, setPlaceholder ] = useState( '' )
	const inputValue = useRef( '' )

	function regexValidations( value ) {
		if ( value !== '' ) {
			checkInput( regex.test( value ) )
			if ( !isInputValid ) {
				setPlaceholder( error )
			}
		} else {
			checkInput( false )
			if ( !isInputValid ) {
				setPlaceholder( error )
			}
		}
	}

	function placeholderStyle() {
		if ( inputValue.current.value ) { // if input is not undefined
			if ( inputValue.current.value.length > 0 ) { // if input length > 0
				if ( !isInputValid ) { // and input is incorrect, that is invalid
					return 'input-box input-white-background-error' // then use this style
				}
				return 'input-box input-white-background' // or this style
			}
		}
		return 'input-box' // or this style
	}

	getValidation( isInputValid, inputName )

	function setPlaceholders( value ) {
		if ( value && value !== '' ) { // if input is not undefined
			togglePlaceholder( true )
		}
		if ( value === '' ) { // if input is not undefined
			togglePlaceholder( false )
		}
	}

	function onChange() {
		regexValidations( inputValue.current.value )
		setPlaceholders( inputValue.current.value )
		setValue( inputValue.current.value, inputName )
	}

	return (
		<div className='input'>
			<div className={ placeholder || input ? 'placeholder-top' : 'input-box-placeholder' }>
				<div className={ placeholder || isInputValid === '' ? 'placeholder-text' : 'error' }>
					{ placeholder || isInputValid === '' ? placeholders : placeholderText }
				</div>
			</div>
			{ input
				? (
					<input
						ref={ inputValue }
						type={ id === 'password' || id === 'confirmPassword' ? 'password' : 'text' }
						id={ id }
						className='input-box input-white-background'
						name={ id }
						onChange={ () => onChange() }
						value={ input }
						disabled={ disabled }
					/>
				) : (
					<input
						ref={ inputValue }
						type={ id === 'password' || id === 'confirmPassword' ? 'password' : 'text' }
						id={ id }
						className={ placeholderStyle() }
						name={ id }
						onChange={ () => onChange() }
						disabled={ disabled }
					/>
				)}
		</div>
	)
}

Input.defaultProps = {
	getValidation: () => {},
	setValue: () => {},
	input: '',
	disabled: false,
}

Input.propTypes = {
	inputName: propTypes.object.isRequired,
	id: propTypes.string.isRequired,
	regex: propTypes.object.isRequired,
	error: propTypes.object.isRequired,
	placeholders: propTypes.object.isRequired,
	getValidation: propTypes.func,
	setValue: propTypes.func,
	input: propTypes.string,
	disabled: propTypes.bool,
}

export default Input
