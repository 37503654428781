import React from 'react'
import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'

import './HeaderActionIcons.css'
import Tooltip from 'react-tooltip-lite'
import ShoppingCardButton from './ShoppingCardButton'
// import NotificationButton from './NotificationButton'
import { ProfileIcon } from '../../../icons'
import { useStateValue } from '../../../stores/contextStore/stateStore'
import store from '../../../stores/mainStore'


const HeaderActionIcons = ( { isAuthenticated } ) => {
	const [ { profileMenu }, openProfileMenu ] = useStateValue()
	function handleOpenMenu() {
		//  If user is not authenticated, go to Keycloak login page
		if ( !isAuthenticated ) {
			window.location.href = store.keycloak.createLoginUrl()
			//  If user is authenticated, and profile is closed, then open it
		} else if ( !profileMenu ) {
			openProfileMenu( { type: 'toggleProfilMenu' } )
		}
	}

	return (
		<div className='logged-in-container'>
			<div />
			<div />
			<div>
				<Tooltip
					id='tooltipShoppingCart'
					arrowSize={ 5 }
					padding='5px'
					content={ <Trans id='Tooltip.basket' /> }
					direction='bottom'
					distance={ 10 }
				>
					<ShoppingCardButton />
				</Tooltip>
			</div>
			{/* <div>
				<Tooltip
					id='tooltipNotifications'
					arrowSize={ 5 }
					padding='5px'
					content={ <Trans id='Tooltip.notifications' /> }
					direction='bottom'
					distance={ 10 }
				>
					<NotificationButton numberOfNotifications={ isAuthenticated ? 10 : 0 } />
				</Tooltip>
			</div> */ }
			<div
				className='header-profile-icon'
				role='button'
				tabIndex={ 0 }
				onKeyPress={ () => handleOpenMenu() }
				onClick={ () => handleOpenMenu() }
			>
				<Tooltip
					id='tooltipProfile'
					arrowSize={ 5 }
					padding='5px'
					content={ isAuthenticated ? <Trans id='Tooltip.profile' /> : <Trans id='Tooltip.login' /> }
					direction='bottom'
					distance={ 10 }
				>
					<ProfileIcon fontSize={ 32 } color='white' />
				</Tooltip>
			</div>
			<div />
		</div>

	)
}

HeaderActionIcons.propTypes = {
	isAuthenticated: propTypes.bool.isRequired
}

export default HeaderActionIcons
