/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react'
import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'
import { useQuery, useSubscription } from '@apollo/react-hooks'
import Swiper from 'react-id-swiper'
import { Navigation } from 'swiper/dist/js/swiper.esm'
import ReactPlaceholder from 'react-placeholder'
import ProductComponent from '../ProductComponent'
import ProductComponentPlaceholder from '../ProductComponent/components/ProductComponentPlaceholder'
import 'react-placeholder/lib/reactPlaceholder.css'

import './SliderRow.css'
import './SliderDefault.css'
import Loader from '../Loader'

import {
	GET_ALL_ACTIVE_PRODUCTS_BY_HUB,
	GET_ALL_ACTIVE_PRODUCTS_BY_HUB_SUBSCRIPTION,
	GET_ALL_DEFAULT_QUANTITIES
} from './SliderRowHubGQL'

const SliderRowHub = ( {
	isHubMenuClosed,
	hubId,
	hubName,
	marketFilters,
	gs1Filters,
} ) => {
	const [ swiper, updateSwiper ] = React.useState( null )

	const [ productsDisplay, getNewProductsToDisplay ] = React.useState( [] )
	const [ beginning, isBeginning ] = React.useState( !( swiper && swiper.isBeginning === false ) )
	const [ end, isEnd ] = React.useState( !( swiper && swiper.isEnd === false ) )

	const [ sliderWidthValue, getSliderWidth ] = React.useState( '100%' )
	const [ itemWidthValue, getItemWidth ] = React.useState( '100%' )

	const [ paramsValues, getParams ] = React.useState( {
		spaceBetween: 10,
		noSwiping: true,
		threshold: 10000,
		observer: true,
		loop: false,
		shouldSwiperUpdate: true,
		slidesPerView: 3
	} )


	const {
		data: productsByHubData,
		loading: productsByHubLoading,
		error: productsByHubError,
		fetchMore: productsByHubFetchMore,
	} = useQuery( GET_ALL_ACTIVE_PRODUCTS_BY_HUB, {
		variables: {
			offset: 0,
			limit: 6,
			hub_id: hubId,
			marketFilters: marketFilters.length > 0 ? marketFilters : null,
			gs1Filters: gs1Filters.length > 0 ? gs1Filters : null,
		},
		fetchPolicy: 'cache-and-network',
	} )

	const products = productsByHubData
		&& productsByHubData.provider_market
		&& productsByHubData.provider_market.length > 0
		? productsByHubData.provider_market.map(
			( marketProduct ) => marketProduct.products
		).flat() : []

	/* eslint-disable */

	useEffect( () => {
		if ( products ) {
			const sliderWidth = document.getElementById( 'sliderWidth' ).offsetWidth

			if ( sliderWidth <= 640 ) {
				isEnd( 	!( products && products.length > 2 ) )
			} else if ( sliderWidth <= 760 ) {
				isEnd( 	!( swiper && products && products.length > 3 ) )
			} else if ( sliderWidth <= 1080 ) {
				isEnd( 	!( products && products.length > 3 ) )
			} else {			
				isEnd( 	!( products && products.length > 3 ) )
			}
		}

	}, [ swiper ] )



	const {
		data: defaultQuantities,
		loading: defaultQuantitiesLoading,
		error: defaultQuantitiesError
	} = useQuery( GET_ALL_DEFAULT_QUANTITIES )

	//	TODO - Create better breakpoints and test

	const {
		data: subscribeHubProducts,
		loading: subscribeHubProductsLoading,
		error: subscribeHubProductsError
	} = useSubscription( GET_ALL_ACTIVE_PRODUCTS_BY_HUB_SUBSCRIPTION, {
		variables: {
			hub_id: hubId,
			limit: products.length,
			marketFilters: marketFilters.length > 0 ? marketFilters : null,
			gs1Filters: gs1Filters.length > 0 ? gs1Filters : null,
		},
		onSubscriptionData: ( data ) => {
			getNewProductsToDisplay( data?.subscriptionData?.data?.provider_market?.map(
				( marketProduct ) => marketProduct.products
			)?.flat() ?? [] )
		}
	} )

	useEffect( () => {
		if ( swiper ) {
			const handleResize = () => {
				const sliderWidth = document.getElementById( 'sliderWidth' ).offsetWidth
				if ( sliderWidth <= 640 ) {
					getParams( {
						spaceBetween: 10,
						noSwiping: false,
						observer: true,
						loop: false,
						shouldSwiperUpdate: true,
						slidesPerView: 1
					} )
				} else if ( sliderWidth <= 760 ) {
					getParams( {
						spaceBetween: 10,
						noSwiping: false,
						observer: true,
						loop: false,
						shouldSwiperUpdate: true,
						slidesPerView: 2
					} )
				} else if ( sliderWidth <= 1080 ) {
					getParams( {
						spaceBetween: 10,
						noSwiping: true,
						observer: true,
						loop: false,
						shouldSwiperUpdate: true,
						slidesPerView: 3
					} )
				} else {
					getParams( {
						spaceBetween: 10,
						noSwiping: true,
						observer: true,
						loop: false,
						shouldSwiperUpdate: true,
						slidesPerView: 4
					} )
				}
			}
			window.addEventListener( 'resize', handleResize )
			return () => { window.removeEventListener( 'resize', handleResize ) }
		}
	}, [ swiper ] )


	//	TODO - Create better breakpoints and test

	function onLoadMoreHubProducts() {
		productsByHubFetchMore( {
			variables: {
				offset: productsByHubData.provider_market.map(
					( marketProduct ) => marketProduct.products
				).flat().length
			},
			updateQuery: ( prev, { fetchMoreResult } ) => {
				if ( !fetchMoreResult ) return prev
				return {
					...prev,
					provider_market: [
						...prev.provider_market,
						...fetchMoreResult.provider_market
					]
				}
			}
		} )
	}

	// helper that checks if the quantity of the product is less than the lowest purchasable amount
	function productDisplayHelper( product ) {
		const unitId = product.product.gs1_brick.unit.id
		// eslint-disable-next-line camelcase
		if ( defaultQuantities?.code_register_units_default_quantities !== undefined ) {
			// eslint-disable-next-line max-len
			for ( let j = 0; j < defaultQuantities.code_register_units_default_quantities.length; j += 1 ) {
				if ( unitId === defaultQuantities.code_register_units_default_quantities[ j ].unit_id ) {
					if ( product.product.quantity
						>= defaultQuantities.code_register_units_default_quantities[ j ].quantity ) {
						return true
					}
				}
			}
		}
		return false
	}

	const goNext = () => {
		if ( swiper !== null ) {
			swiper.isBeginning = swiper.realIndex === 0
			isBeginning( false )
			swiper.slideNext()
			if ( swiper.isEnd ) {
				isEnd( swiper.isEnd )
				onLoadMoreHubProducts()
			}
		}
	}

	const goPrev = () => {
		if ( swiper !== null ) {
			swiper.isEnd = false
			swiper.realIndex = swiper.realIndex > 0 ? swiper.realIndex - 1 : 0
			swiper.isBeginning = swiper.realIndex === 0
			isBeginning( swiper.isBeginning )
			isEnd( swiper.isEnd )
			swiper.slidePrev()
		}
	}

	return (
		<div className='shopping-row' id='shoppingRowHub'>
			<div />
			<div className='shopping-row-title'>
				<Trans id='SliderRowAllProducts:choosen product on hub' />
				{ ` ${ hubName }` }
			</div>
			<div />
			<div className='arrow-left'>
				<button
					id={ beginning ? 'btn-swiper-prev-disabled' : 'btn-swiper-prev' }
					label='button'
					type='button'
					onClick={ goPrev }
				/>
			</div>
			<div className='slider' id='sliderWidth'>
				{ productsByHubError ? <div>{ JSON.stringify( productsByHubError ) }</div> : null }
				{ subscribeHubProductsError
					? <div>{ JSON.stringify( subscribeHubProductsError ) }</div>
					: null }
				{ productsByHubLoading || subscribeHubProductsLoading ? <Loader /> : null }
				{ productsByHubData ? (
					<Swiper { ...paramsValues } getSwiper={ updateSwiper } modules={ [ Navigation ] }>
						{/* eslint-disable-next-line max-len */}
						{ productsDisplay.filter( ( product ) => productDisplayHelper( product ) ).map( ( product ) => (
							<div
								className='item-holder'
								style={ { width: itemWidthValue } }
								key={ `${ product.market.hub_id }  ${ product.product.id }` }
							>
								<ReactPlaceholder
									showLoadingAnimation
									ready={
										!productsByHubLoading
									}
									customPlaceholder={ ProductComponentPlaceholder }
								>
									<ProductComponent itemData={ product } hubId={ hubId } />
								</ReactPlaceholder>
							</div>
						) ) }
					</Swiper>
				) : null }
			</div>
			<div className='arrow-right'>
				<button
					id={ end ? 'btn-swiper-next-disabled' : 'btn-swiper-next' }
					label='button'
					type='button'
					onClick={ goNext }
				/>
			</div>
		</div>

	)
}

SliderRowHub.defaultProps = {
	isHubMenuClosed: undefined,
}

SliderRowHub.propTypes = {
	isHubMenuClosed: propTypes.bool,
	hubId: propTypes.number.isRequired,
	hubName: propTypes.string.isRequired,
	marketFilters: propTypes.array.isRequired,
	gs1Filters: propTypes.array.isRequired,
}
export default SliderRowHub
