import React from 'react'
import propTypes from 'prop-types'

const FilterIcon = ( {
	color, fontSize, id, onClick, onKeyPress
} ) => (
	<span
		className='icon-filter-icon'
		role='button'
		tabIndex={ -1 }
		style={ { fontSize: `${ fontSize }px`, color } }
		id={ id }
		onClick={ onClick }
		onKeyPress={ onKeyPress }
	/>
)

FilterIcon.defaultProps = {
	color: 'black',
	fontSize: 18
}
FilterIcon.propTypes = {
	color: propTypes.string,
	fontSize: propTypes.number,
	id: propTypes.string.isRequired,
	onClick: propTypes.func.isRequired,
	onKeyPress: propTypes.func.isRequired
}

export default FilterIcon
