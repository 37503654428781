import React from 'react'
import { Trans } from '@lingui/macro'
import propTypes from 'prop-types'

import './TableHubCycleRow.css'
import { dateToString } from '../../../utils/DateToString'

const TableHubCycleRow = React.memo( ( {
	data: {
		// eslint-disable-next-line camelcase
		start_time, duration, closed_time, orders, shownId
	}, onClick
} ) => {
	// count just payed orders
	const okOrders = orders.filter( ( order ) => order.status_id > 3 )

	return (
		<div
			className='tableHubCycle-row-container'
			role='button'
			tabIndex={ -1 }
			onClick={ onClick }
			onKeyPress={ onClick }
		>
			<div className='tableHubCycle-numCycle'>
				{ shownId }
			</div>
			<div className='tableHubCycle-startDate'>
				{ dateToString( start_time ) }
			</div>
			<div className='tableHubCycle-duration'>
				<span>
					{ duration }
				</span>
				<span>
					<Trans id='TableHubCycle.days' />
				</span>
			</div>
			<div className='tableHubCycle-endDate'>
				{ dateToString( closed_time ) }
			</div>
			{/* <div className='tableHubCycle-numOffers'>
			{ numOffers }
		</div> */}
			<div className='tableHubCycle-numOrders'>
				{ okOrders.length }
			</div>
		</div>
	)
} )

TableHubCycleRow.propTypes = {
	data: propTypes.object.isRequired,
	onClick: propTypes.func.isRequired
}

export default TableHubCycleRow
