import React, { useEffect, useReducer } from 'react'
import PubSub from '@result/react-pubsub'

import { Done, Warning, Error } from '../../icons'
import NotifierAddProductToCart from './notifierAddProductToCart'

/** Reducer which handles message stack */
const initStackValue = []

function reducer( state, action ) {
	switch ( action.type ) {
	case 'addMessage':
		return [ ...state, action.add ]
	case 'removeMessage': {
		state.splice( 0, 1 )
		return [ ...state ]
	}
	case 'removeLastMessage': {
		state.pop()
		return [ ...state ]
	}
	default:
		return state
	}
}

const NotifierHandlerAddProductToCart = () => {
	const [ state, handleMessage ] = useReducer( reducer, initStackValue )

	function closeNotifier() {
		handleMessage( { type: 'removeMessage' } )
	}


	function openNotifier( newMessage ) {
		// eslint-disable-next-line max-len
		const found = state.find( ( element ) => element.message.props.id === newMessage.message.props.id )

		if ( found ) {
			handleMessage( { type: 'removeLastMessage' } )
			handleMessage( { type: 'addMessage', add: newMessage } )
		} else {
			handleMessage( { type: 'addMessage', add: newMessage } )
		}

		setTimeout( () => {
			closeNotifier()
		}, 5000 )
	}

	useEffect( () => {
		const addMessageHandler = PubSub.subscribe( 'Notifier::addProductToCart', openNotifier.bind( this ) )
		return () => {
			PubSub.unsubscribe( addMessageHandler )
		}
	} )

	function selectIcon( type ) {
		switch ( type ) {
		case 'OK': return <Done color='blue' />
		case 'ERROR': return <Error color='red' />
		case 'WARNING': return <Warning color='yellow' />
		default: return <Warning color='yellow' />
		}
	}

	function messageBackgroundColor( type ) {
		switch ( type ) {
		case 'OK': return '#639676'
		case 'ERROR': return '#d14949'
		case 'WARNING': return '#ff9933'
		default: return '#ff9933'
		}
	}

	function fontColor( type ) {
		switch ( type ) {
		case 'OK': return 'white'
		case 'ERROR': return 'white'
		case 'WARNING': return 'black'
		default: return 'black'
		}
	}


	return state.map( ( notify, index ) => (
		<NotifierAddProductToCart
			key={ notify.id }
			id={ index + 1 }
			icon={ selectIcon( notify.type ) }
			message={ notify.message }
			backgroundColor={ messageBackgroundColor( notify.type ) }
			fontColor={ fontColor( notify.type ) }
		/>
	) )
}

export default NotifierHandlerAddProductToCart
