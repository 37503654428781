import React from 'react'
import { Trans } from '@lingui/macro'

const myOfferTabs = [
	{ id: 'myHubs', link: '/my-offers/my-hubs', roles: [ 'provider' ] },
	{ id: 'myStands', link: '/my-offers/my-stands', roles: [ 'provider' ] },
	{ id: 'myOffer', link: '/my-offers/my-offer', roles: [ 'provider' ] },
	{ id: 'myOrders', link: '/my-offers/my-orders', roles: [ 'provider' ] }
]

const myOfferTranslations = {
	myHubs: <Trans id='MyOffers.myHubs' />,
	myStands: <Trans id='MyOffers.myStands' />,
	myOffer: <Trans id='MyOffers.myOffer' />,
	myServices: <Trans id='MyOffers.myServices' />,
	myOrders: <Trans id='MyOffers.myOrders' />
}
const hubPageTabs = [
	{ id: 'hub', link: '/hub/hub', roles: [ 'employee', 'owner' ] },
	{ id: 'SLA', link: '/hub/SLA', roles: [ 'owner' ] },
	{ id: 'providers', link: '/hub/providers', roles: [ 'owner' ] },
	{ id: 'cycles', link: '/hub/cycles', roles: [ 'owner' ] },
	{ id: 'orders', link: '/hub/orders', roles: [ 'employee', 'owner' ] },
	{ id: 'pricelist', link: '/hub/pricelist', roles: [ 'owner' ] },
	{ id: 'warehouse', link: '/hub/warehouse', roles: [ 'employee', 'owner' ] },
	// { id: 'packages', link: '/hub/packages', roles: [ 'employee', 'owner' ] },
	// { id: 'employees', link: '/hub/employees', roles: [ 'owner' ] },
	{ id: 'gs1', link: '/hub/gs1', roles: [ 'owner' ] }
]

const hubPageTranslations = {
	hub: <Trans id='Hub.hub' />,
	SLA: <Trans id='Hub.SLA' />,
	providers: <Trans id='Hub.providers' />,
	cycles: <Trans id='Hub.cycles' />,
	orders: <Trans id='Hub.orders' />,
	pricelist: <Trans id='Hub.pricelist' />,
	warehouse: <Trans id='Hub.warehouse' />,
	// packages: <Trans id='Hub.packages' />,
	// employees: <Trans id='Hub.employees' />,
	gs1: <Trans id='Hub.gs1' />
}

const mainTabsForMobile = [
	{ id: 'categories', link: '', roles: [ 'provider', 'owner', 'employee' ] },
	{ id: 'hubs', link: '', roles: [ 'provider', 'owner', 'employee' ] },
	{ id: 'myPurchases', link: '/my-purchases', roles: [ 'provider', 'owner', 'employee' ] },
	{ id: 'myOffers', link: '/my-offers/my-hubs', roles: [ 'provider' ] },
	{ id: 'hub', link: '/hub/hub', roles: [ 'owner', 'employee' ] },
]

const mainTabsForMobileTranslations = {
	categories: <Trans id='CategoryMenu.categories' />,
	hubs: <Trans id='CategoryMenu.hubs' />,
	myPurchases: <Trans id='CategoryMenu.myPurchases' />,
	myOffers: <Trans id='CategoryMenu.myOffers' />,
	hub: <Trans id='CategoryMenu.hub' />,
}

export {
	mainTabsForMobile,
	mainTabsForMobileTranslations,
	myOfferTabs,
	myOfferTranslations,
	hubPageTabs,
	hubPageTranslations
}
