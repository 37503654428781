import React from 'react'
import { Trans } from '@lingui/macro'
import propTypes from 'prop-types'

import './ArticlesBanner.css'
import SliderRowMostSellingProducts from '../SliderRowMostSellingProducts'

const ArticlesBanner = ( { marketsShoppingList, gs1ShoppingList } ) => {
	const slides = []
	const marketFilters = marketsShoppingList
		.filter( ( item ) => !item.disabled )
		.map( ( market ) => market.id )
	const gs1Filters = gs1ShoppingList
		.filter( ( item ) => !item.disabled )
		.map( ( gs1Product ) => gs1Product.id )

	return (
		<div className='articles-main-container'>
			<SliderRowMostSellingProducts
				isHubMenuClosed
				sliderTitle={ <Trans id='Slider.bestSellingProducts' /> }
				slides={ slides }
				marketFilters={ marketFilters }
				gs1Filters={ gs1Filters }
			/>
			<div className='articles-container'>
				<a className='article-1' href='http://www.google.si' target='_blank' rel='noopener noreferrer'>
					<div className='article-title'>
						<Trans id='Article.articleUno' />
					</div>
				</a>
				<a className='article-2' href='http://www.google.si' target='_blank' rel='noopener noreferrer'>
					<div className='article-title'>
						<Trans id='Article.articleDue' />
					</div>
				</a>
				<a className='article-3' href='http://www.google.si' target='_blank' rel='noopener noreferrer'>
					<div className='article-title'>
						<Trans id='Article.articleTree' />
					</div>
				</a>
				<a className='article-4' href='http://www.google.si' target='_blank' rel='noopener noreferrer'>
					<div className='article-title'>
						<Trans id='Article.articleQuatro' />
					</div>
				</a>
			</div>
		</div>
	)
}

ArticlesBanner.propTypes = {
	marketsShoppingList: propTypes.array.isRequired,
	gs1ShoppingList: propTypes.array.isRequired,
}

export default ArticlesBanner
