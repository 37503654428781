import React from 'react'
import propTypes from 'prop-types'

const SearchIcon = ( { color, fontSize } ) => (
	<span className='icon-search-icon-05' style={ { fontSize: `${ fontSize }px`, color } } />
)

SearchIcon.defaultProps = {
	color: 'black',
	fontSize: 18
}
SearchIcon.propTypes = {
	color: propTypes.string,
	fontSize: propTypes.number
}

export default SearchIcon
