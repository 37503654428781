import React, { useEffect } from 'react'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import './UniversalTabs.css'
import ConditionalWrapper from '../ConditionalWrapper/index'
import roleHandler from '../../../utils/ContentRoleHandler'


const UniversalTabs = ( {
	tabs, currentTab, translations, action,
	setPageTitle, isAuth, history
} ) => {
	useEffect( () => {
		const { pathname } = history.location

		//	handle tabs change with URL or category menu
		const changedTab = tabs.find( ( tab ) => tab.link === pathname )

		// if URL is invalid, send user to 'home' page
		if ( changedTab !== undefined ) {
			action( tabs.indexOf( changedTab ) )
			setPageTitle( translations[ changedTab.id ] )
		} else {
			history.push( '/' )
		}
	}, [ action, history, history.location.pathname, setPageTitle, tabs, translations ] )

	function renderStepBrick( tab, index ) { // Rendering step bar
		// if condition met, render brick blue with an arrow
		if ( tab === index ) {
			return 'tab-blue'
		}
		// otherwise render brick default
		return 'tab'
	}

	return (
		<div className='tabs-bar'>
			{ tabs.map( ( tab, index ) => (
				<ConditionalWrapper
					key={ tab.id }
					condition={ roleHandler( isAuth, tab.roles ) }
					falseConditionComponent={ (
						<button
							key={ tab.id }
							type='button'
							tabIndex={ 0 }
							className='tab-disable'
						>
							<span
								key={ tabs.indexOf( tab ) }
								className='text-disable'
							>
								{ translations[ tab.id ] }
							</span>
						</button>
					) }
				>
					<button
						type='button'
						key={ tab.id }
						tabIndex={ 0 }
						className={ renderStepBrick( currentTab, index ) }
						onKeyPress={ currentTab !== index ? () => {
							history.push( `${ tab.link }` )
						} : null }
						onClick={ currentTab !== index ? () => {
							history.push( `${ tab.link }` )
						} : null }
					>
						<span
							key={ currentTab }
							className={ currentTab === index ? 'text text-white' : 'text' }
						>
							{ translations[ tab.id ] }
						</span>
					</button>
				</ConditionalWrapper>
			) ) }
		</div>
	)
}
UniversalTabs.defaultProps = {
	setPageTitle: '',
}

UniversalTabs.propTypes = {
	tabs: propTypes.array.isRequired,
	translations: propTypes.object.isRequired,
	currentTab: propTypes.number.isRequired,
	action: propTypes.func.isRequired,
	history: propTypes.object.isRequired,
	isAuth: propTypes.bool.isRequired,
	setPageTitle: propTypes.func
}

export default withRouter( UniversalTabs )
