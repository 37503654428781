import React from 'react'
// import { Trans } from '@lingui/macro'
import propTypes from 'prop-types'

// import './CycleDropdown.css'f

const dropdownValues = [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ]

const CommissionFeeDropdown = ( {
	selectedValue, showDropdown, toggleDropdown, setDropdownValue
} ) => (
	<div
		className=''
		onClick={ () => toggleDropdown() }
		onKeyPress={ () => toggleDropdown() }
		role='button'
		tabIndex={ 0 }
	>
		<div className='hub-cost-for-intervention-dropdown-value'>
			{ `${ selectedValue } % ` }
			{' '}
			<div id='arrow-down' />
		</div>
		<div
			className='hub-cost-for-intervention-dropdown-content'
			style={ showDropdown ? { display: 'block' } : { display: 'none' } }
		>
			{
				dropdownValues.map( ( val ) => (
					<div
						key={ val }
						className='intervention-cost-values'
						role='button'
						tabIndex={ 0 }
						onClick={ () => setDropdownValue( val ) }
						onKeyPress={ () => setDropdownValue( val ) }
					>
						{ val }
					</div>
				) )
			}
		</div>
	</div>
)

CommissionFeeDropdown.propTypes = {
	selectedValue: propTypes.number.isRequired,
	showDropdown: propTypes.bool.isRequired,
	toggleDropdown: propTypes.func.isRequired,
	setDropdownValue: propTypes.func.isRequired
}

export default CommissionFeeDropdown
