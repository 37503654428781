import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { Trans } from '@lingui/macro'
import propTypes from 'prop-types'


// import {
// 	GET_CURRENT_ORDERS
// } from '../../_Provider/MyOrders/myOrdersGQL'

import { addNotification } from '../../../utils/messageHandler/notifierStack'

const UPDATE_STATUS_ITEM = gql`
mutation updateStatusOfItem( $id: bigint!, $status_id: Int! ) {
	update_cart_items( where: { id: { _eq: $id } }, _set: { status_id: $status_id } ) {
    	returning {
			id
      		status_id
    	}
  	}
}`

const ItemStatusDropdown = React.memo( ( {
	data,
	type,
	translations,
	status,
	id
} ) => {
	const [ displayDropDown, showDropDown ] = useState( false )
	const [ selectedItem, chooseItem ] = useState()

	const [ updateProductStatus ] = useMutation( UPDATE_STATUS_ITEM )


	function selectItem( item ) {
		showDropDown( !displayDropDown )
		chooseItem( item )
	}

	function getStatusClass( item ) {
		if ( item === 'inPreparation' ) {
			return '#0E5FA6'
		}
		if ( item === 'readyAtProvider' ) {
			return '#f0f00e'
		}
		if ( item === 'deliveredToHub' ) {
			return '#eb9f34'
		}
		if ( item === 'storedAtHub' ) {
			return '#53eb34 '
		}
		if ( item === 'nonDelivered' ) {
			return '#eb4034'
		}
		if ( item === 'itemRejected' ) {
			return '#000000'
		}
		return 'grey'
	}

	function getNewStatus( item ) {
		let newStatus
		if ( item === 'inPreparation' ) {
			newStatus = 1
		}
		if ( item === 'readyAtProvider' ) {
			newStatus = 2
		}
		if ( item === 'deliveredToHub' ) {
			newStatus = 3
		}
		if ( item === 'storedAtHub' ) {
			newStatus = 4
		}
		if ( item === 'nonDelivered' ) {
			newStatus = 5
		}
		if ( item === 'itemRejected' ) {
			newStatus = 6
		}
		return newStatus
	}

	function getStatusText() {
		if ( status === 1 ) {
			return <Trans id='MyOrders.inPreparation' />
		}
		if ( status === 2 ) {
			return <Trans id='MyOrders.readyAtProvider' />
		}
		if ( status === 3 ) {
			return <Trans id='MyOrders.deliveredToHub' />
		}
		if ( status === 4 ) {
			return <Trans id='MyOrders.storedAtHub' />
		}
		if ( status === 5 ) {
			return <Trans id='MyOrders.nonDelivered' />
		}
		if ( status === 6 ) {
			return <Trans id='MyOrders.itemRejected' />
		}
		return <Trans id='MyOrders.nonDelivered' />
	}


	function displayDropdownListStatus() {
		if ( displayDropDown ) {
			if ( data && ( status === 1 || status === 2 || status === 3
                || status === 4 || status === 5 || status === 6 ) ) {
				return (
					<div
						className='dropdown-item-list-my-items-statuses'
						id='dropdownItemList-my-orders'
						onMouseLeave={ () => showDropDown( !displayDropDown ) }
					>
						<div label={ selectItem ? selectedItem : '' } />
						{ data.map( ( item ) => (
							<div
								className='item'
								style={ { backgroundColor: getStatusClass( item ) } }
								key={ item }
								name={ item }
								role='button'
								tabIndex={ 0 }
								onKeyPress={ () => {
									updateProductStatus( { variables: { id, status_id: getNewStatus( item ) } } )
									selectItem( translations[ item ] )
								} }
								onClick={ () => {
									updateProductStatus( { variables: { id, status_id: getNewStatus( item ) } } )
									selectItem( translations[ item ] )
									addNotification( 'OK', 'ITEM_ORDER_STATUS_CHANGED' )
								} }
							>
								{ translations[ item ] }
							</div>
						) ) }
					</div>
				)
			}
			return null
		}
		return null
	}

	function displaySelectedItemNameStatus() {
		if ( selectedItem ) {
			return (
				<div
					className='Value'
					role='button'
					tabIndex={ -1 }
					onKeyPress={ () => showDropDown( !displayDropDown ) }
					onClick={ () => showDropDown( !displayDropDown ) }
				>
					{ selectedItem }
				</div>
			)
		}
		return (
			<div
				className='Value'
				role='button'
				tabIndex={ -1 }
				onKeyPress={ () => showDropDown( !displayDropDown ) }
				onClick={ () => showDropDown( !displayDropDown ) }
			>
				{ getStatusText() }

			</div>
		)
	}

	return (
		<div
			className='dropdown-select-my-items-statuses'
			name='dropdown-select'
			id='ItemStatusDropdown'
		>
			<div className='Triangle' />
			{ data.length > 0 && type === 'status' ? displaySelectedItemNameStatus() : null }
			{ data.length > 0 && type === 'status' ? displayDropdownListStatus() : null }
		</div>
	)
} )

ItemStatusDropdown.defaultProps = {
	status: null,
	translations: null
}

ItemStatusDropdown.propTypes = {
	data: propTypes.array.isRequired,
	type: propTypes.string.isRequired,
	translations: propTypes.object,
	status: propTypes.number,
	id: propTypes.number.isRequired,
}

export default ItemStatusDropdown
