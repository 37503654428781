import React, { useState } from 'react'
import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'

import '../../UserProfile/UserProfileSettings.css'

const ChooseUser = ( { usersArray, handleUserChoice } ) => {
	const [ selectedUser, setSelectedUser ] = useState( <Trans id='ChooseUser.ChooseUser' /> )
	const [ displayUsers, setDisplayUsers ] = useState( false )

	function selectUser( user ) {
		handleUserChoice( user )
		setSelectedUser( user.email )
		setDisplayUsers( false )
	}

	return (
		<div id='update-user-container-box'>
			<div className='user-profile-container'>
				<p className='name-settings'><Trans id='ChooseUser.ChooseUser' /></p>
				<div className='choose-user-dropdown'>
					<div
						className='dropdown-title'
						onClick={ () => setDisplayUsers( !displayUsers ) }
						onKeyPress={ () => setDisplayUsers( !displayUsers ) }
						role='button'
						tabIndex='0'
					>
						<p id='choose-user-text' className='name-settings'>{selectedUser}</p>
						<div id='choose-user-arrow' className='arrow-style-down' />
					</div>
					{ displayUsers
						? (
							<div className='choose-user-dropdown-content'>
								{
									usersArray.map( ( user ) => (
										<div
											key={ user.username }
											onClick={ () => selectUser( user ) }
											onKeyPress={ () => selectUser( user ) }
											role='button'
											tabIndex='0'
											className='user-info-dropdown'
										>
											<p className='user-info-text'>{`${ user.email }`}</p>
										</div>
									) )
								}
							</div>
						) : null }
				</div>
			</div>
		</div>
	)
}

ChooseUser.propTypes = {
	usersArray: propTypes.array.isRequired,
	handleUserChoice: propTypes.func.isRequired
}

export default ChooseUser
