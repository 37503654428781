import React from 'react'
import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'

import './CurrentOrdersHeader.css'

const CurrentOrdersHeader = React.memo( ( { numberOfOrders } ) => (
	<div id='current-orders-header'>
		<span className='current-orders-text'><Trans id='MyPurchasesPage.currentOrders' /></span>
		<span className='number-of-orders'>
			<Trans id='MyPurchasesPage.numberOfOrders' />
			<span>{`: ${ numberOfOrders }`}</span>
		</span>
	</div>
) )

CurrentOrdersHeader.propTypes = {
	numberOfOrders: propTypes.number.isRequired
}

export default CurrentOrdersHeader
