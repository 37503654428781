import React from 'react'
import { Trans } from '@lingui/macro'
import { UhubIcon } from '../../../icons'

import './FooterBottom.css'

const FooterBottom = () => (
	<div className='footer-bottom-main'>
		<div className='logo'>
			<UhubIcon fontSize={ 47 } />
		</div>
		<div className='contact-info'>
			<span> 080 333 666 </span>
			<br />
			<span>info@uhub.si</span>
		</div>
		<div className='copyright-info'>
			<Trans id='Footer.copyRight' />
		</div>
	</div>
)
export default FooterBottom
