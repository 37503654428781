import React, { useState } from 'react'
import propTypes from 'prop-types'

import './CheckBox.css'

const CheckBox = ( {
	text,
	id,
	name,
	value,
	isChecked,
	onSelect,
	onDeselect
} ) => {
	const [ checked, handleChecked ] = useState(
		isChecked || false
	)

	return (
		<label className='container' htmlFor={ id }>
			{ text === null || text === undefined ? '' : text }
			<input
				type='checkbox'
				name={ name }
				id={ id }
				value={ value }
				checked={ checked }
				onClick={ () => ( !checked ? onSelect() : onDeselect() ) }
				onChange={ () => {
					handleChecked( !checked )
				} }
			/>
			<span className='checkmark' />
		</label>
	)
}

CheckBox.defaultProps = {
	name: '',
	text: undefined || null,
	isChecked: false,
	onSelect: () => {},
	onDeselect: () => {},
}


CheckBox.propTypes = {
	text: propTypes.object,
	id: propTypes.string.isRequired,
	name: propTypes.string,
	value: propTypes.number.isRequired,
	isChecked: propTypes.bool,
	onSelect: propTypes.func,
	onDeselect: propTypes.func,
}

export default CheckBox
