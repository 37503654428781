/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import { useQuery, useSubscription } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Trans } from '@lingui/macro'

import { dateToStringNoHoursMinutes, dateToString } from '../../../../../utils/DateToString'
import store from '../../../../../stores/mainStore'
import TableHubCycleOrderHeader from '../../../../_Globals/TableHubCycleOrderHeader'
import TableHubCycleOrderRow from '../../../../_Globals/TableHubCycleOrderRow'
import ConditionalWrapper from '../../../../_Globals/ConditionalWrapper'
import { ReturnSendIcon } from '../../../../../icons'
import Loader from '../../../../Loader'
// import DropdownMyOrders from '../../../../_Globals/DropdownMyOrders'
import OrderStatusDropdown from './OrderStatusDropdown'

import './OrderDisplay.css'

import GET_ORDER_STATUSES from './OrderDisplayGQL'

const GET_ORDER_ITEMS = gql`
query getOrderItems( 
	$order_id: bigint!,
	$hub_id: bigint!,
	$cycle_id: bigint!,
 ) {
	cart_items( 
		where: { 
			order_id:  { _eq: $order_id },
			hub_id: { _eq: $hub_id }
		},
		order_by: { qr_code_id: desc }
	){
		id
    	qr_code_id
        qr_code {
			id
            base64_data
        }
    	aggregated
    	product {
			id
      		gs1_brick {
				id
        		translation_sl
				unit {
					id
      				unit
    			}
      		}
			variety {
				id
				name
			}
    	}
   		market {
			id
      		name
    	}
    	quantity
    	bruto_price
		neto_price
    	order {
			id
      		customer_pickup
			warehouses {
				id
        		drawer_code
      		}
    	}
    	status_id
		hub {
			id
      		cycles(where: { id: { _eq: $cycle_id } } ) {
				id
        		closed_time
      		}
    	}
		delivery_to_hub
  	}
}`

const GET_ORDER_INFO = gql`
subscription getOrderInfo( $order_id: bigint! ) {
	cart_orders(where: { id: { _eq: $order_id } } ) {
		id
		qr_code_id
        qr_code {
			id
            base64_data
        }
		cycle_id
		status_id
		customer_pickup
		created_at
		customer_id
	}
}`

const statusTranslations = {
	inPreparation: <Trans id='OrderDisplay.inPreparation' />,
	paymentFailed: <Trans id='OrderDisplay.paymentFailed' />,
	payed: <Trans id='OrderDisplay.payed' />,
	paymentProcessing: <Trans id='OrderDisplay.paymentProcessing' />,
	packaging: <Trans id='OrderDisplay.packaging' />,
	orderReady: <Trans id='OrderDisplay.orderReady' />,
	sending: <Trans id='OrderDisplay.sending' />,
	delivered: <Trans id='OrderDisplay.delivered' />,
	time: <Trans id='OrderDisplay.time' />,
	user: <Trans id='OrderDisplay.user' />,
	note: <Trans id='OrderDisplay.note' />,
}

const STATUS = [ 'orderReady', 'inDelivery', 'deliveredToCustomer', 'payed' ]

const TRANSLATIONS_STATUS_ORDER = {
	orderReady: <Trans id='OrderDisplay.orderReady' />,
	inDelivery: <Trans id='myPurchaseOrder.inDelivery' />,
	deliveredToCustomer: <Trans id='myPurchaseOrder.deliveredToCustomer' />
}

const OrderDisplay = React.memo( ( { selectedOrder, selectOrder, showDetails } ) => {
	const [ windowWidth, setWidth ] = useState( window.innerWidth > 768 )
	const [ newClickedStatus, setNewClickedStatus ] = useState()
	const [ isNewStatusButtonClicked, orderButtonClicked ] = useState()

	const {
		data: itemsData,
		error: itemsError,
		loading: ItemsLoading
	} = useQuery( GET_ORDER_ITEMS, {
		variables: {
			order_id: selectedOrder ? selectedOrder.order_id : 0,
			hub_id: selectedOrder ? selectedOrder.hub_id : 0,
			cycle_id: selectedOrder ? selectedOrder.cycle_id : 0
		},
		fetchPolicy: 'no-cache',
	} )

	const {
		data: orderStatusesData,
		error: orderStatusesError,
		loading: orderStatusesLoading,
		refetch: orderStatusesRefetch,
	} = useQuery( GET_ORDER_STATUSES, {
		variables: {
			order_id: selectedOrder ? selectedOrder.order_id : 0,
		},
		fetchPolicy: 'no-cache',
	} )


	const {
		data: orderData,
		error: orderError,
		loading: orderLoading
	} = useSubscription( GET_ORDER_INFO, {
		variables: {
			order_id: selectedOrder ? selectedOrder.order_id : 0,
		},
		fetchPolicy: 'no-cache',
	} )

	const items = itemsData
        && itemsData.cart_items
		&& itemsData.cart_items.length > 0 ? itemsData.cart_items : []

	const orderInfo = orderData
		&& orderData.cart_orders
		&& orderData.cart_orders.length > 0 ? orderData.cart_orders[ 0 ] : {}

	const orderStatuses = orderStatusesData
		&& orderStatusesData.cart_order_status_history.length > 0
		? orderStatusesData.cart_order_status_history : []

	useEffect( () => {
		const handleResize = () => {
			setWidth( window.innerWidth > 768 )
		}
		window.addEventListener( 'resize', handleResize )
		return () => { window.removeEventListener( 'resize', handleResize ) }
	}, [] )

	useEffect( () => {
		setNewClickedStatus( orderInfo.status_id )
		orderButtonClicked( true )
	}, [ orderInfo.status_id ] )

	function getOrderStatuses( progressNum ) {
		let i = 0
		let time
		for ( i; i < orderStatuses.length; i++ ) {
			if ( orderStatuses[ i ].new_status_id === 3 && progressNum === 2 ) {
				time = orderStatuses[ i ].timestamp
			}

			if ( orderStatuses[ i ].new_status_id === 2 && progressNum === 2 ) {
				time = orderStatuses[ i ].timestamp
			}

			if ( orderStatuses[ i ].new_status_id === 4 && progressNum === 2 ) {
				time = orderStatuses[ i ].timestamp
			}

			if ( orderStatuses[ i ].new_status_id === 5 && progressNum === 3 ) {
				time = orderStatuses[ i ].timestamp
			}

			if ( orderStatuses[ i ].new_status_id === 6 && progressNum === 3 ) {
				time = orderStatuses[ i ].timestamp
			}

			if ( orderStatuses[ i ].new_status_id === 7 && progressNum === 4 ) {
				time = orderStatuses[ i ].timestamp
			}

			if ( orderStatuses[ i ].new_status_id === 8 && progressNum === 5 ) {
				time = orderStatuses[ i ].timestamp
			}
		}
		return time
	}

	function getOrderStatus( progressNum ) {
		let i = 0
		let status
		for ( i; i < orderStatuses.length; i++ ) {
			if ( orderStatuses[ i ].new_status_id === 3 && progressNum === 2 ) {
				status = statusTranslations.paymentProcessing
			}

			if ( orderStatuses[ i ].new_status_id === 9 && progressNum === 2 ) {
				status = statusTranslations.paymentFailed
			}

			if ( orderStatuses[ i ].new_status_id === 4 && progressNum === 2 ) {
				status = statusTranslations.payed
			}

			if ( orderStatuses[ i ].new_status_id === 5 && progressNum === 3 ) {
				status = statusTranslations.packaging
			}

			if ( orderStatuses[ i ].new_status_id === 6 && progressNum === 3 ) {
				status = statusTranslations.orderReady
			}

			if ( orderStatuses[ i ].new_status_id === 7 && progressNum === 4 ) {
				status = statusTranslations.sending
			}

			if ( orderStatuses[ i ].new_status_id === 8 && progressNum === 5 ) {
				status = statusTranslations.delivered
			}
		}
		return status
	}

	function getProgressColor( progressNum ) {
		let i = 0
		let color = '#ececec'
		for ( i; i < orderStatuses.length; i++ ) {
			if ( orderStatuses[ i ].new_status_id === 3 && progressNum === 2 ) {
				color = '#f8a800'
			}

			if ( orderStatuses[ i ].new_status_id === 9 && progressNum === 2 ) {
				color = '#ff3333'
			}

			if ( orderStatuses[ i ].new_status_id === 4 && progressNum === 2 ) {
				color = '#f8a800'
			}

			if ( orderStatuses[ i ].new_status_id === 5 && progressNum === 3 ) {
				color = '#f8a800'
			}

			if ( orderStatuses[ i ].new_status_id === 6 && progressNum === 3 ) {
				color = '#f8a800'
			}

			if ( orderStatuses[ i ].new_status_id === 7 && progressNum === 3 ) {
				color = '#f8a800'
			}

			if ( orderStatuses[ i ].new_status_id === 8 && progressNum === 3 ) {
				color = '#f8a800'
			}

			if ( orderStatuses[ i ].new_status_id === 7 && progressNum === 4 ) {
				color = '#f8a800'
			}

			if ( orderStatuses[ i ].new_status_id === 8 && progressNum === 4 ) {
				color = '#f8a800'
			}

			if ( orderStatuses[ i ].new_status_id === 8 && progressNum === 5 ) {
				color = '#f8a800'
			}
		}
		return color
	}

	/* eslint-disable */


	return (
		<ConditionalWrapper
			condition={ windowWidth }
			falseConditionComponent={ (
				<div className='warning'>
					<Trans id='orderSectionPage.Warning' />
				</div>
			) }
		>
			<div className='order-section-info-page-container'>
				<div className='button-back-to-orders-table'>
					<div
						role='button'
						className='back-to-store'
						tabIndex={ -1 }
						onKeyPress={ () => {
							selectOrder( null )
							showDetails( false )
						} }
						onClick={ () => {
							selectOrder( null )
							showDetails( false )
						} }
					>
						<span><ReturnSendIcon fontSize={ 14 } /></span>
						<span className='text'><Trans id='OrderSectionInfoPage.backToOrdersTable' /></span>
					</div>
				</div>
				{ orderError ? <div>{ JSON.stringify( orderError ) }</div> : null }
				{ orderLoading ? <Loader /> : null }
				{ orderData
					? (
						<div className='order-info'>
							<div className='QRcode'>
								<img alt='qr-code' width='175' height='175' src={ `data:image/png;base64, ${ orderInfo.qr_code.base64_data }` } />
							</div>
							<div className='infoOne'>
								<div className='order'>
									<span><Trans id='InfoOne.order' /></span>
									<span className='order-infoOne-text'>{ ` ${ orderInfo.id }` }</span>
								</div>
								<div className='orderCode'>
									<span><Trans id='InfoOne.code:' /></span>
									<span className='order-infoOne-text'>{ ` ${ orderInfo.qr_code_id }` }</span>
								</div>
								<div className='orderCycle'>
									<span><Trans id='InfoOne.cycle:' /></span>
									<span className='order-infoOne-text'>{ selectedOrder.shownId }</span>
								</div>
								<div className='orderCustomer'>
									<span><Trans id='InfoOne.customer:' /></span>
									<span className='order-infoOne-text'>
										{ ` ${ selectedOrder.customer.owner.firstName } ${ selectedOrder.customer.owner.lastName }` }
									</span>
								</div>
								<div className='customerTelephone'>
									<div className='text-status-container'>
										<span><Trans id='InfoOne.customerTelephone:' /></span>
									</div>
									<span className='order-infoOne-text'>
										{ ` ${ selectedOrder.customer.applications[ 0 ].data.mobile }` }
									</span>
								</div>
							</div>
							<div className='infoTwo'>
								<div className='orderStatus'>
									<div className='text-status-container'>
										<span><Trans id='InfoTwo.status:' /></span>
									</div>
									<span className='order-infoOne-text'>
										<OrderStatusDropdown
											data={ STATUS }
											translations={ TRANSLATIONS_STATUS_ORDER }
											type='status'
											id={ orderInfo.id }
											status={ orderInfo.status_id }
											orderButtonClicked={ ( clicked ) => orderButtonClicked( clicked ) }
											setNewClickedStatus={ ( statusOrder ) => setNewClickedStatus( statusOrder ) }
											orderStatusesRefetch={ ( ) => orderStatusesRefetch() }
										/>
									</span>
								</div>
								<div className='orderLogistic'>
									<span><Trans id='InfoTwo.logistic:' /></span>
									<span className='order-infoOne-text'>{ orderInfo.customer_pickup
										? <Trans id='TableHubCycleOrderRow.hub' />
										: <Trans id='TableHubCycleOrderRow.customer_address' /> }
									</span>
								</div>
								<div className='orderStart'>
									<span><Trans id='InfoTwo.start:' /></span>
									<span className='order-infoOne-text'>{ ` ${ dateToStringNoHoursMinutes( orderInfo.created_at, store.language ) }` }</span>
								</div>
								<div className='orderEnd'>
									<span><Trans id='InfoTwo.end:' /></span>
									<span className='order-infoOne-text'>{ ` ${ dateToStringNoHoursMinutes( orderInfo.created_at, store.language ) }` }</span>
								</div>
							</div>
						</div>
					) : null }
				{ orderStatusesLoading ? <Loader /> : null }
				{ orderStatusesError ? <div>{ JSON.stringify( orderStatusesError ) }</div> : null }
				{ orderStatusesData
					? (
						<div className='order-history'>
							<div className='order-history-title'>
								<Trans id='OrderHistory.title' />
							</div>
							<div className='order-history-progress'>
								<div className='status'>
									<div className='status-text'>
										{ statusTranslations.inPreparation }
									</div>
									<div className='progress' style={ { borderBottom: '5px solid #f8a800' } } />
									<div className='details'>
										<div className='time'>
											<span>{ statusTranslations.time } </span>
											<span>{ ` ${ dateToString( orderInfo.created_at, store.language ) }` }</span>
										</div>
										<div className='user'>
											<span>{ statusTranslations.user } </span>
											<span>{ `${ selectedOrder.customer.owner.firstName } ${ selectedOrder.customer.owner.lastName }` }</span>
										</div>
									</div>
								</div>
								<div className={ getOrderStatus( 2 ) ? 'status' : 'status status-greyed' }>
									<div className='status-text'>
										{ getOrderStatus( 2 ) || statusTranslations.paymentProcessing }
									</div>
									<div className='progress' style={ getProgressColor( 2 ) ? { borderBottom: '5px solid', borderColor: getProgressColor( 2 ) } : { borderBottom: '5px solid #ececec' } } />
									{ getOrderStatus( 2 )
										? 
											<div className='details'>
												<div className='time'>
													<span>{ statusTranslations.time } </span>
													<span>{ ` ${ dateToString( getOrderStatuses( 2 ), store.language ) }` }</span>
												</div>
												<div className='user'>
													<span>{ statusTranslations.user } </span>
													{ orderStatuses[ 0 ]
														? <span>{ `${ orderStatuses[ 0 ].action_author_to_tenant.owner.firstName } ${ orderStatuses[ 0 ].action_author_to_tenant.owner.lastName }` }</span>
														: null }
													{ orderStatuses[ 0 ] && orderStatuses[ 0 ].imprint && orderStatuses[ 0 ].meta 
														? <div className='verify-button-statuses'>
																<a
																	href={ `https://verify.0xcert.org/?assetId=${ orderStatuses[ 0 ].id }&ledgerId=${process.env.REACT_APP_LEDGER_ID}&network=2`}
																	target='_blank'
																>
																	<Trans id='OrderDisplay.Verify' />
																</a>
															</div>
														: null }
												</div>
											</div>
										: <p /> }
								</div>
								<div className={ getOrderStatus( 3 ) ? 'status' : 'status status-greyed' }>
									<div className='status-text'>
										{ getOrderStatus( 3 ) || statusTranslations.packaging }
									</div>
									{
										isNewStatusButtonClicked
											&& ( newClickedStatus === 4 )
											? <div className='progress' style={ { borderBottom: '5px solid #ececec' } } /> : null
									}
									{
										newClickedStatus === 5 || newClickedStatus === 6
										|| newClickedStatus === 7 || newClickedStatus === 8
											? (
												<div
													className='progress'
													style={ getProgressColor( 3 )
														? { borderBottom: '5px solid', borderColor: getProgressColor( 3 ) }
														: { borderBottom: '5px solid #ececec' } }
												/>
											) : null
									}
									{ getOrderStatus( 3 )
										?
											<div className='details'>
												<div className='time'>
													<span>{ statusTranslations.time } </span>
													<span>{ ` ${ dateToString( getOrderStatuses( 3 ), store.language ) }` }</span>
												</div>
												<div className='user'>
													<span>{ statusTranslations.user } </span>
													{ orderStatuses[ 1 ]
														? <span>{ `${ orderStatuses[ 1 ].action_author_to_tenant.owner.firstName } ${ orderStatuses[ 1 ].action_author_to_tenant.owner.lastName }` }</span>
														: <></> }
													{ orderStatuses[ 1 ] && orderStatuses[ 1 ].imprint && orderStatuses[ 1 ].meta 
													? <div className='verify-button-statuses'>
															<a
																href={ `https://verify.0xcert.org/?assetId=${ orderStatuses[ 1 ].id }&ledgerId=${process.env.REACT_APP_LEDGER_ID}&network=2`}
																target='_blank'
															>
																<Trans id='OrderDisplay.Verify' />
															</a>
														</div>
													: null }
												</div>
											</div>
										: <p /> }
								</div>
								<div className={ getOrderStatus( 4 ) ? 'status' : 'status status-greyed' }>
									<div className='status-text'>
										{ getOrderStatus( 4 ) || statusTranslations.sending }
									</div>
									{
										isNewStatusButtonClicked
										&& ( newClickedStatus === 6
											|| newClickedStatus === 4
											|| newClickedStatus === 5 )
											? (
												<div
													className='progress'
													style={ { borderBottom: '5px solid #ececec' } }
												/>
											) : null
									}
									{
										!newClickedStatus === 5
										|| newClickedStatus === 7
										|| newClickedStatus === 8
											? (
												<div
													className='progress'
													style={ getProgressColor( 4 )
														? { borderBottom: '5px solid', borderColor: getProgressColor( 4 ) } : { borderBottom: '5px solid #ececec' } }
												/>
											) : null
									}
									{ getOrderStatus( 4 )
										?
											<div className='details'>
												<div className='time'>
													<span>{ statusTranslations.time } </span>
													<span>{ ` ${ dateToString( getOrderStatuses( 4 ), store.language ) }` }</span>
												</div>
												<div className='user'>
													<span>{ statusTranslations.user } </span>
													{ orderStatuses[ 2 ]
														? <span>{ `${ orderStatuses[ 2 ].action_author_to_tenant.owner.firstName } ${ orderStatuses[ 2 ].action_author_to_tenant.owner.lastName }` }</span>
														: <></> }
													{ orderStatuses[ 2 ] && orderStatuses[ 2 ].imprint && orderStatuses[ 2 ].meta 
														? <div className='verify-button-statuses'>
																<a
																	href={ `https://verify.0xcert.org/?assetId=${ orderStatuses[ 2 ].id }&ledgerId=${process.env.REACT_APP_LEDGER_ID}&network=2`}
																	target='_blank'
																>
																	<Trans id='OrderDisplay.Verify' />
																</a>
															</div>
														: null }
												</div>
											</div>
										: <p /> }
								</div>
								<div className={ getOrderStatus( 5 ) ? 'status' : 'status status-greyed' }>
									<div className='status-text'>
										{ getOrderStatus( 5 ) || statusTranslations.delivered }
									</div>
									{
										isNewStatusButtonClicked
											&& ( newClickedStatus === 7
												|| newClickedStatus === 6
												|| newClickedStatus === 4 )
											? <div className='progress' style={ { borderBottom: '5px solid #ececec' } } /> : null
									}
									{
										isNewStatusButtonClicked && newClickedStatus === 8
											? <div className='progress' style={ getProgressColor( 5 ) ? { borderBottom: '5px solid', borderColor: getProgressColor( 5 ) } : { borderBottom: '5px solid #ececec' } } /> : null
									}
									{ getOrderStatus( 5 ) ? (
										<div className='details'>
											<div className='time'>
												<span>{ statusTranslations.time } </span>
												<span>{ ` ${ dateToString( getOrderStatuses( 5 ), store.language ) }` }</span>
											</div>
											<div className='user'>
												<span>{ statusTranslations.user } </span>
												{ orderStatuses[ 3 ]
													? <span>{ `${ orderStatuses[ 3 ].action_author_to_tenant.owner.firstName } ${ orderStatuses[ 3 ].action_author_to_tenant.owner.lastName }` }</span>
													: null }
												{ orderStatuses[ 3 ] && orderStatuses[ 3 ].imprint && orderStatuses[ 3 ].meta 
													? <div className='verify-button-statuses'>
															<a
																href={ `https://verify.0xcert.org/?assetId=${ orderStatuses[ 3 ].id }&ledgerId=${process.env.REACT_APP_LEDGER_ID}&network=2`}
																target='_blank'
															>
																<Trans id='OrderDisplay.Verify' />
															</a>
														</div>
													: null }
											</div>
										</div>
									) : <p /> }
								</div>
							</div>
						</div>
					)
					: null }
				<div className='hub-orders'>
					<div className='orders-product-title'>
						<Trans id='OrdersProduct.title' />
					</div>
					<TableHubCycleOrderHeader />
					{ ItemsLoading ? <Loader /> : null }
					{ itemsError ? <div>{ JSON.stringify( itemsError ) }</div> : null }
					{ itemsData ? items.map( ( item ) => (
						<div className={ item.status_id !== 4 ? 'order-row-red' : null } key={ item.qr_code_id }>
							<TableHubCycleOrderRow
								data={ item }
								orderInfo={ orderInfo }
								onClick={ () => {} }
							/>
						</div>
					) ) : null }
				</div>
			</div>
		</ConditionalWrapper>
	)
} )

OrderDisplay.defaultProps = {
	selectedOrder: null
}

OrderDisplay.propTypes = {
	selectOrder: propTypes.func.isRequired,
	selectedOrder: propTypes.object,
	showDetails: propTypes.func.isRequired,
}

export default OrderDisplay
