/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import './CategoriesMenuMobile.css'
import { AppsIcon } from '../../../icons'
import {
	mainTabsForMobile,
	mainTabsForMobileTranslations,
} from '../../../pages/Tabs'
import store from '../../../stores/mainStore'
import { useStateValue } from '../../../stores/contextStore/stateStore'
import ConditionalWrapper from '../../_Globals/ConditionalWrapper'
import DropdownContentMobile from './DropdownContentMobile'

const CategoriesMenuMobile = React.memo( ( { isAuthenticated, history } ) => {
	const [ { galleryOpened }, closeGallery ] = useStateValue()
	const [ dropdownShown, displayDropdown ] = useState( false )

	function closeGalleryWhenClickOutside( e ) {
		if ( e.target.id === 'categoriesMenuOverlay' ) {
			closeGallery( { type: 'galleryOpened', galleryOpened: false } )
		}
	}

	return (
		<div id='categories-menu-container-mobile'>
			{ galleryOpened
				? (
					<div
						id='categoriesMenuOverlay'
						className='categories-menu-overlay'
						onKeyPress={ ( e ) => closeGalleryWhenClickOutside( e ) }
						onClick={ ( e ) => closeGalleryWhenClickOutside( e ) }
					/>
				) : null }
			<div id='appsIcon'>
				<AppsIcon fontSize={ 31 } />
			</div>
			<div id='category-dropdown-mobile'>
				<button
					type='button'
					className='btn-category'
					onClick={ () => displayDropdown( !dropdownShown ) }
				>
					Izberite kategorijo
				</button>
				<DropdownContentMobile
					isAuth={ isAuthenticated }
					tabs={ mainTabsForMobile }
					translations={ mainTabsForMobileTranslations }
					dropdownShown={ dropdownShown }
					closeDropDown={ () => displayDropdown( false ) }
				/>
			</div>
			<ConditionalWrapper
				condition={ isAuthenticated }
				falseConditionComponent={ (
					<div
						className='login-register-container'
						role='button'
						tabIndex={ -1 }
						onKeyPress={ () => history.push( '/register' ) }
						onClick={ () => history.push( '/register' ) }
					>
						<Trans id='CategoryMenu.login/register' />
					</div>
				) }
			>
				<div className='authenticated-container'>{ `${ store.user.name }` }</div>
			</ConditionalWrapper>
		</div>
	)
} )

CategoriesMenuMobile.propTypes = {
	isAuthenticated: propTypes.bool.isRequired,
	history: propTypes.object.isRequired,
}

export default withRouter( CategoriesMenuMobile )
