import React from 'react'
import propTypes from 'prop-types'

const ConditionalWrapper = React.memo( ( props ) => {
	const { children, condition, falseConditionComponent } = props
	return (
		condition ? children : falseConditionComponent || null
	)
} )

ConditionalWrapper.defaultProps = {
	falseConditionComponent: null,
	condition: null
}

ConditionalWrapper.propTypes = {
	children: propTypes.object.isRequired,
	condition: propTypes.bool,
	falseConditionComponent: propTypes.object
}

export default ConditionalWrapper
