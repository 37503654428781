import React from 'react'
import propTypes from 'prop-types'

import './ShoppingListFilterItem.css'
import { Clear } from '../../../icons'


const ShoppingListFilterItem = React.memo( ( {
	id, itemName, disabled, handleShoppingList
} ) => {
	function handleToggleDisable() {
		handleShoppingList( { type: 'toggleShoppingListDisable', id } )
	}

	function handleRemoveFromShoppingList( e ) {
		e.stopPropagation()
		handleShoppingList( { type: 'removeItemFromShoppingList', id } )
	}

	return (
		<div
			className='shopping-filter-item'
			role='button'
			tabIndex={ 0 }
			onKeyPress={ () => handleToggleDisable() }
			onClick={ () => handleToggleDisable() }
		>
			<div id='filter-item-dot' className={ disabled ? 'filter-dot-disabled' : null } />
			<div id='filter-item-name' className={ disabled ? 'filter-item-disabled' : null }>{ itemName }</div>
			<div
				id='filter-item-remove'
				className={ disabled ? 'filter-item-disabled' : null }
				role='button'
				tabIndex={ 0 }
				onKeyPress={ ( e ) => handleRemoveFromShoppingList( e ) }
				onClick={ ( e ) => handleRemoveFromShoppingList( e ) }
			>
				<Clear fontSize={ 9 } color={ disabled ? '#a2a2a2' : 'black' } />
			</div>
		</div>
	)
} )

ShoppingListFilterItem.defaultProps = {
	disabled: true
}

ShoppingListFilterItem.propTypes = {
	handleShoppingList: propTypes.func.isRequired,
	itemName: propTypes.string.isRequired,
	id: propTypes.number.isRequired,
	disabled: propTypes.bool
}

export default ShoppingListFilterItem
