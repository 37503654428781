import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { Trans } from '@lingui/macro'
import propTypes from 'prop-types'
import './Dropdown.css'

import {
	GET_CURRENT_ORDERS,
	GET_CURRENT_EXPIRED_ORDERS
} from '../../_Provider/MyOrders/myOrdersGQL'

const UPDATE_STATUS_ITEM = gql`
mutation updateStatusOfItem( $id: bigint!, $status_id: Int! ) {
	update_cart_items( where: { id: { _eq: $id } }, _set: { status_id: $status_id } ) {
    	returning {
			id
      		status_id
    	}
  	}
}`

const UPDATE_DELIVERY_TO_HUB_STATUS = gql`
mutation updateDeliveryToHub( $id: bigint!, $delivery_to_hub: Boolean! ) {
	update_cart_items( where: { id: { _eq: $id } }, _set: { delivery_to_hub: $delivery_to_hub } ) {
    	returning {
			id
			delivery_to_hub
    	}
  	}
}`

const DropdownMyOrders = React.memo( ( {
	data,
	type,
	translations,
	status,
	id,
	deliveryToHub,
	ddvValue,
	selectTaxValue,
	providerId,
} ) => {
	const [ displayDropDown, showDropDown ] = useState( false )
	const [ selectedItem, chooseItem ] = useState()
	const [ selectedItemHub, chooseItemHub ] = useState()
	const [ selectedTaxValue, chooseTaxValue ] = useState()


	const [ updateProductStatus ] = useMutation( UPDATE_STATUS_ITEM, {
		refetchQueries: () => [ {
			query: gql`${ GET_CURRENT_ORDERS }`,
			variables: {
				providerId
			}
		} ]
	} )
	const [ updateDeliveryToHubStatus ] = useMutation( UPDATE_DELIVERY_TO_HUB_STATUS, {
		refetchQueries: () => [ {
			query: gql`${ GET_CURRENT_EXPIRED_ORDERS }`,
			variables: {
				providerId
			}
		} ]
	} )

	function selectItem( item ) {
		showDropDown( !displayDropDown )
		chooseItem( item )
		chooseItemHub( item )
		chooseTaxValue( item )
	}

	function getStatusClass() {
		if ( status === 1 ) {
			return '#0E5FA6'
		}
		if ( status === 2 ) {
			return 'green'
		}
		if ( status === 5 ) {
			return 'red'
		}
		return 'grey'
	}

	function getNewStatus( item ) {
		let newStatus
		if ( item === 'inPreparation' ) {
			newStatus = 1
		}
		if ( item === 'readyAtProvider' ) {
			newStatus = 2
		}
		if ( item === 'nonDelivered' ) {
			newStatus = 5
		}
		return newStatus
	}

	function getStatusText() {
		if ( status === 1 ) {
			return <Trans id='MyOrders.inPreparation' />
		}
		if ( status === 2 ) {
			return <Trans id='MyOrders.readyAtProvider' />
		}
		if ( status === 3 ) {
			return <Trans id='MyOrders.deliveredToHub' />
		}
		if ( status === 4 ) {
			return <Trans id='MyOrders.storedAtHub' />
		}
		if ( status === 5 ) {
			return <Trans id='MyOrders.itemRejected' />
		}
		return <Trans id='MyOrders.nonDelivered' />
	}

	function getDeliveryToHub( item ) {
		if ( item === 'Provider' ) {
			return true
		}
		if ( item === 'Hub' ) {
			return false
		}
		return null
	}

	function getDeliveryToHubText() {
		if ( deliveryToHub === true ) {
			return <Trans id='MyOrders.provider' />
		}
		return <Trans id='MyOrders.hub' />
	}

	function displayDropdownListStatus() {
		if ( displayDropDown ) {
			if ( data && ( status === 1 || status === 2 || status === 5 ) ) {
				return (
					<div
						className='dropdown-item-list-my-orders-status'
						id='dropdownItemList-my-orders'
						onMouseLeave={ () => showDropDown( !displayDropDown ) }
					>
						<div label={ selectItem ? selectedItem : '' } />
						{ data.map( ( item ) => (
							<div
								className='item'
								key={ item }
								name={ item }
								role='button'
								tabIndex={ 0 }
								onKeyPress={ () => {
									updateProductStatus( { variables: { id, status_id: getNewStatus( item ) } } )
									selectItem( translations[ item ] )
								} }
								onClick={ () => {
									updateProductStatus( { variables: { id, status_id: getNewStatus( item ) } } )
									selectItem( translations[ item ] )
								} }
							>
								{ translations[ item ] }
							</div>
						) ) }
					</div>
				)
			}
			return null
		}
		return null
	}

	function displaySelectedItemNameStatus() {
		if ( selectedItem ) {
			return (
				<div
					className='Value'
					role='button'
					tabIndex={ -1 }
					onKeyPress={ () => showDropDown( !displayDropDown ) }
					onClick={ () => showDropDown( !displayDropDown ) }
				>
					{ selectedItem }
				</div>
			)
		}
		return (
			<div
				className='Value'
				role='button'
				tabIndex={ -1 }
				onKeyPress={ () => showDropDown( !displayDropDown ) }
				onClick={ () => showDropDown( !displayDropDown ) }
			>
				{ getStatusText() }

			</div>
		)
	}

	function displayDropdownListDeliveryToHub() {
		if ( displayDropDown ) {
			if ( data ) {
				return (
					<div
						className='dropdown-item-list-my-orders'
						id='dropdownItemList-my-orders'
						onMouseLeave={ () => showDropDown( !displayDropDown ) }
					>
						<div label={ selectItem ? selectedItem : '#0E5FA6' } />
						{ data.map( ( item ) => (
							<div
								className='item'
								key={ item }
								name={ item }
								role='button'
								tabIndex={ 0 }
								onKeyPress={ () => {
									updateDeliveryToHubStatus( {
										variables: { id, delivery_to_hub: getDeliveryToHub( item ) }
									} )
									selectItem( translations[ item ] )
								} }
								onClick={ () => {
									updateDeliveryToHubStatus( {
										variables: { id, delivery_to_hub: getDeliveryToHub( item ) }
									} )
									selectItem( translations[ item ] )
								} }
							>
								{ translations[ item ] }
							</div>
						) ) }
					</div>
				)
			}
			return null
		}
		return null
	}

	function displaySelectedItemNameDeliveryToHub() {
		if ( selectedItemHub ) {
			return (
				<div
					className='Value'
					role='button'
					tabIndex={ -1 }
					onKeyPress={ () => showDropDown( !displayDropDown ) }
					onClick={ () => showDropDown( !displayDropDown ) }
				>
					{ selectedItemHub }
				</div>
			)
		}
		return (
			<div
				className='Value'
				role='button'
				tabIndex={ -1 }
				onKeyPress={ () => showDropDown( !displayDropDown ) }
				onClick={ () => showDropDown( !displayDropDown ) }
			>
				{ getDeliveryToHubText() }

			</div>
		)
	}

	function displayDropdownListTaxRates() {
		if ( displayDropDown ) {
			if ( data ) {
				return (
					<div
						className='dropdown-item-list-my-orders'
						id='dropdownItemList-my-orders'
						onMouseLeave={ () => showDropDown( !displayDropDown ) }
					>
						<div label={ selectItem ? selectedItem : '#0E5FA6' } />
						{ data.map( ( item ) => (
							<div
								className='item'
								key={ item.value }
								name={ item.value }
								role='button'
								tabIndex={ 0 }
								onKeyPress={ () => {
									selectItem( item.value )
									selectTaxValue( item.value )
								} }
								onClick={ () => {
									selectItem( item.value )
									selectTaxValue( item.value )
								} }
							>
								{ `${ item.value * 100 } %` }
							</div>
						) ) }
					</div>
				)
			}
			return null
		}
		return null
	}

	function displaySelectedItemTaxRates() {
		if ( selectedTaxValue ) {
			return (
				<div
					className='Value'
					role='button'
					tabIndex={ -1 }
					onKeyPress={ () => showDropDown( !displayDropDown ) }
					onClick={ () => showDropDown( !displayDropDown ) }
				>
					{ `${ selectedTaxValue * 100 } %` }
				</div>
			)
		}
		return (
			<div
				className='Value'
				role='button'
				tabIndex={ -1 }
				onKeyPress={ () => showDropDown( !displayDropDown ) }
				onClick={ () => showDropDown( !displayDropDown ) }
			>
				{ `${ ddvValue === 0.09 ? '8.5' : ddvValue * 100 } %` }
			</div>
		)
	}

	return (
		<div
			className='dropdown-select-my-orders'
			style={ { backgroundColor: type !== 'deliveryToHub' ? getStatusClass() : '#0E5FA6' } }
			name='dropdown-select'
			id='productClass-my-orders'
		>
			<div
				className='Triangle'
				label='button'
				role='button'
				tabIndex={ -1 }
				onKeyPress={ () => showDropDown( !displayDropDown ) }
				onClick={ () => showDropDown( !displayDropDown ) }
			/>
			{ data.length > 0 && type === 'status' ? displaySelectedItemNameStatus() : null }
			{ data.length > 0 && type === 'status' ? displayDropdownListStatus() : null }
			{ data.length > 0 && type === 'deliveryToHub' ? displaySelectedItemNameDeliveryToHub() : null }
			{ data.length > 0 && type === 'deliveryToHub' ? displayDropdownListDeliveryToHub() : null }
			{ data.length > 0 && type === 'taxRate' ? displaySelectedItemTaxRates() : null }
			{ data.length > 0 && type === 'taxRate' ? displayDropdownListTaxRates() : null }
		</div>
	)
} )

DropdownMyOrders.defaultProps = {
	status: null,
	translations: null,
	deliveryToHub: null,
	ddvValue: null,
	providerId: null,
	selectTaxValue: () => {}
}

DropdownMyOrders.propTypes = {
	data: propTypes.array.isRequired,
	type: propTypes.string.isRequired,
	translations: propTypes.object,
	deliveryToHub: propTypes.bool,
	ddvValue: propTypes.number,
	status: propTypes.number,
	id: propTypes.number.isRequired,
	selectTaxValue: propTypes.func,
	providerId: propTypes.number,
}

export default DropdownMyOrders
