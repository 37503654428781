/* eslint-disable no-unused-vars */
import React from 'react'
import { TextRow, RectShape } from 'react-placeholder/lib/placeholders'

import './StandRow.css'

const StandRowPlaceholder = (
	<div id='stand-row-line'>
		<RectShape style={ { width: 165, height: 145 } } color='#ececec' />

		<div className='stand-row-line-info'>
			<TextRow style={ { height: 12 } } color='#ececec' />
			<TextRow style={ { height: 12 } } color='#ececec' />
			<TextRow style={ { height: 12 } } color='#ececec' />
		</div>
		<div className='actions-container'>
			<TextRow style={ { height: 12 } } color='#ececec' />
			<TextRow style={ { height: 12 } } color='#ececec' />
			<TextRow style={ { height: 12 } } color='#ececec' />
		</div>
		<div className='stand-dots-row' />
	</div>
)

export default StandRowPlaceholder
