import React from 'react'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import './DropdownContent.css'
import ConditionalWrapper from '../../_Globals/ConditionalWrapper'
import roleHandler from '../../../utils/ContentRoleHandler'

const DropdownContent = React.memo( ( {
	tabs, translations, isAuth, history, mobile, dropdownSubCatShown, closeDropDown
} ) => (
	<div
		id={ !dropdownSubCatShown ? 'category-dropdown-content' : 'category-dropdown-content-open' }
		className={ mobile ? 'mobile' : null }
	>
		{
			tabs.map( ( tab, index ) => (
				<ConditionalWrapper
					key={ tab.id }
					condition={ roleHandler( isAuth, tab.roles ) }
					falseConditionComponent={ <div /> }
				>
					<div
						className={ mobile ? 'buttonMobile' : null }
						role='button'
						tabIndex={ index }
						onKeyPress={ () => {
							history.push( tab.link )
							closeDropDown()
						} }
						onClick={ () => {
							history.push( tab.link )
							closeDropDown()
						} }
					>
						{ translations[ tab.id ] }
					</div>
				</ConditionalWrapper>
			) )
		}
	</div>
) )

DropdownContent.defaultProps = {
	tabs: [],
	translations: {},
	mobile: false,
	dropdownSubCatShown: false,
	closeDropDown: () => {},
}

DropdownContent.propTypes = {
	tabs: propTypes.array,
	translations: propTypes.object,
	isAuth: propTypes.bool.isRequired,
	history: propTypes.object.isRequired,
	mobile: propTypes.bool,
	dropdownSubCatShown: propTypes.bool,
	closeDropDown: propTypes.func,
}

export default withRouter( DropdownContent )
