/* eslint-disable no-unused-vars */
import React, { useState, Suspense, lazy } from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Trans } from '@lingui/macro'
import { round } from 'mathjs'
import propTypes from 'prop-types'
import getNumberFormatter from '../../../../utils/NumberFormater'
import getPriceFormatter from '../../../../utils/PriceFormatter'
import store from '../../../../stores/mainStore'

// import { LogoSmallIcon } from '../../../../icons'
// import StarComponent from '../../../_Globals/StarComponent'
import ToggleButton from '../../../_Globals/ToggleButton'

import './ProductRow.css'

const Image = lazy( () => import( './image' ) )

const GET_MARKET = gql`
query getMarketMyOffers(
	$product_id: bigint!,
){
	provider_market_has_product(where: { product_id: {_eq: $product_id } } ) {
		id
		market {
			id
			name
			hub_id
		}
    	market_id
  	}
}`

const ProductRow = React.memo( ( {
	product,
	disabled,
	selectProduct,
	updateProductActiveStatus,
	fileId,
	tenantId,
} ) => {
	const [ deactivate, setActiveStatus ] = useState( disabled )
	const productVarietyName = product.variety !== null ? product.variety.name : ''
	const { language } = store

	const {
		data: marketData,
		loading: marketLoading,
		error: marketError,
		refetch: marketRefetch,
	} = useQuery( GET_MARKET, {
		variables: {
			product_id: product.id
		}
	} )

	const market = marketData
		&& marketData.provider_market_has_product
		? marketData.provider_market_has_product[ 0 ] : null

	const selectedProduct = {
		market_id: market ? market.market_id : null,
		market: { hub_id: market && market.market ? market.market.hubId : null },
		product
	}

	function toggleState() {
		if ( deactivate ) {
			setActiveStatus( false )
			updateProductActiveStatus( false, product.id )
		} else {
			setActiveStatus( true )
			updateProductActiveStatus( true, product.id )
		}
	}

	function displayProductNameTitle() {
		if ( product.variety ) {
			return ` ${ product.gs1_brick.translation_sl.charAt( 0 ).toUpperCase() + product.gs1_brick.translation_sl.slice( 1 ) } - ${ product.variety.name }`
		}
		return ` ${ product.gs1_brick.translation_sl.charAt( 0 ).toUpperCase() + product.gs1_brick.translation_sl.slice( 1 ) }`
	}

	return (
		<div id='offer-row-line'>
			<Suspense fallback={ <div style={ { width: 165, height: 145, background: '#ececec' } } /> }>
				{ product.gs1_brick.files ? (
					<Image
						tenantId={ tenantId }
						fileId={ fileId }
						deactivate={ deactivate }
						publicImage={ product.gs1_brick.files.public }
						galleries={ product.galleries }
					/>
				) : null }
				{ !product.gs1_brick.files ? (
					<img style={ { width: 145, height: 145 } } color='#ececec' src='/img/no_default_picture.png' alt='' />
				) : null }
			</Suspense>
			<div className='offer-row-line-info'>
				<span className='offer-row-text'>
					<Trans id='OfferRow.productName:' />
					<span className='product-info-row-text'>
						{ displayProductNameTitle( product ) }
					</span>
				</span>
				<span className='offer-row-text'>
					<Trans id='ItemComponent.PricePerKilo' />
					{ ` ${ product.gs1_brick.unit.unit }` }
					<span className='product-info-row-text'>
						{ `: ${ getPriceFormatter( language ).format( product.bruto_price ) } ` }
					</span>
				</span>
				<span className='offer-row-text'>
					<Trans id='OfferRow.productQuantity:' />
					<span className='product-info-row-text'>
						{ product.quantity ? ` ${ getNumberFormatter( language ).format( round( `${ product.quantity }`, 2 ) ) }  ${ product.gs1_brick.unit.unit } ` : '' }
					</span>
				</span>
				<span className='offer-row-text'>
					<Trans id='OfferRow.marketName:' />
					<span className='product-info-row-text'>
						{ ` ${ market && market.market ? market.market.name : '' }` }
					</span>
				</span>
			</div>
			<div className='actions-container'>
				<div className='toggle-activate-deactivate'>
					<ToggleButton
						activate={ deactivate }
						rowId={ product.id }
						onClick={ () => toggleState() }
						textActive={ <Trans id='ProductRow.activate' /> }
						textDeactive={ <Trans id='ProductRow.deactivate' /> }
					/>
				</div>
				<div
					className='offer-row-line-show-offer'
					role='button'
					tabIndex={ 0 }
					onKeyPress={ () => selectProduct( selectedProduct ) }
					onClick={ () => selectProduct( selectedProduct ) }
				>
					<div className='button-text'>
						<Trans id='offerRow.lookMoreEdit' />
					</div>
					<div className='offer-row-line-arrow-right' />
				</div>
			</div>
			<div className='offer-row-dots-row' />
		</div>
	)
} )

ProductRow.defaultProps = {
	disabled: propTypes.bool,
}

ProductRow.propTypes = {
	product: propTypes.object.isRequired,
	disabled: propTypes.bool,
	selectProduct: propTypes.func.isRequired,
	updateProductActiveStatus: propTypes.func.isRequired,
	fileId: propTypes.string.isRequired,
	tenantId: propTypes.string.isRequired,
}

export default ProductRow
