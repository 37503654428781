import gql from 'graphql-tag'

const GET_PROVIDER_ID = gql`
query getProviderId( $tenant_id: String! ) {
	provider_provider(where: { tenant_id: { _eq: $tenant_id } } ) {
    	id
  }
}
`

const GET_CURRENT_ORDERS = 	gql`
query getCurrentOrders($providerId: bigint, $hubIds: [bigint!], $cycleIds: [bigint!]) {
  cart_orders(where: {status_id: {_in: [4, 5, 6, 7, 8]}, hub_id: {_in: $hubIds}, cycle_id: {_in: $cycleIds}, cycle: {finished: {_eq: true}}}, order_by: {id: desc}) {
    id
    hub {
	  id
      name
    }
    status_id
    created_at
    items(where: {market: {provider_id: {_eq: $providerId}}}, order_by: {id: desc}) {
      id
      qr_code_id
      qr_code {
		id
        base64_data
      }
      aggregated
      product {
        id
        gs1_brick {
		  id
          translation_sl
          unit {
            id
            unit
          }
        }
        variety {
		  id
          name
        }
      }
      market {
		id
        name
        provider_id
      }
      status_id
      hub {
		id
        name
      }
      order {
		id
        customer_pickup
        cycle {
		  id
          closed_time
        }
      }
      delivery_to_hub
      bruto_price
      quantity
    }
    orders_status_enum {
	  id
      value
    }
  }
}

`


const GET_CURRENT_CYCLE__AND_HUB_ID = gql`
{
  hub_cycles(where: {finished: {_eq: false}}) {
    id
    hub_id
  }
}
`

const GET_CURRENT_EXPIRED_ORDERS = 	gql`
query getCurrentExpiredOrders($providerId: bigint, $maxCycleId: bigint, $hubIds: [bigint!], $excludedCycleIds: [bigint!]) {
  cart_orders(where: {status_id: {_in: [4, 5, 6, 7]}, hub_id: {_in: $hubIds}, cycle_id: {_lt: $maxCycleId, _nin: $excludedCycleIds}, cycle: {hub_id: {_in: $hubIds}, finished: {_eq: true}}}, order_by: {id: desc}) {
    id
    hub {
	  id
      name
    }
    status_id
    created_at
    items(where: {market: {provider_id: {_eq: $providerId}}}, order_by: {id: desc}) {
      id
      qr_code_id
      qr_code {
        id
        base64_data
      }
      aggregated
      product {
        id
        gs1_brick {
		  id
          translation_sl
          unit {
            id
            unit
          }
        }
        variety {
		  id
          name
        }
      }
      market {
		id
        name
        provider_id
      }
      status_id
      hub {
		id
        name
      }
      order {
		id
        customer_pickup
        cycle {
		  id
          closed_time
        }
      }
      delivery_to_hub
      bruto_price
      quantity
    }
    orders_status_enum {
	  id
      value
    }
  }
}
`

export {
	GET_CURRENT_ORDERS,
	GET_CURRENT_EXPIRED_ORDERS,
	GET_PROVIDER_ID,
	GET_CURRENT_CYCLE__AND_HUB_ID
}
