import Keycloak from 'keycloak-js'

/*	global sessionStorage */

const keycloak = Keycloak( {
	url: process.env.REACT_APP_KEYCLOAK_URL,
	realm: process.env.REACT_APP_KEYCLOAK_REALM,
	clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID
} )

const onLogoutClearStore = () => {
	sessionStorage.clear()
	window.location.assign( window.location.hostname )
}

const parseUserRoles = ( roles ) => {
	const validRoles = Object.keys( roles ).filter( ( key ) => key.match( /\w_roles_\w/ ) )

	const appRoles = {}

	validRoles.forEach( ( key ) => {
		const currentAppRoles = roles[ key ]
		const appIdTenantId = key.split( '_roles_' )

		const appId = appIdTenantId[ 0 ]
		const tenantId = appIdTenantId[ 1 ]
		sessionStorage.setItem(
			'uhub_tenantId',
			tenantId
		)
		if ( !appRoles[ appId ] ) appRoles[ appId ] = {}
		appRoles[ appId ][ tenantId ] = currentAppRoles
	} )

	return appRoles
}

const refreshToken = ( keycloakInstance, callback ) => {
	const REFRESH_TOKEN_TIME = process.env.REACT_APP_KEYCLOAK_TOKEN_REFRESH_TIME

	keycloakInstance
		.updateToken( REFRESH_TOKEN_TIME )
		.success( ( refreshed ) => {
			if ( refreshed ) {
				callback( { success: true } )
			} else {
				callback( { success: false } )
			}
		} )
		.error( () => {
			callback( { success: false } )
		} )
}

export {
	keycloak, onLogoutClearStore, parseUserRoles, refreshToken
}
