import gql from 'graphql-tag'

const UPDATE_ORDER_WITH_CUSTOMER_ID = gql`
mutation updateOrderWithCustomerId( $id: bigint, $customer_id: String! ) {
	update_cart_orders( where: { id: { _eq: $id }, status_id: { _eq: 1 }, customer_id: { _eq: null } }, _set: { customer_id: $customer_id } ) {
    	affected_rows
  	}
}`

const GET_USER_ID = gql`
query getUserId( $keycloakId: String ) {
	users_data( where: { keycloakID: { _eq: $keycloakId } } ) {
    	id
  	}
}`

const GET_ORDERS = gql`
	query getOrders( $cart_uuid: uuid! ) {
		cart_orders( where: { 
			cart_uuid: { _eq: $cart_uuid },
			status_id: {_eq: 1 },
			customer_id: { _eq: null }
		} ){
			id
    		items {
				id
    		}
  		}
	}
`

const UPDATE_CART_ITEM_WITH_ORDER_ID = gql`
	mutation( 
		$customer_id: String!
		$order_id: bigint!
	) {
		update_cart_items( 
			where: { 
				order_id: { _eq: $order_id },
				customer_id: { _eq: null } 
			},
			_set: { customer_id: $customer_id }
		) {
			affected_rows
		}
	}
`

export {
	GET_ORDERS,
	UPDATE_ORDER_WITH_CUSTOMER_ID,
	GET_USER_ID,
	UPDATE_CART_ITEM_WITH_ORDER_ID
}
