import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'

import './HubpriceListRow.css'
import { Trans } from '@lingui/macro'
import EditArea from '../../../_Globals/EditArea'

const HubRowData = ( {
	priceListRow: {
		id, service, unit, from, to, price, currency
	}, editable, passText, newPricelistDetails, setNewPricelistDetails, deleteObjectFromArr
} ) => {
	const [ editedText, setEditedText ] = useState( {} )
	const tableCopy = newPricelistDetails
	const row = {
		id, service, unit, from, to, price, currency
	}


	function translateService( service1 ) {
		switch ( service1 ) {
		case 'DELIVERY':
			return <Trans id='FilterOrderByStatus.delivery' />
		case 'PICKUP':
			return <Trans id='HubPriceList.pickup' />
		default:
			return null
		}
	}

	useEffect( () => {
		if ( Object.keys( editedText ).length > 0 ) {
			passText( editedText )
			setEditedText( {} )
		}
	}, [ editedText, passText ] )

	function updateObjectByID( idObj, bla, text ) {
		for ( let i = 0; i < tableCopy.length; i += 1 ) {
			if ( tableCopy[ i ].id === idObj ) {
				tableCopy[ i ][ bla ] = text
				setNewPricelistDetails( [ ...tableCopy ] )
				break
			}
		}
	}

	function getText( key, text ) {
		const objectCopy = editedText
		objectCopy[ key ] = text

		objectCopy[ key ] = Number( text )


		objectCopy.id = id
		setEditedText( objectCopy )
		updateObjectByID( id, key, text )
	}

	return (
		<div id='hub-price-list-row-data' key={ id }>
			<div>{ translateService( service ) }</div>
			<div>{ `${ unit }` }</div>
			<EditArea
				id='from'
				getText={ getText }
				text={ from === '' || from === 0 ? '0' : from }
				editable={ from === '' || from === 0 ? false : editable }
				// regex={ regex.quality }
				isInput
			/>
			<EditArea
				id='to'
				getText={ getText }
				text={ to === 2147483647 ? 'Max' : to }
				editable={ to === 2147483647 ? false : editable }
				// regex={ regex.quality }
				isInput
			/>
			<div className='price-area-pricelist'>
				<EditArea
					id='price'
					getText={ getText }
					text={ price }
					editable={ editable }
					// regex={ regex.quality }
					isInput
					// onChange={ ( e ) => checkInput( e ) }
				/>
				<p className='currency-box-center'>{ currency }</p>
			</div>
			{ from === 0 || to === 2147483647
				? (
					null
				) : (
					<button
						key={ Math.random().toString( 36 ).substr( 2, 9 ) }
						id='delete-pricelist-row-button'
						type='button'
						label='button'
						className='delete-row-pricelist-button currency-box-center'
						// eslint-disable-next-line no-alert
						onClick={ ( ) => deleteObjectFromArr( row ) }
					>
						<p key={ Math.random().toString( 36 ).substr( 2, 9 ) }>x</p>

					</button>
				)			}

		</div>
	)
}

HubRowData.defaultProps = {
	passText: () => {},
}

HubRowData.propTypes = {
	priceListRow: propTypes.object.isRequired,
	editable: propTypes.bool.isRequired,
	passText: propTypes.func,
	newPricelistDetails: propTypes.array.isRequired,
	setNewPricelistDetails: propTypes.func.isRequired,
	deleteObjectFromArr: propTypes.func.isRequired
}

export default HubRowData
