import React from 'react'
import propTypes from 'prop-types'

const Error = ( { color, fontSize } ) => (
	<span className='icon-Error' style={ { fontSize: `${ fontSize }px`, color } } />
)

Error.defaultProps = {
	color: 'black',
	fontSize: 18
}
Error.propTypes = {
	color: propTypes.string,
	fontSize: propTypes.number
}

export default Error
