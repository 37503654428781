import React from 'react'
import propTypes from 'prop-types'

const CartBlueIcon = ( { color, fontSize } ) => (
	<span className='icon-vozicek-blue-10' style={ { fontSize: `${ fontSize }px`, color } } />
)

CartBlueIcon.defaultProps = {
	color: 'black',
	fontSize: 18
}
CartBlueIcon.propTypes = {
	color: propTypes.string,
	fontSize: propTypes.number
}

export default CartBlueIcon
