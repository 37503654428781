/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react'
import { Trans } from '@lingui/macro'
import propTypes from 'prop-types'
import Tooltip from 'react-tooltip-lite'
import { useQuery, useLazyQuery, useSubscription } from '@apollo/react-hooks'

import ToggleButton from './components/ToggleButton'
import ToggleHubButton from './components/ToggleHubButton'
import ItemsGrid from './components/ItemsGrid'
import HubMenu from '../HubMenu'
import SliderRow from '../SliderRow'
import SliderRowHub from '../SliderRowHub'
import SliderRowAllProducts from '../SliderRowAllProducts'
import ProviderBannerInfo from './components/ProviderBannerInfo'
import HubBannerInfo from './components/HubBannerInfo'
import { useStateValue } from '../../stores/contextStore/stateStore'
import Loader from '../Loader'

import './ShoppingContent.css'

import {
	GET_ACTIVE_AND_CONFIRMED_MARKETS,
	GET_PRODUCTS_BY_HUB_ID_AND_MARKET_ID,
	GET_ALL_ACTIVE_PRODUCTS_BY_MARKET,
	GET_HUBS,
	GET_ALL_ACTIVE_PRODUCTS_BY_HUB_SUBSCRIPTION,
	GET_PRODUCTS_BY_HUB_ID_AND_MARKET_ID_SUBSCRIPTION,
	GET_ALL_DEFAULT_QUANTITIES
} from './ShoppingContentGQL'

import {
	GET_ALL_ACTIVE_PRODUCTS_BY_HUB,
} from '../SliderRowHub/SliderRowHubGQL'

const ShoppingContent = React.memo( ( {
	marketsShoppingList, gs1ShoppingList, limit, setRefetch, refetching
} ) => {
	const [ {
		hubMenu,
		isProvider,
		selectedHub,
	}, toggleMenus ] = useStateValue()
	const [ , setFatalError ] = useStateValue()
	const [ selectedProviderData, setProvider ] = useState()
	const [ currentLenght, getCurrentLegth ] = useState( 1 )
	const [ currentLenght2, getCurrentLegth2 ] = useState( 1 )
	const [ currentLenghtMarkets, getCurrentLenghtMarkets ] = useState( 1 )

	const [ productsDisplayByHub, getNewProductsToDisplayByHub ] = React.useState( null )
	const [ productsDisplayByHubMarket, getNewProductsToDisplayByHubMarket ] = React.useState( null )


	// Create filters for shopping list
	const marketFilters = marketsShoppingList
		.filter( ( item ) => !item.disabled )
		.map( ( market ) => market.id )
	const gs1Filters = gs1ShoppingList
		.filter( ( item ) => !item.disabled )
		.map( ( gs1Product ) => gs1Product.id )

	const {
		data: hubsData,
		error: hubsError,
		loading: hubsLoading,
	} = useQuery( GET_HUBS )

	const {
		data: marketData,
		loading: marketLoading,
		error: marketError,
		fetchMore: marketsFetchMore,
	} = useQuery( GET_ACTIVE_AND_CONFIRMED_MARKETS, {
		variables: {
			limit: 25,
			marketFilters: marketFilters.length > 0 ? marketFilters : null
		},
		fetchPolicy: 'cache-and-network',

	} )

	const [
		getProductsByHub,
		{
			data: productsByHubData,
			loading: productsByHubLoading,
			error: productsByHubError,
			fetchMore: productsByHubFetchMore,
		}
	] = useLazyQuery( GET_ALL_ACTIVE_PRODUCTS_BY_HUB, {
		variables: {
			limit: 10,
			marketFilters: marketFilters.length > 0 ? marketFilters : null,
			gs1Filters: gs1Filters.length > 0 ? gs1Filters : null,
		},
		fetchPolicy: 'cache-and-network',
	} )

	const [
		getProductsOnMarketByMarketId,
		{
			data: productsByMarketData,
			loading: productsByMarketLoading,
			error: productsByMarketError
		}
	] = useLazyQuery( GET_ALL_ACTIVE_PRODUCTS_BY_MARKET, {
		variables: {
			gs1Filters: gs1Filters.length > 0 ? gs1Filters : null,
			limit: 10
		}
	} )

	const [
		getProductsByHubIdandTenantId,
		{
			data: productsByHubTenantIdData,
			loading: productsByHubTenantIdDataLoading,
			error: productsByHubTenantIdDataError,
			fetchMore: productsByHubAndMarketFetchMore,
		}
	] = useLazyQuery( GET_PRODUCTS_BY_HUB_ID_AND_MARKET_ID, {
		variables: {
			limit: 10,
			marketFilters: marketFilters.length > 0 ? marketFilters : null,
			gs1Filters: gs1Filters.length > 0 ? gs1Filters : null,
		},
		fetchPolicy: 'cache-and-network',
	} )

	const markets = marketData && marketData.provider_market ? marketData.provider_market : []
	const hubs = hubsData && hubsData.hub_hub && hubsData.hub_hub.length > 0 ? hubsData.hub_hub : []

	const {
		data: defaultQuantities,
		loading: defaultQuantitiesLoading,
		error: defaultQuantitiesError
	} = useQuery( GET_ALL_DEFAULT_QUANTITIES )

	function quantityHelper( product ) {
		const unitId = product.product.gs1_brick.unit.id
		// eslint-disable-next-line camelcase
		if ( defaultQuantities?.code_register_units_default_quantities !== undefined ) {
			// eslint-disable-next-line max-len
			for ( let j = 0; j < defaultQuantities.code_register_units_default_quantities.length; j += 1 ) {
				if ( unitId === defaultQuantities.code_register_units_default_quantities[ j ].unit_id ) {
					if ( product.product.quantity
						>= defaultQuantities.code_register_units_default_quantities[ j ].quantity ) {
						return true
					}
				}
			}
		}
		return false
	}


	function marketHelper() {
		const finalArray = []
		if ( markets ) {
			for ( let i = 0; i < markets.length; i++ ) {
				if ( markets[ i ].products.length !== 0 ) {
					const { products } = markets[ i ]
					let added = false
					for ( let j = 0; j < products.length; j++ ) {
						const product = products[ j ]
						if ( quantityHelper( product ) ) {
							added = true
						}
					}
					if ( added ) {
						finalArray.push( markets[ i ] )
					}
				}
			}
		}
		return finalArray
	}

	let products = []

	if ( productsByHubData && selectedHub && !selectedProviderData
		&& productsByHubData.provider_market
		&& productsByHubData.provider_market.length > 0 ) {
		products = productsByHubData.provider_market.map(
			( marketProduct ) => marketProduct.products
		).flat()
	}

	if ( productsByHubData && selectedHub && !selectedProviderData
		&& productsByHubData.provider_market
		&& productsByHubData.provider_market.length === 0 ) {
		products = []
	}

	if ( productsByMarketData && !selectedHub && selectedProviderData
		&& productsByMarketData.provider_market
		&& productsByMarketData.provider_market.length > 0 ) {
		products = productsByMarketData.provider_market[ 0 ].products
	}

	if ( productsByHubTenantIdData && selectedHub && selectedProviderData
	) {
		products = productsByHubTenantIdData.hub_hub
		&& productsByHubTenantIdData.hub_hub[ 0 ].markets
		&& productsByHubTenantIdData.hub_hub[ 0 ].markets.length > 0
			? productsByHubTenantIdData.hub_hub[ 0 ].markets[ 0 ].products : []
	}

	const {
		data: subscribeHubProducts,
		error: subscribeHubProductsError
	} = useSubscription( GET_ALL_ACTIVE_PRODUCTS_BY_HUB_SUBSCRIPTION, {
		variables: {
			hub_id: selectedHub ? selectedHub.id : 0,
			limit: productsByHubData ? productsByHubData.provider_market.map(
				( marketProduct ) => marketProduct.products
			).flat().length : 0,

			marketFilters: marketFilters.length > 0 ? marketFilters : null,
			gs1Filters: gs1Filters.length > 0 ? gs1Filters : null,
		},
		onSubscriptionData: ( data ) => {
			getNewProductsToDisplayByHub( data?.subscriptionData?.data?.provider_market?.map(
				( marketProduct ) => marketProduct.products
			)?.flat() ?? [] )
		}
	} )

	const {
		data: subscribeHubMarketProducts,
		error: subscribeHubMarketProductsError
	} = useSubscription( GET_PRODUCTS_BY_HUB_ID_AND_MARKET_ID_SUBSCRIPTION, {
		variables: {
			hub_id: selectedHub ? selectedHub.id : 0,
			market_id: selectedProviderData ? selectedProviderData.id : 0,
			limit: productsByHubTenantIdData
				? productsByHubTenantIdData.hub_hub[ 0 ].markets[ 0 ].products.length : 0,

			marketFilters: marketFilters.length > 0 ? marketFilters : null,
			gs1Filters: gs1Filters.length > 0 ? gs1Filters : null,
		},
		onSubscriptionData: ( data ) => {
			getNewProductsToDisplayByHubMarket(
				data.subscriptionData.data.hub_hub.length > 0
				&& data.subscriptionData.data.hub_hub[ 0 ].markets.length > 0
					? data.subscriptionData.data.hub_hub[ 0 ].markets[ 0 ].products
					: null
			)
		}
	} )

	let productsSub = []

	if ( productsByHubData && selectedHub && !selectedProviderData ) {
		productsSub = productsDisplayByHub
	}

	if ( productsByHubTenantIdData && selectedHub && selectedProviderData
	) {
		productsSub = productsDisplayByHubMarket
	}

	useEffect( () => {
		if ( selectedHub && selectedHub.id && !selectedProviderData ) {
			getProductsByHub( {
				variables: {
					hub_id: selectedHub.id,
				}
			} )
		}

		if ( selectedHub && selectedProviderData ) {
			getProductsByHubIdandTenantId(
				{
					variables:
						{
							hub_id: selectedHub.id,
							market_id: selectedProviderData.id,
						}
				}
			)
		}

		if ( selectedProviderData ) {
			getProductsOnMarketByMarketId(
				{
					variables:
						{
							market_id: selectedProviderData.id,
						}
				}
			)
		}
	}, [
		getProductsByHub,
		getProductsByHubIdandTenantId,
		getProductsOnMarketByMarketId, selectedHub, selectedProviderData ] )

	function onLoadMoreHubProducts() {
		getCurrentLegth2( 1 )
		getCurrentLenghtMarkets( 1 )
		productsByHubFetchMore( {
			variables: {
				offset: productsByHubData.provider_market.map(
					( marketProduct ) => marketProduct.products
				).flat().length
			},
			updateQuery: ( prev, { fetchMoreResult } ) => {
				if ( !fetchMoreResult ) return prev
				getCurrentLegth( fetchMoreResult.provider_market[ 0 ].products.length )
				return {
					...prev,
					provider_market: [
						...prev.provider_market,
						...fetchMoreResult.provider_market
					]
				}
			}
		} )
	}

	function onLoadMoreMarketAndHubProducts() {
		getCurrentLegth( 1 )
		getCurrentLenghtMarkets( 1 )
		productsByHubAndMarketFetchMore( {
			variables: {
				offset: productsByHubTenantIdData.hub_hub[ 0 ].markets[ 0 ].products.length
			},
			updateQuery: ( prev, { fetchMoreResult } ) => {
				if ( !fetchMoreResult ) return prev
				getCurrentLegth2( fetchMoreResult.hub_hub[ 0 ].markets[ 0 ].products.length )
				return {
					...prev,
					hub_hub: [
						{
							id: productsByHubTenantIdData.hub_hub[ 0 ].id,
							markets: [
								{
									id: productsByHubTenantIdData.hub_hub[ 0 ].markets[ 0 ].id,
									active: true,
									confirmed: true,
									hub_id: productsByHubTenantIdData.hub_hub[ 0 ].markets[ 0 ].hub_id,
									products: [
										...prev.hub_hub[ 0 ].markets[ 0 ].products,
										...fetchMoreResult.hub_hub[ 0 ].markets[ 0 ].products
									],
									__typename: 'provider_market'
								}
							],
							__typename: 'hub_hub'
						}
					]
				}
			}
		} )
	}

	function onLoadMoreMarkets() {
		getCurrentLegth( 1 )
		getCurrentLegth2( 1 )
		marketsFetchMore( {
			variables: {
				offset: productsByHubData.provider_market.length
			},
			updateQuery: ( prev, { fetchMoreResult } ) => {
				if ( !fetchMoreResult ) return prev
				getCurrentLenghtMarkets( fetchMoreResult.provider_market.length )
				let array = [ ...prev.provider_market, ...fetchMoreResult.provider_market ]
				array = array.filter( ( v, i, a ) => a.findIndex( ( t ) => ( t.id === v.id ) ) === i )
				const finalArray = []
				for ( let i = 0; i < array.length; i += 1 ) {
					if ( array[ i ].products.length !== 0 ) {
						finalArray.push( array[ i ] )
					}
				}
				return {
					...prev,
					provider_market: finalArray
				}
			}
		} )
	}

	function getHubSliders() {
		return hubs.map( ( hub ) => (
			<div key={ hub.id }>
				{ !isProvider
					? (
						<SliderRowHub
							hubId={ hub.id }
							hubName={ hub.name }
							marketFilters={ marketFilters }
							gs1Filters={ gs1Filters }
							isHubMenuClosed={ hubMenu }
							key={ hub.id }
						/>
					) : (
						<SliderRow
							productOrProvider={ isProvider }
							isHubMenuClosed={ hubMenu }
							sliderTitle={ <Trans id='Slider.providersOnHub { hubName }' values={ { hubName: hub.name } } /> }
							slides={ isProvider ? marketHelper().filter( ( m ) => m.hub_id === hub.id ) : [] }
							setProvider={ ( provider ) => setProvider( provider ) }
							productsByHubLoading={ productsByHubLoading }
							productsByHubTenantIdDataLoading={ productsByHubTenantIdDataLoading }
							marketLoading={ marketLoading }
							// productsLoading={ productsLoading }
						/>
					) }
			</div>
		) )
	}

	if ( productsByHubError
		|| hubsError
		|| marketError
		|| productsByHubTenantIdDataError
		|| productsByMarketError
		|| subscribeHubProductsError ) {
		// setFatalError( { type: 'fatalError', fatalError: true } )
		return (
			<div>
				{ JSON.stringify( hubsError ) }
				{ JSON.stringify( marketError ) }
				{ JSON.stringify( productsByHubError ) }
				{ JSON.stringify( productsByHubTenantIdDataError ) }
				{ JSON.stringify( productsByMarketError ) }
				{ JSON.stringify( subscribeHubProductsError ) }
				{ JSON.stringify( subscribeHubMarketProductsError ) }
			</div>
		)
	}

	return (
		<div className='shopping-container'>
			<div className={ hubMenu ? 'white-background' : 'white-background-full' } />
			<div id='hub-menu' className={ hubMenu ? 'hub-menu-closed' : 'hub-menu-open' }>
				<HubMenu resetProvider={ () => setProvider() } />
			</div>
			<div className={ hubMenu ? 'divider-shaddow' : 'divider-shaddow-full' } />
			<div className={ hubMenu ? 'container-layout' : 'container-layout-full' }>
				<div id='shopping-grid' className={ hubMenu ? 'shopping-grid-full' : 'shopping-grid-threeth' }>

					{ selectedProviderData
						? (
							<ProviderBannerInfo
								hubMenu={ hubMenu }
								selectedProviderData={ selectedProviderData }
								setProvider={ ( provider ) => setProvider( provider ) }
							/>
						) : null }
					{ selectedHub
						? (
							<HubBannerInfo
								hubMenu={ hubMenu }
								selectedHub={ selectedHub }
							/>
						) : null }
					<Tooltip
						content={ hubMenu ? <Trans id='Tooltip.toggleOpen' /> : <Trans id='Tooltip.toggleClose' /> }
						direction='right'
						arrowSize={ 5 }
						padding='5px'
						distance={ 10 }
					>
						<ToggleHubButton
							isHubMenuClosed={ hubMenu }
							handleButtonClick={ () => toggleMenus( { type: 'toggleHubMenu' } ) }
						/>
					</Tooltip>
					<div className={ hubMenu ? 'toggle-button' : 'toggle-button-hide' }>
						<Tooltip
							id='tooltipSwitchButton'
							content={ <Trans id='Tooltip.toggleProviderProduct' /> }
							direction='bottom'
							arrowSize={ 5 }
							padding='5px'
							distance={ 10 }
						>
							<ToggleButton
								isProviderClicked={ isProvider }
								hubMenu={ hubMenu }
								handleToggleClick={ ( e ) => toggleMenus( { type: 'toggleProviderProduct', value: e } ) }
							/>
						</Tooltip>
					</div>
					<div className='shopping-list-container'>
						{ selectedHub === null
							? (
								<>
									{ getHubSliders() }
									{ !isProvider
										? (
											<SliderRowAllProducts
												marketFilters={ marketFilters }
												gs1Filters={ gs1Filters }
												isHubMenuClosed={ hubMenu }
											/>
										) : (
											<SliderRow
												productOrProvider={ isProvider }
												isHubMenuClosed={ hubMenu }
												sliderTitle={ <Trans id='Slider.allProviders' /> }
												slides={ isProvider ? marketHelper() : [] }
												setProvider={ ( provider ) => setProvider( provider ) }
												productsByHubLoading={ productsByHubLoading }
												productsByHubTenantIdDataLoading={ productsByHubTenantIdDataLoading }
												marketLoading={ marketLoading }
												// productsLoading={ productsLoading }
											/>
										) }
								</>
							)
							: (
								<ItemsGrid
									items={ isProvider ? marketHelper() : productsSub }
									setProvider={ ( provider ) => setProvider( provider ) }
									productsByHubLoading={ productsByHubLoading }
									productsByHubTenantIdDataLoading={ productsByHubTenantIdDataLoading }
									marketLoading={ marketLoading }
									// productsLoading={ productsLoading }
								/>
							) }

						{ /* When to display button loader */ }
						{
							marketLoading
							|| productsByHubLoading
							|| productsByHubTenantIdDataLoading
							|| productsByMarketLoading
							|| productsDisplayByHub === []
							|| productsDisplayByHubMarket === []
								? <div className='loader'><Loader /></div> : null
						}

						{ /* When to display button for getting more products */ }
						{ selectedHub && selectedProviderData
							? (
								<div className='button-container-fetch-more'>
									<div
										className={ currentLenght2 !== 0 ? 'fetch-more' : 'fetch-more-disabled' }
										role='button'
										tabIndex={ -1 }
										onKeyPress={ () => (
											currentLenght2 !== 0 ? onLoadMoreMarketAndHubProducts() : {}
										) }
										onClick={ () => (
											currentLenght2 !== 0 ? onLoadMoreMarketAndHubProducts() : {}
										) }
									>
										<Trans id='Button.ShowMore' />
									</div>
								</div>
							) : null }
						{ selectedHub && !selectedProviderData && !isProvider
							? (
								<div className='button-container-fetch-more'>
									<div
										className={ currentLenght !== 0 ? 'fetch-more' : 'fetch-more-disabled' }
										role='button'
										tabIndex={ -1 }
										onKeyPress={ () => ( currentLenght !== 0 ? onLoadMoreHubProducts() : {} ) }
										onClick={ () => ( currentLenght !== 0 ? onLoadMoreHubProducts() : {} ) }
									>
										<Trans id='Button.ShowMore' />
									</div>
								</div>
							) : null }
						{ selectedHub && !selectedProviderData && isProvider
							? (
								<div className='button-container-fetch-more'>
									<div
										className={ currentLenghtMarkets !== 0 ? 'fetch-more' : 'fetch-more-disabled' }
										role='button'
										tabIndex={ -1 }
										onKeyPress={ () => ( currentLenghtMarkets !== 0 ? onLoadMoreMarkets() : {} ) }
										onClick={ () => ( currentLenghtMarkets !== 0 ? onLoadMoreMarkets() : {} ) }
									>
										<Trans id='Button.ShowMore' />
									</div>
								</div>
							) : null }
					</div>
				</div>
			</div>
		</div>
	)
} )

ShoppingContent.propTypes = {
	marketsShoppingList: propTypes.array.isRequired,
	gs1ShoppingList: propTypes.array.isRequired,
	// limit: propTypes.number.isRequired,
	// refetching: propTypes.bool.isRequired,
	// setRefetch: propTypes.func.isRequired,
}

export default ShoppingContent
