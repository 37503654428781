/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import propTypes from 'prop-types'

import { useStateValue } from '../stores/contextStore/stateStore'
import store from '../stores/mainStore'

const PrivateRoute = ( { component: Component, ...rest } ) => {
	const [ { isAuth } ] = useStateValue()
	return (
		<Route
			{ ...rest }
			// eslint-disable-next-line no-return-assign
			render={ ( props ) => ( isAuth ? (
				<Component { ...props } />
			) : (
				<Redirect to={ window.location.assign( store.keycloak.createLoginUrl() ) } />
			) ) }
		/>
	)
}

PrivateRoute.propTypes = {
	component: propTypes.any.isRequired
}

export default PrivateRoute
