import React from 'react'
import { Trans } from '@lingui/macro'
import propTypes from 'prop-types'

import EditArea from '../../../../../components/_Globals/EditArea'

const AddUserInfo = ( {
	newEmail, user, getText, availableRoles,
	isUserNew, backButton, addRole, deleteAssignedRole, userAssignedRoles, onAddUserButtonClick
} ) => {
	// eslint-disable-next-line no-useless-escape
	const regexEmail = new RegExp( /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'i' )
	const regexName = new RegExp( /^[A-Za-zČŠčšŽž-\s]+$/, 'i' )
	const regexUsername = new RegExp( /^([a-z0-9])+$/ )
	const regexPassword = new RegExp( /^.{8,}$/ )

	return (
		<div className='user-profile-container'>
			<div className='back-button'>
				<button className='settings-button back-button' type='button' onClick={ () => backButton() }>
					<p><Trans id='UserProfileSettings.BackButton' /></p>
				</button>
			</div>
			<div className='name-settings'>
				<Trans id='UserProfileSettings.Email' />
			</div>
			<EditArea
				id='email2'
				isInput
				myStyle='hub-view-hub-title-editable edit-area-settings'
				text={ newEmail }
				regex={ regexEmail }
			/>
			<div className='name-settings'>
				<Trans id='UserProfileSettings.Username' />
			</div>
			<EditArea
				id='username'
				isInput
				myStyle='hub-view-hub-title-editable edit-area-settings'
				text={ user.username }
				getText={ getText }
				editable={ isUserNew }
				regex={ regexUsername }
			/>
			<div className='name-settings'>
				<Trans id='UserProfileSettings.FirstName' />
			</div>
			<EditArea
				id='firstName'
				isInput
				myStyle='hub-view-hub-title-editable edit-area-settings'
				text={ user.firstName }
				getText={ getText }
				editable={ isUserNew }
				regex={ regexName }
			/>
			<div className='name-settings'>
				<Trans id='UserProfileSettings.Surname' />
			</div>
			<EditArea
				id='lastName'
				isInput
				myStyle='hub-view-hub-title-editable edit-area-settings'
				text={ user.lastName }
				getText={ getText }
				editable={ isUserNew }
				regex={ regexName }
			/>
			<div className='name-settings'><Trans id='UserProfileSettings.AvilableRoles' /></div>
			<div className='role-container'>
				{availableRoles && availableRoles.map( ( role ) => (
					<div
						key={ role }
						className='avilable-roles-chip'
						onClick={ () => addRole( role ) }
						onKeyPress={ () => addRole( role ) }
						role='button'
						tabIndex='0'
					>
						{role}
					</div>

				) )}
			</div>
			<div className='name-settings'><Trans id='UserProfileSettings.AssignedRoles' /></div>
			<div className='role-container'>
				{ userAssignedRoles.roles && userAssignedRoles.roles.map( ( item ) => (
					<div
						key={ item }
						className='avilable-roles-chip'
						onClick={ () => deleteAssignedRole( item ) }
						onKeyPress={ () => deleteAssignedRole( item ) }
						role='button'
						tabIndex='0'
					>
						{item}
						<span className='chip-closebtn' type='button'> x</span>

					</div>

				) )}
			</div>
			{
				isUserNew
					? (
						<>
							<div className='name-settings'>
								<Trans id='RegistrationInput.password' />
							</div>
							<EditArea
								id='password'
								isInput
								myStyle='hub-view-hub-title-editable edit-area-settings'
								text={ user.username }
								getText={ getText }
								editable={ isUserNew }
								regex={ regexPassword }
							/>
							<div className='name-settings'>
								<Trans id='confirmPassword.confirmPassword' />
							</div>
							<EditArea
								id='passwordConfirm'
								isInput
								myStyle='hub-view-hub-title-editable edit-area-settings'
								text={ user.username }
								getText={ getText }
								editable={ isUserNew }
								regex={ regexPassword }
							/>
						</>
					)
					: null
			}

			<div className='my-profile-button-container'>
				<button
					className='settings-button'
					type='button'
					onClick={ () => onAddUserButtonClick() }
				>
					<Trans id='UserProfileSettings.AddUser' />
				</button>
			</div>
		</div>
	)
}

AddUserInfo.propTypes = {
	newEmail: propTypes.string.isRequired,
	user: propTypes.object.isRequired,
	getText: propTypes.func.isRequired,
	availableRoles: propTypes.array.isRequired,
	isUserNew: propTypes.bool.isRequired,
	backButton: propTypes.func.isRequired,
	addRole: propTypes.func.isRequired,
	deleteAssignedRole: propTypes.func.isRequired,
	userAssignedRoles: propTypes.object.isRequired,
	onAddUserButtonClick: propTypes.func.isRequired
}

export default AddUserInfo
