import React, { Suspense, lazy } from 'react'
import propTypes from 'prop-types'
// import LogoSmallIcon from '../../icons/LogoSmallIcon'
import { useStateValue } from '../../stores/contextStore/stateStore'
import './ProviderComponent.css'

const Image = lazy( () => import( './components/image' ) )

const ProviderComponent = React.memo( ( { itemData, setProvider } ) => {
	const [ , getProviderInfo ] = useStateValue()
	const fileId = itemData.galleries.length > 0 ? itemData.galleries[ 0 ].file.file_id : null
	const tenantId = itemData.galleries.length > 0 ? itemData.galleries[ 0 ].file.tenant_id : null

	return (
		<div
			className='seller-grid-container'
			role='button'
			tabIndex={ 0 }
			onClick={ () => {
				setProvider( itemData )
				getProviderInfo( { type: 'getProviderInfo', getProviderInfo: itemData } )
			} }
			onKeyPress={ () => {
				setProvider( itemData )
				getProviderInfo( { type: 'getProviderInfo', getProviderInfo: itemData } )
			} }
		>
			<div className='seller-image'>
				{ itemData && itemData.galleries.length > 0 ? (
					<Suspense fallback={ <div style={ { width: 165, height: 145, background: '#ececec' } } /> }>
						<Image
							tenantId={ tenantId }
							fileId={ fileId }
						/>
					</Suspense>
				) : (
					<img
						className='image-slider-image'
						style={ { width: 165, height: 145 } }
						src='/img/market_default.png'
						alt=''
					/>
				) }
			</div>
			<div className='seller-name'>{ itemData.name }</div>
			<div className='seller-description'>{ itemData.description }</div>
			{/* <div id='seller-products' className={
                itemData.providerDescription.filters.length > 4 ? 'seller-products-more'
                : 'seller-products-less' }>
				{ itemData.providerDescription.filters.map( ( item ) => (
					<div key={ item.id } className='products-item'>{ item.item }</div>
				) )}
			</div> */}
		</div>
	)
} )

ProviderComponent.propTypes = {
	itemData: propTypes.object.isRequired,
	setProvider: propTypes.func.isRequired,
}

export default ProviderComponent
