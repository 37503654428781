import React from 'react'
import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'

import { dateToStringNoHoursMinutes } from '../../../utils/DateToString'
import store from '../../../stores/mainStore'


import './TableHubCycleOrderRow.css'
import ItemStatusDropdown from './ItemStatusDropdown'

const STATUS = [ 'inPreparation', 'readyAtProvider', 'deliveredToHub', 'storedAtHub', 'nonDelivered', 'itemrejected' ]

const TRANSLATIONS_STATUS_ORDER = {
	inPreparation: <Trans id='OrderDisplay.inPreparation' />,
	readyAtProvider: <Trans id='OrderDisplay.readyAtProvider' />,
	deliveredToHub: <Trans id='OrderDisplay.deliveredToHub' />,
	storedAtHub: <Trans id='OrderDisplay.storedAtHub' />,
	nonDelivered: <Trans id='OrderDisplay.nonDelivered' />,
	itemrejected: <Trans id='OrderDisplay.rejected' />
}
const TableHubCycleOrderRow = React.memo( ( {
	data, /* orderInfo, */ onClick
} ) => {
	function displayDeliveryToHub() {
		if ( data.delivery_to_hub === true ) {
			return <Trans id='TableHubCycleOrderRow.provider' />
		}
		return <Trans id='TableHubCycleOrderRow.hub' />
	}

	function displayProductNameTitle() {
		if ( data.product.variety ) {
			return ` ${ data.product.gs1_brick.translation_sl.charAt( 0 ).toUpperCase() + data.product.gs1_brick.translation_sl.slice( 1 ) } - ${ data.product.variety.name }`
		}
		return ` ${ data.product.gs1_brick.translation_sl.charAt( 0 ).toUpperCase() + data.product.gs1_brick.translation_sl.slice( 1 ) }`
	}

	return (
		<div
			className='tableHubCycleOrder-row-container'
			role='button'
			tabIndex={ -1 }
			onClick={ onClick }
			onKeyPress={ onClick }
		>
			<div className='tableHubCycle-code'>
				<img alt='qr-code' width='120' height='120' src={ `data:image/png;base64, ${ data.qr_code.base64_data }` } />
			</div>
			<div className='tableHubCycle-aggregation'>
				{ data.aggregated
					? <Trans id='TableHubCycleOrderRow.aggregatedYes' />
					: <Trans id='TableHubCycleOrderRow.aggregatedNo' /> }
			</div>
			<div className='tableHubCycle-product'>
				{ displayProductNameTitle() }
			</div>
			<div className='tableHubCycle-provider'>
				{ data.market.name }
			</div>
			<div className='tableHubCycle-quantity'>
				{ data.product.quantity || data.quantity}
			</div>
			<div className='tableHubCycle-unit'>
				{ data.product.gs1_brick.unit.unit }
			</div>
			<div className='tableHubCycle-price'>
				{ `${ data.product.bruto_price || data.bruto_price } EUR` }
			</div>
			<div className='tableHubCycle-delivery'>
				{ displayDeliveryToHub() }
			</div>
			<div className='tableHubCycle-packaging'>
				<Trans id='TableHubCycleOrderRow.provider' />
			</div>
			<div className='tableHubCycle-deliverability'>
				{ dateToStringNoHoursMinutes( data.hub.cycles[ 0 ].closed_time, store.language ) }
			</div>
			<div className='item-status-orders'>
				<ItemStatusDropdown
					data={ STATUS }
					translations={ TRANSLATIONS_STATUS_ORDER }
					type='status'
					id={ data.id }
					status={ data.status_id }
				/>
			</div>
			<div className='tableHubCycle-storageLocation'>
				{ data.order.warehouses.length > 0 ? data.order.warehouses[ 0 ].drawer_code : '' }
			</div>
		</div>
	)
} )

TableHubCycleOrderRow.propTypes = {
	data: propTypes.object.isRequired,
	// orderInfo: propTypes.object.isRequired,
	onClick: propTypes.func.isRequired
}

export default TableHubCycleOrderRow
