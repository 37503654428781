import React, { useState } from 'react'

import propTypes from 'prop-types'
import ConditionalWrapper from '../_Globals/ConditionalWrapper'
import renderLandingPage from '../../LandingPage'
import Button from '../_Globals/Button/Button'
import './TenantPickerPopup.css'


const TenantPickerPopup = React.memo( ( {
	roles
} ) => {
	const [ showPopup, setShowPopup ] = useState( true )


	function splitRole( tenant ) {
		const appIdTenantId = tenant.split( '_roles_' )

		const tenantId = appIdTenantId[ 1 ]
		return tenantId
	}

	function togglePopup( value ) {
		setShowPopup( value )
		if ( showPopup ) {
			const e = document.getElementById( 'slct' )
			const getTenantId = e.options[ e.selectedIndex ].value
			window.sessionStorage.setItem(
				'uhub_tenantId',
				getTenantId
			)
			renderLandingPage( true )
		}
	}

	return (
		<ConditionalWrapper condition={ showPopup } falseConditionComponent={ <div /> }>
			<div className='popupTenant-main-container'>
				<div className='popup-window'>
					<p className='popup-window-title'>Choose Tenant</p>
					<div className='popup-window-dropdown-container'>
						<select
							className='popup-select'
							name='slct'
							id='slct'
						>
							{ roles.map( ( role ) => (
								<option
									key={ splitRole( role ) }
									value={ splitRole( role ) }
								>
									{ splitRole( role ) }
								</option>
							) ) }
						</select>
					</div>
					<div className='popup-windows-close-btn'>
						<Button
							text='Confirm'
							disabled
							onClick={ () => togglePopup( false ) }
						/>
					</div>
				</div>
			</div>
		</ConditionalWrapper>
	)
} )

TenantPickerPopup.propTypes = {
	roles: propTypes.func.isRequired
}


export default TenantPickerPopup
