import React from 'react'
import propTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Trans } from '@lingui/macro'
import { withRouter } from 'react-router-dom'

import Image from './components/image'
import { ReturnSendIcon } from '../../../../icons'
import './ProviderBannerInfo.css'

const GET_MARKET_GALLERY = gql`
	query get_product_gallery(
		$market_id: bigint!
	){
		media_gallery(where: { market_id: { _eq: $market_id }, default: { _eq: true } } ) {
			id
			file {
				id
				file_id
				tenant_id
			}
		}
	}
`

const ProviderBannerInfo = React.memo( ( {
	hubMenu,
	selectedProviderData,
	history,
	setProvider
} ) => {
	const {
		data: galleryData
	} = useQuery( GET_MARKET_GALLERY, {
		variables: { market_id: selectedProviderData.id }
	} )

	const fileId = galleryData
		&& galleryData.media_gallery.length > 0
		? galleryData.media_gallery[ 0 ].file.file_id : null

	const tenantId = galleryData
		&& galleryData.media_gallery.length > 0
		? galleryData.media_gallery[ 0 ].file.tenant_id : null

	return (
		<div className={ hubMenu ? 'shopping-list-provider-info-market' : 'shopping-list-provider-info-market-full' }>
			<div
				className='back-to-markets'
				role='button'
				tabIndex={ 0 }
				onClick={ () => setProvider( null ) }
				onKeyPress={ () => setProvider( null ) }
			>
				<span>
					<ReturnSendIcon fontSize={ 14 } />
				</span>
				<span className='text'><Trans id='ProviderInfo.backToStands' /></span>
			</div>
			<div className='market-title'>
				<span>
					<Trans id='ProviderInfo.stand' />
				</span>
				<span style={ { textTransform: 'capitalize' } }>
					<b>{ selectedProviderData?.name?.toLowerCase() ?? '' }</b>
				</span>
			</div>
			<div className='market-image'>
				{ galleryData && galleryData.media_gallery.length > 0 ? (
					<Image
						tenantId={ tenantId }
						fileId={ fileId }
					/>
				) : (
					<img
						style={ { width: 165, height: 145 } }
						src='/img/market_default.png'
						alt=''
					/>
				) }
			</div>
			<div className='market-description'>
				<p>
					{ selectedProviderData.description }
				</p>
			</div>
			{/* <div className='market-filters'>
				{ selectedProviderDescription.providerDescription.filters.map( ( item ) => (
					<div key={ item.id } className='products-item'>{ item.item }</div>
				) )}
			</div> */}
			<div
				className='shopping-list-button-show-more'
				role='button'
				tabIndex={ -1 }
				onKeyPress={ () => history.push( '/provider-info' ) }
				onClick={ () => history.push( '/provider-info' ) }
			>
				<Trans id='Button.showMore' />
			</div>
		</div>
	)
} )

ProviderBannerInfo.propTypes = {
	selectedProviderData: propTypes.object.isRequired,
	hubMenu: propTypes.bool.isRequired,
	history: propTypes.object.isRequired,
	setProvider: propTypes.func.isRequired,
}

export default withRouter( ProviderBannerInfo )
