import React from 'react'
import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'
import { useQuery } from '@apollo/react-hooks'
import Button from '../../_Globals/Button/Button'
import './AddressPage.css'
import Loader from '../../Loader'

/* global sessionStorage */

import GET_DELIVERY_ADDRESS from './addressPageGQL'

const AddressPage = React.memo( ( {
	clickBack, clickNext
} ) => {
	const tenantId = sessionStorage.getItem( 'uhub_tenantId' )

	const {
		data: addressData,
		loading: addressLoading,
		error: addressError,
	} = useQuery( GET_DELIVERY_ADDRESS, {
		variables: {
			customer_id: tenantId
		},
		fetchPolicy: 'network-only',
	} )

	const deliveryAddress = addressData
		&& addressData.cart_orders.length > 0
		&& addressData.cart_orders[ 0 ].customer
		? addressData.cart_orders[ 0 ].customer : null


	return (
		<div className='shopping-cart-address-main-container'>
			<div className='delivery-forms-container'>
				<div className='address-title'><Trans id='ShoppingCart.deliveryAddress' /></div>
				{ addressLoading ? <Loader /> : null }
				{ addressError ? <div>{ JSON.stringify( addressError ) }</div> : null }
				{ deliveryAddress ? (
					<div className='address-form-delivery'>
						<p className='name'>
							{ `${ deliveryAddress.owner?.firstName } ${ deliveryAddress.owner?.lastName }` }
						</p>
						<p className='address'>
							{ `${ deliveryAddress.applications[ 0 ]?.data?.address[ 0 ]?.address }` }
						</p>
						<p className='zip'>
							{ `${ deliveryAddress.applications[ 0 ]?.data?.address[ 0 ]?.zip } ${ deliveryAddress.applications[ 0 ]?.data.address[ 0 ]?.city }` }
						</p>
						<p className='telephone'>
							{ `${ deliveryAddress.applications[ 0 ]?.data?.mobile }` }
						</p>
						{/* <div className='choose-address'><Trans
						id='ShoppingCart.changeTheAdrdress' /></div> */}
					</div>
				) : null }
				<div className='address-title-account'><Trans id='ShoppingCart.accountAddress' /></div>
				{ deliveryAddress ? (
					<div className='address-form-account'>
						<p className='name'>
							{ `${ deliveryAddress.owner?.firstName } ${ deliveryAddress.owner?.lastName }` }
						</p>
						<p className='address'>
							{ `${ deliveryAddress.applications[ 0 ]?.data?.address[ 0 ]?.address }` }
						</p>
						<p className='zip'>
							{ `${ deliveryAddress.applications[ 0 ]?.data?.address[ 0 ]?.zip } ${ deliveryAddress.applications[ 0 ]?.data?.address[ 0 ]?.city }` }
						</p>
						<p className='telephone'>
							{ `${ deliveryAddress.applications[ 0 ]?.data?.mobile }` }
						</p>
						{/* <div className='choose-address'><Trans
						id='ShoppingCart.changeTheAdrdress' /></div> */}
					</div>
				) : null }
			</div>

			<div className='button-next-step'>
				<Button
					text={ <Trans id='Button.back' /> }
					disabled={ !false }
					onClick={ () => clickBack() }
				/>
				<Button
					text={ <Trans id='ShoppingCart.continueToTheNextStep' /> }
					disabled={ !false }
					onClick={ () => clickNext() }
				/>
			</div>

			<div className='delivery-title'>
				{ /* <Trans id='ShoppingCart.advantagesOfRegistration' /> */ }
				{ /* TODO - ADVANTAGES OF REGISTRATION */ }
			</div>
			{ /* <div className='delivery-container' /> */}
		</div>
	)
} )

AddressPage.propTypes = {
	clickNext: propTypes.func.isRequired,
	clickBack: propTypes.func.isRequired,
}

export default AddressPage
