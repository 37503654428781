/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans } from '@lingui/macro'

import './TableGS1Header.css'

const TableGS1Header = React.memo( () => {
	const gs1Components = [ <Trans id='GS1.Segment' />, <Trans id='GS1.Family' />, <Trans id='GS1.SegmentClass' />, <Trans id='GS1.Brick' /> ]

	return (
		<div className='gs1-header'>
			{gs1Components.map( ( item, index ) => (
				<span id='gs1-header-items' key={ index }>{ item }</span>
			) )}

		</div>
	)
} )

export default TableGS1Header
