import React from 'react'
import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'

import './HubWarehouseRow.css'

const HubWarehouseRow = ( { warehouseRow: { code, occupied, order } } ) => {
	const translateOccupied = ( occupied1 ) => ( occupied1 ? <Trans id='HubWarehouse.Occupied' /> : <Trans id='HubWarehouse.Free' /> )

	return (
		<div className='hub-warehouse-row-data'>
			<div className='gridWarehouse-item-info'>{ `${ code }` }</div>
			<div className='gridWarehouse-item-info'>{ translateOccupied( occupied ) }</div>
			<div className='gridWarehouse-item-info'>{ order || <Trans id='HubWarehouse.empty' /> }</div>
			<div className='gridWarehouse-item-dots-row'><div id='dots-row' /></div>
		</div>

	)
}

HubWarehouseRow.propTypes = {
	warehouseRow: propTypes.object.isRequired
}

export default HubWarehouseRow
