import React, { useState, useEffect } from 'react'
import { Trans } from '@lingui/macro'
import gql from 'graphql-tag'
import { useMutation, useQuery } from '@apollo/react-hooks'
import UploadSLAPopup from '../../_Globals/UploadSLAPopup'

import './SLA.css'
import ConditionalWrapper from '../../_Globals/ConditionalWrapper'
import Button from '../../_Globals/Button/Button'
import Loader from '../../Loader'
import PdfRender from '../../_Globals/PdfRender'

/*	global sessionStorage */

const GET_HUB_BY_TENANT_ID = gql`
	query getHubByTenantIdHubSLA(
		$tenantId: String!
	) {
		hub_hub( where: { tenant_id: { _eq: $tenantId } } ) {
			id
            name
            sla_id
			sla {
				id
				file_id
			}
		}
	}
`

const GET_SAVED_SLA = gql`
	mutation createDownloadURL(
		$appId: String!,
		$fileId: String!,
		$tenantId: String!
	) {
		Storage_createDownloadURL(
			appId: $appId, 
			fileId: $fileId, 
			tenantId: $tenantId
		) {
			url
		}
	}
`

const Sla = React.memo( () => {
	const [ windowWidth, setWidth ] = useState( window.innerWidth > 768 )
	const [ uploadSlaMenu, toggleSlaMenu ] = useState( false )
	const [ pdf, setPdf ] = useState( false )
	const [ pdfUrl, setPdfUrl ] = useState( false )

	const tenantId = sessionStorage.getItem( 'uhub_tenantId' )
	const [ savedSlaUrl ] = useMutation( GET_SAVED_SLA )
	const {
		data: hubResponseData,
		loading: hubResponseLoading,
		error: hubResponseError
	} = useQuery( GET_HUB_BY_TENANT_ID, { variables: { tenantId } } )

	const hubData = hubResponseData
        && hubResponseData.hub_hub
        && hubResponseData.hub_hub[ 0 ]
		? hubResponseData.hub_hub[ 0 ] : false
	const slaId = hubData
        && hubData.sla
		? hubData.sla_id
		: false

	const fileId = hubData
        && hubData.sla
		? hubData.sla.file_id
		: false

	useEffect( () => {
		const handleResize = () => {
			setWidth( window.innerWidth > 768 )
		}

		if ( fileId ) {
			savedSlaUrl( {
				variables: {
					appId: 'uhub',
					fileId: `${ fileId }`,
					tenantId
				}
			} ).then( ( response ) => {
				const { url } = response.data.Storage_createDownloadURL
				setPdfUrl( url )

				window.pdfjsLib.getDocument( url ).then( ( pdfRes ) => {
					setPdf( pdfRes )
				} )
			} )
		}

		window.addEventListener( 'resize', handleResize )
		return () => { window.removeEventListener( 'resize', handleResize ) }
	}, [ fileId, savedSlaUrl, tenantId ] )

	if ( hubResponseLoading ) {
		return <Loader />
	}

	if ( hubResponseError ) {
		return <div>{ JSON.stringify( hubResponseError ) }</div>
	}

	return (
		<ConditionalWrapper
			condition={ windowWidth }
			falseConditionComponent={ (
				<div className='warning'>
					<Trans id='CycleSectionPage.Warning' />
				</div>
			) }
		>
			<div>
				{ uploadSlaMenu
					? <UploadSLAPopup fileId={ fileId } slaId={ slaId } toggleSlaMenu={ toggleSlaMenu } />
					: null }
				<div className='SLA-section-page-container'>
					<div className='SLA-title-row'>
						<div className='SLA-title'>
							<span>
								<Trans id='SLASectionPage.titleSLA' />
							</span>
							<span>{ hubData.name }</span>
						</div>
						<div className='SLA-button-container'>
							<Button
								text={ <Trans id='SLASectionPage.uploadSLA' /> }
								disabled
								onClick={ () => toggleSlaMenu( true ) }
							/>
							{
								pdfUrl
									? (
										<Button
											text={ <Trans id='SLASectionPage.downloadSLA' /> }
											disabled
											onClick={ () => window.location.assign( pdfUrl ) }
										/>
									)
									: null
							}
						</div>
					</div>
					{
						pdf
							? <PdfRender pdf={ pdf } />
							: <p className='no-sla'><Trans id='SLASectionPage.pleaseUploadSLA' /></p>
					}
				</div>
			</div>
		</ConditionalWrapper>
	)
} )

export default Sla
