/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react'
import propTypes from 'prop-types'
import Swiper from 'react-id-swiper'
import { Navigation } from 'swiper/dist/js/swiper.esm'
import ReactPlaceholder from 'react-placeholder'
import ProviderComponent from '../ProviderComponent'
import ProductComponent from '../ProductComponent'
import ProductComponentPlaceholder from '../ProductComponent/components/ProductComponentPlaceholder'
import ProviderComponentPlaceholder from '../ProviderComponent/components/ProviderComponentPlaceholder'
import 'react-placeholder/lib/reactPlaceholder.css'

import './SliderRow.css'
import './SliderDefault.css'

const SliderRow = ( {
	isHubMenuClosed,
	sliderTitle,
	slides,
	productOrProvider,
	setProvider,
	productsByHubLoading,
	productsByHubTenantIdDataLoading,
	marketLoading,
	productsLoading,
} ) => {
	const [ swiper, updateSwiper ] = React.useState( null )
	const [ end, isEnd ] = React.useState( !( swiper && swiper.isEnd === false ) )

	//	TODO - Create better breakpoints and test
	const params = {
		spaceBetween: 10,
		noSwiping: true,
		threshold: 10000,
		slidesPerView: isHubMenuClosed ? 4 : 3,
		observer: true,
		loop: false,
		shouldSwiperUpdate: true,
		breakpoints: {
			// when window width is <= 480px
			480: {
				slidesPerView: 1,
			},
			// when window width is <= 640px
			640: {
				slidesPerView: 2,
			},
			760: {
				slidesPerView: isHubMenuClosed ? 2 : 1,
			},
			// when window width is <= 1080px
			1080: {
				slidesPerView: isHubMenuClosed ? 3 : 2,
			}
		}
	}

	const goNext = () => {
		if ( swiper !== null ) {
			swiper.slideNext()
		}
	}

	const goPrev = () => {
		if ( swiper !== null ) {
			swiper.slidePrev()
		}
	}

	/* eslint-disable */

	useEffect( () => {
		if ( slides && swiper ) {
			const sliderWidth = swiper.width

			if ( sliderWidth <= 640 ) {
				isEnd( 	!( slides && slides.length > 2 ) )
			} else if ( sliderWidth <= 760 ) {
				isEnd( 	!( slides && slides.length > 3 ) )
			} else if ( sliderWidth <= 1080 ) {
				isEnd( 	!( slides && slides.length > 3 ) )
			} else {
				isEnd( 	!( slides && slides.length > 3 ) )
			}
		}
	}, [ swiper ] )

	return (
		<div className='shopping-row'>
			<div />
			<div className='shopping-row-title'>{ sliderTitle }</div>
			<div />
			<div className='arrow-left'>
				<button
					id='btn-swiper-prev'
					label='button'
					type='button'
					onClick={ goPrev }
				/>
			</div>
			<div className='slider'>
				<Swiper { ...params } getSwiper={ updateSwiper } modules={ [ Navigation ] }>
					{ slides.map( ( slide ) => (
						<div className='item-holder' key={ slide.id }>
							{ productOrProvider
								? (
									<ReactPlaceholder
										showLoadingAnimation
										ready={
											!marketLoading
											|| !productsLoading
											|| !productsByHubLoading
											|| !productsByHubTenantIdDataLoading
										}
										customPlaceholder={ ProviderComponentPlaceholder }
									>
										<ProviderComponent
											itemData={ slide }
											setProvider={ ( provider ) => setProvider( provider ) }
										/>
									</ReactPlaceholder>
								)
								: (
									<ReactPlaceholder
										showLoadingAnimation
										ready={
											!marketLoading
											|| !productsLoading
											|| !productsByHubLoading
											|| !productsByHubTenantIdDataLoading
										}
										customPlaceholder={ ProductComponentPlaceholder }
									>
										<ProductComponent itemData={ slide } />
									</ReactPlaceholder>
								)}
						</div>
					) ) }
				</Swiper>
			</div>
			<div className='arrow-right'>
				<button
					id={ end ? 'btn-swiper-next-disabled' : 'btn-swiper-next' }
					label='button'
					type='button'
					onClick={ goNext }
				/>
			</div>
		</div>
	)
}

SliderRow.defaultProps = {
	sliderTitle: 'no-title',
	slides: [],
	productOrProvider: false,
	isHubMenuClosed: undefined,
	setProvider: () => {},
	productsByHubLoading: null,
	productsByHubTenantIdDataLoading: null,
	marketLoading: null,
	productsLoading: null,
}

SliderRow.propTypes = {
	isHubMenuClosed: propTypes.bool,
	sliderTitle: propTypes.object,
	slides: propTypes.array,
	productOrProvider: propTypes.bool,
	setProvider: propTypes.func,
	productsByHubLoading: propTypes.bool,
	productsByHubTenantIdDataLoading: propTypes.bool,
	marketLoading: propTypes.bool,
	productsLoading: propTypes.bool,
}
export default SliderRow
