/* eslint-disable camelcase */
import React, { useState } from 'react'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Trans } from '@lingui/macro'
import propTypes from 'prop-types'
import axios from 'axios'

import './PrintOverlay.css'
import { useStateValue } from '../../../stores/contextStore/stateStore'
import mainStore from '../../../stores/mainStore'


/* global sessionStorage Blob navigator */

const GET_PROVIDER_INFO = gql`
query getProviderInfo( $tenantId: String! ) {
    provider_market( where: { tenant_id: { _eq: $tenantId } } ) {
		id
        provider_id
        hub {
			id
            cycles( where: { finished: { _eq: true } }, order_by: {id: asc} ) {
                id
            }
        }
    }
}`

const GET_HUB_INFO = gql`
query getHubInfo( $tenantId: String! ) {
    hub_hub( where: { tenant_id: { _eq: $tenantId } } ) {
        id
        cycles( where: { finished: { _eq: true } }, order_by: {id: asc} ) {
            id
        }
    }
}`

const GET_ORDERS_TO_PRINT = gql`
query getOrdersToPrint ( $hubId: bigint!, $cycleId: bigint! ) {
    cart_orders( where: { hub_id: { _eq: $hubId }, cycle_id: { _eq: $cycleId }, orders_status_enum: { id: { _eq: 4 } } } ) {
        id
        customer {
			id
            owner {
				keycloakId
                firstName
                lastName
            }
        }
        customer_pickup
        qr_code_id
    }
}`

const GET_ITEMS_TO_PRINT = gql`
query getItemsToPrint ( $providerId: bigint!, $cycleId: [ bigint! ] ) {
    cart_items( where: { 
            market: { provider_id: { _eq: $providerId } }, 
            status_id: { _in: [ 1 ] }, 
            order: { cycle_id: { _in: $cycleId }, status_id: { _in: [ 4, 5 ] } } 
        } ) {
        id
        order_id
        qr_code_id
        product {
			id
            gs1_brick {
				id
                translation_sl
            }
        }
        quantity
        unit {
			id
            unit
        }
    }
}`

const CustomLoading = ( { text } ) => (
	<p id='custom-loader'>{ text }</p>
)

const GeneratePrintInfo = ( { isProvider, closeOverlay } ) => {
	const tenantId = sessionStorage.getItem( 'uhub_tenantId' )
	const [ isDownloadCompleted, toggleDownloadCompleted ] = useState( false )
	const [ generatePDFLoading, setGeneratePDFLoading ] = useState( false )
	const [ generatePDFError, setGeneratePDFError ] = useState( false )

	const { data: baseInfo, loading: baseLoading, error: baseError } = useQuery(
		isProvider ? GET_PROVIDER_INFO : GET_HUB_INFO, { skip: !tenantId, variables: { tenantId } }
	)

	const [ getOrders, {
		data: ordersData,
		error: ordersError,
		loading: ordersLoading,
		called: ordersCalled
	} ] = useLazyQuery( GET_ORDERS_TO_PRINT )

	const [ getItems, {
		data: itemsData,
		error: itemsError,
		loading: itemsLoading,
		called: itemsCalled
	} ] = useLazyQuery( GET_ITEMS_TO_PRINT )

	if ( baseLoading ) return <CustomLoading text={ <Trans id='PrintOverlay.PleaseWaitBaseConfigIsFetching' /> } />
	if ( baseError ) return <CustomLoading text={ <Trans id='PrintOverlay.PleaseTryAgainLater' /> } />

	if ( isProvider && !itemsCalled ) {
		const providerId = baseInfo?.provider_market?.[ 0 ]?.provider_id
		const markets = baseInfo?.provider_market ?? []
		const cycleArray = []

		markets.forEach( ( market ) => {
			const cycles = market?.hub?.cycles ?? []
			if ( cycles.length > 0 ) {
				cycleArray.push( cycles?.[ cycles.length - 1 ]?.id )
			}
		} )

		if ( providerId && cycleArray.length > 0 ) {
			//  call LazyQuery
			getItems( { variables: { providerId, cycleId: cycleArray } } )
		} else {
			return <CustomLoading text={ <Trans id='PrintOverlay.NoCycleOrProvider' /> } />
		}
	} else if ( !isProvider && !ordersCalled ) {
		const hubId = baseInfo?.hub_hub?.[ 0 ]?.id
		const cycles = baseInfo?.hub_hub?.[ 0 ]?.cycles
		const cycleId = cycles?.[ cycles.length - 1 ]?.id

		if ( hubId && cycleId ) {
			//  Call LazyQuery
			getOrders( { variables: { hubId, cycleId } } )
		} else {
			return <CustomLoading text={ <Trans id='PrintOverlay.NoCycleOrHub' /> } />
		}
	}

	if ( ordersLoading || itemsLoading || generatePDFLoading ) {
		return <CustomLoading text={ <Trans id='PrintOverlay.PleaseWaitDataIsGenerating' /> } />
	}

	if ( ordersError || itemsError || generatePDFError ) {
		return <CustomLoading text={ <Trans id='PrintOverlay.PleaseTryAgainLater' /> } />
	}

	function formatedCurrentDate() {
		const today = new Date()
		let dd = today.getDate()
		let mm = today.getMonth() + 1
		const yyyy = today.getFullYear()

		if ( dd < 10 ) dd = `0${ dd }`
		if ( mm < 10 ) mm = `0${ mm }`

		return `${ dd }-${ mm }-${ yyyy }`
	}

	function createAndDownloadBlobFile( byteArray, filename, extension = 'pdf' ) {
		const blob = new Blob( [ byteArray ] )
		const fileName = `${ filename }-${ formatedCurrentDate() }.${ extension }`
		if ( navigator.msSaveBlob ) {
			// IE 10+
			navigator.msSaveBlob( blob, fileName )
		} else {
			// Other browsers
			const link = document.createElement( 'a' )
			if ( link.download !== undefined ) {
				const url = URL.createObjectURL( blob )
				link.setAttribute( 'href', url )
				link.setAttribute( 'download', fileName )
				link.style.visibility = 'hidden'
				document.body.appendChild( link )
				link.click()
				document.body.removeChild( link )
			}
		}

		setGeneratePDFLoading( false )

		//  Close after 1500ms
		setTimeout( () => { closeOverlay( { type: 'togglePrintOverlayMenu', printOverlayMenu: false } ) }, 1500 )
	}

	function createAndDownloadPDF( fileName, inputData, apiEndpoint ) {
		setGeneratePDFLoading( true )
		const url = `${ process.env.REACT_APP_BASE_QR_CODE_SERVIS }${ apiEndpoint }`

		axios.post( url, inputData ).then( ( response ) => {
			const byteString = window.atob( response?.data?.result )
			const bytes = new Uint8Array( byteString.length )
			const arrayBuffer = bytes.map( ( byte, i ) => byteString.charCodeAt( i ) )
			createAndDownloadBlobFile( arrayBuffer, fileName )
		} ).catch( ( error ) => {
			console.log( 'Axios - error -> ', error )
			setGeneratePDFError( true )
		} )
		toggleDownloadCompleted( true )
	}

	if ( ordersData ) {
		if ( !isDownloadCompleted ) {
			createAndDownloadPDF( 'qr-kode-narocil', ordersData.cart_orders, '/api/v1/QRcode/Order' )
		}
		return (
			<div>
				PDF ( poimenovan: qr-kode-narocil ), bo shranjen na vas racunalnik.
			</div>
		)
	} if ( itemsData ) {
		if ( !isDownloadCompleted ) {
			createAndDownloadPDF( 'qr-kode-izdelkov', itemsData.cart_items, '/api/v1/QRcode/Item' )
		}
		return (
			<div>
				PDF ( poimenovan: qr-kode-izdelkov ), bo shranjen na vas racunalnik.
			</div>
		)
	}

	return <CustomLoading text={ <Trans id='PrintOverlay.SomethingWentWrong' /> } />
}

const PrintOverlay = () => {
	const [ { printOverlayMenu }, closeOverlay ] = useStateValue()

	if ( !printOverlayMenu ) return null

	const isProvider = mainStore.userRoles.includes( 'provider' )
	const isOwner = mainStore.userRoles.includes( 'owner' )
	const isEmployee = mainStore.userRoles.includes( 'employee' )

	if ( !isProvider && !isOwner && !isEmployee ) {
		setTimeout( () => { closeOverlay( { type: 'togglePrintOverlayMenu', printOverlayMenu: false } ) }, 5000 )
		return (
			<div id='print-overlay-container'>
				<span className='print-no-permissions'><Trans id='No-permissions-please-wait' /></span>
			</div>
		)
	}

	return (
		<div id='print-overlay-container'>
			<div
				className='print-overlay-close'
				role='button'
				tabIndex={ 0 }
				onClick={ () => closeOverlay( { type: 'togglePrintOverlayMenu', printOverlayMenu: false } ) }
				onKeyPress={ () => closeOverlay( { type: 'togglePrintOverlayMenu', printOverlayMenu: false } ) }
			>
				&times;
			</div>
			<div className='print-overlay-content'>
				<GeneratePrintInfo isProvider={ isProvider } closeOverlay={ closeOverlay } />
			</div>
		</div>
	)
}

GeneratePrintInfo.propTypes = {
	isProvider: propTypes.bool.isRequired,
	closeOverlay: propTypes.func.isRequired
}

CustomLoading.propTypes = {
	text: propTypes.object.isRequired
}

export default PrintOverlay
