/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react'
import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'
import { useQuery, useSubscription } from '@apollo/react-hooks'
import Swiper from 'react-id-swiper'
import { Navigation } from 'swiper/dist/js/swiper.esm'
import ReactPlaceholder from 'react-placeholder'
import ProductComponent from '../ProductComponent'
import ProductComponentPlaceholder from '../ProductComponent/components/ProductComponentPlaceholder'
import 'react-placeholder/lib/reactPlaceholder.css'

import './SliderRow.css'
import './SliderDefault.css'
import Loader from '../Loader'

import {
	GET_ALL_DEFAULT_QUANTITIES,
	GET_PRODUCTS,
	GET_PRODUCTS_SUBSCRIPTION,
} from './SliderRowAllProductsGQL'
import { useStateValue } from '../../stores/contextStore/stateStore'

const SliderRowAllProducts = ( {
	isHubMenuClosed,
	marketFilters,
	gs1Filters
} ) => {
	const [ swiper, updateSwiper ] = React.useState( null )
	const [ productsDisplay, getNewProductsToDisplay ] = React.useState( [] )
	const [ beginning, isBeginning ] = React.useState( !( swiper && swiper.isBeginning === false ) )
	const [ end, isEnd ] = React.useState( !( swiper && swiper.isEnd === false ) )

	const {
		data: productsData,
		loading: productsLoading,
		error: productsError,
		fetchMore: productsMore,
	} = useQuery( GET_PRODUCTS, {
		variables: {
			offset: 0,
			limit: 6,
			marketFilters: marketFilters.length > 0 ? marketFilters : null,
			gs1Filters: gs1Filters.length > 0 ? gs1Filters : null,
		},
		fetchPolicy: 'cache-and-network',
	} )

	const {
		data: defaultQuantities,
		loading: defaultQuantitiesLoading,
		error: defaultQuantitiesError
	} = useQuery( GET_ALL_DEFAULT_QUANTITIES )

	const products = productsData && productsData.provider_market_has_product
		&& productsData.provider_market_has_product.length > 0
		? productsData.provider_market_has_product : []

	//	TODO - Create better breakpoints and test
	const params = {
		spaceBetween: 10,
		noSwiping: true,
		threshold: 10000,
		slidesPerView: isHubMenuClosed ? 4 : 3,
		observer: true,
		loop: false,
		shouldSwiperUpdate: true,
		breakpoints: {
			// when window width is <= 480px
			480: {
				slidesPerView: 1,
			},
			// when window width is <= 640px
			640: {
				slidesPerView: 2,
			},
			760: {
				slidesPerView: isHubMenuClosed ? 2 : 1,
			},
			// when window width is <= 1080px
			1080: {
				slidesPerView: isHubMenuClosed ? 3 : 2,
			}
		}
	}

	const {
		data: subscribeAllProducts,
		loading: subscribeAllProductsLoading,
		error: subscribeAllProductsError,
	} = useSubscription( GET_PRODUCTS_SUBSCRIPTION, {
		variables: {
			limit: products.length,
			marketFilters: marketFilters.length > 0 ? marketFilters : null,
			gs1Filters: gs1Filters.length > 0 ? gs1Filters : null,
		},
		onSubscriptionData: ( data ) => {
			getNewProductsToDisplay( data?.subscriptionData?.data?.provider_market_has_product?.reverse() )
		}
	} )

	/* eslint-disable */

	useEffect( () => {
		if ( products && swiper ) {
			const sliderWidth =  swiper.width

			if ( sliderWidth <= 640 ) {
				isEnd( 	!( products && products.length > 2 ) )
			} else if ( sliderWidth <= 760 ) {
				isEnd( 	!( swiper && products && products.length > 3 ) )
			} else if ( sliderWidth <= 1080 ) {
				isEnd( 	!( products && products.length > 3 ) )
			} else {			
				isEnd( 	!( products && products.length > 3 ) )
			}
		}

	}, [ swiper ] )


	function onLoadMoreHubProducts() {
		productsMore( {
			variables: {
				offset: productsData.provider_market_has_product.length
			},
			updateQuery: ( prev, { fetchMoreResult } ) => {
				if ( !fetchMoreResult ) return prev
				return {
					...prev,
					provider_market_has_product: [
						...prev.provider_market_has_product,
						...fetchMoreResult.provider_market_has_product
					]
				}
			}
		} )
	}

	// helper that checks if the quantity of the product is less than the lowest purchasable amount
	function productDisplayHelper( product ) {
		const unitId = product.product.gs1_brick.unit.id
		// eslint-disable-next-line camelcase
		if ( defaultQuantities?.code_register_units_default_quantities !== undefined ) {
			// eslint-disable-next-line max-len
			for ( let j = 0; j < defaultQuantities.code_register_units_default_quantities.length; j += 1 ) {
				if ( unitId === defaultQuantities.code_register_units_default_quantities[ j ].unit_id ) {
					if ( product.product.quantity
						>= defaultQuantities.code_register_units_default_quantities[ j ].quantity ) {
						return true
					}
				}
			}
		}
		return false
	}

	const goNext = () => {
		if ( swiper !== null ) {
			swiper.isBeginning = swiper.realIndex === 0
			isBeginning( false )
			swiper.slideNext()
			if ( swiper.isEnd ) {
				isEnd( swiper.isEnd )
				onLoadMoreHubProducts()
			}
		}
	}

	const goPrev = () => {
		if ( swiper !== null ) {
			swiper.isEnd = false
			swiper.realIndex = swiper.realIndex > 0 ? swiper.realIndex - 1 : 0
			swiper.isBeginning = swiper.realIndex === 0
			isBeginning( swiper.isBeginning )
			isEnd( swiper.isEnd )
			swiper.slidePrev()
		}
	}

	if ( subscribeAllProductsError ) {
		return (
			<div>
				{ JSON.stringify( subscribeAllProductsError ) }
			</div>
		)
	}

	return (
		<div className='shopping-row'>
			<div />
			<div className='shopping-row-title'>
				<Trans id='SliderRowAllProducts:newProductsToOfferOnUHUB' />
			</div>
			<div />
			<div className='arrow-left'>
				<button
					id={ beginning ? 'btn-swiper-prev-disabled' : 'btn-swiper-prev' }
					label='button'
					type='button'
					onClick={ goPrev }
				/>
			</div>
			<div className='slider'>
				{ productsError ? <div>{ JSON.stringify( productsError ) }</div> : null }
				{ productsLoading || subscribeAllProductsLoading ? <Loader /> : null }
				{ productsData ? (
					<Swiper { ...params } getSwiper={ updateSwiper } modules={ [ Navigation ] }>
						{/* eslint-disable-next-line max-len */}
						{ productsDisplay.filter( ( product ) => productDisplayHelper( product ) ).map( ( product ) => (
							<div className='item-holder' key={ `${ product.market.hub_id }  ${ product.product.id } all-products` }>
								<ReactPlaceholder
									showLoadingAnimation
									ready={
										!productsLoading
									}
									customPlaceholder={ ProductComponentPlaceholder }
								>
									<ProductComponent itemData={ product } />
								</ReactPlaceholder>
							</div>
						) ) }
					</Swiper>
				) : null }
			</div>
			<div className='arrow-right'>
				<button
					id={ end ? 'btn-swiper-next-disabled' : 'btn-swiper-next' }
					label='button'
					type='button'
					onClick={ goNext }
				/>
			</div>
		</div>
	)
}

SliderRowAllProducts.defaultProps = {
	isHubMenuClosed: undefined,
}

SliderRowAllProducts.propTypes = {
	isHubMenuClosed: propTypes.bool,
	marketFilters: propTypes.array.isRequired,
	gs1Filters: propTypes.array.isRequired
}
export default SliderRowAllProducts
