import React from 'react'
import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'

import DropdownMyOrders from '../DropdownMyOrders'
import { dateToStringNoHoursMinutes } from '../../../utils/DateToString'
import store from '../../../stores/mainStore'


import './TableMyOrderRow.css'

const STATUS = [
	'inPreparation', 'readyAtProvider', 'nonDelivered'
]

const DELIVERY_TO_HUB = [
	'Provider', 'Hub'
]

const TRANSLATIONS_DELIVERY_TO_HUB = {
	Provider: <Trans id='MyOrders.provider' />,
	Hub: <Trans id='MyOrders.hub' />
}

const TRANSLATIONS_STATUS = {
	inPreparation: <Trans id='MyOrders.inPreparation' />,
	readyAtProvider: <Trans id='MyOrders.readyAtProvider' />,
	nonDelivered: <Trans id='MyOrders.nonDelivered' />,
}

const TableMyOrderRow = React.memo( ( {
	data, onClick, refetch, orderStatusId, providerId, expired
} ) => {
	function displayDeliveryStatusForExpiredOrders() {
		if ( data.delivery_to_hub ) {
			return <Trans id='MyOrders.provider' />
		}
		return <Trans id='MyOrders.hub' />
	}


	function displayItemStatusForExpiredOrders() {
		if ( data.status_id === 1 ) {
			return <Trans id='MyOrders.inPreparation' />
		}
		if ( data.status_id === 2 ) {
			return <Trans id='MyOrders.readyAtProvider' />
		}
		if ( data.status_id === 3 ) {
			return <Trans id='MyOrders.deliveredToHub' />
		}
		if ( data.status_id === 4 ) {
			return <Trans id='MyOrders.storedAtHub' />
		}
		if ( data.status_id === 5 ) {
			return <Trans id='MyOrders.nonDelivered' />
		}
		return <Trans id='MyOrders.itemRejected' />
	}

	function displayProductNameTitle() {
		if ( data.product.variety ) {
			return ` ${ data.product.gs1_brick.translation_sl.charAt( 0 ).toUpperCase() + data.product.gs1_brick.translation_sl.slice( 1 ) } - ${ data.product.variety.name }`
		}
		return ` ${ data.product.gs1_brick.translation_sl.charAt( 0 ).toUpperCase() + data.product.gs1_brick.translation_sl.slice( 1 ) }`
	}

	return (
		<div
			className='tableMyOrder-row-container'
			role='button'
			tabIndex={ -1 }
			onClick={ onClick }
			onKeyPress={ onClick }
		>
			<div className='tableHubCycle-code'>
				<img alt='qr-code' width='120' height='120' src={ `data:image/png;base64, ${ data.qr_code.base64_data }` } />
			</div>
			<div className='tableHubCycle-aggregation'>
				{ data.aggregated
					? <Trans id='TableHubCycleOrderRow.aggregatedYes' />
					: <Trans id='TableHubCycleOrderRow.aggregatedNo' /> }
			</div>
			<div className='tableHubCycle-product'>
				{ displayProductNameTitle() }
			</div>
			<div className='tableHubCycle-provider'>
				{ data.market.name }
			</div>
			<div className='tableHubCycle-quantity'>
				{ data.quantity }
			</div>
			<div className='tableHubCycle-unit'>
				{ data.product.gs1_brick.unit.unit }
			</div>
			<div className='tableHubCycle-price'>
				{ `${ data.bruto_price } EUR` }
			</div>
			<div className='tableHubCycle-delivery'>
				{ ( orderStatusId === 4 || orderStatusId === 5 ) && !expired
					? (
						<DropdownMyOrders
							data={ DELIVERY_TO_HUB }
							translations={ TRANSLATIONS_DELIVERY_TO_HUB }
							type='deliveryToHub'
							id={ data.id }
							deliveryToHub={ data.delivery_to_hub }
							refetch={ refetch }
							providerId={ providerId }
						/>
					) : displayDeliveryStatusForExpiredOrders() }
			</div>
			<div className='tableHubCycle-packaging'>
				<Trans id='TableHubCycleOrderRow.provider' />
			</div>
			<div className='tableHubCycle-deliverability'>
				{ dateToStringNoHoursMinutes( data.order.cycle.closed_time, store.language ) }
			</div>
			<div className='tableHubCycle-status'>
				{ ( orderStatusId === 4 || orderStatusId === 5 ) && !expired

					? (
						<DropdownMyOrders
							data={ STATUS }
							translations={ TRANSLATIONS_STATUS }
							type='status'
							id={ data.id }
							status={ data.status_id }
							refetch={ refetch }
						/>
					) : displayItemStatusForExpiredOrders() }
			</div>
		</div>
	)
} )

TableMyOrderRow.defaultProps = {
	onClick: () => {},
	refetch: () => {},
	providerId: undefined
}

TableMyOrderRow.propTypes = {
	data: propTypes.object.isRequired,
	onClick: propTypes.func,
	refetch: propTypes.func,
	orderStatusId: propTypes.number.isRequired,
	providerId: propTypes.number,
	expired: propTypes.bool.isRequired
}

export default TableMyOrderRow
