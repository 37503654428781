import React from 'react'
import propTypes from 'prop-types'

const AppsIcon = ( { color, fontSize } ) => (
	<span className='icon-apps-icon-04' style={ { fontSize: `${ fontSize }px`, color } }>
		<span className='path1' />
		<span className='path2' />
		<span className='path3' />
		<span className='path4' />
		<span className='path5' />
		<span className='path6' />
		<span className='path7' />
		<span className='path8' />
		<span className='path9' />
		<span className='path10' />
	</span>
)

AppsIcon.defaultProps = {
	color: 'black',
	fontSize: 18
}
AppsIcon.propTypes = {
	color: propTypes.string,
	fontSize: propTypes.number
}

export default AppsIcon
