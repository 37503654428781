import React from 'react'
import propTypes from 'prop-types'
import { useSubscription } from '@apollo/react-hooks'
import { withRouter } from 'react-router-dom'
import { CartWhiteIcon } from '../../../icons/index'

import './ShoppingCardButton.css'
import NumberOfElements from './NumberOfElements'
import CART_ORDERS_NUMBER from './ShoppingCardButtonGQL'

/* global sessionStorage */

const ShoppingCardButton = ( { history } ) => {
	const {
		data: cartOrdersData,
		error: cartOrdersDataError
	} = useSubscription( CART_ORDERS_NUMBER, {
		variables: {
			cart_uuid: sessionStorage.getItem( 'uuid' ),
			status_id: sessionStorage.getItem( 'uuid' ) ? 1 : 0
		}
	} )

	const productsInCart = cartOrdersData
		&& cartOrdersData.cart_items.length > 0
		? cartOrdersData.cart_items : null

	return (
		<div
			role='button'
			className='shopping-card-button'
			tabIndex={ -1 }
			onKeyPress={ () => history.push( '/shopping-cart' ) }
			onClick={ () => history.push( '/shopping-cart' ) }
		>
			{ productsInCart && productsInCart.length > 0
				? <NumberOfElements numberOfNotifications={ productsInCart.length } />
				: null }
			{ !cartOrdersDataError ? <CartWhiteIcon fontSize={ 32 } /> : null }
		</div>
	)
}

ShoppingCardButton.propTypes = {
	history: propTypes.object.isRequired
}

export default withRouter( ShoppingCardButton )
