import React, { useEffect } from 'react'
import { Trans } from '@lingui/macro'
import propTypes from 'prop-types'
import { useLazyQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { withRouter } from 'react-router-dom'
import { useStateValue } from '../../stores/contextStore/stateStore'
import ProviderDetails from '../ProviderMainInfoDetail/components/ProviderDetails'
import HubDetails from './components/HubDetails'
import HubView from '../../components/_Hub/HubView'
import ProductView from '../../components/_Provider/ProductView'
import Footer from '../../components/Footer'
import Loader from '../../components/Loader'
import { ReturnSendIcon } from '../../icons'

import './HubMainInfoDetail.css'

const GET_ALL_ACTIVE_PRODUCTS_BY_HUB = gql`
query getProductsOnMarketByHubIdHubDetails( $hub_id: bigint! ){
    provider_market( where: { 
        hub_id: { _eq: $hub_id }, 
        active: { _eq: true }, 
        confirmed: { _eq: true } 
    } ) {
        products( where: { product: { disabled: { _eq: false } } } ) {
			id
            product {
                id
                gs1_brick {
					id
                    translation_sl
					files {
						id
						file_id
						tenant_id
					}
                }
                description
                bruto_price
				neto_price
				ddv_enum
                quality
                quantity
                variety {
					id
                    name
                }
            }
            market {
				id
                name
				hub_id
				description
				hub {
					id
                    description
                    tenant_id
                }
            }
        }
    }
}`


const HubMainInfoDetail = React.memo( ( { history } ) => {
	const [ { isProductSelected }, closeHubInfo ] = useStateValue()

	const [
		getProductsByHub,
		{
			loading: productsByHubLoading,
			error: productsByHubError
		}
	] = useLazyQuery( GET_ALL_ACTIVE_PRODUCTS_BY_HUB )

	useEffect( () => {
		if ( isProductSelected
				&& isProductSelected.market
				&& isProductSelected.market.hub_id ) {
			getProductsByHub( { variables: { hub_id: isProductSelected.market.hub_id } } )
		}
	}, [ getProductsByHub, isProductSelected ] )

	if ( productsByHubLoading ) {
		return <Loader />
	}

	if ( productsByHubError ) {
		return (
			<div>
				{ JSON.stringify( productsByHubError ) }
			</div>
		)
	}

	return (
		<div className='HubInfoDetail-main-container'>
			<div className='HubInfoDetail-container'>
				<div className='HubInfoDetail-content'>
					<div className='HubInfoDetail-back-to-hubs'>
						<div
							role='button'
							className='back-to-store'
							tabIndex={ -1 }
							onClick={ () => {
								closeHubInfo( { type: 'changeSelectedProviderDescription', selectedProviderDescription: null } )
								closeHubInfo( { type: 'isProductSelected', isProductSelected: null } )
								history.push( '/' )
							} }
							onKeyPress={ () => {
								closeHubInfo( { type: 'changeSelectedProviderDescription', selectedProviderDescription: null } )
								closeHubInfo( { type: 'isProductSelected', isProductSelected: null } )
								history.push( '/' )
							} }
						>
							<span><ReturnSendIcon fontSize={ 14 } /></span>
							<span className='text'><Trans id='ProviderHubInfoDetail.backToHubs' /></span>
						</div>
					</div>
					<div className='HubInfoDetail-provider-or-product-component'>
						{
							!isProductSelected
								? <HubView />
								: <ProductView product={ isProductSelected } />
						}
					</div>
					<div className='HubInfoDetail-provider-details-component'>
						{ isProductSelected ? <ProviderDetails data={ isProductSelected.market } /> : null }
					</div>
					<div className='HubInfoDetail-hub-details-component'>
						{ isProductSelected ? <HubDetails hubId={ isProductSelected.market.hub_id } /> : null }
					</div>
				</div>
			</div>
			<div className='HubInfoDetail-product-sliders' />
			<div className='HubInfoDetail-footer'>
				<Footer />
			</div>
		</div>
	)
} )

HubMainInfoDetail.propTypes = {
	history: propTypes.object.isRequired,
}

export default withRouter( HubMainInfoDetail )
