/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'
import './DropDownSelect.css'


const DropDownSelect = React.memo( ( {
	items,
	select,
	type,
} ) => {
	const [ displayDropDown, showDropDown ] = useState( false )
	const [ selectedItem, chooseItem ] = useState()

	function selectItem( item ) {
		showDropDown( !displayDropDown )
		chooseItem( item )
		select( item )
	}

	function displayDropdownList() {
		if ( displayDropDown ) {
			if ( items ) {
				return (
					<div className='item-list'>
						<div label={ selectItem ? selectedItem : '' } />
						{ items.map( ( item ) => (
							<div
								className='item'
								key={ item.id }
								name={ item.translation_sl }
								role='button'
								tabIndex={ -1 }
								onKeyPress={ () => selectItem( item ) }
								onClick={ () => selectItem( item ) }
							>
								{ type === 'product' ? `${ item.id } - ${ item.translation_sl }` : `${ item.id } - ${ item.name }` }
							</div>
						) ) }
					</div>
				)
			}

			return null
		}
		return null
	}

	function displaySelectedItemName() {
		if ( selectedItem && type === 'product' ) {
			return `${ selectedItem.id } -  ${ selectedItem.translation_sl }`
		}
		if ( selectedItem && type === 'variety' ) {
			return `${ selectedItem.id } -  ${ selectedItem.name }`
		}
		return ''
	}

	return (
		<div
			className='popup-select'
			name='productClass'
			id='productClass'
			role='button'
			tabIndex={ -1 }
			onKeyPress={ () => showDropDown( !displayDropDown ) }
			onClick={ () => showDropDown( !displayDropDown ) }
		>
			<div className='Triangle' />
			{ displaySelectedItemName() }
			{ displayDropdownList() }
		</div>
	)
} )

DropDownSelect.defaultProps = {
	items: []
}

DropDownSelect.propTypes = {
	items: propTypes.array,
	select: propTypes.func.isRequired,
	type: propTypes.string.isRequired,
}

export default DropDownSelect
