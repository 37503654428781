import React from 'react'
import { Trans } from '@lingui/macro'
import PubSub from '@result/react-pubsub'

const translations = {
	/** Errors */
	UNKNOWN_ERROR: <Trans id='ERROR.Unknown_error' />,
	PLEASE_RE_AUTH: <Trans id='ERROR.Please_re_auth' />,
	NO_PERMISSIONS: <Trans id='ERROR.No_permissions' />,
	MISSING_INPUTS: <Trans id='ERROR.Missing_inputs' />,
	EMAIL_EXISTS: <Trans id='ERROR.Email_exist' />,
	USER_DOES_NOT_EXISTS: <Trans id='ERROR.User_does_not_exist' />,
	TENANT_ACCOUNTS_NOT_FOUND: <Trans id='ERROR.Tenant_accounts_not_found' />,
	TENANT_APP_EXISTS: <Trans id='ERROR.Tenant_app_exist' />,
	TENANT_EXISTS: <Trans id='ERROR.Tenant_exist' />,
	TENANT_KEYCLOAK_USER_EXISTS: <Trans id='ERROR.Tenant_keycloak_user_exist' />,
	KEYCLOAK_PASSWORD_MISMATCH: <Trans id='ERROR.Tenant_keycloak_password_mismatch ' />,
	DIFFERENT_INPUT_PASSWORDS: <Trans id='ERROR.Different_input_passwords' />,
	REGISTER_NEW_USER_FAILED: <Trans id='ERROR.Register_new_user_failed' />,
	REGISTER_EXISTING_USER_FAILED: <Trans id='ERROR.Register_existing_user_failed' />,
	CREATE_ACCOUNT_FAILED: <Trans id='ERROR.Create_account_failed' />,
	ACCOUNT_ALREADY_EXISTS: <Trans id='ERROR.Account_already_exist' />,
	TENANT_ACCOUNT_NOT_FOUND: <Trans id='ERROR.Tenant_account_not_found' />,
	INVALID_INPUTS: <Trans id='ERROR.Inputs_invalid' />,
	MISSING_EMAIL_INPUT: <Trans id='ERROR.Missing_email_input' />,
	CANNOT_DELETE_ACCOUNT_TENANT: <Trans id='ERROR.Cannot_delete_account_tenant' />,
	COMPANY_ALREADY_EXISTS: <Trans id='ERROR.Company_exist' />,
	FILE_MUST_BE_PDF: <Trans id='ERROR.FileMustBePDF' />,
	FILE_MUST_BE_IMAGE: <Trans id='ERROR.FileMustBeInCorrectImageFormat' />,
	SOMETHING_WENT_WRONG_AT_FILE_UPLOAD: <Trans id='ERROR.ErrorAtUpdatingFile' />,
	CANNOT_ADD_PRODUCT_ON_MARKET: <Trans id='ERROR.CannotAddProductToMarket' />,
	CANNOT_REMOVE_PRODUCT_ON_MARKET: <Trans id='ERROR.CannotRemovedProductFromMarket' />,
	CANNOT_UPDATE_PRODUCT_STATUS: <Trans id='ERROR.CannotUpdateProductStatus' />,
	CANNOT_UPDATE_PRODUCT: <Trans id='ERROR.CannotUpdateProduct' />,
	CANNOT_ADD_PRODUCT_TO_CART: <Trans id='ERROR.CannotAddProductToCart' />,
	NOT_ENOUGH_OF_PRODUCT_ON_STOCK: <Trans id='ERROR.PriceListRowDeletedThereIsNotEnoughOfProductOnStok' />,
	INVALID_INPUTS_USER_PROFILE_SETTINGS: <Trans id='ERROR.InvalidInputsUserProfileSettings' />,
	INVALID_INPUTS_MISSING_ROLES: <Trans id='ERROR.InvalidInputMissingRoles' />,
	PRICELIST_ROW_DELETED: <Trans id='ERROR.PriceListRowDeleted' />,
	INVALID_INPUTS_OVERLEAP_EXIST: <Trans id='ERROR.PriceListOverleapExist' />,
	PRICELIST_INVALID_INPUTS: <Trans id='ERROR.PriceListInvalidInputs' />,
	CANT_UPDATE_EXISTING_USER: <Trans id='ERROR.CANT_UPDATE_EXISTING_USER' />,
	CANT_UPDATE_USER_SETTINGS: <Trans id='ERROR.CantUpdate_User_Settings' />,
	USER_EXISTS: <Trans id='ERROR.User_Exist' />,
	UTIL_GEO_GENERAL: <Trans id='ERROR.CannotCalculateDistance' />,

	/** Warnings */
	ONLY_LOGGED_USER_CAN_RATE_PROVIDER_ORDER_DELIVERED: <Trans id='WARNING.OnlyLoggedUserCanRateProvider' />,
	ONLY_LOGGED_USER_CAN_RATE_PROVIDER: <Trans id='WARNING.OnlyLoggedUserCanRateProviderNoOrderDelivred' />,
	YOU_ALREADY_RATED_PROVIDER_WITH_SAME_RATING_CHOOSE_ANOTHER_RATING_VALUE: <Trans id='WARNING.SameRating' />,
	CHOOSE_QUANTITY_BEFORE_ORDER: <Trans id='WARNING.chooseQuantityBeforeOrder' />,
	PRODUCT_IS_OUT_OF_STOCK: <Trans id='Warning.noMoreProductOnStock' />,
	CURRENTLY_UNAVAILABLE: <Trans id='Warning.CurrentlyUnavailable' />,


	/** Oks */
	MARKET_UPDATED: <Trans id='OK.Market_updated' />,
	FILE_WAS_UPDATED: <Trans id='OK.FileWasUpdated' />,
	PRODUCT_STATUS_UPDATED: <Trans id='OK.ProductStatusWasUpdated' />,
	PRODUCT_UPDATED: <Trans id='OK.ProductWasUpdated' />,
	PRODUCT_ADDED_TO_MARKET: <Trans id='OK.ProductWasAddedToMarket' />,
	PRODUCT_REMOVED_FROM_MARKET: <Trans id='OK.ProductWasRemovedFromMarket' />,
	CYCLE_STARTED: <Trans id='OK.CycleStarted' />,
	CYCLE_ENDED: <Trans id='OK.CycleEnded' />,
	PRODUCT_ADDED_TO_CART: <Trans id='OK.ProductAddedToCart' />,
	PROVIDER_RATED: <Trans id='OK.ThankYouForYourEvaluation' />,
	ORDER_STATUS_CHANGED: <Trans id='OK.OrderStatusWasChanged' />,
	ITEM_ORDER_STATUS_CHANGED: <Trans id='OK.ItemStatusWasChanged' />,
	SLA_WAITING_FOR_CONFIRMATION_BY_HUB: <Trans id='OK.SlaIsWaitingForConfirmationByHub' />,
	USER_PROFILE_SETTINGS_UPDATED: <Trans id='OK.UserProfileSettingsUpdated' />,
	USER_ROLES_UPDATED: <Trans id='OK.UserRolesUpdated' />,
	PRICELIST_UPDATED: <Trans id='OK.PricelistUpdated' />,
	PRICELIST_UPDATED_NEW_ROW: <Trans id='OK.PricelistUpdatedNewRow' />,
	HUB_COMMISSION_UPDATED: <Trans id='OK.HubCommissionUpdated' />,
	CYCLE_LENGTH_UPDATED: <Trans id='OK.CycleLengthsEdited' />
}

const addNotification = ( type, message ) => PubSub.publish(
	'Notifier::addNewMessage',
	{ id: Date.now(), type, message: translations[ message ] || translations.UNKNOWN_ERROR }
)

const addNotificationAddProductToCart = ( type, message ) => PubSub.publish(
	'Notifier::addProductToCart',
	{ id: Date.now(), type, message: translations[ message ] || translations.UNKNOWN_ERROR }
)

export { addNotification, addNotificationAddProductToCart }
