import React, { useState, useEffect } from 'react'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import { Trans } from '@lingui/macro'

import Loader from '../../Loader'
import OrderRow from './components/OrderRow'
import TableMyOrderRow from '../../_Globals/TableMyOrderRow'
import TableMyOrderHeader from '../../_Globals/TableMyOrderHeader'
import ConditionalWrapper from '../../_Globals/ConditionalWrapper'
import { useStateValue } from '../../../stores/contextStore/stateStore'

/* global sessionStorage */

import './MyOrders.css'

import {
	GET_CURRENT_ORDERS,
	GET_CURRENT_EXPIRED_ORDERS,
	GET_PROVIDER_ID,
	GET_CURRENT_CYCLE__AND_HUB_ID
} from './myOrdersGQL'

const MyOrders = React.memo( () => {
	const customerId = sessionStorage.getItem( 'uhub_tenantId' )
	const [ windowWidth, setWidth ] = useState( window.innerWidth > 953 )
	const [ , togglePrintOverlay ] = useStateValue()

	const {
		data: providerData,
		loading: providerLoading,
		error: providerError,
	} = useQuery( GET_PROVIDER_ID, {
		variables: {
			tenant_id: customerId,
		},
	} )

	const [ getCurrentOrders, {
		data: currentOrdersData,
		loading: currentOrdersLoading,
		error: currentOrdersError,
		refetch: refetchCurrentOrders,
	} ] = useLazyQuery( GET_CURRENT_ORDERS, { fetchPolicy: 'no-cache' } )

	const {
		data: cycleData,
		loading: cycleLoading,
		error: cycleError,
	} = useQuery( GET_CURRENT_CYCLE__AND_HUB_ID )

	// gets cycles for use in getting the correct orders to display
	function cycleHelper() {
		const result = {
			ids: [],
			cycles: []
		}
		if ( cycleData ) {
			for ( let i = 0; i < cycleData.hub_cycles.length; i += 1 ) {
				result.ids.push( cycleData.hub_cycles[ i ].hub_id )
				result.cycles.push( ( cycleData.hub_cycles[ i ].id - 1 ) )
			}
		}
		return result
	}

	const cycleDataArray = cycleHelper()

	const [ getCurrentExpiredOrders, {
		data: currentExpiredOrdersData,
		loading: currentExpiredOrdersLoading,
		error: currentExpiredOrdersError
	} ] = useLazyQuery( GET_CURRENT_EXPIRED_ORDERS, { fetchPolicy: 'no-cache' } )

	const providerId = providerData
		&& providerData.provider_provider.length > 0
		? providerData.provider_provider[ 0 ].id : null

	const currentOrdersArr = currentOrdersData
		&& currentOrdersData.cart_orders
		? currentOrdersData.cart_orders : []

	const currentExpiredOrdersArr = currentExpiredOrdersData
		&& currentExpiredOrdersData.cart_orders
		? currentExpiredOrdersData.cart_orders : []

	useEffect( () => {
		const handleResize = () => {
			setWidth( window.innerWidth > 953 )
		}
		window.addEventListener( 'resize', handleResize )
		return () => { window.removeEventListener( 'resize', handleResize ) }
	}, [] )

	useEffect( () => {
		if ( providerId && cycleDataArray.ids && cycleDataArray.cycles ) {
			// eslint-disable-next-line max-len
			getCurrentOrders( { variables: { providerId, cycleIds: cycleDataArray.cycles, hubIds: cycleDataArray.ids } } )
			getCurrentExpiredOrders( {
				variables: {
					// eslint-disable-next-line max-len
					providerId, maxCycleId: Math.max( cycleDataArray.cycles ), excludedCycleIds: cycleDataArray.cycles, hubIds: cycleDataArray.ids
				}
			} )
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ providerId, cycleData, getCurrentOrders, getCurrentExpiredOrders ] )

	return (
		<ConditionalWrapper
			condition={ windowWidth }
			falseConditionComponent={ (
				<div className='warning'>
					<Trans id='orderSectionPage.Warning' />
				</div>
			) }
		>
			<div>
				<div className='container-text-order'>
					<div
						id='btn-send-to-print'
						role='button'
						tabIndex={ 0 }
						onKeyPress={ () => togglePrintOverlay( { type: 'togglePrintOverlayMenu', printOverlayMenu: true } ) }
						onClick={ () => togglePrintOverlay( { type: 'togglePrintOverlayMenu', printOverlayMenu: true } ) }
					>
						<Trans id='PrintItemsCode' />
					</div>
					<span className='my-current-orders-text'>
						<Trans id='MyOrders.CurrentOrders' />
					</span>
					{ currentOrdersLoading || providerLoading || cycleLoading ? <Loader /> : null }
					{ currentOrdersError || providerError || cycleError
						? JSON.stringify(
							currentOrdersError || providerError
						) : null }
					{ currentOrdersArr ? currentOrdersArr.map( ( order ) => (
						<div key={ order.id }>
							{ order.items.length > 0
								? (
									<div>
										<div className='hub-name'>{ order.hub.name }</div>
										<div>
											<TableMyOrderHeader />
											{ order.items.map( ( item ) => (
												<div key={ item.qr_code_id }>
													<TableMyOrderRow
														data={ item }
														refetch={ refetchCurrentOrders }
														orderStatusId={ order.status_id }
														providerId={ providerId }
														expired={ false }
													/>
												</div>
											) ) }
										</div>
									</div>
								) : null }

						</div>
					) ) : null }
					{/* <div className='button-container'>
				<button className='submit-order-button' type='button'>
					<Trans id='MyOrders.SubmitOrders' />
				</button>
						</div> */ }

					{ /* <div id='dots-row' /> */ }
				</div>
				<div className='container-text-order'>
					<span className='my-past-orders-text'>
						<Trans id='MyOrders.PastOrders' />
					</span>
					{ currentExpiredOrdersLoading || providerLoading || cycleLoading ? <Loader /> : null }
					{ currentExpiredOrdersError || providerError || cycleError
						? JSON.stringify(
							currentOrdersError || providerError
						) : null }
					{ currentExpiredOrdersArr ? currentExpiredOrdersArr.map( ( order ) => (
						<div key={ order.id }>
							{ order.items.length
								? (
									<div>
										<div className='hub-name'>{ order.hub.name }</div>
										<div>
											<TableMyOrderHeader />
											{ order.items.map( ( item ) => (
												<div key={ item.qr_code_id }>
													<TableMyOrderRow
														data={ item }
														orderStatusId={ order.status_id }
														expired
													/>
												</div>
											) ) }
										</div>
									</div>
								) : null }
							{' '}

						</div>
					) ) : null }
				</div>
				{
					currentExpiredOrdersArr.map( ( order ) => (
						order.pastOrder
					&& (
						<OrderRow key={ order.id } id={ order.id } order={ order } />
					)
					) )
				}
			</div>
		</ConditionalWrapper>
	)
} )


export default MyOrders
