import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'

import './UpdateUserSettings.css'
import UpdateUserInfo from './components/UpdateUserInfo'
import ChooseUser from './components/ChooseUser'
import { addNotification } from '../../../../utils/messageHandler/notifierStack'
// import mainStore from '../../../../stores/mainStore'

const INFO_ABOUT_USERS = gql`
query getUsersInfo( $tenantId: String! ){
    tenant( input: { id: $tenantId } ) {
		id
		applications {
		  accounts {
			user {
			  firstName
			  keycloakId
			  roles
			  email
			  lastName
			  username
			  id
			}
			id
			disabled
		  }
		  id
		}
	}
}
`

const DISABLE_TENANT_ACCOUNT = gql`
mutation disableUser ( $input:  DisableTenantAppAccountInput ){
	Tenant_disableAppAccount( input: $input ) {
	  id
	}
  }`

const ENABLE_TENANT_ACCOUNT = gql`
mutation enableUser ( $input: EnableTenantAppAccountInput ) {
Tenant_enableAppAccount(input: $input ) {
	id
	}
}`

const GET_ALL_ROLES = gql`
query getAllRoles{
	Util_ClientAppRoles(input: {appId: "uhub"}){
		name
	}
}`


/* global sessionStorage */


const UpdateUserSettings = ( ) => {
	const [ userSelectedForm, toggleUserSelectedForm ] = useState( false )
	const [ user, setnewUser ] = useState( {} )
	const [ availableRoles, setAvailableRoles ] = useState( [] )

	const [ selectedUserData, setSelected ] = useState( {} )

	const tenantId = sessionStorage.getItem( 'uhub_tenantId' )

	const [ disableUser ] = useMutation( DISABLE_TENANT_ACCOUNT, {
		onCompleted: () => { addNotification( 'OK', 'USER_PROFILE_SETTINGS_UPDATED' ) },
		onError: () => { addNotification( 'ERROR', 'CANT_UPDATE_USER_SETTINGS' ) },
		refetchQueries: [ 'getUsersInfo' ]
	} )
	const [ enableUser ] = useMutation( ENABLE_TENANT_ACCOUNT, {
		onCompleted: () => { addNotification( 'OK', 'USER_PROFILE_SETTINGS_UPDATED' ) },
		onError: () => { addNotification( 'ERROR', 'CANT_UPDATE_USER_SETTINGS' ) },
		refetchQueries: [ 'getUsersInfo' ]
	} )

	const {
		data: rolesData
	} = useQuery( GET_ALL_ROLES )

	const { data: userInfoData } = useQuery(
		INFO_ABOUT_USERS, { variables: { tenantId } }
	)

	const allDataForHubUsers = userInfoData?.tenant?.applications[ 0 ]?.accounts

	const usersArray = []

	if ( allDataForHubUsers ) {
		for ( let i = 0; i < allDataForHubUsers.length; i += 1 ) {
			const tmp = allDataForHubUsers[ i ].user
			tmp.disabled = allDataForHubUsers[ i ].disabled
			usersArray.push( tmp )
		}
	}

	// eslint-disable-next-line camelcase
	const allRoles = rolesData?.Util_ClientAppRoles.map( ( el ) => el.name )


	function handleUserChoice( selectedUser ) {
		const available = []
		setSelected( selectedUser )
		allRoles.map( ( role ) => ( selectedUser.roles.indexOf( role ) < 0 ? (
			available.push( role ) ) : null ) )
		setAvailableRoles( available )

		setnewUser( {
			roles: selectedUser.roles,
			keycloakId: selectedUser.keycloakId
		} )
		toggleUserSelectedForm( true )
	}

	function handleAvilableRoles( item ) {
		if ( user.roles ) {
			const indexAssigned = user.roles.indexOf( item )
			const indexAvailable = availableRoles.indexOf( item )
			if ( indexAssigned < 0 ) {
				user.roles.push( item )
				availableRoles.splice( indexAvailable, 1 )
			}
		}
		setnewUser( {
			roles: user.roles,
			keycloakId: user.keycloakId
		} )
		setAvailableRoles( availableRoles )
	}

	function handleroles( item ) {
		if ( availableRoles ) {
			const indexAssigned = user.roles.indexOf( item )
			const indexAvailable = availableRoles.indexOf( item )

			if ( indexAvailable < 0 ) {
				user.roles.splice( indexAssigned, 1 )
				availableRoles.push( item )
			}
		}
		setnewUser( {
			roles: user.roles,
			keycloakId: user.keycloakId
		} )
		setAvailableRoles( availableRoles )
	}


	function onDisableUserButtonClick() {
		if ( user ) {
			if ( user.disabled !== true ) {
				disableUser( {
					variables: {
						input: {
							app: 'UHUB',
							tenant: tenantId,
							user: user?.keycloakId
						}

					}
				} )
			} else {
				enableUser( {
					variables: {
						input: {
							app: 'UHUB',
							tenant: tenantId,
							user: user?.keycloakId
						}

					}
				} )
			}
		}
	}

	return (
		<div>
			<ChooseUser
				usersArray={ usersArray }
				handleUserChoice={ handleUserChoice }
			/>
			{ userSelectedForm
				? (
					<UpdateUserInfo
						user={ selectedUserData }
						availableRoles={ availableRoles }
						handleAssignedRoles={ handleroles }
						handleAvilableRoles={ handleAvilableRoles }
						onDisableUserButtonClick={ onDisableUserButtonClick }
					/>
				) : null }
		</div>
	)
}


export default UpdateUserSettings
