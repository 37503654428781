import React, { useEffect, useState } from 'react'
import { Trans } from '@lingui/macro'

import './HubPackages.css'
import HubPackagesRow from './components/HubPackagesRow'
import ConditionalWrapper from '../../_Globals/ConditionalWrapper'
import TableHubPackagesHeader from './components/TableHubPackagesHeader'

const packagesPlaceholder = [
	{
		id: 1, packetagcode: 'AE4R', packetcode: 'ACDT', quantity: '3kg', warehouseLocationAgPacket: 'Hub Brezovica', warehouseLocationPacket: 'Hub Brezovica', driver: 'Štefan', supplier: 'Ponudnik d.o.o', buyer: 'Janez Novak', status: true, packer: 'Janez', date: '23. 11. 2019'
	},
	{
		id: 2, packetagcode: 'Y34N', packetcode: 'SFEF', quantity: '8kg', warehouseLocationAgPacket: 'Hub Brezovica', warehouseLocationPacket: 'Hub Brezovica', driver: 'Štefan', supplier: 'Ponudnik d.o.o', buyer: 'Janez Novak', status: true, packer: 'Miha', date: '23. 11. 2019'
	},
	{
		id: 3, packetagcode: 'Y34N', packetcode: 'GRGD', quantity: '4kg', warehouseLocationAgPacket: 'Hub Brezovica', warehouseLocationPacket: 'Hub Brezovica', driver: 'Štefan', supplier: 'Ponudnik d.o.o', buyer: 'Janez Novak', status: false, packer: 'Jan', date: '23. 11. 2019'
	},
	{
		id: 4, packetagcode: 'Y34N', packetcode: 'EFRF', quantity: '8kg', warehouseLocationAgPacket: 'Hub Brezovica', warehouseLocationPacket: 'Hub Brezovica', driver: 'Štefan', supplier: 'Ponudnik d.o.o', buyer: 'Janez Novak', status: true, packer: 'Janez', date: '23. 11. 2019'
	},
]


const HubPackages = () => {
	const [ windowWidth, setWidth ] = useState( window.innerWidth > 768 )

	useEffect( () => {
		const handleResize = () => {
			setWidth( window.innerWidth > 768 )
		}
		window.addEventListener( 'resize', handleResize )
		return () => { window.removeEventListener( 'resize', handleResize ) }
	}, [] )

	return (
		<ConditionalWrapper
			condition={ windowWidth }
			falseConditionComponent={ (
				<div className='warning'>
					<Trans id='CycleSectionPage.Warning' />
				</div>
			)}
		>

			<div>

				<div className='hub-packages-container'>

					<div className='gridPackages-title'>
						<Trans id='HubPackages.Packages' />
					</div>
					<div className='data-range-selector'>
						<span className='cycle-num-text'>
							<Trans id='orderSectionPage.cycleNum' />
						</span>
						<span>
							<input id='cycleListNum' list='cycleNums' name='cycles' />
							<datalist id='cycleNums'>
								{ packagesPlaceholder.map( order => (
									<option
										key={ order.id }
										value={ order.packetagcode }
									/>
								) ) }
							</datalist>
						</span>
					</div>
					<div className='gridPackages-header'>
						<TableHubPackagesHeader />
					</div>
					<div className='hub-packages-rows-main'>

						<div>
							{
								packagesPlaceholder.map( packagesRow => (
									<HubPackagesRow key={ packagesRow.id } packagesRow={ packagesRow } /> ) )
							}

						</div>

					</div>
					<div className='hub-packages-buttons'>
						<button type='button' className='hub-section-edit-button'>
							<Trans id='HubPackages.AllQRCodes' />
						</button>
						<button type='button' className='hub-section-edit-button'>
							<Trans id='HubPackages.SelectedQRCodes' />
						</button>
						<button type='button' className='hub-section-edit-button'>
							<Trans id='HubPackages.QRCodesForDriving' />
						</button>
					</div>

				</div>

			</div>
		</ConditionalWrapper>
	)
}

export default HubPackages
