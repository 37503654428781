/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
import React, { useState } from 'react'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import './DropdownContentMobile.css'
import './CategoriesMenuMobile.css'
import ConditionalWrapper from '../../_Globals/ConditionalWrapper'
import roleHandler from '../../../utils/ContentRoleHandler'
import DropdownContent from './DropdownContent'
import {
	myOfferTabs,
	myOfferTranslations,
	hubPageTabs,
	hubPageTranslations
} from '../../../pages/Tabs'

const DropdownContentMobile = React.memo( ( {
	tabs, translations, isAuth, dropdownShown, closeDropDown, history
} ) => {
	const [ dropdownSubCatCategories, displayDropdownCategories ] = useState( false )
	const [ dropdownSubCatHubs, displayDropdownHubs ] = useState( false )
	const [ dropdownSubCatMyPurchases, displayDropdownMyPurchases ] = useState( false )
	const [ dropdownSubCatMyOffers, displayDropdownMyOffers ] = useState( false )
	const [ dropdownSubCatHub, displayDropdownHub ] = useState( false )

	function closeDropdownWhenClickOutside( event ) {
		document.addEventListener( 'click', ( event ) => {
			if ( event.target.closest( '.category-dropdown' ) ) return
			if ( event.target.closest( '.btn-category' ) ) return
			closeDropDown()
		} )
	}

	function getDropdown( tab ) {
		switch ( tab ) {
		case 'categories':
			break
		case 'hubs':
			break
		case 'myPurchases':
			break
		case 'myOffers':
			return (
				<DropdownContent
					isAuth={ isAuth }
					tabs={ myOfferTabs }
					translations={ myOfferTranslations }
					dropdownSubCatShown={ dropdownSubCatMyOffers }
					closeDropDown={ closeDropDown }
					mobile
				/>
			)
		case 'hub':
			return (
				<DropdownContent
					isAuth={ isAuth }
					tabs={ hubPageTabs }
					translations={ hubPageTranslations }
					dropdownSubCatShown={ dropdownSubCatHub }
					closeDropDown={ closeDropDown }
					mobile
				/>
			)
		default:
			return 'empty'
		}
	}

	function getDropdownProperFunc( tab ) {
		switch ( tab ) {
		case 'categories':
			displayDropdownCategories( true )
			displayDropdownHubs( false )
			displayDropdownMyPurchases( false )
			displayDropdownMyOffers( false )
			displayDropdownHub( false )
			break
		case 'hubs':
			displayDropdownCategories( false )
			displayDropdownHubs( true )
			displayDropdownMyPurchases( false )
			displayDropdownMyOffers( false )
			displayDropdownHub( false )
			break
		case 'myPurchases':
			displayDropdownCategories( false )
			displayDropdownHubs( false )
			displayDropdownMyPurchases( true )
			displayDropdownMyOffers( false )
			displayDropdownHub( false )
			history.push( '/my-purchases' )
			break
		case 'myOffers':
			displayDropdownCategories( false )
			displayDropdownHubs( false )
			displayDropdownMyPurchases( false )
			displayDropdownMyOffers( true )
			displayDropdownHub( false )
			break
		case 'hub':
			displayDropdownCategories( false )
			displayDropdownHubs( false )
			displayDropdownMyPurchases( false )
			displayDropdownMyOffers( false )
			displayDropdownHub( true )
			break
		default:
			return 'empty'
		}
	}

	return (
		<div id={ !dropdownShown ? 'category-dropdown-content-mobile' : 'category-dropdown-content-mobile-open' }>
			{
				tabs.map( ( tab, index ) => (
					<ConditionalWrapper
						key={ tab.id }
						condition={ roleHandler( isAuth, tab.roles ) }
						falseConditionComponent={ <div /> }
					>
						<div
							id='category-dropdown'
							className='category-dropdown'
						>
							<button
								type='button'
								className='btn-category'
								tabIndex={ index }
								onKeyPress={ ( e ) => {
									getDropdownProperFunc( tab.id )
									closeDropdownWhenClickOutside( e )
								} }
								onClick={ ( e ) => {
									getDropdownProperFunc( tab.id )
									closeDropdownWhenClickOutside( e )
								} }
							>
								{ translations[ tab.id ] }
								{ getDropdown( tab.id ) }
							</button>
						</div>
					</ConditionalWrapper>
				) )
			}
		</div>
	)
} )

DropdownContentMobile.defaultProps = {
	tabs: [],
	translations: {},
}

DropdownContentMobile.propTypes = {
	tabs: propTypes.array,
	translations: propTypes.object,
	isAuth: propTypes.bool.isRequired,
	dropdownShown: propTypes.bool.isRequired,
	closeDropDown: propTypes.func.isRequired,
	history: propTypes.object.isRequired,
}

export default withRouter( DropdownContentMobile )
