const formatters = {}
const formatterValues = {
	en: 'en-EN',
	sl: 'sl-SI',
}

const getNumberFormatter = ( locale ) => {
	if ( formatters[ locale ] ) {
		return formatters[ locale ]
	}

	const priceFormatter = new Intl.NumberFormat( formatterValues[ locale ] )
	formatters[ locale ] = priceFormatter

	return formatters[ locale ]
}

export default getNumberFormatter
