/** Base reducer */
const reducer = ( state, action ) => {
	switch ( action.type ) {
	/** --------------------- reducer actions --------------------- */

	/** Toggles profile side menu */
	case 'toggleProfilMenu':
		return {
			...state,
			profileMenu: !state.profileMenu
		}

		/** Toggles Hub selection side menu */
	case 'toggleHubMenu':
		return {
			...state,
			hubMenu: !state.hubMenu
		}

		/** Toggles PrintOverlay menu */
	case 'togglePrintOverlayMenu':
		return {
			...state,
			printOverlayMenu: action.printOverlayMenu
		}

		/** Toggles Notifications box */
	case 'toggleNotificationBox':
		return {
			...state,
			notificationBoxMenu: !state.notificationBoxMenu
		}

		/** Toggles between providers and products */
	case 'toggleProviderProduct':
		return {
			...state,
			isProvider: action.value
		}
		/** Toggles the shopping list visibility */
	case 'toggleShoppingListVisibility':
		return {
			...state,
			shoppingListVisibility: !state.shoppingListVisibility
		}
		/** Toggles the myOffersFilter visibility */
	case 'toggleMyOfferListVisibility':
		return {
			...state,
			myOfferListVisibility: !state.myOfferListVisibility
		}
		/** Change selected hub to new value */
	case 'changeSelectedHub':
		return {
			...state,
			selectedHub: action.selectedHub
		}

	case 'selectedHubProducts':
		return {
			...state,
			selectedHubProducts: action.selectedHubProducts
		}
	case 'getProviderInfo':
		return {
			...state,
			getProviderInfo: action.getProviderInfo
		}
	case 'selectedHubCycle':
		return {
			...state,
			selectedHubCycle: action.selectedHubCycle
		}
	case 'displayCodeFilter':
		return {
			...state,
			displayCodeFilter: action.displayCodeFilter
		}
	/* case 'displayProviderFilter':
		return {
			...state,
			displayProviderFilter: action.displayProviderFilter
		} */
	case 'displayCustomerFilter':
		return {
			...state,
			displayCustomerFilter: action.displayCustomerFilter
		}
	case 'displayStatusFilter':
		return {
			...state,
			displayStatusFilter: action.displayStatusFilter
		}
	case 'displayLogisticFilter':
		return {
			...state,
			displayLogisticFilter: action.displayLogisticFilter
		}
	case 'displayDateFilter':
		return {
			...state,
			displayDateFilter: action.displayDateFilter
		}
	case 'galleryOpened':
		return {
			...state,
			galleryOpened: action.galleryOpened
		}
	case 'deafultQuantities':
		return {
			...state,
			deafultQuantities: action.deafultQuantities
		}
	case 'productName':
		return {
			...state,
			productName: action.productName
		}
	case 'quantity':
		return {
			...state,
			quantity: action.quantity
		}
	case 'editProduct':
		return {
			...state,
			editProduct: action.editProduct
		}
	case 'dataForMutationProduct':
		return {
			...state,
			quantity: action.dataForMutationProduct
		}
	case 'isProductSelected':
		return {
			...state,
			isProductSelected: action.isProductSelected
		}
	case 'fatalError':
		return {
			...state,
			fatalError: action.fatalError
		}

		/** If can't find action just return state */
	default: return state
	}
}

export default reducer
