import gql from 'graphql-tag'

const CART_ORDERS_NUMBER = gql`
subscription getCartOrdersNumber(
	$cart_uuid: uuid
	$status_id: Int!
){
	cart_items(
		where: {
			cart_uuid: { _eq: $cart_uuid },
			status_id: { _eq: $status_id },
			order: { 
				status_id: { _in: [ 1, 2, 3 ] }
			} 
		} 
	) {
    	id
  	}
}`

export default CART_ORDERS_NUMBER
