import React, { useState, Suspense, lazy } from 'react'
import { Trans } from '@lingui/macro'
import propTypes from 'prop-types'
import gql from 'graphql-tag'
import { useMutation, useLazyQuery, useQuery } from '@apollo/react-hooks'

import './ProviderInfoRow.css'
import ToggleButton from '../../../_Globals/ToggleButton'

const GET_ALL_MARKETS = gql`
query getAllMarketsInfoRow( $hubId: bigint! ){
    provider_market( where: { hub_id: { _eq: $hubId } } ) {
    id
    active
    name
    confirmed
    description
    sla_id
    tenant {
	  id
      applications {
		id
        data {
          bankAccount {
            IBAN
            BIC
            name
          }
          address {
            address
            zip
            city
            country
          }
          phone
          mobile
        }
      }
    }
  }
}`

const GET_SAVED_SLA = gql`
mutation createDownloadURL( $appId: String!, $fileId: String!, $tenantId: String! ) {
	Storage_createDownloadURL( appId: $appId, fileId: $fileId, tenantId: $tenantId ) {
		url
	}
}
`

const UPDATE_MARKET_CONFIRM_STATUS = gql`
mutation updateMarketConfirmStatus( $id: bigint!, $confirmed: Boolean, $sla_status: provider_sla_status_enum_enum! ) {
    update_provider_market( where: { id: { _eq: $id } }, _set: { confirmed: $confirmed, sla_status: $sla_status } ) {
        affected_rows
    }
}`

const GET_PROVIDER_EMAIL = gql`
query getProviderEmail( $marketId: bigint! ) {
	provider_market(where: { id: { _eq: $marketId } } ) {
		id
		provider {
			id
			tenant {
				id
				owner {
					email
				}
			}
		}
  }
}`

const SEND_MAIL = gql`
query sendMail( $input: MailInput ) {
	sendMail(
		input: $input
	)
}`

const Image = lazy( () => import( './image' ) )

const ProviderInfoRow = React.memo( ( {
	market, selectMarket, hubId, setPdf, setHub, showSLA
} ) => {
	const fileId = market.galleries.length > 0 ? market.galleries[ 0 ].file.file_id : null
	const tenantId = market.galleries.length > 0 ? market.galleries[ 0 ].file.tenant_id : null

	const [ confirmed, setActiveStatus ] = useState( market.confirmed )
	const [ updateConfirmStatus ] = useMutation( UPDATE_MARKET_CONFIRM_STATUS, {
		refetchQueries: () => [ {
			query: gql`${ GET_ALL_MARKETS }`,
			variables: {
				hubId
			},
			skip: !hubId
		} ]
	} )

	const [ savedSlaUrl ] = useMutation( GET_SAVED_SLA, {
		variables: {
			appId: 'uhub',
			fileId: `${ market.sla ? market.sla.file_id : null }`,
			tenantId: market.tenant_id
		},
		onCompleted: ( data ) => {
			const { url } = data.Storage_createDownloadURL

			window.pdfjsLib.getDocument( url ).then( ( pdfRes ) => {
				setPdf( pdfRes )
			} )
		}
	} )

	const [ getSendMail ] = useLazyQuery( SEND_MAIL )

	const {
		data: getProviderEmailData,
	} = useQuery( GET_PROVIDER_EMAIL, {
		variables: {
			marketId: market.id,
		}
	} )

	const providerEmail = ( getProviderEmailData && getProviderEmailData.provider_market
		&& getProviderEmailData.provider_market[ 0 ]
		&& getProviderEmailData.provider_market[ 0 ].provider
		&& getProviderEmailData.provider_market[ 0 ].provider.tenant
		&& getProviderEmailData.provider_market[ 0 ].provider.tenant.owner
		&& getProviderEmailData.provider_market[ 0 ].provider.tenant.owner.email )
		? getProviderEmailData.provider_market[ 0 ].provider.tenant.owner.email : null

	function toggleState() {
		setActiveStatus( !confirmed )
		updateConfirmStatus( { variables: { id: market.id, confirmed: !confirmed, sla_status: ( !confirmed ) ? 'CONFIRMED' : 'REJECTED' } } )

		if ( !confirmed ) {
			getSendMail( {
				variables: {
					input: {
						recipient: providerEmail,
						template: 'SlaIsConfirmed',
						locals: '{}'
					}
				}
			} )
		}
	}

	function showSLAButton( input ) {
		setHub( input )
		if ( market.sla && market.sla.file_id ) {
			savedSlaUrl()
			setHub( market.hub )
			showSLA( true )
		} else {
			showSLA( false )
		}
	}

	return (
		<div className='provider-info-line'>
			{ market && market.galleries.length > 0 ? (
				<Suspense fallback={ <div style={ { width: 165, height: 145, background: '#ececec' } } /> }>
					<Image
						tenantId={ tenantId }
						fileId={ fileId }
					/>
				</Suspense>
			) : (
				<img
					className='image-slider-image'
					style={ { width: 165, height: 145 } }
					src='/img/market_default.png'
					alt=''
				/>
			) }
			<div className='provider-info-line-info'>
				<div className='provider-info-name'><Trans id='ProviderInfoRow.name' /></div>
				<div className='provider-info-name-value'>{ market.name }</div>
			</div>
			<div className='provider-info-details'>
				<div className='provider-info-address'><Trans id='ProviderInfoRow.address:' /></div>
				<div className='provider-info-telephone'><Trans id='ProviderInfoRow.city:' /></div>
				<div className='provider-info-mobile-phone'><Trans id='ProviderInfoRow.zip:' /></div>
				<div className='provider-info-SLA-signed'><Trans id='ProviderInfoRow.SLAsigned:' /></div>
				<div className='provider-info-active'><Trans id='ProviderInfoRow.active( from provider ):' /></div>

				<div className='provider-info-address-value'>{ market.tenant.applications[ 0 ].data.address[ 0 ].address }</div>
				<div className='provider-info-city'>{ market.tenant.applications[ 0 ].data.address[ 0 ].city }</div>
				<div className='provider-info-zip-value'>{ market.tenant.applications[ 0 ].data.address[ 0 ].zip }</div>
				<div className='provider-info-SLA-signed-value'>
					{
						market.sla_id
							? <Trans id='ProviderInfoRow.signedSLA' />
							: <Trans id='ProviderInfoRow.notSignedSLA' />
					}
				</div>
				<div className='provider-info-active-value'>
					{
						market.active
							? <Trans id='ProviderInfoRow.activeValue' />
							: <Trans id='ProviderInfoRow.deactiveValue' />
					}
				</div>
			</div>
			<div className='provider-info-actions-container'>
				<div className='toggle-activate-deactivate'>
					{ market.sla_id
						? (
							<ToggleButton
								activate={ !confirmed }
								rowId={ market.id }
								onClick={ () => toggleState() }
								textActive={ <Trans id='ProviderInfoRow.deactivate' /> }
								textDeactive={ <Trans id='ProviderInfoRow.activate' /> }
							/>
						) : null }
				</div>
				{
					market.sla_id ? (
						<div
							className='provider-info-line-show-SLA'
							role='button'
							tabIndex={ 0 }
							onKeyPress={ () => showSLAButton( market.hub ) }
							onClick={ () => showSLAButton( market.hub ) }
						>
							<Trans id='ProviderInfoRow.showSLA' />
							<div className='provider-info-line-arrow-right-SLA' />
						</div>
					) : <div />
				}
				<div
					className='provider-info-line-show-provider'
					role='button'
					tabIndex={ 0 }
					onKeyPress={ () => selectMarket( market.id ) }
					onClick={ () => selectMarket( market.id ) }
				>
					<Trans id='providerRow.lookMoreEdit' />
					<div className='provider-info-line-arrow-right' />
				</div>
			</div>
			<div key='signed-dots' id='provider-info-dots-row' />
		</div>
	)
} )

ProviderInfoRow.propTypes = {
	market: propTypes.object.isRequired,
	selectMarket: propTypes.func.isRequired,
	hubId: propTypes.number.isRequired,
	setPdf: propTypes.func.isRequired,
	setHub: propTypes.func.isRequired,
	showSLA: propTypes.func.isRequired,
	hub: propTypes.object.isRequired,
}

export default ProviderInfoRow
