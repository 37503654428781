/* eslint-disable max-len */
import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'
// eslint-disable-next-line no-unused-vars
import StarComponent from '../../../../components/_Globals/StarComponent'
import GET_PROVIDER_RATING from './ProviderDetailsGQL'

import './ProviderDetails.css'

const ProviderDetails = React.memo( ( { data } ) => {
	const {
		data: dataProviderRating,
		error: errorProviderRating,
	} = useQuery( GET_PROVIDER_RATING, {
		variables: {
			id: data.provider ? data.provider.id : data.market.provider.id
		}
	} )

	function showMarketDescription() {
		if ( !data.description ) {
			return <Trans id='ProviderDetails.descriptionNotAvailable' />
		}
		if ( data.description ) {
			return data.description
		}
		if ( !data.market ) {
			return <Trans id='ProviderDetails.descriptionNotAvailable' />
		}
		if ( data.market ) {
			return data.market.description
		}
		return null
	}

	const providerRating = dataProviderRating
		&& dataProviderRating.provider_provider
		&& dataProviderRating.provider_provider.length > 0
		? dataProviderRating.provider_provider[ 0 ].average_mark : null

	return (
		<div className='providerDetails-main-container'>
			<div className='providerDetails-name'>
				{ data.name ? data.name : data.market.name }
				<div className='providerDetails-rating'>
					{ errorProviderRating ? <div>{ JSON.stringify( errorProviderRating ) }</div> : null }
					{ dataProviderRating ? (
						<StarComponent
							fontSize={ 18 }
							starCount={ providerRating }
							providerId={ data.provider ? data.provider.id : data.market.provider.id }
						/>
					) : null }
				</div>
			</div>
			<div className='providerDetails-description'>
				{ showMarketDescription() }
			</div>
			{/* <div className='providerDetails-typeOfProducts'>
				<Trans id='ProviderDetails.typeOfProducts' />
			</div> */}
			<div className='providerDetails-filters'>
				{/* TODO */}
				{/* data.providerDescription.filters.map( ( item ) => (
					<div key={ item.id } className='providerDetails-products-item'>{ item.item }</div>
				) ) */}
			</div>
		</div>
	)
} )

ProviderDetails.defaultProps = {
	data: null
}

ProviderDetails.propTypes = {
	data: propTypes.object,
}

export default ProviderDetails
