
import React, { useState, useEffect } from 'react'
import { Trans } from '@lingui/macro'
import propTypes from 'prop-types'

import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

import EditArea from '../../../../../components/_Globals/EditArea'
import { addNotification } from '../../../../../utils/messageHandler/notifierStack'

const UPDATE_USER_INFO = gql`
mutation updateUserInfo ( $input:  User_updateInput ){
	User_update( input: $input ) {
		firstName
		id
		lastName
		username
	}
}`

/* global sessionStorage */

const UpdateUserInfo = ( {
	user, availableRoles, handleAssignedRoles, handleAvilableRoles, onDisableUserButtonClick, passText
} ) => {
	const [ editedText, setEditedText ] = useState( {} )

	const tenantId = sessionStorage.getItem( 'uhub_tenantId' )

	const [ updateUser ] = useMutation( UPDATE_USER_INFO, { onCompleted: () => { addNotification( 'OK', 'USER_PROFILE_SETTINGS_UPDATED' ) } } )

	useEffect( () => {
		if ( Object.keys( editedText ).length > 0 ) {
			passText( editedText )
			setEditedText( {} )
		}
	}, [ editedText, passText ] )

	function getText( id, text ) {
		const objectCopy = editedText

		objectCopy[ id ] = text
		setEditedText( objectCopy )
	}

	const regexName = new RegExp( /^[A-Za-zČŠčšŽž-\s]+$/, 'i' )

	function checkNew( ) {
		let keys = null

		if ( user.roles.length > 0 ) {
			if ( Object.keys( editedText ).length > 0 ) {
				keys = Object.entries( editedText )
				let allValuesValid = true
				// eslint-disable-next-line no-restricted-syntax
				for ( const [ key, value ] of keys ) {
					if ( ( key === 'firstName' || key === 'lastName' ) && !regexName.test( value ) ) {
						allValuesValid = false
						break
					}
				}

				if ( allValuesValid ) {
					updateUser( {
						variables: {
							input: {
								appId: 'UHUB',
								id: user.keycloakId,
								firstName: editedText.firstName || '',
								lastName: editedText.lastName || '',
								roles: {
									roles: user.roles,
									tenant: tenantId
								}
							}

						}
					} )
				} else {
					addNotification( 'ERROR', 'INVALID_INPUTS_USER_PROFILE_SETTINGS' )
				}
			} else {
				// samo role se posodobijo
				updateUser( {
					variables: {
						input: {
							appId: 'UHUB',
							id: user.keycloakId,
							roles: {
								roles: user.roles,
								tenant: tenantId
							}
						}

					}
				} )
			}
		} else {
			addNotification( 'ERROR', 'INVALID_INPUTS_MISSING_ROLES' )
		}
	}

	return (

		<div className='user-profile-container'>
			<div className='name-settings'>
				<Trans id='UserProfileSettings.Username' />
			</div>
			<EditArea
				id='username'
				isInput
				myStyle='hub-view-hub-title-editable edit-area-settings'
				text={ user.username }
				editable={ false }
			/>
			<div className='name-settings'>
				<Trans id='UserProfileSettings.Name' />
			</div>
			<EditArea
				id='firstName'
				isInput
				myStyle='hub-view-hub-title-editable edit-area-settings'
				getText={ getText }
				text={ user.firstName }
				editable
			/>

			<div className='name-settings'>
				<Trans id='UserProfileSettings.Surname' />
			</div>
			<EditArea
				id='lastName'
				isInput
				myStyle='hub-view-hub-title-editable edit-area-settings'
				getText={ getText }
				text={ user.lastName }
				editable
			/>

			{/* <div className='filter-checkbox'>
			<CheckBox className='container' id='status' text='Ponastavi geslo' value='bla' />
		</div> */}

			<div className='name-settings'>
				<Trans id='UserProfileSettings.AvilableRoles' />
			</div>
			<div className='role-container'>
				{availableRoles && availableRoles.map( ( role ) => (
					<div
						key={ role }
						className='avilable-roles-chip'
						onClick={ () => handleAvilableRoles( role ) }
						onKeyPress={ () => handleAvilableRoles( role ) }
						role='button'
						tabIndex='0'
					>
						{role}
					</div>

				) )}
			</div>

			<div className='name-settings'>
				<Trans id='UserProfileSettings.AssignedRoles' />
			</div>
			<div className='role-container'>
				{user.roles && user.roles.map( ( role ) => (
					<div
						key={ role }
						className='avilable-roles-chip'
						onClick={ () => handleAssignedRoles( role ) }
						onKeyPress={ () => handleAssignedRoles( role ) }
						role='button'
						tabIndex='0'
					>
						{role}
						<button className='chip-closebtn' type='button'>x</button>

					</div>

				) )}
			</div>
			<div className='my-profile-button-container'>
				<button className='settings-button' type='button' onClick={ () => onDisableUserButtonClick() }>
					<p>
						{ user.disabled
							? <Trans id='UserProfileSettings.EnableUser' />
							: <Trans id='UserProfileSettings.DeactivateUser' /> }

					</p>
				</button>
			</div>
			<div className='my-profile-button-container'>
				<button
					className='settings-button'
					type='button'
					onClick={ () => ( checkNew() )	}
				>
					<p><Trans id='UserProfileSettings.SaveButton' /></p>
				</button>
			</div>

		</div>
	)
}

UpdateUserInfo.defaultProps = {
	passText: () => {},
}

UpdateUserInfo.propTypes = {
	user: propTypes.object.isRequired,
	availableRoles: propTypes.array.isRequired,
	handleAvilableRoles: propTypes.func.isRequired,
	handleAssignedRoles: propTypes.func.isRequired,
	onDisableUserButtonClick: propTypes.func.isRequired,
	passText: propTypes.func
}

export default UpdateUserInfo
