import React, { useEffect, useState } from 'react'
import { Trans } from '@lingui/macro'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

import './GS1.css'
import ConditionalWrapper from '../../_Globals/ConditionalWrapper'
import TableGS1Header from './components/TableGS1Header'
import TableRowGS1 from './components/TableRowGS1'
import Loader from '../../Loader'

const CODE_REGISTER_GS1 = gql`
	query codeRegisterGS1 {
		code_register_gs1 {
            brick_code
			gs1_brick {
				id
                translation_sl
			}
			gs1_class {
				id
                translation_sl
			}
			gs1_family {
				id
				translation_sl
            }
            gs1_segment {
				id
				translation_sl
			}
		}
	}
`

const GS1 = () => {
	const [ windowWidth, setWidth ] = useState( window.innerWidth > 768 )
	const {
		data: crData,
		error: crError,
		loading: crLoading
	} = useQuery( CODE_REGISTER_GS1, { fetchPolicy: 'no-cache' } )

	const gs1Data = crData && crData.code_register_gs1 ? crData.code_register_gs1 : []

	useEffect( () => {
		const handleResize = () => {
			setWidth( window.innerWidth > 768 )
		}
		window.addEventListener( 'resize', handleResize )
		return () => { window.removeEventListener( 'resize', handleResize ) }
	}, [] )

	if ( crLoading ) { return <Loader /> }
	if ( crError ) { return <div>{JSON.stringify( crError ) }</div> }

	// const GS1ContainerCSS = {
	// 	display: 'grid',
	// }

	// const GridGS1TitleCSS = {
	// 	textAlign: 'left',
	// 	gridRow: 1 / 2,
	// 	color: 'green',
	// 	fontSize: '15px',
	// 	fontWeight: 600,
	// }

	return (
		<ConditionalWrapper
			condition={ windowWidth }
			falseConditionComponent={ (
				<div className='warning'>
					<Trans id='CycleSectionPage.Warning' />
				</div>
			) }
		>
			<div id='gs1-container'>
				<div className='grid_gs1_title'><Trans id='GS1.Title' /></div>
				<TableGS1Header />
				<div>
					{
						gs1Data.map( ( item ) => (
							<TableRowGS1
								key={ item.brick_code }
								segments={ item.gs1_segment.translation_sl }
								family={ item.gs1_family.translation_sl }
								segmentClass={ item.gs1_class.translation_sl }
								brick={ item.gs1_brick.translation_sl }
							/>
						) )
					}
				</div>
			</div>
		</ConditionalWrapper>
	)
}

export default GS1
