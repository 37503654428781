import React, { useState } from 'react'
import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'

import ToggleButton from '../../../_Globals/ToggleButton'
import './OrderRow.css'


const OrderRow = React.memo( ( { order } ) => {
	const [ activate, setActiveStatus ] = useState( order.active )

	function toggleState() {
		if ( activate ) {
			setActiveStatus( false )
		} else {
			setActiveStatus( true )
		}
	}

	return (
		<div id='order-row-line'>
			<div className='img-qr-code' />
			<div className='order-row-line-info'>
				<p className='order-blue-text'>
					<Trans id='OrderRow.order' />
					<span className='order-blue-bold-text'>{ order.id }</span>
				</p>
				<p className='order-blue-text ordercode-style'>
					<Trans id='OrderRow.code' />
					<span className='order-blue-bold-text'>{ order.code }</span>
				</p>
				<p className='order-blue-text'>
					<Trans id='OrderRow.Hub' />
					<span className='order-normal-text'>{ order.hub.name }</span>
				</p>
				<p className='order-blue-text'>
					<Trans id='OrderRow.Product' />
					<span className='order-normal-text'>{ order.product }</span>
				</p>
				<p className='order-blue-text'>
					<Trans id='OrderRow.Quantity' />
					<span className='order-normal-text'>{ order.quantity }</span>
				</p>

			</div>

			<div className='order-row-line-info'>
				<p className='order-blue-text'>
					<Trans id='OrderRow.Status' />
					<span className='order-normal-text'>{ order.status }</span>
				</p>
				<p className='order-blue-text'>
					<Trans id='OrderRow.Packing' />
					<span className='order-normal-text'>{ order.packing }</span>
				</p>
				<p className='order-blue-text'>
					<span>
						<Trans id='OrderRow.LogisticsUser' />
					</span>
					<span className='order-normal-text'>{ order.logistics_user }</span>
				</p>
				<p className='order-blue-text'>
					<span>
						<Trans id='OrderRow.LogisticsHub' />
					</span>
					<span className='order-normal-text'>{ order.logistics_hub }</span>
				</p>
				<p className='order-blue-text'>
					<span>
						<Trans id='OrderRow.OrderTime' />
					</span>
					<span className='order-normal-text'>{ order.order_time }</span>
				</p>
			</div>

			<div className='order-status-info'>
				{order.pastOrder
					? (
						<div className='toggle-button-1'>
							{order.deliveryStatus
								? <Trans id='OrderRow.OrderDelivered' />
								: <Trans id='OrderRow.OrderCanceled' />}
						</div>
					)
					: (
						<ToggleButton
							activate={ activate }
							rowId={ order.id }
							onClick={ () => toggleState() }
							textActive={ <Trans id='OrderRow.OrderReady' /> }
							textDeactive={ <Trans id='OrderRow.OrderNotAvilable' /> }
							styleDiv='toggle-button-1'
						/>
					)}
				<div
					className='order-row-line-show-order'
					role='button'
					tabIndex={ 0 }
					type='button'
				>
					<Trans id='HubRow.lookMoreEdit' />
					<div className='order-row-line-arrow-right' />
				</div>

			</div>

		</div>
	)
} )

OrderRow.propTypes = {
	order: propTypes.object.isRequired,
}

export default OrderRow
