import React, { useEffect } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import propTypes from 'prop-types'

const GET_PICTURES_URLS = gql`
	mutation createDownloadURL(
		$fileId: String!,
		$tenantId: String!
	) {
		Storage_createDownloadURL(
			appId: "uhub", 
			fileId: $fileId, 
			tenantId: $tenantId
		) {
			url
		}
}`
const Image = React.memo( ( {
	fileId, tenantId
} ) => {
	const [ getPicturesUrls, {
		data: pictureUrlData
	} ] = useMutation( GET_PICTURES_URLS, {
		variables: { fileId, tenantId }
	} )

	const url = tenantId && pictureUrlData
        && pictureUrlData.Storage_createDownloadURL
        && pictureUrlData.Storage_createDownloadURL.url
		? pictureUrlData.Storage_createDownloadURL.url : null

	useEffect( () => {
		getPicturesUrls()
	}, [ getPicturesUrls ] )

	return (
		<img
			style={ { width: 165, height: 145 } }
			src={ url }
			alt=''
		/>

	)
} )

Image.propTypes = {
	tenantId: propTypes.string.isRequired,
	fileId: propTypes.string.isRequired,
}

export default Image
