/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import propTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'

import {
	ShoppingPage,
	ShoppingCartPage,
	MyPurchasesPage,
	HubMainInfoDetail,
	ProviderMainInfoDetail,
	MyOffers,
	HubPage,
	SettingsPage
} from '../pages'

import SuccessfulPayment from '../components/ShoppingCart/SuccessfullPayment'
import PaymentFailed from '../components/ShoppingCart/PaymentFailed'

import PrivateRoute from './PrivateRoute'

const PublicRoutes = ( {
	isAuthenticated, limit, setRefetch, refetching
} ) => (
	<Switch>
		<Route exact path='/' render={ ( props ) => <ShoppingPage { ...props } isAuthenticated={ isAuthenticated } /* refetching={ refetching } limit={ limit } setRefetch={ ( toRefetch ) => setRefetch( toRefetch ) } */ /> } />
		<Route exact path='/shopping-cart' render={ ( props ) => <ShoppingCartPage { ...props } isAuthenticated={ isAuthenticated } /> } />
		<PrivateRoute exact path='/my-purchases' component={ MyPurchasesPage } />
		<PrivateRoute path='/my-offers' component={ MyOffers } />
		<PrivateRoute path='/hub' component={ HubPage } />
		<PrivateRoute exact path='/settings' component={ SettingsPage } />
		<Route exact path='/provider-info' render={ ( props ) => <ProviderMainInfoDetail { ...props } /> } />
		<Route exact path='/hub-info' component={ HubMainInfoDetail } />
		<PrivateRoute path='/payment-successful' component={ SuccessfulPayment } />
		<PrivateRoute path='/payment-failed' component={ PaymentFailed } />

		{ /* It'll catch anything that has no route. */ }
		<Route render={ () => <Redirect to={ { pathname: '/' } } /> } />
	</Switch>
)

PublicRoutes.propTypes = {
	isAuthenticated: propTypes.bool.isRequired,
	// limit: propTypes.number.isRequired,
	// refetching: propTypes.bool.isRequired,
	// setRefetch: propTypes.func.isRequired
}

export default PublicRoutes
