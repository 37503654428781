import React from 'react'
import propTypes from 'prop-types'

import './HubPackagesRow.css'

const HubPackagesRow = (
	{
		packagesRow: {
			packetagcode,
			packetcode,
			quantity,
			warehouseLocationAgPacket,
			warehouseLocationPacket,
			driver,
			supplier,
			buyer,
			status,
			packer,
			date
		}
	}
) => (
	<div className='hub-packages-row-data'>
		<div className='gridPackages-item-packetagcode'>{ `${ packetagcode }` }</div>
		<div className='gridPackages-item-info'>{ `${ packetcode }` }</div>
		<div className='gridPackages-item-info'>{ `${ quantity }` }</div>
		<div className='gridPackages-item-info'>{ `${ warehouseLocationAgPacket }` }</div>
		<div className='gridPackages-item-info'>{ `${ warehouseLocationPacket }` }</div>
		<div className='gridPackages-item-info'>{ `${ driver }` }</div>
		<div className='gridPackages-item-info'>{ `${ supplier }` }</div>
		<div className='gridPackages-item-info'>{ `${ buyer }` }</div>
		<div className='gridPackages-item-info'>{ status ? 'Pakirano' : 'Nepakirano'}</div>
		<div className='gridPackages-item-info'>{ `${ packer }` }</div>
		<div className='gridPackages-item-info'>{ `${ date }` }</div>
		<div className='gridPackages-item-dots-row'><div id='dots-row' /></div>
	</div>

)


HubPackagesRow.propTypes = {
	packagesRow: propTypes.object.isRequired
}

export default HubPackagesRow
