import React from 'react'
import propTypes from 'prop-types'

const ReturnSendIcon = ( { color, fontSize } ) => (
	<span className='icon-return-send-icon' style={ { fontSize: `${ fontSize }px`, color } } />
)

ReturnSendIcon.defaultProps = {
	color: 'black',
	fontSize: 18
}
ReturnSendIcon.propTypes = {
	color: propTypes.string,
	fontSize: propTypes.number
}

export default ReturnSendIcon
