import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import gql from 'graphql-tag'
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks'

import './AddUser.css'
import ValidateEmailForm from './components/ValidateEmailForm'
import AddUserInfo from './components/AddUserInfo'
import { addNotification } from '../../../../utils/messageHandler/notifierStack'


const CHECK_MAIL = gql`
query User_fetch ( $email : String! ){
	User_fetch(input: { email: $email }) {
		id
		username
		firstName
		lastName
	}
}`

const GET_ALL_ROLES = gql`
query getAllRoles{
	Util_ClientAppRoles(input: {appId: "uhub"}){
		name
	}
}`

const ADD_NEW_USER = gql`
mutation Tenant_addAppAccount ( $input: AddTenantAppAccountInput ){
	Tenant_addAppAccount( input: $input )
  }`

const UPDATE_EXISTING_USER = gql`
mutation Tenant_addAppAccount ( $input: AddTenantAppAccountInput ){
	Tenant_addAppAccount( input: $input )
  }`


/* global sessionStorage */

const AddUser = ( { passText } ) => {
	const [ isEmailValidated, toggleEmailValidationForm ] = useState( false )
	const [ emailInvalidAlert, setEmailInvalidAlert ] = useState( false )
	const [ newMail, setNewMail ] = useState( '' )

	const [ editedText, setEditedText ] = useState( {} )
	const [ isUserNew, toggleIsUSerNew ] = useState( false )

	const [ newUser, setnewUser ] = useState( {} )
	const [ userRoles, setUserRoles ] = useState( { roles: [] } )
	const [ userAvilableRoles, setUserAvilableRoles ] = useState( [] )

	// eslint-disable-next-line no-useless-escape
	const regexEmail = new RegExp( /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'i' )
	const regexName = new RegExp( /^[A-Za-zČŠčšŽž-\s]+$/, 'i' )
	const regexUsername = new RegExp( /^([a-z0-9])+$/ )
	const regexPassword = new RegExp( /^.{8,}$/ )

	const tenantId = sessionStorage.getItem( 'uhub_tenantId' )

	const {
		data: rolesData
	} = useQuery( GET_ALL_ROLES )

	const [ addUserAccount ] = useMutation( ADD_NEW_USER, {
		onCompleted: ( data ) => { if ( data ) { addNotification( 'OK', 'USER_PROFILE_SETTINGS_UPDATED' ) } },
		onError: ( ( err ) => {
			if ( err ) { addNotification( 'ERROR', 'CREATE_ACCOUNT_FAILED' ) }
		} )
	} )

	const [ updateExistingUser ] = useMutation( UPDATE_EXISTING_USER, {
		onCompleted: ( ) => { addNotification( 'OK', 'USER_PROFILE_SETTINGS_UPDATED' ) },
		onError: ( ( err ) => {
			if ( err ) { addNotification( 'ERROR', 'CANT_UPDATE_EXISTING_USER' ) }
		} )
	} )

	// eslint-disable-next-line camelcase
	const testRoles = rolesData?.Util_ClientAppRoles.map( ( el ) => el.name )

	useEffect( () => {
		if ( Object.keys( editedText ).length > 0 ) {
			passText( editedText )
			setEditedText( {} )
		}
	}, [ editedText, passText ] )

	function getText( id, text ) {
		const objectCopy = editedText

		objectCopy[ id ] = text
		setEditedText( objectCopy )


		if ( id === 'email' ) {
			const isValid = regexEmail.test( text )
			if ( isValid && text !== '' ) {
				setNewMail( text )
			} else {
				setEmailInvalidAlert( true )
			}
		}
	}


	const [ loadUserData, { called, loading } ] = useLazyQuery( CHECK_MAIL, {
		variables: {
			email: newMail
		},
		fetchPolicy: 'network-only',
		onCompleted: ( newData ) => {
			if ( newData && newData.User_fetch.id !== null ) {
				// obstojeci user
				toggleEmailValidationForm( true )
				const dataAboutUser = newData.User_fetch
				toggleIsUSerNew( false )
				setnewUser( {
					username: dataAboutUser.username,
					firstName: dataAboutUser.firstName,
					lastName: dataAboutUser.lastName,
					id: dataAboutUser.id
				} )


				setUserAvilableRoles( testRoles )
			} else if ( newData ) {
				// novi user
				toggleEmailValidationForm( true )
				toggleIsUSerNew( true )
				setUserAvilableRoles( testRoles )
			}
		}
	} )


	if ( called && loading ) {
		console.log( 'Loading' )
	}


	function validateEmail() {
		if ( newMail === '' || !( regexEmail.test( newMail ) ) ) {
			setEmailInvalidAlert( true )
		} else {
			loadUserData()
		}
	}


	function onBackButton() {
		setnewUser( {
			username: '',
			firstName: '',
			lastName: '',
			id: ''
		} )
		setEmailInvalidAlert( false )
		setUserAvilableRoles( [] )
		setUserRoles( { roles: [] } )
		setNewMail( '' )
		setTimeout( () => toggleEmailValidationForm( false ), 600 )
	}


	function addRole( item ) {
		const indexAssigned = userRoles.roles.indexOf( item )
		const indexAvilable = userAvilableRoles.indexOf( item )
		if ( indexAssigned < 0 ) {
			userRoles.roles.push( item )
			userAvilableRoles.splice( indexAvilable, 1 )
		}
		setUserAvilableRoles( userAvilableRoles )
		setUserRoles( { roles: userRoles.roles } )
	}

	function deleteAssignedRole( item ) {
		const index = userRoles.roles.indexOf( item )
		if ( index > -1 ) {
			userRoles.roles.splice( index, 1 )
			userAvilableRoles.push( item )
		}
		setUserAvilableRoles( userAvilableRoles )
		setUserRoles( { roles: userRoles.roles } )
	}

	function onAddUserButtonClick() {
		if ( isUserNew === true ) {
			let keys = null
			let allValuesExist = true
			if ( !( 'username' in editedText ) || !( 'firstName' in editedText ) || !( 'lastName' in editedText )
			|| !( 'password' in editedText ) || !( 'passwordConfirm' in editedText ) || ( userRoles.roles.length < 1 ) ) {
				allValuesExist = false
			}

			if ( userRoles.roles.length < 1 ) {
				addNotification( 'ERROR', 'INVALID_INPUTS_MISSING_ROLES' )
			}


			if ( Object.keys( editedText ).length > 0 && allValuesExist ) {
				keys = Object.entries( editedText )
				let allValuesValid = true
				// eslint-disable-next-line no-restricted-syntax
				for ( const [ key, value ] of keys ) {
					if ( ( ( key === 'firstName' || key === 'lastName' ) && !regexName.test( value ) )
						|| ( key === 'username' && !regexUsername.test( value ) )
						|| ( ( key === 'password' || key === 'passwordConfirm' ) && !regexPassword.test( value ) ) ) {
						allValuesValid = false
						break
					}
				}

				let passwordMatch = true
				if ( editedText?.password.localeCompare( editedText?.passwordConfirm ) !== 0 ) {
					passwordMatch = false
					addNotification( 'ERROR', 'DIFFERENT_INPUT_PASSWORDS' )
				}

				if ( allValuesValid === true && allValuesExist === true && passwordMatch === true ) {
					addUserAccount( {
						variables: {
							input: {
								app: 'UHUB',
								tenant: tenantId,
								newUser:
						{
							confirmPassword: editedText.passwordConfirm,
							email: newMail,
							firstName: editedText.firstName,
							lastName: editedText.lastName,
							password: editedText.password,
							roles: userRoles.roles,
							username: editedText.username
						}
							}


						},
					} )
				} else { addNotification( 'ERROR', 'INVALID_INPUTS_USER_PROFILE_SETTINGS' ) }
			} else {
				addNotification( 'ERROR', 'INVALID_INPUTS_USER_PROFILE_SETTINGS' )
			}
		} else if ( isUserNew === false ) {
			if ( userRoles.roles.length > 0 ) {
				updateExistingUser( {
					variables: {
						input: {
							app: 'UHUB',
							tenant: tenantId,
							existingUser:
						{
							id: newUser?.id,
							roles: userRoles.roles
						}
						}
					},
				} )
			} else {
				addNotification( 'ERROR', 'INVALID_INPUTS_MISSING_ROLES' )
			}
		}
	}

	return (
		<>
			{
				isEmailValidated
					? (
						<AddUserInfo
							newEmail={ newMail }
							user={ newUser }
							getText={ getText }
							availableRoles={ userAvilableRoles }
							isUserNew={ isUserNew }
							backButton={ () => onBackButton() }
							addRole={ addRole }
							userAssignedRoles={ userRoles }
							deleteAssignedRole={ deleteAssignedRole }
							onAddUserButtonClick={ onAddUserButtonClick }
						/>
					)
					: (
						<ValidateEmailForm
							getText={ getText }
							validateEmail={ validateEmail }
							emailInvalidAlert={ emailInvalidAlert }
						/>
					)
			}
		</>
	)
}

AddUser.defaultProps = {
	passText: () => {},
}

AddUser.propTypes = {
	passText: propTypes.func,
}

export default AddUser
