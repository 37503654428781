import mainStore from '../../../stores/mainStore'

const { language } = mainStore

const regex = {
	description: /^[A-Za-zČŠčšŽž-]+$/i,
	quality: /^[1-9]+[0-9]*$/,
	// eslint-disable-next-line no-useless-escape
	price: language === 'sl' ? /^\d+(\,\d{1,2})?$/ : /^\d+(\.\d{1,2})?$/,
	// eslint-disable-next-line no-useless-escape
	quantity: language === 'sl' ? /^\d+(\,\d{1,2})?$/ : /^\d+(\.\d{1,2})?$/,
}

export default regex
