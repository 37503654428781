/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
// import ToggleButton from '../../_Globals/ToggleButton'

import './PaymentPage.css'


const HUB_ADDRESS = gql`
query hubAddress(
   $id: String!
){
   tenant(input: { id: $id }) {
	id
	applications {
		id
		data {
			address {
			address
			city
			country
			state
			zip
			}
		}
	}
   }
 }`


const WhereAreWeButton = React.memo( ( {
	customerPickup, hubTenantId
} ) => {
	const {
		data: hubAddressData,
	} = useQuery( HUB_ADDRESS, {
		variables: {
			id: hubTenantId
		},
		skip: hubTenantId === null,
		fetchPolicy: 'no-cache'
	} )

	const hubAddress = hubAddressData?.tenant?.applications[ 0 ]?.data?.address[ 0 ]?.address

	const hubCity = hubAddressData?.tenant?.applications[ 0 ]?.data?.address[ 0 ]?.city


	let addressOfHub = ''
	if ( hubAddress && hubCity ) {
		addressOfHub = hubAddress.replace( /\s/g, '+' )
	}


	return (
		<div className={ customerPickup ? 'where-are-we' : 'where-are-we-inactive' }>
			<a
				href={ `https://www.google.com/maps/search/?api=1&query=${ addressOfHub }+${ hubCity }` }
				target='_blank'
			>
				<Trans id='ShoppingCart.whereAreWe' />
			</a>
		</div>
	)
} )

WhereAreWeButton.propTypes = {
	customerPickup: propTypes.any.isRequired,
	hubTenantId: propTypes.string.isRequired
}

export default WhereAreWeButton
