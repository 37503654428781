import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

import './SettingsPage.css'
import { useStateValue } from '../../stores/contextStore/stateStore'
import ConditionalWrapper from '../../components/_Globals/ConditionalWrapper'
import roleHandler from '../../utils/ContentRoleHandler'
import AddUserSettings from './components/AddUser/index'
import UpdateUserSettings from './components/UpdateUser/index'
import UserProfileSettings from './components/UserProfile/UserProfileSettings'
import ProviderProfile from './components/UserProfile/ProviderProfile'
// import mainStore from '../../stores/mainStore'
import Loader from '../../components/Loader'

/* global sessionStorage */


const settings = {
}

const GET_USER_DATA_BY_TENANT = gql`
query getUserByKCId( $tenantId: String! ){
    tenant( input: { id: $tenantId } ) {
		id
        owner {
			keycloakId
			email
			firstName
			lastName
			username
		}
		applications {
			id
			data {
				address {
					address
					zip
					state
					country
					city
				}
			}
		}
    }
}`

const GET_PROVIDER_DATA = gql`
query getProviderDataByKCId( $tenantId: String! ) 
{
	provider_provider(where: {tenant_id: {_eq: $tenantId}}) {
	  id
	  tenant {
		id
		identifiers {
		  companyName
		  companyRegistrationNumber
		  taxId
		}
		applications {
		  id
		  data {
			mobile
			phone
			bankAccount {
			  BIC
			  IBAN
			  name
			}
		  }
		}
	  }
	}
  }
  
`


const SettingsPage = () => {
	const tenantId = sessionStorage.getItem( 'uhub_tenantId' )
	const [ myProfileMenu, toggleProfileMenu ] = useState( false )
	const [ addUserMenu, toggleAddUserMenu ] = useState( false )
	const [ updateUserMenu, toggleUpdateUserMenu ] = useState( false )
	const [ providerProfile, toggleProviderProfile ] = useState( false )
	const [ { isAuth } ] = useStateValue()

	const { data: userData, error: userError, loading: userLoading } = useQuery(
		GET_USER_DATA_BY_TENANT, { variables: { tenantId }, fetchPolicy: 'network-only' }
	)

	const { data: providerData, error: providerError, loading: providerLoading } = useQuery(
		GET_PROVIDER_DATA, { variables: { tenantId } }
	)

	if ( userLoading || providerLoading ) {
		return <Loader />
	}

	if ( userError || providerError ) {
		return (
			<div>
				{ JSON.stringify( userError ) }
			</div>
		)
	}

	return (
		<div id='settings-page'>
			<div className='settings-text'>
				<Trans id='SettingsPage.Title' />
			</div>
			<div className='settings-page-container'>
				<div className='setting-page-box'>
					<div
						className='settings-page-row'
						onKeyPress={ () => toggleProfileMenu( !myProfileMenu ) }
						onClick={ () => toggleProfileMenu( !myProfileMenu ) }
						tabIndex={ 0 }
						role='button'
					>
						<p className='row-title'><Trans id='SettingsPage.MyProfile' /></p>
						<div className={ myProfileMenu ? 'arrow-style-up' : 'arrow-style-down' } />
					</div>
					<div>
						{myProfileMenu ? (
							<UserProfileSettings
								deliveryAddresses={ userData.tenant.applications[ 0 ].data.address[ 0 ] }
								userData={ userData.tenant.owner }
							/>
						) : null}
					</div>
				</div>

				<ConditionalWrapper
					condition={ roleHandler( isAuth, [ 'owner' ] ) }
				>
					<>
						<div className='setting-page-box'>
							<div
								className='settings-page-row'
								onKeyPress={ () => toggleAddUserMenu( !addUserMenu ) }
								onClick={ () => toggleAddUserMenu( !addUserMenu ) }
								tabIndex={ 0 }
								role='button'
							>
								<p className='row-title'><Trans id='SettingsPage.AddUser' /></p>
								<div className={ addUserMenu ? 'arrow-style-up' : 'arrow-style-down' } />
							</div>
							<div>
								{addUserMenu ? (
									<AddUserSettings setting={ settings } key={ settings.id } /> ) : null}
							</div>
						</div>

						<div className='setting-page-box update-user-box'>
							<div
								className='settings-page-row'
								onKeyPress={ () => toggleUpdateUserMenu( !updateUserMenu ) }
								onClick={ () => toggleUpdateUserMenu( !updateUserMenu ) }
								tabIndex={ 0 }
								role='button'
							>
								<p className='row-title'><Trans id='SettingsPage.UpdateUser' /></p>
								<div className={ updateUserMenu ? 'arrow-style-up' : 'arrow-style-down' } />
							</div>
							<div>
								{updateUserMenu ? (
									<UpdateUserSettings setting={ settings } key={ settings.id } />
								) : null}
							</div>
						</div>
					</>
				</ConditionalWrapper>
				<ConditionalWrapper
					condition={ roleHandler( isAuth, [ 'provider' ] ) }
				>
					<>
						<div className='setting-page-box'>
							<div
								className='settings-page-row'
								onKeyPress={ () => toggleProviderProfile( !providerProfile ) }
								onClick={ () => toggleProviderProfile( !providerProfile ) }
								tabIndex={ 0 }
								role='button'
							>
								<p className='row-title'><Trans id='SettingsPage.ProfileProvider' /></p>
								<div className={ providerProfile ? 'arrow-style-up' : 'arrow-style-down' } />
							</div>
							<div>
								{providerProfile ? (
									<ProviderProfile
										providerData={ providerData.provider_provider[ 0 ] }
									/>
								) : null}
							</div>
						</div>
					</>
				</ConditionalWrapper>
			</div>
		</div>
	)
}

export default SettingsPage
