import React from 'react'
import propTypes from 'prop-types'

import './TableRowGS1.css'


const TableRowGS1 = ( {
	segments, family, segmentClass, brick
} ) => (
	<div>
		<div className='gs1-row-data'>
			<div className='gs1-item-info'>{ `${ segments }` }</div>
			<div className='gs1-item-info'>{ `${ family }` }</div>
			<div className='gs1-item-info'>{ `${ segmentClass }` }</div>
			<div className='gs1-item-info'>{ `${ brick }` }</div>
		</div>
		<div className='gs1-item-dots-row'>
			<div id='dots-row' />
		</div>
	</div>
)


TableRowGS1.propTypes = {
	segments: propTypes.string.isRequired,
	family: propTypes.string.isRequired,
	segmentClass: propTypes.string.isRequired,
	brick: propTypes.string.isRequired
}

export default TableRowGS1
