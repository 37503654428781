import React from 'react'
import propTypes from 'prop-types'

const LogoSmallIcon = ( { color, fontSize } ) => (
	<span className='icon-logo-small-icon-16' style={ { fontSize: `${ fontSize }px`, color } }>
		<span className='path1' />
		<span className='path2' />
		<span className='path3' />
		<span className='path4' />
		<span className='path5' />
	</span>
)

LogoSmallIcon.defaultProps = {
	color: 'black',
	fontSize: 18
}
LogoSmallIcon.propTypes = {
	color: propTypes.string,
	fontSize: propTypes.number
}

export default LogoSmallIcon
