import React, { useEffect, useState } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import propTypes from 'prop-types'
import ReactPlaceholder from 'react-placeholder'
import { useStateValue } from '../../../stores/contextStore/stateStore'
import 'react-placeholder/lib/reactPlaceholder.css'

const GET_PICTURES_URLS = gql`
	mutation createDownloadURL(
		$fileId: String!,
		$tenantId: String!,
	) {
		Storage_createDownloadURL(
			appId: "uhub", 
			fileId: $fileId, 
			tenantId: $tenantId
		) {
			url
		}
}`

const GET_PUBLIC_PICTURES_URLS = gql`
	mutation createPublicDownloadURL(
		$fileId: String!,
	) {
		Storage_createPublicDownloadURL(
			appId: "uhub", 
			fileId: $fileId, 
		) {
			url
		}
}`


const Image = React.memo( ( {
	fileId, tenantId, publicImage, galleries
} ) => {
	const [ , setFatalError ] = useStateValue()
	if ( fileId == null ) {
		return (
			<ReactPlaceholder
				showLoadingAnimation
				customPlaceholder={ <img style={ { width: 145, height: 145 } } color='#ececec' src='/img/no_default_picture.png' alt='' /> }
			>
				<img
					className='image-slider-image'
					style={ { width: 145, height: 145 } }
					alt=''
				/>
			</ReactPlaceholder>
		)
	}
	const [ urlPicture, getUrl ] = useState( '' )
	const [ getPicturesUrls, { error: getPicturesUrlsError } ] = useMutation( GET_PICTURES_URLS, {
		variables: { fileId, tenantId },
		onCompleted: ( data ) => {
			if ( data.Storage_createDownloadURL.url ) {
				getUrl( data.Storage_createDownloadURL.url )
			}
		}
	} )

	const [ getPublicPicturesUrls, {
		error: getPublicPicturesUrlsError
	} ] = useMutation( GET_PUBLIC_PICTURES_URLS, {
		variables: { fileId },
		onCompleted: ( data ) => {
			if ( data.Storage_createPublicDownloadURL.url ) {
				getUrl( data.Storage_createPublicDownloadURL.url )
			}
		}
	} )

	useEffect( () => {
		if ( fileId != null ) {
			if ( publicImage && galleries.length === 0 ) {
				getPublicPicturesUrls()
			} else {
				getPicturesUrls()
			}
		}
	}, [ getPicturesUrls, getPublicPicturesUrls, publicImage, galleries, fileId ] )

	if ( getPicturesUrlsError || getPublicPicturesUrlsError ) {
		setFatalError( { type: 'fatalError', fatalError: true } )
		return (
			<div>
				{ JSON.stringify( getPicturesUrlsError ) }
				{ JSON.stringify( getPublicPicturesUrlsError ) }
			</div>
		)
	}

	return (
		<ReactPlaceholder
			showLoadingAnimation
			ready={ !!urlPicture }
			customPlaceholder={ <img style={ { width: 145, height: 145 } } color='#ececec' src='/img/no_default_picture.png' alt='' /> }
		>
			<img
				className='image-slider-image'
				style={ { width: 145, height: 145 } }
				src={ urlPicture }
				alt=''
			/>
		</ReactPlaceholder>
	)
} )

Image.propTypes = {
	tenantId: propTypes.string.isRequired,
	fileId: propTypes.string.isRequired,
	publicImage: propTypes.bool.isRequired,
	galleries: propTypes.array.isRequired,
}

export default Image
