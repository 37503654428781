import React from 'react'
import { Trans } from '@lingui/macro'
import propTypes from 'prop-types'


import './ToggleButton.css'

const ToggleButton = React.memo( ( { isProviderClicked, handleToggleClick } ) => (
	<div className='btn-toggle'>
		<div
			id='btn-markets'
			className={ isProviderClicked ? 'btn-toggle-active' : null }
			role='button'
			tabIndex={ -1 }
			onKeyPress={ () => handleToggleClick( true ) }
			onClick={ () => handleToggleClick( true ) }
		>
			<Trans id='ToggleButton.markets' />
		</div>
		<div
			id='btn-products'
			className={ isProviderClicked ? null : 'btn-toggle-active' }
			role='button'
			tabIndex={ 0 }
			onKeyPress={ () => handleToggleClick( false ) }
			onClick={ () => handleToggleClick( false ) }
		>
			<Trans id='ToggleButton.products' />
		</div>
	</div>
) )

ToggleButton.propTypes = {
	isProviderClicked: propTypes.bool.isRequired,
	handleToggleClick: propTypes.func.isRequired
}

export default ToggleButton
