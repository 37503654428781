import React from 'react'
import propTypes from 'prop-types'

const NotificationIcon = ( { color, fontSize } ) => (
	<span className='icon-notifications-02' style={ { fontSize: `${ fontSize }px`, color } } />
)

NotificationIcon.defaultProps = {
	color: 'black',
	fontSize: 18
}
NotificationIcon.propTypes = {
	color: propTypes.string,
	fontSize: propTypes.number
}

export default NotificationIcon
