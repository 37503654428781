import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Trans } from '@lingui/macro'

import Loader from '../../components/Loader'
import MarketingBanner from '../../components/MarketingBanner'
import MyPurchasesHeader from './components/MyPurchasesHeader'
import CurrentOrdersHeader from './components/CurrentOrdersHeader'
import PreviousOrdersHeader from './components/PreviousOrdersHeader'
import MyPurchaseOrder from '../../components/MyPurchaseOrder'
import FooterBottom from '../../components/Footer/components/FooterBottom'

import './MyPurchasesPage.css'

/* global sessionStorage */

const GET_CURRENT_ORDERS = 	gql`
query getCurrentOrdersMyPurchases( 
	$customer_id: String!
){
	cart_orders( 
		where: { 
			customer_id: { _eq: $customer_id },
			status_id: { _in:[ 3, 4, 6, 7 ] }
		}
	) {
		id
		commission_fee
		hub {
			id
			name
			cycles {
				id
				closed_time
                finished
			}
		}
		status_id
		created_at
        updated_at
        total_bruto_price
        customer_pickup
		items {
			id
			quantity
			bruto_price
			unit {
				id
				unit
			}
			product {
				id
				gs1_brick {
					id
					translation_sl
				}
				variety {
					id
					name
				}
				created_at
			}
			market {
				id
				name
			}
		}
		orders_status_enum {
			value
		}
        qr_code_id
        qr_code {
			id
            base64_data
        }
	}
}`

const GET_CURRENT_EXPIRED_ORDERS = 	gql`
query getCurrentOrdersExpiredMyPurchases( 
	$customer_id: String!
){
	cart_orders( where: { customer_id: { _eq: $customer_id }, status_id: { _eq: 8 } } ) {
		id
		commission_fee
		hub {
			id
			name
			cycles {
				id
                finished
				closed_time
			}
		}
		status_id
		created_at
        updated_at
        total_bruto_price
        customer_pickup
		items {
			id
			quantity
			bruto_price
			unit {
				id
				unit
			}
			product {
				id
				gs1_brick {
					id
					translation_sl
				}
				variety {
					id
					name
				}
				created_at
			}
			market {
				id
				name
			}
		}
		orders_status_enum {
			id
			value
		}
		qr_code_id
        qr_code {
			id
            base64_data
        }
	}
}`

// PlaceHolders

const MyPurchasesPage = React.memo( () => {
	const customerId = sessionStorage.getItem( 'uhub_tenantId' )

	const {
		data: currentOrdersData,
		loading: currentOrdersLoading,
		error: currentOrdersError
	} = useQuery( GET_CURRENT_ORDERS, {
		variables: {
			customer_id: customerId,
		},
		fetchPolicy: 'no-cache',
	} )

	const {
		data: currentExpiredOrdersData,
		loading: currentExpiredOrdersLoading,
		error: currentExpiredOrdersError
	} = useQuery( GET_CURRENT_EXPIRED_ORDERS, {
		variables: {
			customer_id: customerId,
		},
		fetchPolicy: 'no-cache',
	} )

	const currentOrdersArr = currentOrdersData
		&& currentOrdersData.cart_orders
		? currentOrdersData.cart_orders : []

	const currentExpiredOrdersArr = currentExpiredOrdersData
		&& currentExpiredOrdersData.cart_orders
		? currentExpiredOrdersData.cart_orders : []

	return (
		<div id='my-purchases-page'>
			<MarketingBanner />

			<div id='my-purchases-container'>
				<MyPurchasesHeader />
				<div>
					{ currentOrdersData
						? <CurrentOrdersHeader numberOfOrders={ currentOrdersData.cart_orders.length } />
						: null }
					{ currentOrdersLoading ? <Loader /> : null }
					{ currentOrdersError ? (
						<div>
							{ JSON.stringify( currentOrdersError ) }
						</div>
					) : null }
					{ currentOrdersData
						? (
							<div id='current-orders-container' key={ 1 }>
								{currentOrdersArr.length > 0 ? currentOrdersArr.map( ( order ) => (
									<div key={ order.id }>
										<MyPurchaseOrder current order={ order } />
									</div>
								) ) : <Trans id='myPurchasePage.noOrders' /> }
							</div>
						) : null }
					<PreviousOrdersHeader />
					{ currentExpiredOrdersLoading ? <Loader /> : null }
					{ currentExpiredOrdersError ? JSON.stringify( currentExpiredOrdersError ) : null }
					{ currentExpiredOrdersData
						? (
							<div id='current-orders-container' key={ 2 }>
								{ currentExpiredOrdersArr.length > 0 ? currentExpiredOrdersArr.map( ( order ) => (
									<div key={ `${ order.id } expired` }>
										<MyPurchaseOrder current={ false } order={ order } />
									</div>
								) ) : <Trans id='myPurchasePage.noExpiredOrders' /> }
							</div>
						) : null }
				</div>
			</div>
			<FooterBottom />
		</div>
	)
} )

export default MyPurchasesPage
