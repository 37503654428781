import React, { useState } from 'react'
import PropTypes from 'prop-types'

import './EditArea.css'

// <EditArea id='test' getText={ textFromEdit } />

const EditArea = ( {
	id, text, editable, getText, isInput, myStyle, placeholder, regex
} ) => {
	const [ textAreaValue, setTextAreaValue ] = useState( '' )
	const [ isValid, toggleValid ] = useState( true )

	React.useEffect( () => {
		setTextAreaValue( text )
	}, [ text ] )

	function regexValidations( value ) {
		toggleValid( regex.test( value ) )
		setTextAreaValue( value )
	}

	function onChangeHandler( e ) {
		regexValidations( e.target.value )
	}

	function onBlurHandler() {
		getText( id, textAreaValue )
	}

	return isInput
		? (
			<input
				id={ id }
				className={
					`edit-area 
                    ${ editable ? 'edit-area-editable' : null } 
                    ${ myStyle } 
                    ${ isValid ? null : ' validation-failed' }`
				}
				disabled={ !editable }
				placeholder={ placeholder || '' }
				value={ textAreaValue || '' }
				onBlur={ () => onBlurHandler() }
				onChange={ ( e ) => onChangeHandler( e ) }
			/>
		)
		: (
			<textarea
				id={ id }
				className={
					`edit-area 
                    ${ editable ? 'edit-area-editable' : null } 
                    ${ myStyle }  
                    ${ isValid ? null : ' validation-failed' }`
				}
				disabled={ !editable }
				placeholder={ placeholder }
				value={ textAreaValue || '' }
				onBlur={ () => onBlurHandler() }
				onChange={ ( e ) => setTextAreaValue( e.target.value ) }
			/>
		)
}

EditArea.defaultProps = {
	placeholder: '',
	text: '',
	editable: false,
	isInput: false,
	myStyle: null,
	getText: () => {},
	regex: /(.*)/gi,
}

EditArea.propTypes = {
	id: PropTypes.string.isRequired,
	text: PropTypes.any,
	editable: PropTypes.bool,
	getText: PropTypes.func,
	isInput: PropTypes.bool,
	myStyle: PropTypes.string,
	placeholder: PropTypes.string,
	regex: PropTypes.any,
}

export default EditArea
