import React from 'react'
import { Trans } from '@lingui/macro'
import propTypes from 'prop-types'

import EditArea from '../../../../../components/_Globals/EditArea'


const ValidateEmailForm = ( {
	getText, emailInvalidAlert, validateEmail
} ) => {
	// const [ newEmail, setnewEmail ] = useState( '' )
	// const userProfileValues = {}

	// eslint-disable-next-line no-useless-escape
	const regexEmail = new RegExp( /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'i' )


	return (
		<div className='user-profile-container'>
			<div className='name-settings'>
				<Trans id='UserProfileSettings.Email' />
			</div>
			<EditArea
				id='email'
				isInput
				myStyle='hub-view-hub-title-editable edit-area-settings'
				getText={ getText }
				regex={ regexEmail }
				editable
			/>
			{
				emailInvalidAlert ? (
					<div className='invalid-mail-notification'>
						<Trans id='UserProfileSettings.InvalidMail' />
					</div>
				) : null
			}
			<div className='my-profile-button-container'>
				<button className='settings-button' type='button' onClick={ () => validateEmail() }>
					<p><Trans id='UserProfileSettings.Forward' /></p>
				</button>
			</div>
		</div>
	)
}

ValidateEmailForm.propTypes = {
	getText: propTypes.func.isRequired,
	validateEmail: propTypes.func.isRequired,
	emailInvalidAlert: propTypes.bool.isRequired,
	// setMailOK: propTypes.any.isRequired
}

export default ValidateEmailForm
