import React from 'react'
import './loader.css'

const Loader = () => (
	<div className='spinner'>
		<div className='bounce1' id='spinner' />
		<div className='bounce2' id='spinner' />
		<div className='bounce3' id='spinner' />
	</div>
)

export default Loader
