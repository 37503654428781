/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react'
import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

import './PaymentPage.css'
import './PriceListButton.css'


const HUB_PRICELISTS = gql`
query getHubPricelists(
	$hub_id: bigint!
){
	hub_pricelists( where: { hub_id: {_eq: $hub_id } } ) {
	  id
	}
}`


const HUB_PRICELISTS_DETAILS = gql`
query getHubPricelistsDetails(
	$pricelist_id: bigint!
){
	hub_pricelist_details(where: {service: {_eq: "DELIVERY"}, pricelist_id: {_eq: $pricelist_id}}, order_by: {range_from: asc}) {
      id
	  range_from
	  range_to
	  price_with_VAT
	  price_without_VAT
	  unit {
		id
		unit
	  }
	  currency
	}
}`


const PriceListButton = React.memo( ( {
	hubId
} ) => {
	const [ pricelistPopUp, showPricelistPopUp ] = useState( false )

	const { data: pricelistData } = useQuery( HUB_PRICELISTS,
		{
			variables: {
				hub_id: hubId
			}
		} )

	const pricelistId = pricelistData
	&& pricelistData.hub_pricelists
	&& pricelistData.hub_pricelists.length > 0
		? pricelistData.hub_pricelists[ 0 ].id : null

	const { data: pricelistDet } = useQuery( HUB_PRICELISTS_DETAILS,
		{
			variables: {
				pricelist_id: pricelistId
			},
			skip: pricelistId === null,
		} )

	return (
		<div>
			<div
				role='button'
				tabIndex={ -1 }
				onKeyPress={ () => showPricelistPopUp( !pricelistPopUp ) }
				onClick={ () => showPricelistPopUp( !pricelistPopUp ) }
			>

				<Trans id='ShoppingCart.deliveryPrices' />

			</div>
			{ pricelistPopUp
				? (
					<div className='pricelist-main-container-payment-page'>
						<div className='pricelist-window-payment-page'>
							<div className='hub-price-list-header-payment-page'>
								<div><Trans id='PriceList.unit' /></div>
								<div><Trans id='PriceList.from' /></div>
								<div><Trans id='PriceList.to' /></div>
								<div><Trans id='PriceList.price' /></div>
							</div>
							{ pricelistDet && pricelistDet.hub_pricelist_details
								? pricelistDet.hub_pricelist_details.map( ( priceListRow ) => (
									<div id='hub-price-list-row-data-payment-page' key={ priceListRow.id }>
										<div>{ `${ priceListRow.unit.unit }` }</div>
										<div>{ `${ priceListRow.range_from }` }</div>
										<div>{ `${ priceListRow.range_to === 2147483647 ? 'Max' : priceListRow.range_to }` }</div>
										<div>{ `${ priceListRow.price_with_VAT } €` }</div>
									</div>
								) ) : (
									<div id='hub-price-list-not-available'>
										<Trans id='ImageGallery.PriceListNotAvailable' />
									</div>
								)}
							<div
								role='button'
								id='close-price-list-payment-page'
								tabIndex={ -1 }
								onKeyPress={ () => showPricelistPopUp( !pricelistPopUp ) }
								onClick={ () => showPricelistPopUp( !pricelistPopUp ) }
							>

								<Trans id='ImageGallery.buttonClose' />

							</div>
						</div>
					</div>
				)
				: null}
		</div>
	)
} )

PriceListButton.propTypes = {
	hubId: propTypes.number.isRequired
}

export default PriceListButton
