import React from 'react'
import { Trans } from '@lingui/macro'

const fieldInputsTranslation = {
	name: <Trans id='RegistrationInput.name' />,
	surname: <Trans id='RegistrationInput.surname' />,
	username: <Trans id='RegistrationInput.username' />,
	phoneNum: <Trans id='RegistrationInput.phoneNum' />,
	mobileNum: <Trans id='RegistrationInput.mobileNum' />,
	email: <Trans id='RegistrationInput.email' />,
	taxId: <Trans id='RegistrationInput.taxId' />,
	companyRegistrationNumber: <Trans id='RegistrationInput.companyRegistrationNumber' />,
	companyName: <Trans id='RegistrationInput.companyName' />,
	postcode: <Trans id='RegistrationInput.postcode' />,
	address: <Trans id='RegistrationInput.address' />,
	city: <Trans id='RegistrationInput.city' />,
	country: <Trans id='RegistrationInput.country' />,
	Iban: <Trans id='RegistrationInput.Iban' />,
	Bic: <Trans id='RegistrationInput.Bic' />,
	bankName: <Trans id='RegistrationInput.bankName' />,
	password: <Trans id='RegistrationInput.password' />,
	confirmPassword: <Trans id='confirmPassword.confirmPassword' />
}

const fieldInputsErrors = {
	name: <Trans id='RegistrationInput.nameError' />,
	surname: <Trans id='RegistrationInput.surnameError' />,
	username: <Trans id='RegistrationInput.usernameError' />,
	phoneNum: <Trans id='RegistrationInput.phoneNumError' />,
	mobileNum: <Trans id='RegistrationInput.mobileNumError' />,
	email: <Trans id='RegistrationInput.emailError' />,
	taxId: <Trans id='RegistrationInput.taxIdError' />,
	companyRegistrationNumber: <Trans id='RegistrationInput.companyRegistrationNumberError' />,
	companyName: <Trans id='RegistrationInput.companyNameError' />,
	postcode: <Trans id='RegistrationInput.postcodeError' />,
	address: <Trans id='RegistrationInput.addressError' />,
	city: <Trans id='RegistrationInput.cityError' />,
	country: <Trans id='RegistrationInput.countryError' />,
	Iban: <Trans id='RegistrationInput.IbanError' />,
	Bic: <Trans id='RegistrationInput.BicError' />,
	bankName: <Trans id='RegistrationInput.bankNameError' />,
	password: <Trans id='RegistrationInput.passwordError' />,
	confirmPassword: <Trans id='confirmPassword.confirmPasswordError' />
}

const regex = {
	name: /^[A-Za-zČŠčšŽžĐđĆć-\s]+$/i,
	surname: /^[A-Za-zČŠčšŽžĐđĆć-\s]+$/i,
	// eslint-disable-next-line no-useless-escape
	email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	username: /^([a-z0-9])+$/,
	phoneNum: /^[+]*[0-9\-\s]+$/i,
	mobileNum: /^[+]*[0-9\-\s]+$/i,
	taxId: /^[0-9]{8,}$/,
	companyRegistrationNumber: /\d{10}/,
	companyName: /[A-Za-z0-9.\-\s,]+$/g,
	address: /^[0-9A-Za-zČŠčšŽžĐđĆć-\s,]+$/i,
	postcode: /^\d{4}$/g,
	city: /^[A-Za-z\s]+$/g,
	country: /^[A-Za-z\s]+$/g,
	// eslint-disable-next-line no-useless-escape
	Iban: /^[A-Z]{2}\d{2} ?\d{4} ?\d{4} ?\d{4} ?\ ?[\d]{0,3}$/,
	// eslint-disable-next-line no-useless-escape
	Bic: /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/,
	bankName: /[A-Za-z0-9.\-\s,.]+$/g,
	password: /^.{8,}$/,
	confirmPassword: /^.{8,}$/,
}

const fieldInputsExistingUserEmail = [
	'email',
]

const fieldInputsNewCompany = [
	'phoneNum',
	'mobileNum',
	'taxId',
	'companyRegistrationNumber',
	'companyName',
	'address',
	'postcode',
	'city',
	'country',
	'bankName',
	'Iban',
	'Bic',
]

const fieldInputs = [
	'name',
	'surname',
	'email',
	'username',
	'phoneNum',
	'mobileNum',
	'taxId',
	'companyRegistrationNumber',
	'companyName',
	'address',
	'postcode',
	'city',
	'country',
	'bankName',
	'Iban',
	'Bic',
	'password',
	'confirmPassword'
]

export {
	fieldInputsTranslation,
	fieldInputsErrors,
	regex,
	fieldInputsExistingUserEmail,
	fieldInputsNewCompany,
	fieldInputs
}
