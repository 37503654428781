import React, { useEffect } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import propTypes from 'prop-types'
import ReactPlaceholder from 'react-placeholder'
import { useStateValue } from '../../../stores/contextStore/stateStore'
import 'react-placeholder/lib/reactPlaceholder.css'

const GET_PICTURES_URLS = gql`
	mutation createDownloadURL(
		$fileId: String!,
		$tenantId: String!
	) {
		Storage_createDownloadURL(
			appId: "uhub", 
			fileId: $fileId, 
			tenantId: $tenantId
		) {
			url
		}
}`

const GET_PUBLIC_PICTURES_URLS = gql`
	mutation createPublicDownloadURL(
		$fileId: String!,
	) {
		Storage_createPublicDownloadURL(
			appId: "uhub", 
			fileId: $fileId, 
		) {
			url
		}
}`

const Image = React.memo( ( {
	fileId, tenantId, publicImage, galleries
} ) => {
	const [ , setFatalError ] = useStateValue()
	const [ getPicturesUrls, {
		data: pictureUrlData,
		error: getPicturesUrlsError
	} ] = useMutation( GET_PICTURES_URLS, {
		variables: { fileId, tenantId }
	} )

	const [ getPublicPicturesUrls, {
		data: publicPictureUrlData,
		error: getPublicPicturesUrlsError
	} ] = useMutation( GET_PUBLIC_PICTURES_URLS, {
		variables: { fileId }
	} )

	const url = tenantId && pictureUrlData
        && pictureUrlData.Storage_createDownloadURL
        && pictureUrlData.Storage_createDownloadURL.url
		? pictureUrlData.Storage_createDownloadURL.url : null

	const publicUrl = publicPictureUrlData
		&& publicPictureUrlData.Storage_createPublicDownloadURL
		&& publicPictureUrlData.Storage_createPublicDownloadURL.url
		? publicPictureUrlData.Storage_createPublicDownloadURL.url : null

	useEffect( () => {
		if ( publicImage && galleries.length === 0 ) {
			getPublicPicturesUrls()
		} else {
			getPicturesUrls()
		}
	}, [ galleries, getPicturesUrls, getPublicPicturesUrls, publicImage ] )

	if ( getPicturesUrlsError || getPublicPicturesUrlsError ) {
		setFatalError( { type: 'fatalError', fatalError: true } )
		return (
			<div>
				{ JSON.stringify( getPicturesUrlsError ) }
				{ JSON.stringify( getPublicPicturesUrlsError ) }
			</div>
		)
	}

	return (
		<ReactPlaceholder
			showLoadingAnimation
			ready={ !!url || !!publicUrl }
			customPlaceholder={ <img style={ { width: 145, height: 145 } } color='#ececec' src='/img/no_default_picture.png' alt='' /> }
		>
			<img
				alt='product'
				className='image-slider-image'
				src={ url || publicUrl }
			/>
		</ReactPlaceholder>

	)
} )
Image.defaultProps = {
	publicImage: null,
	galleries: null,
}

Image.propTypes = {
	tenantId: propTypes.string.isRequired,
	fileId: propTypes.string.isRequired,
	publicImage: propTypes.bool,
	galleries: propTypes.array,
}

export default Image
