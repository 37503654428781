/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { Trans } from '@lingui/macro'
import gql from 'graphql-tag'

import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks'

import './HubPriceList.css'

import HubPriceListRow from './components/HubPriceListRow'
import ConditionalWrapper from '../../_Globals/ConditionalWrapper'
import Loader from '../../Loader'

import { addNotification } from '../../../utils/messageHandler/notifierStack'
// import Dropdown from '../../_Globals/Dropdown'

// import PricelistDropdown from './PricelistDropdown'

/*	global sessionStorage */

const GET_HUB_ID_BY_TENANT = gql`
	query gethubIdByTenantIdPriceList(
		$tenant_id: String!,
	) {
		hub_hub( where: { tenant_id: { _eq: $tenant_id } } ) {
    		id
  		}
	}
`

const GET_CURRENT_PRICELIST_BY_TENANT_AND_HUB = gql`
	query getCurrentPricelistByTenantAndHub(
		$hub_id: bigint!
	) {
		hub_pricelists(
			where: { 
				hub_id: { _eq: $hub_id }, 
				
			},
			order_by: { valid_from: desc }
		) {
    		id
  		}
	}
`

const GET_CURRENT_PRICELIST_DETAILS = gql`
	query getCurrentPriceListDetails(
		$pricelist_id: bigint!
	) {
		hub_pricelist_details( where: { pricelist_id: { _eq: $pricelist_id }, service: { _eq: "DELIVERY" } }, order_by: {range_from: asc} ) {
			id
			service
			range_from
			range_to
			price_with_VAT
			price_without_VAT
			currency
			unit {
				id
				unit
			}
		}
	}
`

const DELETE_ROW_PRICELIST = gql`
	mutation deleteRowPricelist ($id: bigint!){
		delete_hub_pricelist_details(where: {id: {_eq: $id}}) {
			affected_rows
		}
	}
`
const INSERT_ROW_PRICELIST_DETAILS = gql`
mutation insertPricelistRow ( $price_with_VAT: float8!, $price_without_VAT: float8!, $range_from: Int!,  $range_to: Int!, $pricelist_id: bigint!){
	insert_hub_pricelist_details(objects: {price_with_VAT: $price_with_VAT, 
		range_from: $range_from, range_to: $range_to, service: "DELIVERY", 
		currency: "EUR", unit_id: 3, pricelist_id: $pricelist_id, price_without_VAT: $price_without_VAT}) {
	  affected_rows
	}
  }
`
const UPDATE_PRICELIST_DETAILS = gql`
mutation updatePricelistDetails( $id: bigint!, $price_with_VAT: float8!, $price_without_VAT: float8!, $range_from: Int!, $range_to: Int! ) {
	update_hub_pricelist_details(where: {id: {_eq: $id}}, _set: {price_with_VAT: $price_with_VAT, price_without_VAT: $price_without_VAT, range_from: $range_from, range_to: $range_to}){
		affected_rows
	}
  } 
`


const HubPriceList = () => {
	const [ windowWidth, setWidth ] = useState( window.innerWidth > 768 )
	const [ editable, toggleEditable ] = useState( false )

	const [ newPricelist, setNewPricelist ] = useState( [] )

	const [ deleteRowPricelist ] = useMutation( DELETE_ROW_PRICELIST )
	const [ insertRowPricelist ] = useMutation( INSERT_ROW_PRICELIST_DETAILS )
	const [ updatePriceListDetails ] = useMutation( UPDATE_PRICELIST_DETAILS )

	const regexPrice = new RegExp( /^([0-9.,])+$/ )
	const regexFromTo = new RegExp( /^([0-9])+$/ )

	useEffect( () => {
		const handleResize = () => {
			setWidth( window.innerWidth > 768 )
		}
		window.addEventListener( 'resize', handleResize )
		return () => { window.removeEventListener( 'resize', handleResize ) }
	}, [] )

	const tenantId = sessionStorage.getItem( 'uhub_tenantId' )

	const [ getPricelistDetails, {
		loading: pricelistDetailsLoading,
		error: pricelistDetailsError
	} ] = useLazyQuery( GET_CURRENT_PRICELIST_DETAILS, {
		onCompleted: ( data ) => {
			const priceListPlaceholder = []
			data.hub_pricelist_details.forEach( ( pricelistDetail ) => { // dodaj opt ch.
				priceListPlaceholder.push(
					{
						id: pricelistDetail.id,
						service: pricelistDetail.service,
						unit: pricelistDetail.unit.unit,
						from: pricelistDetail.range_from,
						to: pricelistDetail.range_to,
						price: pricelistDetail.price_with_VAT,
						currency: pricelistDetail.currency
					}
				)
			} )
			setNewPricelist( [ ...priceListPlaceholder ] )
		},
		fetchPolicy: 'cache-and-network'
	} )

	const [ getPricelistByHubAndTenant, {
		data: pricelistData,
		loading: pricelistLoading,
		error: pricelistError
	} ] = useLazyQuery( GET_CURRENT_PRICELIST_BY_TENANT_AND_HUB, {
		fetchPolicy: 'no-cache',
		onCompleted: ( data ) => {
			if ( data
				&& data.hub_pricelists.length > 0
				&& data.hub_pricelists[ 0 ].id ) {
				getPricelistDetails( {
					variables: {
						pricelist_id: data.hub_pricelists[ 0 ].id,
					},
					// fetchPolicy: 'no-cache',
					skip: data.hub_pricelists[ 0 ].id === null,
				} )
			}
		}
	} )


	const {
		loading: tenantLoading,
		error: tenantError
	} = useQuery( GET_HUB_ID_BY_TENANT, {
		variables: {
			tenant_id: tenantId
		},
		fetchPolicy: 'no-cache',
		onCompleted: ( data ) => {
			if ( data && data.hub_hub.length > 0 && data.hub_hub[ 0 ].id ) {
				getPricelistByHubAndTenant( {
					variables: {
						hub_id: data.hub_hub[ 0 ].id
					},
					skip: data.hub_hub[ 0 ].id === null,
					fetchPolicy: 'no-cache',
				} )
				// setHubId( data.hub_hub[ 0 ].id )
			}
		}
	} )


	const pricelistId = pricelistData
		&& pricelistData?.hub_pricelists
		&& pricelistData?.hub_pricelists[ 0 ]
		&& pricelistData?.hub_pricelists[ 0 ]?.id
		? pricelistData?.hub_pricelists[ 0 ]?.id : null


	if ( tenantLoading || pricelistLoading || pricelistDetailsLoading ) {
		return <Loader />
	}

	if ( tenantError || pricelistError || pricelistDetailsError ) {
		return (
			<div>
				{ JSON.stringify( tenantError ) }
				{ JSON.stringify( pricelistError ) }
				{ JSON.stringify( pricelistDetailsError ) }
			</div>
		)
	}


	function deleteObjectFromArr( item ) {
		deleteRowPricelist( {
			variables: {
				id: item.id

			},
			refetchQueries: [ 'getCurrentPriceListDetails' ]
		} )
		setNewPricelist( [ ...newPricelist ] )
		addNotification( 'ERROR', 'PRICELIST_ROW_DELETED' )
	}

	function checkInputPrice( input ) {
		let changedInput = ( input.toString() ).replace( /[^0-9.,]/g, '' )
		// replace all NON digits, dots and commas
		changedInput = changedInput.replace( /,/g, '.' )
		const firstOccuranceIndex = changedInput.search( /\./ ) + 1
		// Index of first occurance of (.)

		changedInput = changedInput.substr( 0, firstOccuranceIndex )
								+ changedInput.slice( firstOccuranceIndex ).replace( /\./g, '' )
		return changedInput
	}

	function checkOverlap( list ) {
		let isOverleap = false
		for ( let i = 0; i < list.length; i += 1 ) {
			for ( let x = 0; x < list.length; x += 1 ) {
				const tmp = !!( ( list[ i ] !== list[ x ] && ( ( list[ i ].to.toString() - list[ x ].from.toString() ) > 0 && ( list[ x ].to.toString() - list[ i ].from.toString() ) > 0 ) ) )

				if ( tmp === true ) {
					isOverleap = true
					return true
				}
			}
			if ( isOverleap === true ) {
				return true
			}
		}
		return false
	}

	function validateAllRanges( testi ) {
		// sort values from
		const nova = testi
		const list = nova.sort( ( a, b ) => ( ( a.from > b.from ) ? 1 : -1 ) )
		let regexValid = true
		for ( let i = 0; i < list.length; i += 1 ) {
			if ( !( regexFromTo.test( list[ i ].from ) && regexFromTo.test( list[ i ].to ) ) || !( regexPrice.test( list[ i ].price ) ) ) {
				regexValid = false
				break
			}

			if ( list[ i ].from < 0 || list[ i ].to < 1 || list[ i ].price < 0 ) {
				regexValid = false
				break
			}

			if ( list[ i ].from > list[ i ].to ) {
				regexValid = false
				break
			}

			const formatedPrice = checkInputPrice( list[ i ].price )
			list[ i ].price = Number( formatedPrice )
		}

		if ( regexValid === true ) {
			const isOverleapExist = checkOverlap( list )
			if ( isOverleapExist === false ) {
				const result = newPricelist.filter( ( obj ) => obj.id === 'newRow' )
				if ( result.length <= 0 ) {
					for ( let i = 0; i < list.length; i += 1 ) {
						const priceWithoutVat = ( Number( list[ i ].price ) / 1.22 ).toFixed( 2 )
						updatePriceListDetails( {
							variables: {
								id: list[ i ].id,
								range_from: list[ i ].from,
								range_to: list[ i ].to,
								price_with_VAT: Number( list[ i ].price ),
								price_without_VAT: priceWithoutVat,

							}
						} )
					}
					setNewPricelist( [ ...list ] )
					toggleEditable( !editable )
					addNotification( 'OK', 'PRICELIST_UPDATED' )
				} else {
					const priceWithoutVat = ( Number( result[ 0 ].price ) / 1.22 ).toFixed( 2 )
					insertRowPricelist( {
						variables: {
							pricelist_id: pricelistId,
							range_from: result[ 0 ].from,
							range_to: result[ 0 ].to,
							price_with_VAT: Number( result[ 0 ].price ),
							price_without_VAT: priceWithoutVat,

						}
					} )
					setNewPricelist( [ ...list ] )
					const tmp = list.map( ( a ) => ( a.id !== 'newRow' ? a.id : 0 ) )
					const maxId = Math.max( ...tmp ) + 1
					result[ 0 ].id = maxId
					addNotification( 'OK', 'PRICELIST_UPDATED_NEW_ROW' )
				}
				setNewPricelist( [ ...list ] )
				return true
			}
			addNotification( 'ERROR', 'INVALID_INPUTS_OVERLEAP_EXIST' )
			return false
		}
		addNotification( 'ERROR', 'PRICELIST_INVALID_INPUTS' )
		return false
	}


	function setNewPrice( value ) {
		setNewPricelist( value )
	}


	function myFunction( ) {
		let row = {}
		const fromNew = document.getElementById( 'fromNew' ).value
		const toNew = document.getElementById( 'toNew' ).value
		const priceNew = document.getElementById( 'priceNew' ).value
		row = {
			id: 'newRow', service: 'DELIVERY', unit: 'kg', from: fromNew, to: toNew, price: priceNew, currency: 'EUR'
		}


		if ( fromNew > toNew ) {
			addNotification( 'ERROR', 'PRICELIST_INVALID_INPUTS' )
		} else {
			const copyTable = newPricelist
			copyTable.push( row )
			const isNewRowOK = validateAllRanges( copyTable )
			if ( isNewRowOK === false ) {
				const index = copyTable.indexOf( row )
				copyTable.splice( index, 1 )
			}
		}
	}

	return (
		<ConditionalWrapper
			condition={ windowWidth }
			falseConditionComponent={ (
				<div className='warning'>
					<Trans id='CycleSectionPage.Warning' />
				</div>
			) }
		>
			<div>
				<p className='hub-price-list-title'>
					<Trans id='PriceList.priceList' />
					<span>{ ` ${ pricelistId }` }</span>
				</p>

				<div className='hub-price-list-header'>
					<div><Trans id='PriceList.service' /></div>
					<div><Trans id='PriceList.unit' /></div>
					<div><Trans id='PriceList.from' /></div>
					<div><Trans id='PriceList.to' /></div>
					<div><Trans id='PriceList.price' /></div>
				</div>
				<div className='all-pricelist-details-container'>
					{
						newPricelist.map( ( priceListRow ) => (
							<HubPriceListRow key={ priceListRow.id } priceListRow={ priceListRow } editable={ editable } newPricelistDetails={ newPricelist } setNewPricelistDetails={ setNewPrice } deleteObjectFromArr={ deleteObjectFromArr } />

						) )
					}
				</div>

				<div className='hub-price-list-buttons'>
					<button type='button' id='editingButton' label='button' className='hub-section-edit-button' onClick={ editable ? () => validateAllRanges( newPricelist ) : () => toggleEditable( !editable ) }>{ editable ? <Trans id='UserProfileSettings.SaveButton' /> : <Trans id='UserProfileSettings.EditButton' /> }</button>
				</div>
				<div className='hub-price-list-row-input'>
					<div><input className='hub-price-list-row-input-box' type='text' disabled placeholder='DOSTAVA' id='deliveryNew' /></div>
					<div><input className='hub-price-list-row-input-box' type='text' disabled placeholder='kg' id='kgNew' /></div>
					<div><input className='hub-price-list-row-input-box' type='text' placeholder='Vnesi količino-od' id='fromNew' /></div>
					<div><input className='hub-price-list-row-input-box' type='text' placeholder='Vnesi količino-do' id='toNew' /></div>
					<div className='price-input-pricelist'>
						<div><input className='hub-price-list-row-input-box' type='text' placeholder='Vnesi ceno' id='priceNew' /></div>
						<div><input className='hub-price-list-row-input-box' type='text' disabled placeholder='EUR' id='eurNew' /></div>
					</div>
				</div>
				<div className='hub-price-list-buttons'>
					<button type='button' id='addButton' label='button' className='hub-section-edit-button' onClick={ () => myFunction( ) }><Trans id='PriceList.add' /></button>
				</div>

			</div>
		</ConditionalWrapper>
	)
}

export default HubPriceList
