import store from '../stores/mainStore'

const roleHandler = ( isAuth, allowedRoles ) => {
	//  If user is not Authorized he can't see content
	if ( !isAuth ) {
		return false
	}

	//  get userRoles based on TenantId
	const { userRoles } = store

	//  Check if allowedRoles contains atlesat one element. And check if userRole got role
	if ( userRoles
		&& allowedRoles.length > 0
		&& userRoles.some( ( role ) => allowedRoles.includes( role ) ) ) {
		return true
	}

	return false
}

export default roleHandler
