import React from 'react'
import { Trans } from '@lingui/macro'
import propTypes from 'prop-types'

import './CycleDropdown.css'

const dropdownValues = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14 ]

const CycleDropdown = ( {
	selectedValue, showDropdown, toggleDropdown, setDropdownValue
} ) => (
	<div
		className='hub-view-cycle-dropdown'
		onClick={ () => toggleDropdown() }
		onKeyPress={ () => toggleDropdown() }
		role='button'
		tabIndex={ 0 }
	>
		<div className='hub-view-dropdown-value'>
			{ `${ selectedValue } ` }
			<Trans id='UploadPic.days' />
		</div>
		<div
			className='hub-view-cycle-dropdown-content'
			style={ showDropdown ? { display: 'block' } : { display: 'none' } }
		>
			{
				dropdownValues.map( ( val ) => (
					<div
						key={ val }
						role='button'
						tabIndex={ 0 }
						onClick={ () => setDropdownValue( val ) }
						onKeyPress={ () => setDropdownValue( val ) }
					>
						{ val }
					</div>
				) )
			}
		</div>
	</div>
)

CycleDropdown.propTypes = {
	selectedValue: propTypes.number.isRequired,
	showDropdown: propTypes.bool.isRequired,
	toggleDropdown: propTypes.func.isRequired,
	setDropdownValue: propTypes.func.isRequired
}

export default CycleDropdown
