import React from 'react'
import { Switch } from 'react-router-dom'

import HubPrivateRoutes from './HubPrivateRoute'
import HubSectionPage from '../../components/_Hub/HubSectionPage'
import CycleSectionPage from '../../components/_Hub/CycleSectionPage'
import HubWarehouse from '../../components/_Hub/HubWarehouse'
import HubPriceList from '../../components/_Hub/HubPriceList'
import HubPackages from '../../components/_Hub/HubPackages'
import OrdersSectionPage from '../../components/_Hub/OrdersSectionPage'
import Sla from '../../components/_Hub/SLA'
import ProvidersSectionPage from '../../components/_Hub/ProvidersSectionPage'
import GS1 from '../../components/_Hub/GS1'

const HubRoutes = () => (
	<Switch>
		<HubPrivateRoutes exact path='/hub/hub' component={ HubSectionPage } />
		<HubPrivateRoutes exact path='/hub/SLA' ownerOnly component={ Sla } />
		<HubPrivateRoutes exact path='/hub/providers' ownerOnly component={ ProvidersSectionPage } />
		<HubPrivateRoutes exact path='/hub/cycles' ownerOnly component={ CycleSectionPage } />
		<HubPrivateRoutes exact path='/hub/orders' component={ OrdersSectionPage } />
		<HubPrivateRoutes exact path='/hub/warehouse' component={ HubWarehouse } />
		<HubPrivateRoutes exact path='/hub/pricelist' ownerOnly component={ HubPriceList } />
		<HubPrivateRoutes exact path='/hub/packages' component={ HubPackages } />
		<HubPrivateRoutes exact path='/hub/gs1' ownerOnly component={ GS1 } />
	</Switch>
)

export default HubRoutes
