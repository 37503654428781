import React from 'react'
import propTypes from 'prop-types'

const LocationIcon = ( { color, fontSize } ) => (
	<span className='icon-location-icon-13' style={ { fontSize: `${ fontSize }px`, color } } />
)

LocationIcon.defaultProps = {
	color: 'black',
	fontSize: 18
}
LocationIcon.propTypes = {
	color: propTypes.string,
	fontSize: propTypes.number
}

export default LocationIcon
