import React from 'react'

import './ProfileMenu.css'
import ProfileHeader from './components/ProfileHeader'
import ProfileFooter from './components/ProfileFooter'
import ProfileBody from './components/ProfileBody'
import { useStateValue } from '../../stores/contextStore/stateStore'


/* global document */

const ProfileMenu = React.memo( () => {
	const [ { profileMenu, isAuth }, openProfileMenu ] = useStateValue()
	//	If user is not logged in - No need to render component.
	if ( !isAuth ) { return null }

	React.useEffect( () => {
		//  Check if side-menu-open exist -  If does close it.
		const handleCloseSideMenu = ( event ) => {
			if ( event.target.offsetParent && !event.target.offsetParent.classList.contains( 'side-menu-open' ) ) {
				openProfileMenu( { type: 'toggleProfilMenu' } )
			}
		}

		//  Set click listener event
		if ( profileMenu ) {
			document.addEventListener( 'click', handleCloseSideMenu )
		}

		//  Remove click listener event
		return () => {
			document.removeEventListener( 'click', handleCloseSideMenu )
		}
	} )

	return (
		<div id='side-menu' className={ profileMenu ? 'side-menu-open' : 'side-menu' }>
			<ProfileHeader />
			<ProfileBody />
			<ProfileFooter />
		</div>
	)
} )

export default ProfileMenu
