/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

const HeaderRegistration = React.memo( ( { history } ) => (
	<div id='header'>
		<div
			className='uhub-icon'
			role='button'
			tabIndex={ -1 }
			onKeyPress={ () => history.push( '/' ) }
			onClick={ () => history.push( '/' ) }
		>
			<p className='uhub-title'>Uhub</p>
		</div>
	</div>
) )

HeaderRegistration.propTypes = {
	history: propTypes.object.isRequired
}

export default withRouter( HeaderRegistration )
