import React, { createContext, useContext, useReducer } from 'react'
import propTypes from 'prop-types'

export const StateContext = createContext()

export const StateProvider = ( { reducer, initState, children } ) => (
	<StateContext.Provider value={ useReducer( reducer, initState ) }>
		{ children }
	</StateContext.Provider>
)

export const useStateValue = () => useContext( StateContext )

StateProvider.propTypes = {
	reducer: propTypes.func.isRequired,
	initState: propTypes.object.isRequired,
	children: propTypes.any.isRequired
}
