import gql from 'graphql-tag'

const GET_MOST_SELLING_PRODUCTS = gql`
query getMostSellingProducts( 
	$marketFilters: [ bigint! ],
	$gs1Filters:[ Int ,]
	$limit: Int!,
	$offset: Int,
){
    cart_most_orders(limit: $limit, offset: $offset, order_by: {cnt: desc}) {
		id
		tenant_id
		hub_id
		hub_name
		hub_description
		bruto_price
		quality
		quantity
		default_unit
		translation_sl
		product_id
		product_tenant_id
		market_id
		market_name
		provider_id
		provider_description
		default_unit
		variety_name
		unit
		public
		file_id
		gallery_tenant_id
		gallery_file_id
	}
}`

const GET_MOST_SELLING_PRODUCTS_SUBSCRIPTION = gql`
subscription getMostSellingProductsSubscription(
	$marketFilters: [ bigint! ],
	$gs1Filters:[ Int ,] 
	$limit: Int!,
){
    cart_most_orders(limit: $limit, order_by: {cnt: desc}) {
		id
		tenant_id
		hub_id
		hub_name
		hub_description
		bruto_price
		quality
		quantity
		default_unit
		translation_sl
		product_id
		product_tenant_id
		market_id
		market_name
		provider_id
		provider_description
		default_unit
		variety_name
		unit
		public
		file_id
		gallery_tenant_id
		gallery_file_id
	}
}`

const GET_ALL_DEFAULT_QUANTITIES = gql`
query {
  code_register_units_default_quantities {
	id
    unit_id
    quantity
  }
}`

export {
	GET_MOST_SELLING_PRODUCTS,
	GET_MOST_SELLING_PRODUCTS_SUBSCRIPTION,
	GET_ALL_DEFAULT_QUANTITIES
}
