import React, { useState } from 'react'
import propTypes from 'prop-types'
import { Trans } from '@lingui/macro'
import { withRouter } from 'react-router-dom'

import './HubPage.css'
import { ReturnSendIcon } from '../../icons'
import roleHandler from '../../utils/ContentRoleHandler'
import HubRoutes from '../../routes/HubRoutes/HubRoutes'
import { hubPageTabs, hubPageTranslations } from '../Tabs'
import UniversalTabs from '../../components/_Globals/UniversalTabs'
import { useStateValue } from '../../stores/contextStore/stateStore'
import FooterBottom from '../../components/Footer/components/FooterBottom'

const HubPage = React.memo( ( { history } ) => {
	const [ { isAuth } ] = useStateValue()
	const [ currentTab, setTab ] = useState( 0 )
	const [ pageTitle, setPageTitle ] = useState( <Trans id='MyOffers::titleHub' /> )

	if ( !roleHandler( isAuth, [ 'owner', 'employee' ] ) ) {
		return null
	}

	return (
		<div
			id='hub-page'
		>
			<div className='picture-banner-hub' />
			<div id='hub-page-title-container'>
				<span className='hub-page-title'>{ pageTitle }</span>
				<div
					role='button'
					className='back-to-store'
					tabIndex={ -1 }
					onKeyPress={ () => history.push( '/' ) }
					onClick={ () => history.push( '/' ) }
				>
					<span><ReturnSendIcon fontSize={ 14 } /></span>
					<span className='text'><Trans id='ShoppingCart.backToStore' /></span>
				</div>
			</div>
			<div id='hub-page-navigation-menu'>
				<UniversalTabs
					isAuth={ isAuth }
					tabs={ hubPageTabs }
					currentTab={ currentTab }
					translations={ hubPageTranslations }
					action={ setTab }
					setPageTitle={ setPageTitle }
				/>
			</div>
			<div id='hub-page-content-container'>
				<HubRoutes />
			</div>
			<FooterBottom />
		</div>
	)
} )

HubPage.propTypes = {
	history: propTypes.object.isRequired
}

export default withRouter( HubPage )
