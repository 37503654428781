/* eslint-disable no-console */
import 'unfetch/polyfill'
import ApolloClient from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { HttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { getMainDefinition } from 'apollo-utilities'

import mainStore from '../stores/mainStore'
import { addNotification } from './messageHandler/notifierStack'
import { defaults, resolvers } from '../stores/apollo/apolloStoreConfiguration'

/*	global sessionStorage */

const authMiddleware = new ApolloLink( ( operation, forward ) => {
	operation.setContext( {
		headers: {
			Authorization: sessionStorage.getItem( 'uhub_idToken' ) || false,
			tenantid: sessionStorage.getItem( 'uhub_tenantId' ) || false,
			appid: 'uhub',
			language: mainStore.language || 'en'
		}
	} )

	return forward( operation )
} )

const httpLink = new HttpLink( {
	uri: process.env.REACT_APP_APOLLO_URL
} )

// TODO: In some weird scenario idToken never gets set to the token of the keycloak user

const wsLink = new WebSocketLink( {
	uri: process.env.REACT_APP_APOLLO_SUBSCRIPTION_URL,
	options: {
		reconnect: true,
		connectionParams() {
			return {
				headers: {
					Authorization: sessionStorage.getItem( 'uhub_idToken' ) || 'false',
					tenantid: sessionStorage.getItem( 'uhub_tenantId' ) || 'false',
					appid: 'uhub',
					language: mainStore.language || 'en'
				}
			}
		}
	}
} )

const link = ApolloLink.split(
	( { query } ) => {
		const { kind, operation } = getMainDefinition( query )
		return kind === 'OperationDefinition' && operation === 'subscription'
	},
	wsLink,
	httpLink
)

const cache = new InMemoryCache()

// Configuration for Apollo Client
const client = new ApolloClient( {
	link: ApolloLink.from( [
		onError( ( { graphQLErrors, networkError } ) => {
			if ( graphQLErrors ) {
				graphQLErrors.forEach( ( error ) => {
					if ( error?.extensions?.code === 'access-denied' ) {
						// Do nothing ATM
					} else {
						console.log( 'GraphQLError from apollo.js - ', error )
						console.log( 'GraphQLError from apollo.js - ', JSON.stringify( error ) )
						addNotification( 'ERROR', error?.extensions?.code?.code )
					}
				} )
			}
			if ( networkError ) {
				if ( networkError?.extensions?.code === 'start-failed' ) {
					sessionStorage.clear()
					if ( mainStore.keycloak.authenticated ) {
						window.location.href = mainStore.keycloak.createLogoutUrl()
					}
					addNotification( 'ERROR', 'PLEASE_RE_AUTH' )
				} else {
					console.log( 'NetworkError from apollo.js - ', networkError )
					addNotification( 'ERROR', networkError )
				}
			}
		} ),
		authMiddleware,
		link
	] ),
	cache,
	resolvers
} )

// Write default values into store
cache.writeData( {
	data: defaults
} )

client.onResetStore( () => cache.writeData( { data: defaults } ) )

export default client
